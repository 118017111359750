import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import debounce from 'lodash.debounce';
import { removeExtraSpaces } from 'utils/helpers';
import { SEARCHBAR_DEBOUNCE_DELAY } from './SearchBar.const';
import { SearchBarProps } from './SearchBar.types';

export const SearchBar = ({ defaultValue = '', onChange }: SearchBarProps) => {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    if (!defaultValue) {
      setValue('');
    }
  }, [defaultValue]);

  const debounceFn = useMemo(
    () => debounce(onChange, SEARCHBAR_DEBOUNCE_DELAY),
    [onChange],
  );

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    debounceFn(removeExtraSpaces(event.target.value));
  };

  return (
    <TextField
      value={value}
      autoFocus
      variant="outlined"
      size="small"
      placeholder="search"
      onChange={handleChange}
      InputProps={{
        startAdornment: <SearchIcon style={{ marginRight: '10px' }} />,
      }}
    />
  );
};
