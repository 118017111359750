import { useCallback, useMemo } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { AccordionSummary } from '@material-ui/core';
import Brain from 'assets/brain.svg';
import Hammer from 'assets/hammer.svg';
import { ModuleSummary } from 'components/ModuleSummary';
import { AddRecruitmentQuestion } from '../AddRecruitmentQuestion';
import { RatableQuestion } from '../RatableQuestion';
import { AssessedQuestions, RecruitmentModuleProps } from './RecruitmentModule.types';
import * as S from './RecruitmentModule.css';

export const RecruitmentModule = ({
  recruitmentModule,
  expanded,
  openNextModule,
  setCurrentModule,
  setLoadingRecruitment,
  deleteRecruitmentQuestion,
  reloadRecruitmentQuestion,
  dispatch,
  questions,
  questionsForNumbers,
  setExpandedQuestionIdx,
  expandedQuestionIdx,
  modules,
}: RecruitmentModuleProps) => {
  const assessedQuestions = useMemo(
    () =>
      ({
        questions: questionsForNumbers.reduce((acc, question) => {
          if (question.grade !== null && !question.question.isPractical) {
            return acc + 1;
          }
          return acc;
        }, 0),
        tasks: questionsForNumbers.reduce((acc, question) => {
          if (question.grade !== null && question.question.isPractical) {
            return acc + 1;
          }
          return acc;
        }, 0),
      } as AssessedQuestions),
    [questionsForNumbers],
  );

  const handleNextQuestion = useCallback(() => {
    const isLast =
      expandedQuestionIdx + 1 === recruitmentModule.recruitmentQuestions.length;
    return isLast ? openNextModule() : setExpandedQuestionIdx((prev) => prev + 1);
  }, [
    expandedQuestionIdx,
    openNextModule,
    recruitmentModule.recruitmentQuestions.length,
    setExpandedQuestionIdx,
  ]);

  const handleOpenQuestion = useCallback(
    (id: number, next: boolean) =>
      next ? handleNextQuestion() : setExpandedQuestionIdx(id),
    [handleNextQuestion, setExpandedQuestionIdx],
  );

  const handleOpenModule = () => {
    if (expanded) {
      setCurrentModule(undefined);
    } else {
      setCurrentModule(recruitmentModule.id);
      setExpandedQuestionIdx(
        questionsForNumbers.findIndex(({ grade }) => grade === null),
      );
    }
  };

  const questionNumber = useMemo(
    () =>
      recruitmentModule.recruitmentQuestions.filter(
        ({ question }) => !question.isPractical,
      ).length,
    [recruitmentModule.recruitmentQuestions],
  );

  const taskNumber = useMemo(
    () =>
      recruitmentModule.recruitmentQuestions.filter(
        ({ question }) => question.isPractical,
      ).length,
    [recruitmentModule.recruitmentQuestions],
  );

  const allQuestionsReviewed = useMemo(
    () =>
      assessedQuestions.questions ===
      recruitmentModule.recruitmentQuestions.filter(
        ({ question }) => !question.isPractical,
      ).length,
    [assessedQuestions.questions, recruitmentModule.recruitmentQuestions],
  );

  const allTasksReviewed = useMemo(
    () =>
      assessedQuestions.tasks ===
      recruitmentModule.recruitmentQuestions.filter(
        ({ question }) => question.isPractical,
      ).length,
    [assessedQuestions.tasks, recruitmentModule.recruitmentQuestions],
  );

  const draggableQuestions = useMemo(
    () =>
      questions.map((question, index) => (
        <Draggable key={question.id} draggableId={String(question.id)} index={index}>
          {(providedDraggable) => (
            <div
              ref={providedDraggable.innerRef}
              {...providedDraggable.draggableProps}
              {...providedDraggable.dragHandleProps}
            >
              <RatableQuestion
                index={index}
                openQuestion={handleOpenQuestion}
                question={question}
                expanded={expandedQuestionIdx === index && expanded}
                key={question.id}
                moduleId={recruitmentModule.moduleId}
                setLoadingRecruitment={setLoadingRecruitment}
                setExpandedQuestionIdx={setExpandedQuestionIdx}
                deleteQuestion={deleteRecruitmentQuestion}
                recruitmentQuestionIds={[...questions.map((x) => x.questionId)]}
                reloadRecruitmentQuestion={reloadRecruitmentQuestion}
                dispatch={dispatch}
              />
            </div>
          )}
        </Draggable>
      )),
    [
      deleteRecruitmentQuestion,
      expanded,
      expandedQuestionIdx,
      handleOpenQuestion,
      questions,
      recruitmentModule.moduleId,
      reloadRecruitmentQuestion,
      setLoadingRecruitment,
      setExpandedQuestionIdx,
      dispatch,
    ],
  );

  return (
    <S.ModuleAccordion
      key={recruitmentModule.id}
      expanded={expanded}
      onChange={handleOpenModule}
    >
      <AccordionSummary>
        <S.ModuleTitle $isYellow>{recruitmentModule.module.name}</S.ModuleTitle>
        <S.Icons>
          <S.Icon src={Brain} alt="brain" />
          <S.IconInfo>
            <S.ReviewedQuestions $reviewed={allQuestionsReviewed}>
              {assessedQuestions.questions}
            </S.ReviewedQuestions>
            /{questionNumber} questions
          </S.IconInfo>
          <S.Icon src={Hammer} alt="hammer" />
          <S.IconInfo>
            <S.ReviewedQuestions $reviewed={allTasksReviewed}>
              {assessedQuestions.tasks}
            </S.ReviewedQuestions>
            /{taskNumber} tasks
          </S.IconInfo>
        </S.Icons>
      </AccordionSummary>
      <S.ModuleAccordionDetails>
        {expanded && (
          <Droppable
            droppableId="recruitmentQuestions"
            direction="vertical"
            type={`recruitmentQuestions ${recruitmentModule.id}`}
          >
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {draggableQuestions}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        )}
        <AddRecruitmentQuestion
          recruitmentModule={recruitmentModule}
          questions={questions}
          modules={modules}
          dispatch={dispatch}
        />
        <ModuleSummary
          inProgress
          taskNumber={taskNumber}
          questionNumber={questionNumber}
          defaultValue={recruitmentModule.note || ''}
          recruitmentModuleId={recruitmentModule.id}
          id={recruitmentModule.moduleId}
          moduleDescription={recruitmentModule.module.description || ''}
        />
      </S.ModuleAccordionDetails>
    </S.ModuleAccordion>
  );
};
