import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { DropZoneProps } from './DropZone.types';

const DropZone = ({
  handleOnDragEnd,
  elements,
  droppableId,
  direction = 'vertical',
  type,
}: DropZoneProps) => (
  <DragDropContext onDragEnd={handleOnDragEnd}>
    <Droppable droppableId={droppableId} direction={direction} type={type}>
      {(provided) => (
        <div {...provided.droppableProps} ref={provided.innerRef}>
          {elements}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  </DragDropContext>
);

export default DropZone;
