import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, TextField } from '@material-ui/core';
import { RecruitmentService } from 'api';
import { Button } from 'styles.global';
import Autocomplete from 'components/Autocomplete';
import { useQueryParams } from 'utils/hooks';
import { Filters } from '../FiltersDrawer';
import { TemplateFiltersProps } from './TemplateFilters.types';
import * as S from '../FiltersDrawer/Filters.css';

export const TemplateFilters = ({ redirectTo }: TemplateFiltersProps) => {
  const { push } = useHistory();

  const queryParams = useQueryParams();

  const [filters, setFilters] = useState({
    name: queryParams.name || '',
    position: queryParams.position || '',
    author: queryParams.author || '',
  });

  const [prevFilters] = useState({
    ...filters,
  });

  const [isOpen, setOpen] = useState(false);

  const clearFilters = () => {
    setFilters({
      position: '',
      name: '',
      author: '',
    });
  };

  const handleClose = () => {
    // setTimeout so there is better visual effect when closing filter
    setTimeout(() => {
      setFilters(prevFilters);
    }, 100);
  };

  const handleSubmit = () => {
    const { position, name, author } = filters;
    const { search, perPage } = queryParams;

    push({
      pathname: redirectTo,
      search: `?${new URLSearchParams({
        ...(search && { search }),
        ...(perPage && perPage !== '10' && { perPage }),
        ...(name && { name }),
        ...(position && { position }),
        ...(author && { author }),
      })}`,
    });
  };

  const handleNameChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
    setFilters({ ...filters, name: value });

  const handleAuthorChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
    setFilters({ ...filters, author: value });

  const handlePositionAutocompleted = (event: ChangeEvent<{}>, value: string | null) =>
    setFilters({ ...filters, position: value || '' });

  const handlePositionChange = (e: ChangeEvent<HTMLInputElement>) =>
    setFilters({ ...filters, position: e.target.value || '' });

  const { positionsData, getPositionsData } = RecruitmentService.useGetPositions();

  const positions = useMemo(
    () => positionsData.map(({ positionName }) => positionName),
    [positionsData],
  );

  useEffect(() => {
    if (isOpen) {
      getPositionsData();
    }
  }, [getPositionsData, isOpen]);

  return (
    <Filters
      onClose={handleClose}
      onSubmit={handleSubmit}
      isOpen={isOpen}
      setOpen={setOpen}
    >
      <>
        <S.Label>Name</S.Label>
        <TextField
          variant="outlined"
          size="small"
          placeholder="Type to filter"
          value={filters.name}
          onChange={handleNameChange}
        />
        <S.Label>Position</S.Label>
        <Autocomplete
          disabled={!positions.length}
          onChange={handlePositionAutocompleted}
          textFieldChange={handlePositionChange}
          value={filters.position}
          selectOptions={[filters.position, ...positions]}
          size="small"
          placeholder="Select to filter"
        />
        <S.Label>Author</S.Label>
        <TextField
          variant="outlined"
          size="small"
          placeholder="Type to filter"
          value={filters.author}
          onChange={handleAuthorChange}
        />
        <Box marginLeft="auto" paddingTop="30px">
          <Button $primary onClick={clearFilters}>
            Clear
          </Button>
        </Box>
      </>
    </Filters>
  );
};
