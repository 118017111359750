import { ChangeEvent } from 'react';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { POSITION_LIMIT } from '../const';
import { TemplatePositionAutocompleteProps } from './TemplatePositionAutocomplete.types';

export const TemplatePositionAutocomplete = ({
  position,
  positions,
  setPosition,
  isError,
  fullWidth = false,
}: TemplatePositionAutocompleteProps) => {
  function handlePositionChange(e: ChangeEvent<{}>, value: string | null) {
    setPosition(value || '');
  }

  return (
    <Autocomplete
      freeSolo
      options={positions}
      getOptionSelected={(option, value) => option === value}
      onChange={handlePositionChange}
      value={position}
      renderInput={(params) => (
        <TextField
          {...params}
          style={!fullWidth ? { width: '300px' } : {}}
          fullWidth={fullWidth}
          placeholder="Enter or select position"
          onChange={(e) => setPosition(e.target.value)}
          error={isError}
          helperText={isError && `Limit of characters is ${POSITION_LIMIT}`}
          variant="outlined"
          size="small"
        />
      )}
    />
  );
};
