import { Draggable } from 'react-beautiful-dnd';
import { RecruitmentModule } from '../components/RecruitmentModule';
import { StartRecruitmentModule } from '../components/StartRecruitmentModule';
import {
  DraggableRecruitmentModules,
  DraggableStartModules,
  HandleOnDragEndRecruitmentStateProps,
  HandleOnDragEndStartStateProps,
} from './draggableUtils.types';

export const draggableStartModules = ({
  modulesWithSortedQuestions,
  setCurrentModule,
  currentModule,
  questions,
  reloadQuestion,
  deleteQuestion,
  handleRedrawAll,
  dispatch,
}: DraggableStartModules) =>
  modulesWithSortedQuestions.map((module, index) => (
    <Draggable key={module.id} draggableId={`start-module-${module.id}`} index={index}>
      {(providedDraggable) => (
        <div
          ref={providedDraggable.innerRef}
          {...providedDraggable.draggableProps}
          {...providedDraggable.dragHandleProps}
          style={{ ...providedDraggable.draggableProps.style, marginBottom: '30px' }}
        >
          <StartRecruitmentModule
            setCurrentModule={setCurrentModule}
            expanded={currentModule === module.id}
            key={module.id}
            module={module}
            questions={questions}
            onReloadQuestion={reloadQuestion}
            onRedrawAll={handleRedrawAll}
            onDeleteQuestion={deleteQuestion}
          />
        </div>
      )}
    </Draggable>
  ));

export const draggableRecruitmentModules = ({
  recruitmentModules,
  setCurrentModule,
  currentModule,
  setExpandedQuestionIdx,
  expandedQuestionIdx,
  recruitmentQuestions,
  deleteRecruitmentQuestion,
  openNextModule,
  setLoadingRecruitment,
  reloadRecruitmentQuestion,
  dispatch,
  modulesData,
}: DraggableRecruitmentModules) =>
  recruitmentModules.map((module, index) => (
    <Draggable
      key={module.id}
      draggableId={`recruitment-module-${module.id}`}
      index={index}
    >
      {(providedDraggable) => (
        <div
          ref={providedDraggable.innerRef}
          {...providedDraggable.draggableProps}
          {...providedDraggable.dragHandleProps}
          style={{ ...providedDraggable.draggableProps.style, marginBottom: '30px' }}
        >
          <RecruitmentModule
            setExpandedQuestionIdx={setExpandedQuestionIdx}
            expandedQuestionIdx={expandedQuestionIdx}
            key={index}
            questionsForNumbers={module.recruitmentQuestions}
            questions={recruitmentQuestions}
            deleteRecruitmentQuestion={deleteRecruitmentQuestion(module.id)}
            openNextModule={openNextModule}
            setCurrentModule={setCurrentModule}
            expanded={module.id === currentModule}
            recruitmentModule={module}
            setLoadingRecruitment={setLoadingRecruitment}
            reloadRecruitmentQuestion={reloadRecruitmentQuestion}
            dispatch={dispatch}
            modules={modulesData}
          />
        </div>
      )}
    </Draggable>
  ));

export const handleOnDragEndStartState = ({
  modulesWithSortedQuestions,
  dispatch,
  result,
  sortRecruitmentModules,
  setQuestions,
}: HandleOnDragEndStartStateProps) => {
  if (!result.destination) return;
  if (result.type === 'startRecruitmentModules') {
    const previousItems = Array.from(modulesWithSortedQuestions);
    const [previousReorderedItem] = previousItems.splice(result.source.index, 1);
    previousItems.splice(result.destination.index, 0, previousReorderedItem);
    dispatch({
      type: 'setSortedModules',
      sortedModules: previousItems,
    });
    sortRecruitmentModules({
      data: {
        ids_list: previousItems.map((m) => m.id),
      },
    });
    return;
  }
  const moduleId = Number(result.type.split(' ')[1]);
  const module = modulesWithSortedQuestions.find((m) => m.id === moduleId)!;
  const items = Array.from(module.questions);
  const [reorderedItem] = items.splice(result.source.index, 1);
  items.splice(result.destination.index, 0, reorderedItem);

  dispatch({
    type: 'setModuleQuestions',
    questions: items,
    moduleId: module.id,
  });
  setQuestions(items);
};

export const handleOnDragEndRecruitmentState = ({
  result,
  dispatch,
  sortRecruitmentModules,
  recruitmentModules,
  recruitmentQuestions,
  expandedQuestionIdx,
  setExpandedQuestionIdx,
  setRecruitmentQuestions,
  sortRecruitmentQuestions,
}: HandleOnDragEndRecruitmentStateProps) => {
  if (!result.destination) return;
  if (result.type === 'recruitmentModules') {
    const previousItems = Array.from(recruitmentModules);
    const [previousReorderedItem] = previousItems.splice(result.source.index, 1);
    previousItems.splice(result.destination.index, 0, previousReorderedItem);
    dispatch({
      type: 'setRecruitmentModules',
      recruitmentModules: previousItems,
    });
    sortRecruitmentModules({
      data: {
        ids_list: previousItems.map((m) => m.id),
      },
    });
    return;
  }

  const items = Array.from(recruitmentQuestions);

  const expandedQuestion = items.find((_, idx) => idx === expandedQuestionIdx);
  const [reorderedItem] = items.splice(result.source.index, 1);
  items.splice(result.destination.index, 0, reorderedItem);
  sortRecruitmentQuestions({
    data: {
      ids_list: items.map((q) => q.id),
    },
  });
  dispatch({
    type: 'setRecruitmentQuestions',
    recruitmentQuestions: items,
  });
  setExpandedQuestionIdx(items.findIndex((q) => q.id === expandedQuestion?.id));
  setRecruitmentQuestions(items);
};
