import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { SingleValue } from 'react-select';
import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { RecruitmentService } from 'api';
import debounce from 'lodash.debounce';
import RecruitmentReview from 'pages/EndedRecruitment/components/RecruiterReview';
import { Button } from 'styles.global';
import { HireState, RecruitmentState } from 'types/recruitment';
import { handleException } from 'utils/errorHandlingUtils';
import { percentageCalculator } from 'utils/helpers';
import { useRedirect } from 'utils/hooks';
import { RouteParams } from 'utils/types/RouteParams';
import { UPDATE_OPINION_DELAY } from './constants';
import { Props } from './Summary.types';
import * as S from './Summary.css';

const Summary = ({
  recruitmentData,
  editable,
  loadingRecruitment,
  setLoadingRecruitment,
  notesNotFilled,
}: Props) => {
  const redirect = useRedirect('/ended-recruitment');
  const { push } = useHistory();
  const { id: recruitmentId } = useParams<RouteParams>();
  const [opinion, setOpinion] = useState(recruitmentData?.generalNote || '');

  const [recommendation, setRecommendation] = useState<{
    value: HireState;
    label: string;
  }>();
  const [cooperation, setCooperation] = useState<boolean | undefined>(
    recruitmentData?.cooperation,
  );
  const [independence, setIndependence] = useState<boolean | undefined>(
    recruitmentData?.independence,
  );
  const [management, setManagement] = useState<boolean | undefined>(
    recruitmentData?.management,
  );

  const { patchRecruitment, isLoadingPatchRecruitment } =
    RecruitmentService.usePatchRecruitment();

  const checkboxesNotSelected =
    editable &&
    (cooperation === null ||
      independence === null ||
      management === null ||
      recommendation === undefined ||
      !opinion.trim().length);

  const buttonDisabled =
    checkboxesNotSelected ||
    isLoadingPatchRecruitment ||
    loadingRecruitment ||
    notesNotFilled;

  const loadingData = isLoadingPatchRecruitment || loadingRecruitment;

  const cooperationChecked = editable ? cooperation : recruitmentData?.cooperation;
  const independenceChecked = editable ? independence : recruitmentData?.independence;
  const managementChecked = editable ? management : recruitmentData?.management;

  useEffect(() => {
    if (recruitmentData?.hire) {
      setRecommendation({ value: HireState.Hire, label: 'Hire' });
    } else if (recruitmentData?.hire === false) {
      setRecommendation({ value: HireState.NotHire, label: 'Not hire' });
    }
  }, [recruitmentData?.hire, setRecommendation]);

  const handleNoteChange = useCallback(
    async (event: ChangeEvent<HTMLTextAreaElement>) => {
      try {
        await patchRecruitment(+recruitmentId, { generalNote: event.target.value });
      } catch (e) {
        handleException(e);
      } finally {
        setLoadingRecruitment(false);
      }
    },
    [patchRecruitment, recruitmentId, setLoadingRecruitment],
  );

  const debouncedOpinionChange = useMemo(
    () => debounce(handleNoteChange, UPDATE_OPINION_DELAY),
    [handleNoteChange],
  );

  const cooperationClicked = async (value: boolean) => {
    if (editable) {
      try {
        await patchRecruitment(+recruitmentId, { cooperation: value });
      } catch (e) {
        handleException(e);
      } finally {
        setCooperation(value);
      }
    }
  };

  const independenceClicked = async (value: boolean) => {
    if (editable) {
      try {
        await patchRecruitment(+recruitmentId, { independence: value });
      } catch (e) {
        handleException(e);
      } finally {
        setIndependence(value);
      }
    }
  };

  const managementClicked = async (value: boolean) => {
    if (editable) {
      try {
        await patchRecruitment(+recruitmentId, { management: value });
      } catch (e) {
        handleException(e);
      } finally {
        setManagement(value);
      }
    }
  };

  const handleClick = async (complete: boolean) => {
    try {
      await patchRecruitment(+recruitmentId, {
        recruitmentState: complete ? RecruitmentState.Completed : RecruitmentState.Ended,
        ...(cooperationChecked !== null && { cooperation: cooperationChecked }),
        ...(independenceChecked !== null && { independence: independenceChecked }),
        ...(managementChecked !== null && { management: managementChecked }),
        generalNote: opinion,
      });
      push('/ended-recruitment');
    } catch (e) {
      handleException(e);
    }
  };

  const handleRecommendationChange = async (
    event: SingleValue<{
      value: HireState;
      label: string;
    }>,
  ) => {
    if (editable) {
      try {
        await patchRecruitment(+recruitmentId, {
          hire: event?.value === HireState.Hire,
        });
      } catch (e) {
        handleException(e);
      } finally {
        setRecommendation(event ?? undefined);
      }
    }
  };

  return (
    <>
      <S.Summary>
        <S.SummaryTitle>Summary</S.SummaryTitle>
        <S.SummaryValues>
          <S.SummaryScore>
            Score: <S.YellowText>{recruitmentData?.grade ?? 0}</S.YellowText>/
            {recruitmentData?.maxGrade ?? 0} (
            <S.YellowText>{`${percentageCalculator(
              recruitmentData?.grade,
              recruitmentData?.maxGrade,
            )}%`}</S.YellowText>
            )
          </S.SummaryScore>
        </S.SummaryValues>
      </S.Summary>
      <S.RecruitmentReview>
        <RecruitmentReview
          cooperationChecked={cooperationChecked}
          cooperationClicked={cooperationClicked}
          independenceChecked={independenceChecked}
          independenceClicked={independenceClicked}
          managementChecked={managementChecked}
          managementClicked={managementClicked}
          editable={editable}
          recommendation={recommendation}
          recruitmentData={recruitmentData}
          handleRecommendationChange={handleRecommendationChange}
          debouncedOpinionChange={debouncedOpinionChange}
          setGeneralNote={setOpinion}
          generatedView={false}
        />
        <Box display="flex" justifyContent="space-between" mb={3} marginTop="30px">
          <Button onClick={redirect}>Back to list</Button>
          {editable && (
            <div>
              <S.SaveButton
                onClick={() => handleClick(false)}
                disabled={loadingData}
                endIcon={
                  loadingData ? (
                    <CircularProgress style={{ color: 'black' }} size="16px" />
                  ) : (
                    ''
                  )
                }
              >
                Save
              </S.SaveButton>
              <S.Tooltip
                open={buttonDisabled}
                placement="top"
                title="Sum up modules and complete fields in recruiter review to mark recruitment as completed"
              >
                <span>
                  <Button
                    $secondary
                    onClick={() => handleClick(true)}
                    disabled={buttonDisabled}
                    variant="contained"
                    startIcon={<S.CheckIcon $disabled={buttonDisabled} />}
                  >
                    Save &amp; mark as completed
                  </Button>
                </span>
              </S.Tooltip>
            </div>
          )}
        </Box>
      </S.RecruitmentReview>
    </>
  );
};

export default Summary;
