import { useEffect, useState } from 'react';
import { PaginationOptions } from './types';
import { useQueryParams } from './useQueryParams';

export const usePagination = () => {
  const queryParams = useQueryParams();

  const [paginationOptions, setPaginationOptions] = useState<PaginationOptions>({
    count: 0,
    page: Number(queryParams.page) - 1 || 0,
    perPage: Number(queryParams.perPage) || 10,
    shouldFetch: true,
  });

  useEffect(() => {
    setPaginationOptions((prevState) => ({
      ...prevState,
      page: Number(queryParams.page) - 1 || 0,
      perPage: Number(queryParams.perPage) || 10,
      shouldFetch: true,
    }));
  }, [queryParams]);

  return { paginationOptions, setPaginationOptions };
};
