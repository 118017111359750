import { useHistory } from 'react-router-dom';

export function useRedirect(redirectTo: string) {
  const { push } = useHistory();
  return () => {
    const params = localStorage.getItem('params');
    push({
      pathname: redirectTo,
      search: `${new URLSearchParams({
        ...(params && { ...JSON.parse(params) }),
      })}`,
    });
  };
}
