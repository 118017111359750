import { createStyles, makeStyles, TextField } from '@material-ui/core';
import styled, { css } from 'styled-components';
import { Button } from 'styles.global';
import theme from 'theme';
import { HeaderProps, HeaderTitleProps } from './AdjustRecruitmentModule.types';

export const popperStyles = makeStyles(() =>
  createStyles({
    root: {
      '& .MuiAutocomplete-listbox': {
        whiteSpace: 'pre-wrap',
      },
    },
  }),
);

export const HoverableContainer = styled.div`
  margin-right: 10px;
  user-select: none;
  :hover {
    cursor: pointer;
  }
`;

export const CoreContainer = styled.div`
  margin-top: 64px;
`;

export const AdjustContainer = styled.div`
  display: flex;
  word-break: keep-all;
`;

export const LeftPanel = styled.div`
  border-right: 2px solid rgb(230, 230, 230, 50%);
  flex: 5;
`;

export const RightPanel = styled.div`
  padding-top: 30px;
  padding-left: 30px;
  flex: 1;
  position: sticky;
  align-self: flex-start;
  top: 0;
`;

export const RightPanelHeader = styled.p`
  font-size: 1.1rem;
  margin-bottom: 30px;
`;

export const RightPanelCell = styled.div`
  margin-bottom: 15px;
`;

export const TemplateTextField = styled(TextField)`
  width: 300%;
`;

export const TemplateHeader = styled.div<HeaderProps>`
  display: flex;
  margin-top: 50px;
  font-size: ${(props) => props.fontSize || '1.5rem'};
  padding-right: 32px;
`;

export const Header = styled.div<HeaderProps>`
  display: flex;
  font-size: ${(props) => props.fontSize || '1.5rem'};
  position: fixed;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12), 0px 0px 4px rgba(0, 0, 0, 0.08);
  margin-top: -96px;
  margin-left: -32px;
  padding-left: 32px;
  padding-right: 32px;
  z-index: 994;
  background-color: white;
  width: ${(props) => (props.$width ? `${props.$width}px` : '100%')};
`;

export const ModuleHeader = styled.div<HeaderProps>`
  display: flex;
  align-items: center;
  font-size: ${(props) => props.fontSize || '1.5rem'};
  padding-right: 32px;
`;

export const TemplateSelect = styled.div`
  display: flex;
  align-items: center;
`;

export const TemplateText = styled.div`
  margin-right: 32px;
`;

export const HeaderTitle = styled.p<HeaderTitleProps>`
  font-size: 20px;
  flex: 3;
  ${(props) =>
    props.underline &&
    css`
      text-decoration: underline ${theme.palette.primary.main};
      text-underline-offset: 5px;
    `}
`;

export const ModuleHeaderTitleContainer = styled.div`
  margin-top: 32px;
  margin-bottom: 16px;
  margin-left: -32px;
  flex: 3;
`;

export const ModuleHeaderTitle = styled.div`
  background-color: ${theme.palette.primary.main};
  display: flex;
  align-items: center;
  user-select: text;
  overflow-wrap: break-word;
  font-weight: bold;
  margin: 0;
  width: 25%;
  font-size: 20px;
  padding: 5px 70px 5px 50px;
  clip-path: polygon(0 0, 93% 0%, 90% 120%, 0% 100%);
`;

export const ModulePanel = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-column-gap: 50px;
  grid-row-gap: 10px;
`;

export const ModuleDescription = styled.div`
  word-break: break-word;
  border: 1px solid red;
  margin-top: 30px;
`;

export const SelectedModule = styled.div`
  grid-row: 2 / 3;
  grid-column: 1 / 2;
`;

export const NumberOfQuestionsInput = styled.div`
  grid-row: 2 / 3;
  grid-column: 2 / 3;
`;

export const NumberOfQuestionsTextField = styled(TextField)`
  width: 40%;
`;

export const NumberOfTasksTextField = styled(TextField)`
  width: 40%;
`;

export const NumberOfTasksInput = styled.div`
  grid-row: 4 / 5;
  grid-column: 2 / 3;
`;

export const Minutes = styled.div`
  flex: 2;
`;

export const SaveButton = styled(Button).attrs({ $primary: true })`
  &.MuiButton-root {
    width: 178px;
    margin-right: 30px;
  }
`;

export const CandidateInfo = styled.span`
  font-weight: bold;
  margin-right: 10px;
`;

export const TheoreticalQuestionLabel = styled.div`
  font-size: 16px;
  grid-row: 1 / 2;
  grid-column: 2 / 3;
`;

export const PracticalQuestionLabel = styled.div`
  font-size: 16px;
  grid-row: 3 / 4;
  grid-column: 2 / 3;
  margin-top: 30px;
`;

export const SelectedQuestions = styled.div`
  width: 75%;
  grid-row: 6 / 7;
  grid-column: 2 / 3;
`;

export const SelectedQuestionsLabel = styled.div`
  font-size: 18px;
  grid-row: 5 / 6;
  grid-column: 2 / 3;
  margin-top: 30px;
`;

export const SelectedQuestionsDescription = styled.p`
  font-size: 15px;
  margin-top: 5px;
`;

export const TheoreticalQuestionMinutes = styled.div`
  grid-row: 2 / 3;
  grid-column: 3 / 4;
`;

export const PracticalQuestionMinutes = styled.div`
  grid-row: 4 / 5;
  grid-column: 3 / 4;
`;

export const SelectedQuestionsMinutes = styled.div`
  grid-row: 6 / 7;
  grid-column: 3 / 4;
`;
