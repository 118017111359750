import styled from 'styled-components';

export const MyRecruitmentsContainer = styled.div`
  display: flex;
  gap: 2px;
  align-items: center;
  margin-right: auto;
  margin-left: 16px;
`;

export const MyRecruitmentsLabel = styled.label`
  margin-bottom: 4px;
`;
