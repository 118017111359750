import { Box } from '@material-ui/core';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import styled from 'styled-components';
import { TextArea } from 'styles.global';
import theme from 'theme';

export const ModuleSummaryTextArea = styled(TextArea)<{ $empty: boolean }>`
  width: 840px;
  resize: none;
  height: 150px;
  font-size: large;
  background-color: ${theme.palette.secondary.light};
  box-shadow: inset 1px 3px 4px lightgray;
  border: ${(props) => (props.$empty ? '1px solid red' : 'none')};
  &:focus {
    outline-color: ${(props) => props.$empty && theme.palette.error.main};
  }
`;
export const ModuleNotFilled = styled.div`
  color: ${theme.palette.error.main};
  font-size: 14px;
`;

export const ModuleSummaryIcon = styled(SpeakerNotesIcon)`
  margin-right: 10px;
`;

export const ModuleDescription = styled(Box)`
  white-space: pre-line;
`;

export const EndedModuleSummaryArea = styled.div`
  padding-left: 16px;
  padding-top: 16px;
  white-space: pre-line;
`;

export const BoldText = styled.div`
  font-weight: bold;
  margin-top: 32px;
`;
