import {
  Button,
  CircularProgress as MuiCircularProgress,
  Table as MuiTable,
  TableCell as MuiTableCell,
  TableHead as MuiTableHead,
  TableRow,
} from '@material-ui/core';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import CreateIcon from '@material-ui/icons/Create';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import MuiLinkIcon from '@material-ui/icons/Link';
import EyeIcon from '@material-ui/icons/RemoveRedEye';
import SaveAltOutlinedIcon from '@material-ui/icons/SaveAltOutlined';
import styled, { css } from 'styled-components';
import theme from 'theme';

export { EmptyListImage } from 'styles.global';
export {
  ArrowUp,
  ArrowDown,
} from 'pages/TodoRecruitment/components/RecruitmentTable/RecruitmentTable.css';

export const BinIcon = styled(DeleteOutlineIcon)`
  & {
    margin-right: 4px;
    :hover {
      cursor: pointer;
      color: ${theme.palette.error.main};
    }
  }
`;

export const InfoIcon = styled(InfoOutlined)`
  && {
    margin-right: 5px;
    &:hover {
      cursor: pointer;
      color: ${theme.palette.secondary.dark}80;
    }
  }
`;

export const EditReasonIcon = styled(EditOutlinedIcon)`
  && {
    &:hover {
      cursor: pointer;
      color: ${theme.palette.secondary.dark}80;
    }
  }
`;

export const ViewIcon = styled(EyeIcon)<{ $outlined?: boolean }>`
  &.MuiSvgIcon-root {
    background-color: ${theme.palette.secondary.dark};
    color: white;
    font-size: 18px;
    padding: 7px;

    &:hover {
      background-color: ${theme.palette.secondary.dark}80;
      cursor: pointer;
    }

    ${({ $outlined }) =>
      $outlined &&
      css`
        font-size: 22px;
        padding: 5px;
        background-color: ${theme.palette.secondary.main};
        color: ${theme.palette.secondary.dark};
        margin-right: 14px;
        &:hover {
          color: ${theme.palette.secondary.dark}80;
          background-color: ${theme.palette.secondary.main};
        }
      `}
  }
`;

export const EditIcon = styled(CreateIcon)`
  &.MuiSvgIcon-root {
    background-color: ${theme.palette.primary.main};
    margin-right: 10px;
    font-size: 18px;
    padding: 7px;
    &:hover {
      background-color: ${theme.palette.primary.main}80;
      cursor: pointer;
    }
  }
`;

export const GeneratedEditIcon = styled(CreateIcon)`
  &.MuiSvgIcon-root {
    color: ${theme.palette.success.main};
    margin-right: 10px;
    font-size: 22px;
    padding: 5px;
    &:hover {
      color: ${theme.palette.success.main}80;
      cursor: pointer;
    }
  }
`;

export const LinkIcon = styled(MuiLinkIcon)`
  &.MuiSvgIcon-root {
    color: white;
    background-color: ${theme.palette.success.main};
    margin-right: 10px;
    font-size: 18px;
    padding: 7px;

    &:hover {
      background-color: ${theme.palette.success.main}80;
      cursor: pointer;
    }
  }
`;

export const ArrowIcon = styled(ArrowRightAltIcon)`
  &.MuiSvgIcon-root {
    margin-right: 5px;
    font-size: 20px;
  }
`;
export const ListLinkIcon = styled(MuiLinkIcon)`
  &.MuiSvgIcon-root {
    color: ${theme.palette.success.main};
    margin-right: 5px;
    font-size: 20px;
  }
`;

export const CircularProgress = styled(MuiCircularProgress)`
  &.MuiCircularProgress-root {
    margin-top: 8px;
    margin-right: 16px;
    color: #425cff;
  }
`;

export const DocumentIcon = styled(SaveAltOutlinedIcon)<{ $outlined?: boolean }>`
  &.MuiSvgIcon-root {
    color: white;
    background-color: #425cff;
    margin-right: 10px;
    font-size: 18px;
    padding: 7px;
    &:hover {
      background-color: #425cff80;
      cursor: pointer;
    }
    ${({ $outlined }) =>
      $outlined &&
      css`
        font-size: 22px;
        padding: 5px;
        background-color: ${theme.palette.secondary.main};
        color: #425cff;
        &:hover {
          color: #425cff80;
          background-color: ${theme.palette.secondary.main};
        }
      `}
  }
`;

export const CopyIcon = styled(FileCopyIcon)`
  &.MuiSvgIcon-root {
    color: ${theme.palette.success.main};
    margin-right: 10px;
    font-size: 20px;
    &:hover {
      color: ${theme.palette.success.main}80;
      cursor: pointer;
    }
  }
`;

const PrimaryButton = styled(Button)`
  &.MuiButton-root {
    letter-spacing: 0;
    font-weight: bold;
    width: 94px;
    height: 32px;
    text-transform: none;
    border-radius: 0;
  }
`;

export const ViewButton = styled(PrimaryButton)`
  &.MuiButton-root {
    background-color: black;
    color: white;
  }
`;

export const Table = styled(MuiTable)`
  &.MuiTable-root {
    word-break: break-word;
  }
`;

export const TableHead = styled(MuiTableHead)`
  border-bottom: 1.5px black solid;
`;

export const BoldTableCell = styled(MuiTableCell)<{ $cellWidth?: string }>`
  &.MuiTableCell-root {
    font-weight: bold;
    width: ${({ $cellWidth }) => $cellWidth || 'auto'};
  }
`;

export const TableCell = styled(MuiTableCell)<{ $cellWidth: string; $noWrap?: boolean }>`
  &.MuiTableCell-root {
    width: ${({ $cellWidth }) => $cellWidth};
    ${({ $noWrap }) =>
      $noWrap &&
      css`
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 300px;
      `}
  }
`;

export const GeneratedLinkTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const GeneratedLinkTitle = styled.div`
  margin: 0;
`;

export const GeneratedTableRow = styled(TableRow)`
  &.MuiTableRow-root {
    background-color: ${theme.palette.secondary.main};
  }
`;

export const NotFoundContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const BoldText = styled.span`
  font-weight: bold;
`;
