import { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { QueryParams } from './types';

const queryString = require('query-string');

export function useQueryParams() {
  const { location } = useHistory();
  const params: QueryParams = useMemo(
    () => queryString.parse(location.search),
    [location.search],
  );

  useEffect(() => {
    localStorage.setItem('params', JSON.stringify(queryString.parse(location.search)));
  }, [location.search]);

  return params;
}
