import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ModuleService, QuestionService, RecruitmentService, TemplateService } from 'api';
import { SelectedTemplate } from 'pages/AdjustRecruitment/components/Panel.types';
import AdjustRecruitmentModule from 'pages/components/AdjustRecruitmentModule';
import {
  AdjustRecruitmentModule as IAdjustRecruitmentModule,
  NewRecruitmentModule,
} from 'types/recruitment';
import Container from 'components/Container';
import { handleException } from 'utils/errorHandlingUtils';
import { createRecruitmentModulesFromTemplate } from 'utils/formatters';
import { removeExtraSpaces, stringIsEmpty } from 'utils/helpers';
import { useQuitWithRedirect } from 'utils/hooks';
import { NAME_LIMIT, POSITION_LIMIT } from '../components/const';
import { TemplateHeader } from '../components/TemplateHeader';
import * as S from '../TemplatesPage.css';

export const AddTemplate = () => {
  const { push } = useHistory();

  const [name, setName] = useState('');
  const [position, setPosition] = useState('');

  const { positionsData, getPositionsData } = RecruitmentService.useGetPositions();
  const [isTemplatePrivate, setTemplatePrivate] = useState(false);

  const { isCreatingTemplate, createTemplate } = TemplateService.useCreateTemplate();
  const { isCreatingTemplateModules, createTemplateModules } =
    TemplateService.useCreateTemplateModules();

  const [selectedTemplate, setSelectedTemplate] = useState<SelectedTemplate>(null);

  const { modulesData, isLoadingModules, getModules } = ModuleService.useGetModules();
  const { getTemplate } = TemplateService.useGetTemplate();
  const { getQuestions } = QuestionService.useGetQuestions();
  const { templatesData, getTemplates } = TemplateService.useGetTemplates();

  useEffect(() => {
    getModules();
    getPositionsData();
    getTemplates();
  }, [getModules, getPositionsData, getTemplates]);

  const [recruitmentModules, setRecruitmentModules] =
    useState<IAdjustRecruitmentModule[]>();

  const [isLoading, setLoading] = useState(false);

  const handleQuit = useQuitWithRedirect({
    redirectTo: '/templates',
    canQuit: stringIsEmpty(name) && stringIsEmpty(position),
  });

  const handleSubmit = async (newRecruitmentModules: NewRecruitmentModule[]) => {
    const { data } = await createTemplate({
      data: {
        name: removeExtraSpaces(name),
        position: removeExtraSpaces(position),
        private: isTemplatePrivate,
      },
    });
    await createTemplateModules(data.data.id, newRecruitmentModules);
    push('/templates');
  };

  const canSubmit =
    !stringIsEmpty(name) &&
    !stringIsEmpty(position) &&
    removeExtraSpaces(name).length <= NAME_LIMIT &&
    removeExtraSpaces(position).length <= POSITION_LIMIT;

  async function handleTemplateChange(templateArg: SelectedTemplate) {
    try {
      setSelectedTemplate(templateArg);
      if (templateArg) {
        setLoading(true);

        try {
          const {
            data: {
              data: { templateModules },
            },
          } = await getTemplate(templateArg.id);

          const {
            data: { data: questionsForRecruitmentModules },
          } = await getQuestions({
            moduleIds: templateModules.map(({ moduleId }) => moduleId),
          });

          setRecruitmentModules(
            await createRecruitmentModulesFromTemplate(
              questionsForRecruitmentModules,
              modulesData,
              templateModules,
            ),
          );
        } catch (e) {
          handleException(e);
        }
        setLoading(false);
      } else {
        setRecruitmentModules(undefined);
      }
    } catch (e) {
      handleException(e);
    }
  }

  return (
    <Container isLoading={isLoading || isLoadingModules}>
      <S.TemplateFormContainer>
        <S.LeftPanel>
          <TemplateHeader
            title="Create template"
            name={name}
            position={position}
            setName={setName}
            setPosition={setPosition}
            selectedTemplate={selectedTemplate}
            handleTemplateChange={handleTemplateChange}
            positionsProp={positionsData}
            templatesProp={templatesData}
          />
          <AdjustRecruitmentModule
            handleSubmit={handleSubmit}
            handleQuit={handleQuit}
            canSubmit={canSubmit}
            isMakingRequest={isCreatingTemplate || isCreatingTemplateModules}
            propState={recruitmentModules}
            propModules={modulesData}
            propPositions={positionsData}
            isTemplatePrivate={isTemplatePrivate}
            setTemplatePrivate={setTemplatePrivate}
          />
        </S.LeftPanel>
      </S.TemplateFormContainer>
    </Container>
  );
};
