import { Typography } from '@material-ui/core';
import { ModuleRow } from 'pages/Questions/components/ModuleList/components/ModuleRow';
import Container from 'components/Container';
import { ModuleListProps } from './ModuleList.types';

const ModuleList = ({
  deleteModule,
  isFetching,
  modules,
  addQuestionsToModule,
  deleteQuestion,
  updateModule,
  updateQuestion,
  addQuestion,
  moduleNames,
}: ModuleListProps) => (
  <Container isLoading={isFetching}>
    {modules.length ? (
      <>
        {modules.map((module) => (
          <ModuleRow
            module={module}
            key={module.name}
            deleteModule={deleteModule}
            addQuestionsToModule={addQuestionsToModule}
            deleteQuestion={deleteQuestion}
            updateModule={updateModule}
            updateQuestion={updateQuestion}
            addQuestion={addQuestion}
            moduleNames={moduleNames}
          />
        ))}
      </>
    ) : (
      <Typography variant="h3" align="center">
        No modules
      </Typography>
    )}
  </Container>
);

export default ModuleList;
