import {
  Button,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  Typography,
} from '@material-ui/core';
import styled from 'styled-components';
import theme from 'theme';

export const DialogTitle = styled(Typography)`
  &.MuiTypography-root {
    font-weight: 600;
    align-self: center;
    margin-top: 20px;
    font-size: 25px;
    padding-top: 16px;
    padding-bottom: 20px;
    text-decoration: underline;
    text-underline-offset: 8px;
    text-decoration-color: ${theme.palette.primary.main};
  }
`;

export const DialogContent = styled(MuiDialogContent)`
  &.MuiDialogContent-root {
    padding: 30px 100px;
  }
`;

export const Label = styled(Typography)`
  &.MuiTypography-root {
    align-self: center;
    font-size: 20px;
    margin-bottom: 5px;
  }
`;

export const DialogActions = styled(MuiDialogActions)`
  &.MuiDialogActions-root {
    width: 400px;
    margin: auto;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 50px;
  }
`;

export const SaveButton = styled(Button)`
  &.MuiButton-root {
    width: 170px;
    font-size: large;
    height: 50px;
    border-radius: 0;
    font-weight: bold;
    background-color: ${theme.palette.primary.main};
    border: 0;
    box-shadow: 0px 8px 6px lightgray;
    text-transform: none;
  }
`;

export const CancelButton = styled(Button)`
  &.MuiButton-root {
    width: 170px;
    font-size: large;
    height: 50px;
    border-radius: 0;
    font-weight: bold;
    border: 0;
    box-shadow: 0px 8px 6px lightgray;
    text-transform: none;
  }
`;
