import { ChangeEvent, MouseEvent } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import TablePagination from '@material-ui/core/TablePagination';
import { fixModuleParam } from 'pages/Questions/utils';
import { useQueryParams } from 'utils/hooks';
import { PaginationProps } from './Pagination.types';

const Pagination = ({ paginationOptions }: PaginationProps) => {
  const { push } = useHistory();
  const { pathname } = useLocation();

  const queryParams = useQueryParams();

  const handleChangePage = (
    event: MouseEvent<HTMLButtonElement> | null,
    page: number,
  ) => {
    delete queryParams.page;

    const query = new URLSearchParams({
      ...queryParams,
      ...(page && { page: (page + 1).toString() }),
    });

    push({
      pathname,
      search: `?${
        queryParams.module ? fixModuleParam(query, queryParams.module) : query
      }`,
    });
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const perPage = parseInt(event.target.value, 10);

    delete queryParams.page;
    if (perPage === 10) {
      delete queryParams.perPage;
    }

    const query = new URLSearchParams({
      ...queryParams,
      ...(perPage !== 10 && { perPage: perPage.toString() }),
    });

    push({
      pathname,
      search: `?${
        queryParams.module ? fixModuleParam(query, queryParams.module) : query
      }`,
    });
  };

  return (
    <TablePagination
      component="div"
      count={paginationOptions.count}
      page={paginationOptions.page}
      onPageChange={handleChangePage}
      rowsPerPage={paginationOptions.perPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
};

export default Pagination;
