import { useHistory } from 'react-router-dom';
import { Checkbox } from '@material-ui/core';
import { useUserContext } from 'context';
import { User } from 'types/user';
import { QueryParams } from 'utils/hooks/types';
import * as S from './MyRecruitmentsFilter.css';

type Props = {
  queryParams: QueryParams;
  currentUser: User | null;
  redirectTo: 'todo' | 'ended-recruitment';
};

export const MyRecruitmentsFilter = ({ queryParams, currentUser, redirectTo }: Props) => {
  const { push } = useHistory();
  const { user } = useUserContext();
  const isHrUser = user?.roles?.includes('hr');

  const handleDisplayMyRecruitments = () => {
    const { recruiter, hrContact, ...restOfParams } = queryParams;
    if (
      queryParams.recruiter === currentUser?.name ||
      queryParams.hrContact === currentUser?.name
    ) {

      push({
        pathname: redirectTo,
        search: `${new URLSearchParams({
          ...restOfParams,
          ...(hrContact && !isHrUser && { recruiter }),
          ...(recruiter && isHrUser && { recruiter }),
        })}`,
      });
    } else {
      push({
        pathname: redirectTo,
        search: `${new URLSearchParams({
          ...queryParams,
          ...(currentUser?.name && {
            ...(isHrUser && { hrContact: currentUser?.name }),
            ...(!isHrUser && { recruiter: currentUser?.name }),
          }),
        })}`,
      });
    }
  };

  return (
    <S.MyRecruitmentsContainer>
      <Checkbox
        id="myRecruitments"
        size="medium"
        checked={
          queryParams.recruiter === currentUser?.name ||
          queryParams.hrContact === currentUser?.name
        }
        onClick={handleDisplayMyRecruitments}
      />
      <S.MyRecruitmentsLabel htmlFor="myRecruitments">
        My Recruitments
      </S.MyRecruitmentsLabel>
    </S.MyRecruitmentsContainer>
  );
};
