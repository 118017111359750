import {
  MouseEvent,
  Reducer,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ModuleService, RecruitmentService } from 'api';
import { useRecruitmentContext } from 'context';
import { Loader, LoaderContainer } from 'styles.global';
import { Question, RecruitmentQuestion } from 'types/question';
import { NewRecruitmentModule, RecruitmentState } from 'types/recruitment';
import Container from 'components/Container';
import DropZone from 'components/DropZone';
import { RecruiterNote } from 'components/RecruiterNote';
import ErrorToast from 'components/Toasts/ErrorToast';
import InfoToast from 'components/Toasts/InfoToast';
import { handleException } from 'utils/errorHandlingUtils';
import { getEndedRecruitmentUrl } from 'utils/formatters';
import { RouteParams } from 'utils/types/RouteParams';
import { AddRecruitmentModule } from './components/AddRecruitmentModule';
import { BottomStripe } from './components/BottomStripe';
import { RecruitmentInfo } from './components/RecruitmentInfo';
import {
  draggableRecruitmentModules,
  draggableStartModules,
  handleOnDragEndRecruitmentState,
  handleOnDragEndStartState,
} from './utils/draggableUtils';
import { Actions, reducer, State } from './modulesState';
import {
  ModuleWithSortedQuestions,
  RefreshableRecruitmentQuestion,
} from './Recruitment.types';
import * as S from './Recruitment.css';

const Recruitment = () => {
  const [loadingRecruitment, setLoadingRecruitment] = useState(false);
  const [isStartRecruitmentEnabled, setStartRecruitmentEnabled] = useState(true);
  const [currentModule, setCurrentModule] = useState<number>();
  const [questions, setQuestions] = useState<RefreshableRecruitmentQuestion[]>([]);
  const [recruitmentQuestions, setRecruitmentQuestions] = useState<RecruitmentQuestion[]>(
    [],
  );
  const [expandedQuestionIdx, setExpandedQuestionIdx] = useState(0);

  const [state, dispatch] = useReducer<Reducer<State, Actions>>(reducer, {
    modulesWithSortedQuestions: [],
    recruitmentModules: [],
  });

  const {
    recruitment: { getRecruitment: getRecruitmentContext },
  } = useRecruitmentContext();

  const { id } = useParams<RouteParams>();
  const { push, replace } = useHistory();

  const sortRecruitmentQuestions =
    RecruitmentService.useSortRecruitmentQuestions(currentModule);
  const sortRecruitmentModules = RecruitmentService.useSortRecruitmentModules(id);
  const { recruitmentData, getRecruitment, isLoadingRecruitment } =
    RecruitmentService.useGetRecruitment();
  const { createRecruitmentQuestionsAsync } =
    RecruitmentService.useCreateRecruitmentQuestions();
  const { isLoadingAllQuestionsForRecruitment, getAllQuestionsForRecruitment } =
    RecruitmentService.useGetAllQuestionsForRecruitment(id);
  const { getRecruitmentModules } = RecruitmentService.useGetRecruitmentModules(id);
  const updateRecruitmentModule = RecruitmentService.useUpdateRecruitmentModule();
  const deleteRecruitmentModuleAsync = RecruitmentService.useDeleteRecruitmentModule();
  const { reloadQuestionsAsync } = RecruitmentService.useReloadRecruitmentQuestions();
  const { reloadQuestionAsync } = RecruitmentService.useReloadRecruitmentQuestion();
  const { patchQuestionAsync } = RecruitmentService.usePatchQuestion();
  const { patchRecruitment, isLoadingPatchRecruitment } =
    RecruitmentService.usePatchRecruitment();
  const { getModules, modulesData } = ModuleService.useGetModules();

  const { createCancellation } = RecruitmentService.useCreateCancellation();

  const isLoading = isLoadingRecruitment || isLoadingAllQuestionsForRecruitment;

  const isDisabledFinishRecruitment = useMemo(
    () =>
      state.recruitmentModules.some((module) =>
        module.recruitmentQuestions.some(({ grade }) => grade === null),
      ) ||
      loadingRecruitment ||
      isLoadingPatchRecruitment ||
      isLoading,
    [loadingRecruitment, isLoadingPatchRecruitment, isLoading, state.recruitmentModules],
  );

  const loadView = useCallback(async () => {
    try {
      await getRecruitmentContext(id);
      const recruitmentResponse = await getRecruitment(id);
      if (RecruitmentState.Formed === recruitmentResponse.data.data.recruitmentState) {
        push(`/adjust-recruitment/${id}`);
      }
      if (recruitmentResponse.data.data.recruitmentState === RecruitmentState.Adjusted) {
        const allQuestionsForRecruitmentResponse = await getAllQuestionsForRecruitment();
        dispatch({
          type: 'setModules',
          modules: allQuestionsForRecruitmentResponse.data.data.recruitmentModules,
        });
        setCurrentModule(
          allQuestionsForRecruitmentResponse.data.data.recruitmentModules[0]?.id,
        );
      } else {
        const recruitmentModulesResponse = await getRecruitmentModules();
        dispatch({
          type: 'setRecruitmentModules',
          recruitmentModules: recruitmentModulesResponse.data.data,
        });

        setCurrentModule(recruitmentModulesResponse.data.data[0]?.id);
      }
    } catch (e) {
      handleException(e);
      push('/todo');
    }
  }, [
    getAllQuestionsForRecruitment,
    getRecruitment,
    getRecruitmentModules,
    id,
    push,
    getRecruitmentContext,
  ]);

  useEffect(() => {
    loadView();
  }, [loadView]);

  useEffect(() => {
    if (recruitmentData) {
      switch (recruitmentData?.recruitmentState) {
        case RecruitmentState.Formed:
          toast.error(
            <ErrorToast message={`Recruitment with id ${id} is not adjusted.`} />,
          );
          push('/todo');
          break;
        case RecruitmentState.Ended:
          toast.error(<ErrorToast message={`Recruitment with id ${id} is ended.`} />);
          push('/todo');
          break;
        default:
          break;
      }
    }
  }, [recruitmentData, push, id]);

  const deleteRecruitment = useCallback(async () => {
    try {
      await createCancellation(+id, 'Cancelled by deleting all questions');
      toast.info(<InfoToast message="You cancelled recruitment successfully" />);
      push('/todo');
    } catch (e) {
      handleException(e);
    }
  }, [createCancellation, id, push]);

  const deleteRecruitmentModule = useCallback(
    async (moduleId: number) => {
      try {
        await deleteRecruitmentModuleAsync(moduleId);
      } catch (e) {
        handleException(e);
      }
    },
    [deleteRecruitmentModuleAsync],
  );

  const handleStartRecruitment = async () => {
    try {
      setStartRecruitmentEnabled(false);
      if (!recruitmentData) {
        return;
      }
      await createRecruitmentQuestionsAsync(recruitmentData.id, {
        recruitmentModules: state.modulesWithSortedQuestions.map((m) => ({
          id: m.id,
          questionsId: m.questions.map((q) => q.question.id),
        })),
      });
      await patchRecruitment(+id, {
        recruitmentState: RecruitmentState.InProgress,
      });
      await loadView();
    } catch (e) {
      handleException(e);
      setStartRecruitmentEnabled(true);
    }
  };

  const finishRecruitment = async () => {
    try {
      if (!recruitmentData) {
        return;
      }
      await patchRecruitment(+id, {
        recruitmentState: RecruitmentState.Ended,
        reviewed: true,
      });
      replace(
        getEndedRecruitmentUrl(
          id,
          recruitmentData.recruitmentDate,
          recruitmentData.candidateName,
          true,
        ),
      );
    } catch (e) {
      handleException(e);
    }
  };

  const deleteRecruitmentQuestion = (moduleId: number) => (questionId: number) => {
    const module = state.recruitmentModules.find((x) => x.id === moduleId);
    if (module?.recruitmentQuestions.length === 1) {
      deleteRecruitmentModule(moduleId);
      dispatch({
        type: 'deleteRecruitmentModule',
        moduleId: module.id,
      });
      if (state.recruitmentModules.length === 1) {
        deleteRecruitment();
      }
      return;
    }
    dispatch({ type: 'deleteRecruitmentQuestion', moduleId, questionId });
  };

  const reloadQuestion = async (
    questionId: number,
    moduleId: number,
    currentQuestions: number[],
  ) => {
    try {
      const response = await reloadQuestionAsync(questionId, currentQuestions);
      dispatch({
        type: 'changeQuestion',
        questionId,
        moduleId,
        question: response.data.data.newQuestion,
      });
    } catch (e) {
      handleException(e);
    }
  };

  const reloadRecruitmentQuestion = async (
    question: RecruitmentQuestion,
    moduleId: number,
    currentQuestionIds: number[],
  ) => {
    try {
      const response = await reloadQuestionAsync(question.questionId, currentQuestionIds);
      const newQuestionResponse = await patchQuestionAsync(question.id, {
        questionId: response.data.data.newQuestion.id,
        grade: null,
        note: null,
      });

      await dispatch({
        type: 'changeRecruitmentQuestion',
        questionId: question.id,
        moduleId,
        question: newQuestionResponse.data.data,
      });
    } catch (e) {
      handleException(e);
    }
  };

  const returnNewModuleAfterDelete = (
    deletedQuestion: Question,
    moduleId: number,
    module: ModuleWithSortedQuestions,
  ): NewRecruitmentModule => {
    const questionId = deletedQuestion.id;
    switch (true) {
      case module.questions.some(
        ({ question, refreshable }) => question.id === questionId && !refreshable,
      ): {
        const newChosenQuestionIds = module.questions
          .filter(({ refreshable }) => !refreshable)
          .filter(({ question }) => question.id !== questionId)
          .map(({ question }) => question.id);

        return {
          moduleId,
          chosenQuestionIds: newChosenQuestionIds,
          numberOfRandomQuestions: module.questions.filter(
            ({ question, refreshable }) => refreshable && !question.isPractical,
          ).length,
          numberOfRandomTasks: module.questions.filter(
            ({ question, refreshable }) => refreshable && question.isPractical,
          ).length,
        };
      }
      case deletedQuestion.isPractical: {
        const numberOfRandomTasks =
          module.questions.filter(
            ({ question, refreshable }) => refreshable && question.isPractical,
          ).length - 1;

        return {
          moduleId,
          chosenQuestionIds: module.questions
            .filter(({ refreshable }) => !refreshable)
            .map(({ question }) => question.id),
          numberOfRandomQuestions: module.questions.filter(
            ({ question, refreshable }) => refreshable && !question.isPractical,
          ).length,
          numberOfRandomTasks,
        };
      }
      default: {
        const numberOfRandomQuestions =
          module.questions.filter(
            ({ question, refreshable }) => refreshable && !question.isPractical,
          ).length - 1;

        return {
          moduleId,
          chosenQuestionIds: module.questions
            .filter(({ refreshable }) => !refreshable)
            .map(({ question }) => question.id),
          numberOfRandomQuestions,
          numberOfRandomTasks: module.questions.filter(
            ({ question, refreshable }) => refreshable && question.isPractical,
          ).length,
        };
      }
    }
  };

  const deleteQuestion = async (question: Question, moduleId: number) => {
    const questionId = question.id;
    const module = state.modulesWithSortedQuestions.find((x) => x.id === moduleId);
    if (!module) {
      return;
    }
    try {
      const newModule = returnNewModuleAfterDelete(question, question.moduleId, module);
      if (
        newModule.chosenQuestionIds.length +
          newModule.numberOfRandomQuestions +
          newModule.numberOfRandomTasks ===
        0
      ) {
        await deleteRecruitmentModule(moduleId);
        if (state.modulesWithSortedQuestions.length === 1) {
          deleteRecruitment();
        }
      } else {
        await updateRecruitmentModule(moduleId, newModule);
      }

      dispatch({
        type: 'deleteQuestion',
        questionId,
        moduleId,
      });
    } catch (e) {
      handleException(e);
    }
  };

  const handleRedrawAll = async (
    event: MouseEvent<HTMLElement>,
    module: ModuleWithSortedQuestions,
  ) => {
    event.stopPropagation();
    const currentQuestions = module.questions.map(({ question }) => question.id);

    try {
      const response = await reloadQuestionsAsync(module.id, currentQuestions);
      dispatch({
        type: 'reloadModule',
        reloadedModule: {
          ...response.data.data,
          name: module.name,
          description: module.description,
          id: module.id,
        },
        moduleId: module.id,
      });
    } catch (e) {
      handleException(e);
    }
  };

  const openNextModule = () => {
    const currentIndex = state.recruitmentModules.findIndex(
      (m) => m.id === currentModule,
    );
    if (state.recruitmentModules[currentIndex + 1]) {
      setExpandedQuestionIdx(
        state.recruitmentModules[currentIndex + 1].recruitmentQuestions.findIndex(
          ({ grade }) => grade === null,
        ),
      );
      setCurrentModule(state.recruitmentModules[currentIndex + 1]?.id);
    } else {
      setCurrentModule(undefined);
    }
  };

  useEffect(() => {
    state.modulesWithSortedQuestions.forEach((module) => {
      if (!module.questions.length) {
        toast.error(
          <ErrorToast
            message={`Module ${module.name} has no questions in base, please remove it in edit view`}
          />,
        );
        setStartRecruitmentEnabled(false);
      }
    }, []);
  }, [state.modulesWithSortedQuestions]);

  useEffect(() => {
    const moduleQuestions =
      state.modulesWithSortedQuestions.find((m) => m.id === currentModule)?.questions ??
      [];
    if (
      moduleQuestions.length !== questions.length ||
      questions.some((q, idx) => q.question.id !== moduleQuestions[idx].question.id)
    ) {
      setQuestions(
        state.modulesWithSortedQuestions.find((module) => module.id === currentModule)
          ?.questions ?? [],
      );
    }
  }, [currentModule, state.modulesWithSortedQuestions, questions]);

  useEffect(() => {
    if (recruitmentData?.recruitmentState === RecruitmentState.InProgress) {
      setRecruitmentQuestions(
        state.recruitmentModules.find((m) => m.id === currentModule)
          ?.recruitmentQuestions ?? [],
      );
    }
  }, [currentModule, recruitmentData, state.recruitmentModules]);

  useEffect(() => {
    if (recruitmentData?.recruitmentState === RecruitmentState.InProgress) {
      getModules();
    }
  }, [getModules, recruitmentData?.recruitmentState]);

  if (!recruitmentData) {
    return (
      <LoaderContainer>
        <Loader size="100px" />
      </LoaderContainer>
    );
  }

  return (
    <Container isLoading={isLoading}>
      {recruitmentData.recruitmentState === RecruitmentState.InProgress && (
        <RecruiterNote defaultValue={recruitmentData?.recruiterNote || ''} />
      )}
      <S.Main
        $isNarrow={recruitmentData.recruitmentState !== RecruitmentState.InProgress}
      >
        <RecruitmentInfo recruitment={recruitmentData} />
        <S.ModulesContainer>
          {recruitmentData.recruitmentState === RecruitmentState.InProgress ? (
            <DropZone
              elements={draggableRecruitmentModules({
                recruitmentModules: state.recruitmentModules,
                setCurrentModule,
                currentModule,
                setExpandedQuestionIdx,
                expandedQuestionIdx,
                recruitmentQuestions,
                deleteRecruitmentQuestion,
                openNextModule,
                setLoadingRecruitment,
                reloadRecruitmentQuestion,
                dispatch,
                modulesData,
              })}
              droppableId="recruitmentModules"
              handleOnDragEnd={(result) =>
                handleOnDragEndRecruitmentState({
                  result,
                  dispatch,
                  recruitmentModules: state.recruitmentModules,
                  sortRecruitmentModules,
                  recruitmentQuestions,
                  expandedQuestionIdx,
                  setExpandedQuestionIdx,
                  setRecruitmentQuestions,
                  sortRecruitmentQuestions,
                })
              }
              type="recruitmentModules"
            />
          ) : (
            <DropZone
              elements={draggableStartModules({
                modulesWithSortedQuestions: state.modulesWithSortedQuestions,
                setCurrentModule,
                currentModule,
                questions,
                reloadQuestion,
                deleteQuestion,
                handleRedrawAll,
                dispatch,
              })}
              droppableId="startRecruitmentModules"
              handleOnDragEnd={(result) =>
                handleOnDragEndStartState({
                  result,
                  modulesWithSortedQuestions: state.modulesWithSortedQuestions,
                  dispatch,
                  sortRecruitmentModules,
                  setQuestions,
                })
              }
              type="startRecruitmentModules"
            />
          )}
          <AddRecruitmentModule
            allModules={modulesData}
            usedModules={state.recruitmentModules}
            dispatch={dispatch}
          />
        </S.ModulesContainer>
        <BottomStripe
          recruitmentState={recruitmentData.recruitmentState}
          onRecruitmentStart={handleStartRecruitment}
          onFinishRecruitment={finishRecruitment}
          isFinishButtonDisabled={isDisabledFinishRecruitment}
          isFinishButtonLoading={loadingRecruitment}
          isStartRecruitmentEnabled={isStartRecruitmentEnabled}
        />
      </S.Main>
    </Container>
  );
};

export default Recruitment;
