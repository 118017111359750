import Select from 'react-select';
import { Box, FormControlLabel } from '@material-ui/core';
import { HireState } from 'types/recruitment';
import { CheckedIcon, UncheckedIcon } from 'components/Icons';
import { RecruiterReviewProps } from './RecruiterReview.types';
import * as S from './RecruiterReview.css';

const hireOptions = [
  {
    value: HireState.Hire,
    label: 'Hire',
  },
  { value: HireState.NotHire, label: 'Not hire' },
];

const customStyles = {
  singleValue: (provided: any) => ({
    ...provided,
    backgroundColor: 'white',
    color: 'black',
  }),
  control: (provided: any) => ({
    ...provided,
    backgroundColor: 'white',
  }),
};

const RecruiterReview = ({
  cooperationChecked,
  cooperationClicked,
  independenceChecked,
  independenceClicked,
  managementChecked,
  managementClicked,
  editable,
  recommendation,
  recruitmentData,
  recruitmentSummaryData,
  handleRecommendationChange,
  debouncedOpinionChange,
  generatedView,
  setGeneralNote,
}: RecruiterReviewProps) => {
  const generalNote = recruitmentData?.generalNote || recruitmentSummaryData?.generalNote;
  return (
    <>
      <S.ReviewTitle>{!generatedView ? 'Recruiter Review' : ''}</S.ReviewTitle>
      <S.QuestionsOpinion>
        <S.TitleQuestionOpinion>
          <S.QuestionText />
          <S.TitleQuestionCheckbox>
            <S.QuestionWordCheckbox>Yes</S.QuestionWordCheckbox>
            <S.QuestionWordCheckbox>No</S.QuestionWordCheckbox>
          </S.TitleQuestionCheckbox>
        </S.TitleQuestionOpinion>
        <S.QuestionOpinion>
          <S.QuestionText>Would I like to work with this person?</S.QuestionText>
          <S.QuestionCheckbox>
            <FormControlLabel
              control={
                <S.Checkbox
                  icon={<UncheckedIcon />}
                  checkedIcon={<CheckedIcon />}
                  name="cooperationTrue"
                  onClick={() => cooperationClicked(true)}
                  checked={cooperationChecked}
                  disableRipple={!editable}
                  $editable={editable}
                />
              }
              label=""
            />
            <FormControlLabel
              control={
                <S.Checkbox
                  icon={<UncheckedIcon />}
                  checkedIcon={<CheckedIcon />}
                  name="cooperationFalse"
                  onClick={() => cooperationClicked(false)}
                  checked={cooperationChecked === false}
                  disableRipple={!editable}
                  $editable={editable}
                />
              }
              label=""
            />
          </S.QuestionCheckbox>
        </S.QuestionOpinion>
        <S.QuestionOpinion>
          <S.QuestionText>
            Do I believe that the candidate is suitable for independent work?
          </S.QuestionText>
          <S.QuestionCheckbox>
            <FormControlLabel
              control={
                <S.Checkbox
                  icon={<UncheckedIcon />}
                  checkedIcon={<CheckedIcon />}
                  name="independentTrue"
                  onClick={() => independenceClicked(true)}
                  checked={independenceChecked}
                  disableRipple={!editable}
                  $editable={editable}
                />
              }
              label=""
            />
            <FormControlLabel
              control={
                <S.Checkbox
                  icon={<UncheckedIcon />}
                  checkedIcon={<CheckedIcon />}
                  name="independentFalse"
                  onClick={() => independenceClicked(false)}
                  checked={independenceChecked === false}
                  disableRipple={!editable}
                  $editable={editable}
                />
              }
              label=""
            />
          </S.QuestionCheckbox>
        </S.QuestionOpinion>
        <S.QuestionOpinion>
          <S.QuestionText>
            Do I believe that the candidate is suitable for managing a small group of
            people?
          </S.QuestionText>
          <S.QuestionCheckbox>
            <FormControlLabel
              control={
                <S.Checkbox
                  icon={<UncheckedIcon />}
                  checkedIcon={<CheckedIcon />}
                  name="managementTrue"
                  onClick={() => managementClicked(true)}
                  checked={managementChecked}
                  disableRipple={!editable}
                  $editable={editable}
                />
              }
              label=""
            />
            <FormControlLabel
              control={
                <S.Checkbox
                  icon={<UncheckedIcon />}
                  checkedIcon={<CheckedIcon />}
                  name="managementFalse"
                  onClick={() => managementClicked(false)}
                  checked={managementChecked === false}
                  disableRipple={!editable}
                  $editable={editable}
                />
              }
              label=""
            />
          </S.QuestionCheckbox>
        </S.QuestionOpinion>
      </S.QuestionsOpinion>
      {editable && (
        <Box display="flex" flexDirection="column">
          <S.ReviewSubtitle>General Opinion</S.ReviewSubtitle>
          <S.TextArea
            placeholder="Write your general opinion about the candidate."
            defaultValue={generalNote ?? ''}
            onChange={(e) => {
              setGeneralNote(e.target.value);
              debouncedOpinionChange(e);
            }}
          />
        </Box>
      )}
      {!editable && generalNote && (
        <S.GeneralOpinion>
          <S.ReviewSubtitle>General Opinion</S.ReviewSubtitle>
          <S.GeneralOpinionContent>{generalNote}</S.GeneralOpinionContent>
        </S.GeneralOpinion>
      )}
      <S.RecommendationBox>
        <S.RecommendationTitle>Recommendation:</S.RecommendationTitle>
        <S.RecommendationSelectContainer>
          {editable || !generatedView ? (
            <Select
              options={hireOptions}
              isClearable={false}
              isSearchable={false}
              onChange={handleRecommendationChange}
              value={recommendation}
              isDisabled={!editable}
              styles={customStyles}
            />
          ) : (
            <div>{`${recommendation?.label}`}</div>
          )}
        </S.RecommendationSelectContainer>
      </S.RecommendationBox>
    </>
  );
};

export default RecruiterReview;
