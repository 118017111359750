import styled from 'styled-components';

export const QuestionPageContainer = styled.div`
  background-color: #ffffff;
  min-height: 100vh;
  padding: 46px 32px;
`;

export const AddStripe = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
  justify-content: space-between;
  @media (max-width: 906px) {
    justify-content: center;
  }
`;

export const LeftActionsBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;
