import { useEffect } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Box } from '@material-ui/core';
import { useGetLoggedUser } from 'api/users';
import Exit from 'assets/exit-icon.svg';
import Logo from 'assets/jit-logo.svg';
import { loginRequest } from 'authConfig';
import { useRecruitmentContext, useUserContext } from 'context';
import * as URL from 'router/url';
import { RecruitmentState } from 'types/recruitment';
import { useUserIsAuthorized } from 'utils/hooks';
import * as S from './Navbar.css';

const Navbar = () => {
  const { pathname, search } = useLocation();
  const { push } = useHistory();
  const { setUser, user } = useUserContext();
  const { loggedUser, getLoggedUser } = useGetLoggedUser();

  useEffect(() => {
    if (loggedUser && setUser) {
      setUser(loggedUser);
    }
  }, [loggedUser, setUser]);

  const { instance, accounts } = useMsal();

  const {
    recruitment: { recruitmentData },
  } = useRecruitmentContext();

  const isActive = (path: string) => pathname === path;

  const description =
    recruitmentData?.recruitmentState === RecruitmentState.InProgress
      ? 'In progress'
      : 'Overview';

  const selectedRecruiter = new URLSearchParams(search).get('recruiter');
  const recruiterSearchParam = selectedRecruiter
    ? new URLSearchParams({ recruiter: selectedRecruiter }).toString()
    : '';

  const userIsAuthorized = useUserIsAuthorized();
  const isAuthenticated = useIsAuthenticated();

  if (
    pathname.match(/^\/ended-recruitment\/\d*/) ||
    pathname.match(/^\/generated-ended-recruitment\/\d*/) ||
    pathname.match(/^\/editable-generated-ended-recruitment\/\d*/)
  ) {
    return (
      <S.RecruitmentNavbarContainer>
        <Box display="flex" alignItems="center">
          {!userIsAuthorized ? (
            <S.Logo src={Logo} alt="logo" />
          ) : (
            <S.Logo
              src={Logo}
              alt="logo"
              $clickable
              onClick={() => {
                if (isAuthenticated && !user && !!setUser) {
                  const request = {
                    ...loginRequest,
                    account: accounts[0],
                  };

                  instance.acquireTokenSilent(request).then((response) => {
                    localStorage.setItem('token', response.accessToken);
                    getLoggedUser();
                  });
                }
                push('/ended-recruitment');
              }}
            />
          )}

          <S.NavbarTitle>Technical Recruitment - Summary</S.NavbarTitle>
        </Box>
      </S.RecruitmentNavbarContainer>
    );
  }

  const handleLogout = () => {
    const logoutRequest = {
      account: accounts[0],
    };
    instance.logoutRedirect(logoutRequest).catch((e) => {
      console.error(e);
    });
    localStorage.removeItem('token');
  };

  if (pathname.match(/^\/unauthorized*/)) {
    return (
      <S.RecruitmentNavbarContainer>
        <Box display="flex" alignItems="center">
          <S.Logo src={Logo} alt="logo" />
          <S.NavbarTitle>Sphinx - Recruitment Application</S.NavbarTitle>
        </Box>
        <S.NavbarElem>
          <S.LogoutButton onClick={handleLogout}>
            <Box display="flex" alignItems="center">
              <p> LOG OUT </p>
              <S.ExitIcon src={Exit} alt="exit" />
            </Box>
          </S.LogoutButton>
        </S.NavbarElem>
      </S.RecruitmentNavbarContainer>
    );
  }

  if (pathname.match(/^\/recruitment\d*/)) {
    return (
      <S.RecruitmentNavbarContainer>
        <Box display="flex" alignItems="center">
          <NavLink to={URL.TODO}>
            <S.Logo src={Logo} alt="logo" />
          </NavLink>
          <S.NavbarTitle>Technical Recruitment - {description}</S.NavbarTitle>
        </Box>
      </S.RecruitmentNavbarContainer>
    );
  }

  return (
    <S.NavbarContainer>
      <S.Navbar>
        <S.NavbarElem>
          <S.StyledLink
            to={{
              pathname: URL.TODO,
              search: recruiterSearchParam,
            }}
            $isActive={isActive(URL.TODO)}
          >
            recruitment to do
          </S.StyledLink>
        </S.NavbarElem>
        <S.NavbarElem>
          <S.StyledLink
            to={{
              pathname: URL.ENDED_RECRUITMENT,
              search: recruiterSearchParam,
            }}
            $isActive={isActive(URL.ENDED_RECRUITMENT)}
          >
            recruitment ended
          </S.StyledLink>
        </S.NavbarElem>
        <S.NavbarElem>
          <S.StyledLink to={URL.TEMPLATES} $isActive={isActive(URL.TEMPLATES)}>
            templates
          </S.StyledLink>
        </S.NavbarElem>
        <S.NavbarElem>
          <S.StyledLink to={URL.QUESTIONS} $isActive={isActive(URL.QUESTIONS)}>
            question base
          </S.StyledLink>
        </S.NavbarElem>
        <S.NavbarElem>
          <S.StyledLink to={URL.REPORTS} $isActive={isActive(URL.REPORTS)}>
            reports
          </S.StyledLink>
        </S.NavbarElem>
        <S.NavbarElem>
          <S.LogoutButton onClick={handleLogout}>
            <Box display="flex" alignItems="center">
              <p> LOG OUT </p>
              <S.ExitIcon src={Exit} alt="exit" />
            </Box>
          </S.LogoutButton>
        </S.NavbarElem>
      </S.Navbar>
    </S.NavbarContainer>
  );
};

export default Navbar;
