import { Clear } from '@material-ui/icons';
import styled from 'styled-components';

export const ChosenModulesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  width: 40%;
`;

export const CrossIcon = styled(Clear)`
  &.MuiSvgIcon-root {
    font-size: 15px;
    margin: auto;
    margin-top: 3px;
  }
`;

export const ChosenModuleTile = styled.div`
  background-color: rgba(240, 240, 240);
  margin-right: 5px;
  display: flex;
  margin-bottom: 5px;
  padding: 2px 5px;
  border-radius: 20px;

  &:hover {
    cursor: pointer;
    background-color: rgba(220, 220, 220);
  }
`;

export const ClearAll = styled.p`
  margin-bottom: 20px;
  margin-left: 5px;
  text-decoration: underline;
  width: fit-content;
  font-size: 18px;

  &:hover {
    font-weight: bold;
    cursor: pointer;
  }
`;
