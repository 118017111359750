import { createContext, useContext, useMemo } from 'react';
import { RecruitmentService } from 'api';
import { AxiosPromise } from 'axios';
import { Recruitment } from 'types/recruitment';
import { GlobalTimerProviderProps } from './RecruitmentContext.types';

const RecruitmentContext = createContext<{
  recruitment: {
    recruitmentData: Recruitment | null;
    isLoadingRecruitment: boolean;
    getRecruitment: (id?: number | string) => AxiosPromise<{ data: Recruitment }>;
  };
}>({
  recruitment: {
    isLoadingRecruitment: false,
    recruitmentData: null,
    getRecruitment: () =>
      new Promise(() => {
        // This is intentional - it is beginning state
      }),
  },
});

export const RecruitmentContextProvider = ({ children }: GlobalTimerProviderProps) => {
  const { recruitmentData, getRecruitment, isLoadingRecruitment } =
    RecruitmentService.useGetRecruitment();

  const value = useMemo(
    () => ({
      recruitment: {
        recruitmentData: recruitmentData || null,
        getRecruitment,
        isLoadingRecruitment,
      },
    }),
    [recruitmentData, getRecruitment, isLoadingRecruitment],
  );

  return (
    <RecruitmentContext.Provider value={value}>{children}</RecruitmentContext.Provider>
  );
};

export const useRecruitmentContext = () => useContext(RecruitmentContext);
