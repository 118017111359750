import { Accordion, AccordionDetails } from '@material-ui/core';
import styled from 'styled-components';
import theme from 'theme';

export const ModuleAccordion = styled(Accordion)`
  &.MuiAccordion-root {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
  }
  .MuiAccordionSummary-root {
    padding: 0;
  }
`;

export const ModuleAccordionDetails = styled(AccordionDetails)`
  flex-direction: column;
`;

export const ModuleTitle = styled.div`
  width: 25%;
  font-size: large;
  overflow-wrap: break-word;
  margin: 0;
  font-weight: bold;
  padding: 5px 70px 5px 50px;
  background-color: #ffd242;
  clip-path: polygon(0 0, 93% 0%, 90% 120%, 0% 100%);
`;

export const Icons = styled.div`
  display: flex;
  align-items: center;
`;

export const IconInfo = styled.div`
  margin-left: 5px;
  margin-right: 5px;
`;

export const TotalScore = styled.div`
  font-size: large;
  text-align: center;
  margin-left: auto;
  margin-right: 80px;
`;

export const Icon = styled.img`
  height: 20px;
`;

export const ReviewedQuestions = styled.span`
  color: ${theme.palette.success.main};
`;



export const QuestionTime = styled.div`
  padding-top: 15px;
`;

export const QuestionContainer = styled.div`
  display: flex;
`;

export const BoldText = styled.span`
  font-weight: bold;
`;