import { Module } from 'types/module';
import { useAxios } from './axios';

export const useGetModules = () => {
  const [{ data = { data: [] }, loading: isLoadingModules }, getModules] = useAxios<{
    data: Module[];
  }>({ url: '/modules' }, { manual: true });

  return { modulesData: data.data, isLoadingModules, getModules };
};

export const useCreateModule = () => {
  const [{ data, loading: isCreatingModule }, createModule] = useAxios(
    {
      url: `/modules`,
      method: 'POST',
    },
    { manual: true },
  );
  const moduleData = data?.data;

  return { moduleData, isCreatingModule, createModule };
};

export const useDeleteModule = (id: number) => {
  const [, deleteModule] = useAxios(
    { url: `/modules/${id}`, method: 'DELETE' },
    { manual: true },
  );
  return deleteModule;
};

export const usePatchModule = (id?: number) => {
  const [{ data, loading: isPatchingModule }, patchModule] = useAxios(
    { url: `/modules/${id}`, method: 'PATCH' },
    { manual: true },
  );
  return { moduleData: data?.data, isPatchingModule, patchModule };
};
