import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Box, TextField, Typography } from '@material-ui/core';
import { TemplateService } from 'api';
import { Button, HorizontalLine, Loader, LoaderContainer } from 'styles.global';
import Container from 'components/Container';
import { useRedirect } from 'utils/hooks';
import { RouteParams } from 'utils/types/RouteParams';
import * as S from './TemplateView.css';

export const TemplateView = () => {
  const { push } = useHistory();
  const { template, getTemplate } = TemplateService.useGetTemplate();
  const { id: templateId } = useParams<RouteParams>();

  const redirect = useRedirect('/templates');

  useEffect(() => {
    getTemplate(+templateId);
  }, [getTemplate, templateId]);

  return (
    <div>
      {!template ? (
        <LoaderContainer>
          <Loader size="100px" />
        </LoaderContainer>
      ) : (
        <Container isLoading={!template}>
          <S.TemplateContainer>
            <S.LeftPanel>
              <Box display="flex" flexWrap="wrap" mt={6}>
                <Box display="flex" alignItems="center" mr={6}>
                  <Box mr={2}>
                    <Typography variant="h6"> Name: </Typography>
                  </Box>
                  <S.TemplateTextField
                    variant="outlined"
                    size="small"
                    value={template.name}
                    disabled
                  />
                </Box>
                <Box display="flex" alignItems="center">
                  <Box mr={2}>
                    <Typography variant="h6"> Position: </Typography>
                  </Box>
                  <S.TemplateTextField
                    variant="outlined"
                    size="small"
                    value={template.position}
                    disabled
                  />
                </Box>
              </Box>
              <S.Header>
                <S.HeaderTitle />
              </S.Header>
              {template.templateModules.map((recruitmentModule, index) => (
                <div key={recruitmentModule.id}>
                  <HorizontalLine />
                  <S.Header fontSize="18px">
                    <S.HeaderTitle>Module: {index + 1}</S.HeaderTitle>
                  </S.Header>
                  <S.ModulePanel>
                    <S.TheoreticalQuestionLabel>
                      Number of random theoretical questions:
                    </S.TheoreticalQuestionLabel>
                    <S.SelectedModule>
                      <TextField
                        fullWidth
                        multiline
                        variant="outlined"
                        size="small"
                        disabled
                        value={recruitmentModule.moduleName}
                      />
                    </S.SelectedModule>
                    <S.NumberOfQuestionsInput>
                      <S.NumberOfQuestionsTextField
                        disabled
                        value={recruitmentModule.numberOfRandomQuestions}
                        variant="outlined"
                        size="small"
                      />
                    </S.NumberOfQuestionsInput>
                    <S.PracticalQuestionLabel>
                      Number of random practical tasks:
                    </S.PracticalQuestionLabel>
                    <S.NumberOfTasksInput>
                      <S.NumberOfTasksTextField
                        disabled
                        value={recruitmentModule.numberOfRandomTasks}
                        variant="outlined"
                        size="small"
                      />
                    </S.NumberOfTasksInput>
                    {recruitmentModule.templateChosenQuestions.length > 0 && (
                      <>
                        <S.SelectedQuestionsLabel>
                          Additional mandatory question/task:
                          <S.SelectedQuestionsDescription>
                            This question/task will be drawn automatically from the module
                          </S.SelectedQuestionsDescription>
                        </S.SelectedQuestionsLabel>
                        <S.SelectedQuestions>
                          {recruitmentModule.templateChosenQuestions.map(
                            ({ question }) => (
                              <Box mb={3} key={question.id}>
                                <TextField
                                  fullWidth
                                  size="small"
                                  multiline
                                  variant="outlined"
                                  value={question.text}
                                  disabled
                                />
                              </Box>
                            ),
                          )}
                        </S.SelectedQuestions>
                      </>
                    )}
                  </S.ModulePanel>
                </div>
              ))}
              <HorizontalLine />
              <Box display="flex" justifyContent="flex-end">
                <Box mr={3}>
                  <Button onClick={() => push(`/edit-template/${template.id}`)}>
                    Edit
                  </Button>
                </Box>
                <S.SaveButton onClick={redirect}>Back to list</S.SaveButton>
              </Box>
            </S.LeftPanel>
          </S.TemplateContainer>
        </Container>
      )}
    </div>
  );
};
