import { createStyles, IconButton, makeStyles } from '@material-ui/core';
import styled from 'styled-components';
import { Button } from 'styles.global';
import theme from 'theme';

export const popperStyles = makeStyles(() =>
  createStyles({
    root: {
      '& .MuiAutocomplete-listbox': {
        whiteSpace: 'pre-wrap',
      },
    },
  }),
);

export const AddQuestionsContainer = styled.div`
  background-color: white;
  padding: 24px 16px 24px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12), 0px 0px 4px rgba(0, 0, 0, 0.08);
  border: 0.5px #ededed solid;
  position: relative;
`;

export const AddInputWrapper = styled.div`
  width: 370px;
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const SubmitButton = styled(Button).attrs({ $primary: true })`
  &.MuiButton-root {
    margin-top: 16px;
    width: 200px;
    height: 100%;
    margin-left: 4px;
  }
`;

export const InputsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
`;

export const ActionButton = styled(IconButton)`
  &.MuiButtonBase-root {
    position: absolute;
    top: 8px;
    left: 8px;
    padding: 10px;
    color: ${theme.palette.secondary.dark};
  }
`;
