import { MouseEvent, useState } from 'react';
import { AccordionSummary, Box, Tooltip } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ModuleService, QuestionService } from 'api';
import Brain from 'assets/brain.svg';
import Hammer from 'assets/hammer.svg';
import { ModuleForm } from 'pages/Questions/components/ModuleForm';
import { QuestionForm } from 'pages/Questions/components/QuestionForm';
import QuestionList from 'pages/Questions/components/QuestionList';
import { mapToCriterion } from 'pages/Questions/utils';
import { Question } from 'types/question';
import DeleteDialog from 'components/DeleteDialog';
import { handleException } from 'utils/errorHandlingUtils';
import { getDate } from 'utils/formatters';
import { ModuleRowProps } from './ModuleRow.types';
import * as S from './ModuleRow.css';

export const ModuleRow = ({
  module,
  moduleNames,
  deleteModule,
  addQuestionsToModule,
  addQuestion,
  deleteQuestion,
  updateModule,
  updateQuestion,
}: ModuleRowProps) => {
  const [open, setOpen] = useState(false);
  const [isOpenEdit, setOpenEdit] = useState(false);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isAddQuestionDialogOpen, setAddQuestionDialogOpen] = useState(false);

  const [shouldFetch, setShouldFetch] = useState(true);

  const { isLoadingQuestionsData, getQuestions } = QuestionService.useGetQuestions();

  const fetchQuestions = async () => {
    try {
      const response = await getQuestions({
        moduleIds: [module.id],
        perPage: module.numberOfQuestions + module.numberOfTasks,
      });
      const questions = response.data.data.map((question: any) => ({
        ...question,
        criteria: question.criteria && mapToCriterion(question.criteria),
        updatedAt: getDate(question.updatedAt),
      }));
      addQuestionsToModule(questions, module.id);
      setShouldFetch(false);
    } catch (e) {
      handleException(e);
    }
  };

  const handleModuleClick = () => {
    if (shouldFetch && !module.questions.length) {
      fetchQuestions();
    }
    setOpen((prevState) => !prevState);
  };

  function shouldBreakWord(name: string) {
    const MAX_WORD_CHARS = 30;
    return name.split(' ').some((word) => word.length > MAX_WORD_CHARS);
  }

  const deleteModuleAsync = ModuleService.useDeleteModule(module.id);

  const handleDeleteModule = async () => {
    try {
      await deleteModuleAsync();
      setDeleteDialogOpen(false);
      deleteModule(module.id);
    } catch (e) {
      handleException(e);
    }
  };

  const handleAddQuestionClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setAddQuestionDialogOpen(true);
  };

  const handleEditClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setOpenEdit(true);
  };

  return (
    <S.ModuleRow onChange={handleModuleClick} TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <S.ModuleName breakWord={shouldBreakWord(module.name)}>
          {module.name}
        </S.ModuleName>
        <S.ModuleIcons>
          <S.Icons>
            <Tooltip title="Theoretical questions">
              <img src={Brain} alt="brain" />
            </Tooltip>
            <S.IconInfo>{module.numberOfQuestions} questions</S.IconInfo>
            <Box ml={2}>
              <Tooltip title="Practical tasks">
                <img src={Hammer} alt="hammer" />
              </Tooltip>
            </Box>
            <S.IconInfo>{module.numberOfTasks} tasks</S.IconInfo>
          </S.Icons>
        </S.ModuleIcons>
        <S.ModuleDate>
          <S.Date>last modified {module.updatedAt}</S.Date>
        </S.ModuleDate>
        <S.ModuleButtons>
          {open && (
            <>
              <S.IconsContainer onClick={handleAddQuestionClick} mr={2}>
                <S.AddIcon />
                add question to module
              </S.IconsContainer>
              <S.IconsContainer onClick={handleEditClick}>
                <S.EditIcon />
                edit
              </S.IconsContainer>
            </>
          )}
        </S.ModuleButtons>
      </AccordionSummary>
      <S.QuestionsContainer $enableScroll={!isLoadingQuestionsData}>
        {module?.description && (
          <S.ModuleDescription>{module?.description}</S.ModuleDescription>
        )}
        <QuestionList
          isFetching={isLoadingQuestionsData}
          questions={module.questions}
          deleteQuestion={deleteQuestion}
          updateQuestion={updateQuestion}
          handleDeleteModule={() => setDeleteDialogOpen(true)}
          moduleNames={moduleNames}
        />
      </S.QuestionsContainer>
      {isOpenEdit && (
        <ModuleForm
          open={isOpenEdit}
          module={module}
          moduleAction={updateModule}
          onClose={() => setOpenEdit(false)}
          editMode
        />
      )}
      {isDeleteDialogOpen && (
        <DeleteDialog
          open={isDeleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
          handleClick={handleDeleteModule}
          title="DELETE MODULE"
          content={
            <>
              You&apos;re trying to delete module <S.BoldText>{module.name}.</S.BoldText>
            </>
          }
        />
      )}
      {isAddQuestionDialogOpen && (
        <QuestionForm
          moduleNames={moduleNames}
          open={isAddQuestionDialogOpen}
          addQuestion={addQuestion}
          onClose={() => setAddQuestionDialogOpen(false)}
          question={{ moduleName: module.name, text: '' } as Question}
        />
      )}
    </S.ModuleRow>
  );
};
