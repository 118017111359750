import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Drawer } from '@material-ui/core';
import { RecruitmentService } from 'api';
import debounce from 'lodash.debounce';
import { handleException } from 'utils/errorHandlingUtils';
import { RouteParams } from 'utils/types/RouteParams';
import { RECRUITER_NOTE_DEBOUNCE_DELAY } from './RecruiterNote.const';
import { RecruiterNoteProps } from './RecruiterNote.types';
import * as S from './RecruiterNote.css';

export const RecruiterNote = ({
  defaultValue,
  isDisabled = false,
}: RecruiterNoteProps) => {
  const { id } = useParams<RouteParams>();

  const { patchRecruitment } = RecruitmentService.usePatchRecruitment();

  const [isOpen, setOpen] = useState(false);
  const [recruiterNote, setRecruiterNote] = useState(defaultValue);

  const handleRecruiterNoteChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setRecruiterNote(e.target.value);
    debouncedRecruiterNoteChange(e);
  };

  const patchRecruiterNote = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      try {
        patchRecruitment(+id, { recruiterNote: event.target.value });
      } catch (e) {
        handleException(e);
      }
    },
    [id, patchRecruitment],
  );

  const debouncedRecruiterNoteChange = useMemo(
    () => debounce(patchRecruiterNote, RECRUITER_NOTE_DEBOUNCE_DELAY),
    [patchRecruiterNote],
  );

  const placeholder = isDisabled ? '' : 'Enter note';

  return (
    <div>
      {isOpen ? (
        <Drawer open={isOpen} anchor="right" onClose={() => setOpen(false)}>
          <S.Note>
            <S.NoteContainer>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <h3> Recruiter notes </h3>
                <S.CloseNoteIcon onClick={() => setOpen(false)} />
              </Box>
              <S.RecruiterNoteTextArea
                disabled={isDisabled}
                spellCheck="false"
                placeholder={placeholder}
                defaultValue={recruiterNote}
                onChange={handleRecruiterNoteChange}
              />
              <Box textAlign="right" mt={0} fontSize="0.8vw">
                Your notes are autosaving
              </Box>
            </S.NoteContainer>
          </S.Note>
        </Drawer>
      ) : (
        <S.AddNoteContainer onClick={() => setOpen(true)}>
          <S.AddNoteIcon data-testid="note-icon" />
          <p>For recruiter</p>
        </S.AddNoteContainer>
      )}
    </div>
  );
};
