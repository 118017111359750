import { DateInputProps } from './DateInput.types';
import * as S from './DateInput.css';

const DateInput = ({
  name,
  autoFocus,
  label,
  onBlur,
  onChange,
  value,
  onFocus,
  onKeyDown,
  minDate,
  maxDate,
  invalidDateMessage,
}: DateInputProps) => (
  <S.KeyboardDatePicker
    name={name}
    autoFocus={autoFocus}
    label={label}
    onChange={(date) => {
      if (onChange) {
        onChange(date);
      }
    }}
    onBlur={(_) => {
      if (onBlur) {
        onBlur();
      }
    }}
    invalidDateMessage={invalidDateMessage || ''}
    maxDateMessage=""
    minDateMessage=""
    margin="dense"
    inputVariant="outlined"
    value={value}
    clearable
    format="dd.MM.yyyy"
    inputProps={{
      name,
    }}
    InputAdornmentProps={{
      style: {
        display: 'inherit',
      },
    }}
    KeyboardButtonProps={{
      style: {
        display: 'inherit',
      },
    }}
    onFocus={onFocus}
    style={{ textTransform: 'capitalize', width: '100%' }}
    onKeyDown={onKeyDown}
    placeholder="DD.MM.YYYY"
    minDate={minDate}
    maxDate={maxDate}
    fullWidth
    autoComplete="off"
  />
);

export default DateInput;
