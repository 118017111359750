import styled from 'styled-components';

export const ToDoPageContainer = styled.div`
  background-color: #ffffff;
  padding: 16px 32px;
  min-height: 100vh;
  min-width: 1150px;
`;

export const Actionbar = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 30px 0;
`;
