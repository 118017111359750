import { Accordion, AccordionDetails } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import styled from 'styled-components';

export const ModuleAccordion = styled(Accordion)`
  &.MuiAccordion-root {
    padding: 10px 0px 10px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
  }
  .MuiAccordionSummary-root {
    padding: 0;
  }
`;

export const ModuleAccordionDetails = styled(AccordionDetails)`
  flex-direction: column;
`;

export const ModuleTitle = styled.div`
  width: 25%;
  font-size: large;
  overflow-wrap: break-word;
  margin: 0;
  font-weight: bold;
  padding: 5px 70px 5px 50px;
  background-color: #ffd242;
  clip-path: polygon(0 0, 93% 0%, 90% 120%, 0% 100%);
`;

export const BoldText = styled.span`
  font-weight: bold;
`;

export const Icons = styled.div`
  display: flex;
  align-items: center;
`;

export const IconInfo = styled.div`
  margin-left: 5px;
  margin-right: 5px;
`;

export const RedrawAll = styled.div`
  margin-left: 100px;
  display: flex;
  padding: 5px;
  border-radius: 5px;
  &:hover {
    background-color: rgb(250, 250, 250);
  }
`;

export const Refresh = styled(RefreshIcon)`
  background-color: black;
  color: white;
  margin-left: 10px;
`;

export const Time = styled.div`
  margin-left: 90%;
  white-space: nowrap;
`;

export const Icon = styled.img`
  height: 20px;
  margin-left: 7px;
`;
