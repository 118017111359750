import { useCallback } from 'react';
import { useAxios } from './axios';
import { GetQuestionsOptions } from './types';

export const useGetQuestions = () => {
  const [{ data = { data: [] }, loading: isLoadingQuestionsData }, getQuestionsData] =
    useAxios({ method: 'GET' }, { manual: true });

  const getQuestions = useCallback(
    async ({ moduleIds = [], text = '', page = 0, perPage }: GetQuestionsOptions) =>
      getQuestionsData({
        url: '/questions',
        params: {
          modules: `${moduleIds}`,
          text,
          ...(perPage && { per_page: perPage }),
          page: page + 1, // * Backend counts pages from 1.
        },
      }),
    [getQuestionsData],
  );
  const questionsData = data.data;
  return { questionsData, isLoadingQuestionsData, getQuestions };
};

export const useCreateQuestion = () => {
  const [{ data, loading: isCreatingQuestion }, createQuestion] = useAxios(
    {
      url: `/questions`,
      method: 'POST',
    },
    { manual: true },
  );
  const questionData = data?.data;

  return { questionData, isCreatingQuestion, createQuestion };
};

export const usePatchQuestion = (id?: number) => {
  const [{ data, loading: isPatchingQuestion }, patchQuestion] = useAxios(
    {
      url: `/questions/${id}`,
      method: 'PATCH',
    },
    { manual: true },
  );
  const questionData = data?.data;

  return { questionData, isPatchingQuestion, patchQuestion };
};

export const useDeleteQuestion = (id: number) => {
  const [, deleteQuestion] = useAxios(
    {
      method: 'DELETE',
      url: `/questions/${id}`,
    },
    { manual: true },
  );

  return deleteQuestion;
};
