import { Box } from '@material-ui/core';
import { QuestionRow } from 'pages/Questions/components/QuestionList/components/QuestionRow';
import { DeleteIcon } from 'styles.global';
import Container from 'components/Container';
import { QuestionListProps } from './QuestionList.types';
import * as S from './QuestionList.css';

const QuestionList = ({
  isFetching,
  questions,
  deleteQuestion,
  updateQuestion,
  toggleModuleView,
  handleDeleteModule,
  moduleNames,
  displayModuleName = false,
}: QuestionListProps) => (
  <Container isLoading={isFetching}>
    {questions.length ? (
      <>
        {questions.map((question) => (
          <QuestionRow
            deleteQuestion={deleteQuestion}
            updateQuestion={updateQuestion}
            toggleModuleView={toggleModuleView}
            question={question}
            key={question.id}
            displayModuleName={displayModuleName}
            moduleNames={moduleNames}
          />
        ))}
      </>
    ) : (
      <S.EmptyList>
        {!displayModuleName ? (
          <Box display="flex" justifyContent="space-between">
            <S.EmptyModule>This module is empty</S.EmptyModule>
            <S.DeleteIcon onClick={handleDeleteModule}>
              <DeleteIcon />
              <S.IconText>delete module</S.IconText>
            </S.DeleteIcon>
          </Box>
        ) : (
          <Box display="flex" justifyContent="center" fontSize="25px">
            No questions
          </Box>
        )}
      </S.EmptyList>
    )}
  </Container>
);

export default QuestionList;
