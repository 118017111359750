import { ChangeEvent, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { CircularProgress, Dialog, DialogContent, TextField } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import MUIAutocomplete from '@material-ui/lab/Autocomplete';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { RecruitmentService } from 'api';
import axios from 'axios';
import { useUserContext } from 'context';
import { isValid } from 'date-fns';
import { Button } from 'styles.global';
import { Position, Recruitment } from 'types/recruitment';
import Autocomplete from 'components/Autocomplete';
import DateTimeInput from 'components/DateTimeInput';
import ErrorToast from 'components/Toasts/ErrorToast';
import { ValidationError } from 'components/ValidationError';
import { handleException } from 'utils/errorHandlingUtils';
import { getDateTime, getFullName } from 'utils/formatters';
import { removeExtraSpaces, stringIsEmpty } from 'utils/helpers';
import { useCloseWithConfirm } from 'utils/hooks';
import {
  CANDIDATE_MAX_LENGTH,
  CV_MAX_LENGTH,
  POSITION_MAX_LENGTH,
  SENIORITY_LEVEL_MAX_LENGTH,
} from './RecruitmentForm.const';
import { Errors, FormOutput, RecruitmentFormProps } from './RecruitmentForm.types';
import * as S from './RecruitmentForm.css';

const createDefaultForm = (
  recruitment: Recruitment | undefined,
  getHrContact: () => string,
) =>
  ({
    candidateName: recruitment?.candidateName || '',
    hrContact: getHrContact(),
    recruiter: recruitment?.technicalRecruiter
      ? `${recruitment?.technicalRecruiter.name} ${recruitment?.technicalRecruiter.surname}`
      : '',
    remuneratedTechRecruiter: recruitment?.remuneratedTechRecruiter
      ? `${recruitment?.remuneratedTechRecruiter.name} ${recruitment?.remuneratedTechRecruiter.surname}`
      : '—',
    seniorityLevel: recruitment?.seniorityLevel || '',
    recruitmentDate: recruitment?.recruitmentDate || '',
    positionId: recruitment?.position?.id ?? null,
    cvFile: recruitment?.cvFile ?? null,
  } as FormOutput);

const isFormTouched = (
  formOutput: FormOutput,
  defaultForm: FormOutput,
  position: string,
  positions: Position[],
) =>
  position !==
    (positions.find((pos) => pos.id === defaultForm.positionId)?.positionName ?? '') ||
  formOutput.candidateName !== defaultForm.candidateName ||
  formOutput.recruiter !== defaultForm.recruiter ||
  formOutput.remuneratedTechRecruiter !== defaultForm.remuneratedTechRecruiter ||
  formOutput.recruitmentDate !== defaultForm.recruitmentDate ||
  formOutput.seniorityLevel !== defaultForm.seniorityLevel ||
  formOutput.hrContact !== defaultForm.hrContact ||
  formOutput.cvFile !== defaultForm.cvFile;

export const RecruitmentForm = ({
  editMode = false,
  onClose,
  recruitment,
  setPaginationOptions,
  hrs,
  positions,
  technicalRecruiters,
  getPositionsData,
}: RecruitmentFormProps) => {
  const handleClose = useCloseWithConfirm({ onClose });
  const { user } = useUserContext();
  const ref = useRef<HTMLInputElement>(null);

  const [cv, setCv] = useState<File | null>(null);
  const [isMakingRequest, setMakingRequest] = useState(false);

  const [errors, setErrors] = useState<Errors>({
    position: false,
    seniorityLevel: false,
    candidate: false,
    previousDate: false,
    incorrectDate: false,
    cv: false,
  });

  const [recruitmentDate, setRecruitmentDate] = useState(
    recruitment ? new Date(recruitment.recruitmentDate) : null,
  );

  const deleteCvDocument = RecruitmentService.useDeleteRecruitmentCvDocument();
  const createRecruitment = RecruitmentService.useCreateRecruitment();
  const { patchRecruitment } = RecruitmentService.usePatchRecruitment();

  const hrContactsNames = hrs.map(({ name, surname }) => getFullName(name, surname));
  const technicalRecruiterNames = technicalRecruiters.map(({ name, surname }) =>
    getFullName(name, surname),
  );

  const getHrContact = () => {
    if (recruitment?.hr) return `${recruitment?.hr.name} ${recruitment?.hr.surname}`;
    if (!user?.name) return '';
    return hrContactsNames.includes(user?.name) ? user?.name : '';
  };

  const defaultForm = createDefaultForm(recruitment, getHrContact);

  const [position, setPosition] = useState(recruitment?.position?.positionName || '');
  const [formOutput, setFormOutput] = useState<FormOutput>(defaultForm);

  const { createPosition, isCreatingPosition } = RecruitmentService.useCreatePosition();

  const getPosition = async () => {
    const processedPosition = positions.find((pos) => pos.positionName === position);
    if (!processedPosition) {
      try {
        const response = await createPosition({
          data: { positionName: removeExtraSpaces(position) },
        });
        getPositionsData();
        return response.data.data;
      } catch (e) {
        handleException(e);
      }
    }
    return processedPosition;
  };

  const processFormOutput = async () => {
    try {
      setMakingRequest(true);
      const pos = await getPosition();

      const hrContact = hrs.find(
        ({ name, surname }) => getFullName(name, surname) === formOutput.hrContact,
      );

      const technicalRecruiter = technicalRecruiters.find(
        ({ name, surname }) => getFullName(name, surname) === formOutput.recruiter,
      );

      const remuneratedTechRecruiter = technicalRecruiters.find(
        ({ name, surname }) =>
          getFullName(name, surname) === formOutput.remuneratedTechRecruiter,
      );

      let response = null;

      if (editMode) {
        response = await patchRecruitment(+recruitment!.id, {
          candidateName: removeExtraSpaces(formOutput.candidateName),
          technicalRecruiterId: technicalRecruiter?.id,
          remuneratedTechRecruiterId: remuneratedTechRecruiter?.id || null,
          positionId: pos?.id,
          seniorityLevel: formOutput.seniorityLevel,
          hrId: hrContact?.id,
          recruitmentDate: formOutput.recruitmentDate,
        });
      } else {
        response = await createRecruitment({
          data: {
            candidateName: removeExtraSpaces(formOutput.candidateName),
            technicalRecruiterId: technicalRecruiter?.id,
            remuneratedTechRecruiterId: remuneratedTechRecruiter?.id || null,
            positionId: pos?.id,
            seniorityLevel: formOutput.seniorityLevel,
            hrId: hrContact?.id,
            recruitmentDate: formOutput.recruitmentDate,
          },
        });
      }

      if (defaultForm.cvFile && !formOutput.cvFile) {
        await deleteCvDocument(response.data.data.id);
      }

      if (cv) {
        const formData = new FormData();
        formData.append('cv_file', cv);
        // useAxios is not working with FormData so we use axios directly
        await axios.post(`/api/v1/recruitments/${response.data.data.id}/cv`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
      }
      setPaginationOptions((prevState) => ({
        ...prevState,
        page: editMode ? prevState.page : 0,
        shouldFetch: true,
      }));
      onClose();
    } catch (e) {
      handleException(e);
    } finally {
      setMakingRequest(false);
    }
  };

  const handleRecruiterChange = (e: ChangeEvent<{}>, newValue: string | null) => {
    setFormOutput({
      ...formOutput,
      recruiter: newValue || '',
      remuneratedTechRecruiter: newValue || '',
    });
  };

  const handlRemuneratedTechRecruiterChange = (
    e: ChangeEvent<{}>,
    newValue: string | null,
  ) => {
    setFormOutput({
      ...formOutput,
      remuneratedTechRecruiter: newValue || '',
    });
  };

  const handleCandidateChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormOutput({
      ...formOutput,
      candidateName: e.target.value || '',
    });
    setErrors({
      ...errors,
      candidate: removeExtraSpaces(e.target.value).length > CANDIDATE_MAX_LENGTH,
    });
  };

  const handleHrContactChange = (e: ChangeEvent<{}>, newValue: string | null) => {
    setFormOutput({
      ...formOutput,
      hrContact: newValue || '',
    });
  };

  const handlePositionAutocompleted = (e: ChangeEvent<{}>, newValue: string | null) => {
    setPosition(newValue ?? '');
    if (newValue) {
      setErrors({
        ...errors,
        position: removeExtraSpaces(newValue).length > POSITION_MAX_LENGTH,
      });
    }
  };

  const handlePositionChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPosition(e.target.value ?? '');
    setErrors({
      ...errors,
      position: removeExtraSpaces(e.target.value).length > POSITION_MAX_LENGTH,
    });
  };

  const handleSeniorityLevelChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormOutput({
      ...formOutput,
      seniorityLevel: e.target.value || '',
    });
    setErrors({
      ...errors,
      seniorityLevel:
        removeExtraSpaces(e.target.value).length > SENIORITY_LEVEL_MAX_LENGTH,
    });
  };

  const handleDateChange = (e: MaterialUiPickersDate) => {
    setRecruitmentDate(e);
    if (isValid(e) && e !== null) {
      const dateTime = getDateTime(e, 'yyyy-MM-dd HH:mm');
      const formattedDate = dateTime.split(' ').join('T');
      setFormOutput({
        ...formOutput,
        recruitmentDate: formattedDate,
      });
      if (e.getTime() < Date.now()) {
        setErrors({ ...errors, previousDate: true, incorrectDate: false });
      } else {
        setErrors({ ...errors, previousDate: false, incorrectDate: false });
      }
    } else {
      setErrors({ ...errors, previousDate: false, incorrectDate: false });
      setFormOutput({
        ...formOutput,
        recruitmentDate: '',
      });
    }
  };

  const handleDateOnBlur = () => {
    if (!isValid(recruitmentDate) && recruitmentDate !== null) {
      setErrors({ ...errors, incorrectDate: true });
    } else {
      setErrors({ ...errors, incorrectDate: false });
    }
  };

  const isFormFilled =
    !stringIsEmpty(position) &&
    !stringIsEmpty(formOutput.candidateName) &&
    !stringIsEmpty(formOutput.recruiter) &&
    !stringIsEmpty(formOutput.hrContact) &&
    formOutput.recruitmentDate?.length;

  const isSubmitDisabled =
    isMakingRequest ||
    !isFormFilled ||
    isCreatingPosition ||
    Object.values(errors).some(Boolean);

  const getRecruitmentErrorMessage = () => {
    if (errors.incorrectDate) {
      return 'Invalid date';
    }
    if (errors.previousDate) {
      return "Recruitment date can't be past";
    }
    return '';
  };

  const handleFileSelected = (e: ChangeEvent<HTMLInputElement>): void => {
    const files = Array.from(e.target.files ?? []);
    const fileName = files[0]?.name.split('.');
    if (['pdf'].includes(fileName[fileName.length - 1])) {
      setCv(files[0]);
      setFormOutput({
        ...formOutput,
        cvFile: files[0].name,
      });
      setErrors({ ...errors, cv: files[0].name.length > CV_MAX_LENGTH });
    } else {
      toast.error(<ErrorToast message="Invalid file type" />);
    }
  };

  const resetFile = () => {
    setCv(null);
    setFormOutput({
      ...formOutput,
      cvFile: null,
    });
    setErrors({ ...errors, cv: false });
  };

  const positionOptions = positions.map((pos) => pos.positionName);

  return (
    <Dialog
      open
      fullWidth
      maxWidth="sm"
      onClose={() =>
        handleClose(!isFormTouched(formOutput, defaultForm, position, positions))
      }
    >
      <S.StyledDialogTitle>
        {editMode ? 'EDIT RECRUITMENT' : 'ADD RECRUITMENT'}
      </S.StyledDialogTitle>
      <DialogContent>
        <S.StyledFormControl>
          <S.InputTitle>Candidate: </S.InputTitle>
          <TextField
            onChange={handleCandidateChange}
            variant="outlined"
            size="small"
            defaultValue={defaultForm.candidateName}
            placeholder="Enter candidate"
            style={{ color: 'red' }}
            error={errors.candidate}
          />
          {errors.candidate && <ValidationError limit={CANDIDATE_MAX_LENGTH} />}
          <S.InputTitle>Position: </S.InputTitle>
          <Autocomplete
            size="small"
            selectOptions={positionOptions}
            defaultValue={recruitment?.position?.positionName}
            value={position}
            onChange={handlePositionAutocompleted}
            textFieldChange={handlePositionChange}
            placeholder="Enter or select position"
            error={errors.position}
          />
          <S.InputTitle>Level: </S.InputTitle>
          <TextField
            onChange={handleSeniorityLevelChange}
            variant="outlined"
            size="small"
            defaultValue={defaultForm.seniorityLevel}
            placeholder="Enter seniority level"
            style={{ color: 'red' }}
            error={errors.candidate}
          />
          {errors.position && <ValidationError limit={POSITION_MAX_LENGTH} />}
          <S.InputTitle>Recruitment date and time: </S.InputTitle>
          <DateTimeInput
            name="recruitmentDate"
            onChange={handleDateChange}
            onBlur={handleDateOnBlur}
            value={recruitmentDate}
            minDate={new Date()}
            error={errors.previousDate || errors.incorrectDate}
          />
          {(errors.previousDate || errors.incorrectDate) && (
            <ValidationError
              limit={POSITION_MAX_LENGTH}
              text={getRecruitmentErrorMessage()}
            />
          )}
          <S.InputTitle>Technical recruiter:</S.InputTitle>
          <MUIAutocomplete
            options={technicalRecruiterNames}
            onChange={handleRecruiterChange}
            defaultValue={defaultForm.recruiter}
            style={{ marginBottom: '15px' }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                size="small"
                placeholder="Select technical recruiter"
              />
            )}
          />
          <S.InputTitle>Remunerated tech recruiter:</S.InputTitle>
          <MUIAutocomplete
            options={['—', ...technicalRecruiterNames]}
            onChange={handlRemuneratedTechRecruiterChange}
            defaultValue={defaultForm.remuneratedTechRecruiter}
            value={formOutput.remuneratedTechRecruiter}
            style={{ marginBottom: '15px' }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                size="small"
                placeholder="Select remunerated tech recruiter"
              />
            )}
          />
        </S.StyledFormControl>
        <S.InputTitle>HR contact:</S.InputTitle>
        <MUIAutocomplete
          options={hrContactsNames}
          onChange={handleHrContactChange}
          defaultValue={defaultForm.hrContact}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              size="small"
              placeholder="Select HR contact"
            />
          )}
        />
        <S.InputTitle>CV:</S.InputTitle>
        <S.CVContainer>
          {!formOutput.cvFile ? (
            <>
              <input
                type="file"
                accept=".pdf"
                ref={ref}
                style={{ display: 'none' }}
                onChange={handleFileSelected}
              />
              <Button
                $secondary
                variant="contained"
                startIcon={<AttachFileIcon />}
                onClick={() => ref.current?.click()}
              >
                Attach file
              </Button>
            </>
          ) : (
            <S.CVName>
              <AttachFileIcon /> {formOutput.cvFile}
            </S.CVName>
          )}
          <S.CVTextContainer>
            {formOutput.cvFile ? <S.CloseIcon onClick={resetFile} /> : <>(.pdf)</>}
          </S.CVTextContainer>
        </S.CVContainer>
        {errors.cv && cv && <ValidationError limit={CV_MAX_LENGTH} />}
      </DialogContent>
      <S.StyledDialogActions>
        <Button
          onClick={() =>
            handleClose(!isFormTouched(formOutput, defaultForm, position, positions))
          }
          disabled={isMakingRequest}
        >
          Cancel
        </Button>
        <Button
          $primary
          onClick={processFormOutput}
          disabled={isSubmitDisabled}
          endIcon={
            isMakingRequest ? (
              <CircularProgress style={{ color: 'black' }} size="16px" />
            ) : (
              ''
            )
          }
          variant="contained"
        >
          {editMode ? 'Save' : 'Create'}
        </Button>
      </S.StyledDialogActions>
    </Dialog>
  );
};
