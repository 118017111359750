import { Box, CircularProgress } from '@material-ui/core';
import { Button } from 'styles.global';
import { RecruitmentState } from 'types/recruitment';
import { BottomStripeProps } from './BottomStripe.types';
import * as S from './BottomStripe.css';

export const BottomStripe = ({
  recruitmentState,
  onRecruitmentStart,
  onFinishRecruitment,
  isFinishButtonDisabled,
  isFinishButtonLoading,
  isStartRecruitmentEnabled,
}: BottomStripeProps) => (
  <S.BottomStripe>
    {recruitmentState === RecruitmentState.InProgress ? (
      <Box display="flex">
        <Box ml={2}>
          <S.FinishButton
            onClick={onFinishRecruitment}
            disabled={isFinishButtonDisabled}
            variant="contained"
            endIcon={
              isFinishButtonLoading && <CircularProgress color="primary" size="24px" />
            }
          >
            Finish Recruitment
          </S.FinishButton>
        </Box>
      </Box>
    ) : (
      <Button $primary onClick={onRecruitmentStart} disabled={!isStartRecruitmentEnabled}>
        Start the recruitment
      </Button>
    )}
  </S.BottomStripe>
);
