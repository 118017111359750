import { TOAST_DURATION } from 'config';
import * as S from './ToastProvider.css';

const ToastProvider = () => (
  <div>
    <S.Toast autoClose={TOAST_DURATION} hideProgressBar />
  </div>
);

export default ToastProvider;
