import { RecruitmentService } from 'api';
import { handleException } from 'utils/errorHandlingUtils';
import { RecruitmentInfoProps } from './RecruitmentInfo.types';
import * as S from './RecruitmentInfo.css';

export const RecruitmentInfo = ({
  recruitment,
  shortRecruitment,
}: RecruitmentInfoProps) => {
  const recruiter = `${
    recruitment?.technicalRecruiter.name ?? shortRecruitment?.technicalRecruiter.name
  } ${
    recruitment?.technicalRecruiter.surname ??
    shortRecruitment?.technicalRecruiter.surname
  }`;
  const hrContact = `${recruitment?.hr?.name ?? shortRecruitment?.hr.name} ${
    recruitment?.hr?.surname ?? shortRecruitment?.hr.surname
  }`;
  const remuneratedRecruiter =
    recruitment?.remuneratedTechRecruiter &&
    `${recruitment.remuneratedTechRecruiter.name} ${recruitment.remuneratedTechRecruiter.surname}`;

  const { getCv, isLoadingCv } = RecruitmentService.useGetRecruitmentCvDocument(
    recruitment?.id,
  );

  const handleDownloadCv = async () => {
    try {
      const response = await getCv();
      window.open(response.data.data, '_blank', 'noopener,noreferrer');
    } catch (e) {
      handleException(e);
    }
  };

  return (
    <S.Container>
      <S.T1>Candidate information:</S.T1>
      <S.InfoContainer>
        <S.Info>
          <S.Bolded>ID: </S.Bolded>
          <S.InfoContent>{recruitment?.id ?? shortRecruitment?.id}</S.InfoContent>
        </S.Info>
        <S.Info>
          <S.Bolded>Position: </S.Bolded>
          <S.InfoContent>
            {recruitment?.position?.positionName ??
              shortRecruitment?.position?.positionName}
          </S.InfoContent>
        </S.Info>
        <S.Info>
          <S.Bolded>Level: </S.Bolded>
          <S.InfoContent>
            {(recruitment?.seniorityLevel ?? shortRecruitment?.seniorityLevel) || '-'}
          </S.InfoContent>
        </S.Info>
        <S.Info>
          <S.Bolded>Recruiter: </S.Bolded>
          <S.InfoContent>{recruiter}</S.InfoContent>
        </S.Info>
        <S.Info>
          <S.Bolded>Name: </S.Bolded>
          <S.InfoContent>
            {recruitment?.candidateName ?? shortRecruitment?.candidateName}
          </S.InfoContent>
        </S.Info>
        <S.Info>
          <S.Bolded>HR Contact: </S.Bolded>
          <S.InfoContent>{hrContact}</S.InfoContent>
        </S.Info>
        {remuneratedRecruiter && (
          <S.Info className="remuneratedRecruiterInfo">
            <S.Bolded>Remunerated recruiter: </S.Bolded>
            <S.InfoContent>{remuneratedRecruiter}</S.InfoContent>
          </S.Info>
        )}
        {!!recruitment && !!recruitment?.cvFile && (
          <S.Info onClick={handleDownloadCv}>
            <S.Bolded>CV: </S.Bolded>
            <S.ClickableInfoContent>
              <div>{recruitment?.cvFile}</div>
              {isLoadingCv ? <S.CircularProgress size="16px" /> : <S.DownloadIcon />}
            </S.ClickableInfoContent>
          </S.Info>
        )}
      </S.InfoContainer>
    </S.Container>
  );
};
