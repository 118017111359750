import { produce } from 'immer';
import { Question, RecruitmentQuestion } from 'types/question';
import { RecruitmentModule } from 'types/recruitment';
import {
  ModuleWithQuestions,
  ModuleWithSortedQuestions,
  RefreshableRecruitmentQuestion,
} from '../Recruitment.types';

export type State = {
  modulesWithSortedQuestions: ModuleWithSortedQuestions[];
  recruitmentModules: RecruitmentModule[];
};

type SetModules = {
  type: 'setModules';
  modules: ModuleWithQuestions[];
};

type SetSortedModules = {
  type: 'setSortedModules';
  sortedModules: ModuleWithSortedQuestions[];
};

type SetRecruitmentModules = {
  type: 'setRecruitmentModules';
  recruitmentModules: RecruitmentModule[];
};

type SetRecruitmentQuestions = {
  type: 'setRecruitmentQuestions';
  recruitmentQuestions: RecruitmentQuestion[];
};

type UpdateRecruitmentModule = {
  type: 'updateRecruitmentModule';
  recruitmentModule: RecruitmentModule;
};

type SetModuleQuestions = {
  type: 'setModuleQuestions';
  questions: RefreshableRecruitmentQuestion[];
  moduleId: number;
};

type ChangeQuestion = {
  type: 'changeQuestion';
  question: Question;
  questionId: number;
  moduleId: number;
};

type ChangeRecruitmentQuestion = {
  type: 'changeRecruitmentQuestion';
  question: RecruitmentQuestion;
  questionId: number;
  moduleId: number;
};

type AddQuestions = {
  type: 'addQuestions';
  questions: RecruitmentQuestion[];
  moduleId: number;
};

type DeleteQuestion = {
  type: 'deleteQuestion';
  questionId: number;
  moduleId: number;
};

type ReloadModule = {
  type: 'reloadModule';
  moduleId: number;
  reloadedModule: ModuleWithQuestions;
};

type DeleteRecruitmentQuestion = {
  type: 'deleteRecruitmentQuestion';
  moduleId: number;
  questionId: number;
};

type DeleteRecruitmentModule = {
  type: 'deleteRecruitmentModule';
  moduleId: number;
};

export type Actions =
  | SetModules
  | SetRecruitmentModules
  | SetRecruitmentQuestions
  | UpdateRecruitmentModule
  | ChangeQuestion
  | ReloadModule
  | AddQuestions
  | DeleteRecruitmentQuestion
  | DeleteQuestion
  | ChangeRecruitmentQuestion
  | DeleteRecruitmentModule
  | SetModuleQuestions
  | SetSortedModules;

export const reducer = produce((draft: State, action: Actions) => {
  switch (action.type) {
    case 'setModules':
      draft.modulesWithSortedQuestions = action.modules.map((m) => ({
        id: m.id,
        name: m.name,
        description: m.description,
        questions: [
          ...m.randomQuestions.map((q) => ({ question: q, refreshable: true })),
          ...m.randomTasks.map((q) => ({ question: q, refreshable: true })),
          ...m.chosen.map((q) => ({ question: q.question, refreshable: false })),
        ],
      }));
      break;

    case 'setSortedModules':
      draft.modulesWithSortedQuestions = action.sortedModules;
      break;

    case 'setRecruitmentModules': {
      draft.recruitmentModules = action.recruitmentModules;
      break;
    }

    case 'setRecruitmentQuestions': {
      const index = draft.recruitmentModules.findIndex(
        (q) => q.id === action.recruitmentQuestions[0].recruitmentModuleId,
      );
      draft.recruitmentModules[index].recruitmentQuestions = action.recruitmentQuestions;

      break;
    }

    case 'updateRecruitmentModule': {
      const index = draft.recruitmentModules.findIndex(
        (m) => m.id === action.recruitmentModule.id,
      );
      draft.recruitmentModules[index] = action.recruitmentModule;
      break;
    }

    case 'setModuleQuestions': {
      const module = draft.modulesWithSortedQuestions.find(
        (m) => m.id === action.moduleId,
      );
      module!.questions = action.questions;
      break;
    }

    case 'deleteRecruitmentModule': {
      draft.recruitmentModules = draft.recruitmentModules.filter(
        (mod) => mod.id !== action.moduleId,
      );

      break;
    }

    case 'reloadModule': {
      const module = draft.modulesWithSortedQuestions.find(
        (m) => m.id === action.moduleId,
      );
      const index = draft.modulesWithSortedQuestions.indexOf(module!);
      draft.modulesWithSortedQuestions[index] = {
        id: action.reloadedModule.id,
        name: action.reloadedModule.name,
        description: action.reloadedModule.description,
        questions: [
          ...action.reloadedModule.randomQuestions.map((q) => ({
            question: q,
            refreshable: true,
          })),
          ...action.reloadedModule.randomTasks.map((q) => ({
            question: q,
            refreshable: true,
          })),
          ...action.reloadedModule.chosen.map((q) => ({
            question: q.question,
            refreshable: false,
          })),
        ],
      };
      break;
    }

    case 'changeQuestion': {
      const moduleWithSortedQuestions = draft.modulesWithSortedQuestions.find(
        (mod) => mod.id === action.moduleId,
      );

      moduleWithSortedQuestions!.questions = moduleWithSortedQuestions!.questions.map(
        (x) =>
          x.question.id === action.questionId
            ? { question: action.question, refreshable: true }
            : x,
      );
      break;
    }

    case 'addQuestions': {
      const recruitmentModule = draft.recruitmentModules.find(
        ({ id }) => id === action.moduleId,
      );
      recruitmentModule!.recruitmentQuestions = [
        ...recruitmentModule!.recruitmentQuestions,
        ...action.questions,
      ];
      break;
    }

    case 'deleteQuestion': {
      const moduleWithSortedQuestions = draft.modulesWithSortedQuestions.find(
        (mod) => mod.id === action.moduleId,
      );

      moduleWithSortedQuestions!.questions = moduleWithSortedQuestions!.questions.filter(
        ({ question }) => question.id !== action.questionId,
      );
      if (moduleWithSortedQuestions!.questions.length === 0) {
        draft.modulesWithSortedQuestions = draft.modulesWithSortedQuestions.filter(
          (x) => x.id !== action.moduleId,
        );
      }
      break;
    }

    case 'deleteRecruitmentQuestion': {
      const recruitmentModule = draft.recruitmentModules.find(
        ({ id }) => id === action.moduleId,
      );
      recruitmentModule!.recruitmentQuestions =
        recruitmentModule!.recruitmentQuestions.filter(
          ({ id }) => id !== action.questionId,
        );

      break;
    }

    case 'changeRecruitmentQuestion': {
      const module = draft.recruitmentModules.find(
        (mod) => mod.moduleId === action.moduleId,
      );
      module!.recruitmentQuestions = module!.recruitmentQuestions.map((x) =>
        x.id === action.questionId ? action.question : x,
      );
      break;
    }

    default:
      break;
  }
});
