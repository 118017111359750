import { TextField } from '@material-ui/core';
import { NAME_LIMIT } from '../const';
import { TemplateNameTextFieldProps } from './TemplateNameTextField.types';

export const TemplateNameTextField = ({
  name,
  isError,
  setName,
  fullWidth = false,
}: TemplateNameTextFieldProps) => (
  <TextField
    style={!fullWidth ? { width: '300px' } : {}}
    fullWidth={fullWidth}
    variant="outlined"
    size="small"
    placeholder="Enter name of the template"
    value={name}
    error={isError}
    helperText={isError && `Limit of characters is ${NAME_LIMIT}`}
    onChange={(e) => setName(e.target.value)}
  />
);
