import { Button, InputBase } from '@material-ui/core';
import Popper from '@material-ui/core/Popper';
import DoneIcon from '@material-ui/icons/Done';
import { Autocomplete } from '@material-ui/lab';
import styled from 'styled-components';
import { DoneIconProps } from './Picker.types';

export const StyledPopper = styled(Popper)`
  border: 1px solid rgba(27, 31, 35, 0.15);
  box-shadow: 0 3px 12px rgba(27, 31, 35, 0.15);
  border-radius: 3;
  z-index: 1;
  font-size: 13;
  color: #586069;
  .MuiPaper-root {
    margin: 0;
    width: 20vw;
    word-break: break-word;
  }

  &.MuiAutocomplete-root {
    width: 20vw;
  }
`;

export const PopperContainer = styled.div`
  margin-left: 20px;
  width: 220px;
  font-size: 13px;
  border-bottom: 1px solid;
`;

export const StyledButton = styled(Button)`
  &.MuiButton-root {
    width: 100%;
    letter-spacing: 0;
    display: flex;
    justify-content: space-between;
    color: black;
    font-weight: 300;
    font-size: 1.125rem;
    height: 40px;
    border-radius: 5px;
    text-transform: none;

    &:hover {
      color: gray;
    }
  }
`;

export const StyledInput = styled(InputBase)`
  &.MuiInputBase-root {
    padding: 10px;
    width: 100%;
    border-bottom: 1px solid #dfe2e5;
  }
  & input {
    border-radius: 4px;
    background-color: #dfe2e5;
    padding: 8px;

    &:focus {
      box-shadow: rgb(0, 0, 0) 0 0 0 0.1rem;
      border-color: gray;
      transition: border-color 0.2s linear, box-shadow linear 0.2s;
    }
  }
`;

export const StyledDoneIcon = styled(DoneIcon)<DoneIconProps>`
  visibility: ${(props) => (props.selected ? 'visible' : 'hidden')};
  &.MuiSvgIcon-root {
    width: 17px;
    height: 17px;
    padding-right: 10px;
  }
`;

export const StyledText = styled.div`
  flex-grow: 1px;
`;

export const StyledAutocomplete = styled(Autocomplete)`
  .MuiInputBase-root {
    border-radius: 4px;
    margin: 0;
    width: 20vw;
  }
` as typeof Autocomplete;
