import styled from 'styled-components';

export const CriteriaList = styled.ul`
  overflow-wrap: break-word;
`;

export const CriteriaListItem = styled.li`
  list-style: circle;
  list-style-type: disc;
`;

export const BoldText = styled.span`
  font-weight: bold;
`;
