import styled from 'styled-components';

export const EndedPageContainer = styled.div`
  background-color: #ffffff;
  padding: 46px 32px;
  min-height: 100vh;
`;

export const Actionbar = styled.div`
  display: flex;
`;
