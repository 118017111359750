import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import {
  AccordionDetails,
  Box,
  FormControl,
  Radio,
  RadioGroup,
  Tooltip,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { RecruitmentService } from 'api';
import { usePatchQuestion } from 'api/recruitments';
import { QuestionData } from 'api/types';
import BrainPNG from 'assets/brain.svg';
import HammerPNG from 'assets/hammer.svg';
import debounce from 'lodash.debounce';
import { QuestionCriteria } from 'pages/Questions/components/QuestionCriteria/QuestionCriteria';
import ConfirmDialog from 'components/DeleteDialog';
import { handleException } from 'utils/errorHandlingUtils';
import { copyToClipboard } from 'utils/helpers';
import { UPDATE_QUESTION_DELAY } from '../constants';
import { SCORES } from './RatableQuestion.const';
import { NoteState, RatableQuestionProps } from './RatableQuestion.types';
import * as S from './RatableQuestion.css';

export const RatableQuestion = ({
  question,
  expanded,
  openQuestion,
  setLoadingRecruitment,
  deleteQuestion,
  setExpandedQuestionIdx,
  recruitmentQuestionIds,
  reloadRecruitmentQuestion,
  moduleId,
  index,
  dispatch,
}: RatableQuestionProps) => {
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isRedrawDialogOpen, setRedrawDialogOpen] = useState(false);

  const [assessed, setAssessed] = useState(!!question.grade);
  const [score, setScore] = useState(question.grade);
  const [note, setNote] = useState<NoteState>({
    isOpen: (question.note && question.note.length > 0) || false,
    value: question.note || '',
  });

  const { patchQuestionAsync } = usePatchQuestion();

  const rateQuestion = useCallback(
    (props: QuestionData) => {
      if (expanded) {
        // ? Prevents laggy animations
        setTimeout(() => {
          patchQuestionAsync(question.id, props).catch(() => {
            // this is intentional - we don't want to show the user an error
          });
        }, 500);
      }
    },
    [patchQuestionAsync, question.id, expanded],
  );

  const debouncedNoteChange = useMemo(() => {
    function handleNoteChange(e: ChangeEvent<HTMLTextAreaElement>) {
      setNote((prevNote) => ({ ...prevNote, value: e.target.value }));
      rateQuestion({
        grade: score,
        note: e.target.value,
      });
      dispatch({
        type: 'changeRecruitmentQuestion',
        questionId: question.id,
        moduleId,
        question: {
          ...question,
          note: e.target.value,
        },
      });
      setLoadingRecruitment(false);
    }
    return debounce(handleNoteChange, UPDATE_QUESTION_DELAY);
  }, [setLoadingRecruitment, rateQuestion, score, dispatch, moduleId, question]);

  const handleScoreChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!assessed) {
      setAssessed(true);
    }
    setScore(Number(e.target.value));
    rateQuestion({
      grade: Number(e.target.value),
      note: note.value,
    });
    dispatch({
      type: 'changeRecruitmentQuestion',
      questionId: question.id,
      moduleId,
      question: {
        ...question,
        grade: Number(e.target.value),
      },
    });
  };

  const { deleteRecruitmentQuestion } = RecruitmentService.useDeleteRecruitmentQuestion();

  const handleDeleteQuestion = async () => {
    try {
      await deleteRecruitmentQuestion(question.id);
      deleteQuestion(question.id);
      setExpandedQuestionIdx(0);
    } catch (e) {
      handleException(e);
    }
  };

  const handleRedrawQuestion = () => {
    reloadRecruitmentQuestion(question, moduleId, recruitmentQuestionIds);
    setRedrawDialogOpen(false);
    setAssessed(false);
    setNote({ value: '', isOpen: false });
    setScore(null);
  };

  return (
    <S.Main>
      <S.Accordion onChange={() => openQuestion(index, false)} expanded={expanded}>
        <S.Summary expandIcon={expanded ? null : <ExpandMoreIcon />}>
          <S.Inner>
            <S.Icon
              src={question.question.isPractical ? HammerPNG : BrainPNG}
              alt="icon"
            />
            {expanded ? (
              <Tooltip
                title="Click to copy to clipboard"
                enterDelay={250}
                enterNextDelay={250}
              >
                <S.ExpandedQuestionText
                  onClick={() => copyToClipboard(question.question.text)}
                >
                  {question.question.text}
                </S.ExpandedQuestionText>
              </Tooltip>
            ) : (
              <S.QuestionText>{question.question.text}</S.QuestionText>
            )}
          </S.Inner>
        </S.Summary>
        <AccordionDetails>
          <S.LeftSide>
            <S.CriteriaContainer>
              <QuestionCriteria criteria={question.question.criteria} />
            </S.CriteriaContainer>
            <Box mt={3} display="flex" justifyContent="space-between">
              {note.isOpen ? (
                <S.LeaveNote> Leave a note: </S.LeaveNote>
              ) : (
                <S.NoteButton onClick={() => setNote({ ...note, isOpen: true })}>
                  <S.NoteIcon fontSize="small" />
                  Leave a note
                </S.NoteButton>
              )}
              <Box display="flex" justifyContent="space-evenly">
                <S.RedrawButton onClick={() => setRedrawDialogOpen(true)}>
                  <S.RefreshIcon />
                  draw another
                </S.RedrawButton>
                <S.DeleteButton onClick={() => setDeleteDialogOpen(true)}>
                  <S.BinIcon />
                  delete question
                </S.DeleteButton>
              </Box>
            </Box>
            {note.isOpen && (
              <S.NoteTextArea
                autoFocus
                defaultValue={question.note ?? ''}
                placeholder="Enter question note"
                spellCheck="false"
                onChange={(e) => {
                  debouncedNoteChange(e);
                  setLoadingRecruitment(true);
                }}
              />
            )}
          </S.LeftSide>
          <S.RightSide>
            <FormControl component="fieldset">
              <S.ScoreLabel>Score the answer</S.ScoreLabel>
              <RadioGroup row onChange={handleScoreChange}>
                {SCORES.map((i) => (
                  <S.FormControlLabel
                    key={i}
                    value={i}
                    label={i}
                    checked={score === i}
                    control={<Radio />}
                    labelPlacement="bottom"
                  />
                ))}
              </RadioGroup>
            </FormControl>
            <S.NextButton onClick={() => openQuestion(0, true)}>
              Next one
              <S.RightArrow />
            </S.NextButton>
          </S.RightSide>
        </AccordionDetails>
      </S.Accordion>
      {!expanded && <Box ml={2}>{score !== null && <S.AssessedQuestionIcon />}</Box>}
      {isDeleteDialogOpen && (
        <ConfirmDialog
          open={isDeleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
          handleClick={handleDeleteQuestion}
          title="DELETE QUESTION"
          content="You're trying to delete question"
        />
      )}
      {isRedrawDialogOpen && (
        <ConfirmDialog
          open={isRedrawDialogOpen}
          onClose={() => setRedrawDialogOpen(false)}
          handleClick={handleRedrawQuestion}
          title="REDRAW QUESTION"
          content="You're trying to redraw question"
          buttonLabel="Redraw"
        />
      )}
    </S.Main>
  );
};
