import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { RecruitmentService } from 'api';
import { RecruitmentInfo } from 'pages/Recruitment/components/RecruitmentInfo';
import { RecruitmentState } from 'types/recruitment';
import Container from 'components/Container';
import { RecruiterNote } from 'components/RecruiterNote';
import ErrorToast from 'components/Toasts/ErrorToast';
import { handleException } from 'utils/errorHandlingUtils';
import { useQuery } from 'utils/hooks';
import { RouteParams } from 'utils/types/RouteParams';
import EndedRecruitmentModule from './components/EndedRecruitmentModule';
import Summary from './components/Summary';
import * as S from './PreviewEndedRecruitment.css';

const PreviewEndedRecruitment = () => {
  const query = useQuery();

  const [loadingRecruitment, setLoadingRecruitment] = useState(false);
  const [isFetching, setFetching] = useState(true);

  const { id } = useParams<RouteParams>();
  const { push } = useHistory();

  const { recruitmentData, getRecruitment } = RecruitmentService.useGetRecruitment();
  const { recruitmentModulesData, getRecruitmentModules } =
    RecruitmentService.useGetRecruitmentModules(id);

  const fetchRecruitment = useCallback(async () => {
    try {
      await Promise.all([getRecruitment(id), getRecruitmentModules()]);
    } catch (e) {
      handleException(e);
      push('/todo');
    }
    setFetching(false);
  }, [getRecruitment, push, getRecruitmentModules, id]);

  useEffect(() => {
    fetchRecruitment();
  }, [fetchRecruitment]);

  useEffect(() => {
    if (recruitmentData) {
      if (
        recruitmentData.recruitmentState !== RecruitmentState.Ended &&
        recruitmentData.recruitmentState !== RecruitmentState.Completed &&
        recruitmentData.recruitmentState !== RecruitmentState.InProgress
      ) {
        toast.error(<ErrorToast message={`Recruitment with id ${id} is not ended.`} />);
        push('/todo');
      }
    }
  }, [recruitmentData, push, id]);

  const notesNotFilled = recruitmentModulesData.some(({ note }) => !note?.trim().length);

  const isEditable =
    recruitmentData?.recruitmentState !== RecruitmentState.Completed &&
    query.get('mode') === 'edit';

  const sortedRecruitmentModulesData = useMemo(
    () => recruitmentModulesData.sort((a, b) => a.id - b.id),
    [recruitmentModulesData],
  );

  return (
    <Container isLoading={isFetching}>
      <RecruiterNote
        defaultValue={recruitmentData?.recruiterNote || ''}
        isDisabled={!isEditable}
      />
      <S.Main>
        <RecruitmentInfo recruitment={recruitmentData} />
        {sortedRecruitmentModulesData.map((module) => (
          <EndedRecruitmentModule
            key={module.id}
            module={module}
            editable={isEditable}
            setLoadingRecruitment={setLoadingRecruitment}
            getRecruitmentModules={getRecruitmentModules}
          />
        ))}
        <Summary
          recruitmentData={recruitmentData}
          editable={isEditable}
          loadingRecruitment={loadingRecruitment}
          setLoadingRecruitment={setLoadingRecruitment}
          notesNotFilled={notesNotFilled}
        />
      </S.Main>
    </Container>
  );
};

export default PreviewEndedRecruitment;
