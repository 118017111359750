import { Base } from 'types/common';
import { Question, UpdateQuestion } from 'types/question';

export type QuestionFormProps = {
  open: boolean;
  question?: Question;
  editMode?: boolean;
  moduleNames: Base[];
  onClose: () => void;
  addQuestion?: (question: Question) => void;
  updateQuestion?: (question: Question, options: UpdateQuestion) => void;
};

export interface Criterion {
  id: number;
  value: string;
}

export enum QuestionType {
  Practical = 'practical',
  Theoretical = 'theoretical',
}

export type Errors = {
  question: boolean;
  criteria: boolean[];
};
