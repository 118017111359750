import styled from 'styled-components';
import { Button } from 'styles.global';

export const BottomStripe = styled.div`
  height: 60px;
  width: 100%;
  background-color: white;
  box-shadow: 0px -3px 8px 1px lightgray;
  opacity: 0.9;
  position: fixed;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FinishButton = styled(Button).attrs({ $primary: true })`
  &.MuiButton-root {
    line-height: 1.75;
  }
`;
