import { ChangeEvent, useCallback, useMemo } from 'react';
import { AccordionSummary, Box, debounce } from '@material-ui/core';
import { RecruitmentSummaryService } from 'api';
import { handleException } from 'utils/errorHandlingUtils';
import { percentageCalculator } from 'utils/helpers';
import { MODULE_SUMMARY_DEBOUNCE_DELAY } from './GeneratedEndedRecruitmentModule.const';
import { GeneratedEndedRecruitmentModuleProps } from './GeneratedEndedRecruitmentModule.types';
import * as S from './GeneratedEndedRecruitmentModule.css';

const GeneratedEndedRecruitmentModule = ({
  module,
  editable,
}: GeneratedEndedRecruitmentModuleProps) => {
  const updateRecruitmentSummaryModule =
    RecruitmentSummaryService.useUpdateRecruitmentSummaryModule(module.id);

  const updateModuleSummary = useCallback(
    async (note: string) => {
      try {
        await updateRecruitmentSummaryModule({
          data: {
            note,
          },
        });
      } catch (e) {
        handleException(e);
      }
    },
    [updateRecruitmentSummaryModule],
  );

  const handleNoteChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => updateModuleSummary(e.target.value),
    [updateModuleSummary],
  );

  const debouncedModuleSummaryChange = useMemo(
    () => debounce(handleNoteChange, MODULE_SUMMARY_DEBOUNCE_DELAY),
    [handleNoteChange],
  );

  return (
    <S.ModuleAccordion expanded>
      <AccordionSummary style={{ cursor: 'auto' }}>
        <S.ModuleTitle>
          {module?.recruitmentModule?.module?.name ?? 'Not found'}
        </S.ModuleTitle>
        <S.TotalScore>
          Score: <S.BoldText>{module?.recruitmentModule?.grade ?? 0}</S.BoldText>/
          {module?.recruitmentModule?.maxGrade ?? 0}
          <S.BoldText>{` (${percentageCalculator(
            module?.recruitmentModule?.grade,
            module?.recruitmentModule?.maxGrade,
          )}%)`}</S.BoldText>
        </S.TotalScore>
      </AccordionSummary>
      <S.ModuleAccordionDetails>
        <Box mb={4}>{module?.recruitmentModule?.module.description}</Box>
        <S.ModuleSummaryTitle>Module Summary</S.ModuleSummaryTitle>
        {editable ? (
          <S.ModuleSummaryTextArea
            defaultValue={module.note}
            spellCheck="false"
            placeholder="Enter module note"
            onChange={debouncedModuleSummaryChange}
          />
        ) : (
          <S.ModuleSummary>{module.note}</S.ModuleSummary>
        )}
      </S.ModuleAccordionDetails>
    </S.ModuleAccordion>
  );
};

export default GeneratedEndedRecruitmentModule;
