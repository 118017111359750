import { QuestionCriteriaProps } from './QuestionCriteria.types';
import * as S from './QuestionCriteria.css';

export const QuestionCriteria = ({ criteria }: QuestionCriteriaProps) =>
  criteria?.length ? (
    <>
      <S.BoldText>Criteria for response:</S.BoldText>
      <S.CriteriaList>
        {typeof criteria[0] === 'string'
          ? criteria.map((criterion, index) => (
              <S.CriteriaListItem key={index}>{criterion}</S.CriteriaListItem>
            ))
          : criteria.map(({ id, name }: any) => (
              <S.CriteriaListItem key={id}>{name}</S.CriteriaListItem>
            ))}
      </S.CriteriaList>
    </>
  ) : null;
