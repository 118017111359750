import { Tooltip as MuiTooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MuiCheckCircleIcon from '@material-ui/icons/CheckCircle';
import styled from 'styled-components';
import { Button } from 'styles.global';
import theme from 'theme';

export const Summary = styled.div`
  background-color: black;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12), 0px 0px 4px rgba(0, 0, 0, 0.08);
  border: 0.5px #ededed solid;
`;

export const SummaryTitle = styled.div`
  margin-left: 30px;
  color: ${theme.palette.primary.main};
  font-size: 20px;
  font-weight: bold;
`;

export const SummaryValues = styled.div`
  display: flex;
`;

export const SummaryScore = styled.div`
  color: white;
  font-weight: bold;
  margin-right: 40px;
  font-size: 20px;
`;

export const YellowText = styled.span`
  color: ${theme.palette.primary.main};
`;

export const RecruitmentReview = styled.div`
  background-color: white;
  padding: 10px 30px 10px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12), 0px 0px 4px rgba(0, 0, 0, 0.08);
  border: 0.5px #ededed solid;
`;

export const ReviewTitle = styled.div`
  font-size: large;
  border-bottom: 2px solid #ffd242;
  width: fit-content;
  margin-bottom: 16px;
  margin-top: 16px;
`;

export const ReviewSubtitle = styled.div`
  font-size: large;
  width: fit-content;
  margin-bottom: 16px;
  margin-top: 16px;
`;

export const QuestionsOpinion = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

export const TitleQuestionOpinion = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TitleQuestionCheckbox = styled.div`
  display: flex;
  margin-right: 8px;
  justify-content: flex-end;
`;

export const QuestionText = styled.div`
  margin-right: 30px;
`;

export const QuestionCheckbox = styled.div`
  display: flex;
  justify-content: flex-end;
`;
export const QuestionWordCheckbox = styled.div`
  margin-right: 18px;
`;

export const QuestionOpinion = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
`;

export const GeneralOpinion = styled.div`
  margin-bottom: 16px;
  width: 60%;
  word-break: break-word;
`;

export const GeneralOpinionContent = styled.div`
  margin-left: 16px;
`;

export const SaveButton = styled(Button).attrs({ $primary: true })`
  &.MuiButton-root {
    margin-right: 20px;
  }
`;

export const MarkButton = styled(Button)`
  &.MuiButton-root {
    background-color: ${theme.palette.secondary.dark};
    margin-left: 20px;
    color: white;
    font-size: 14px;
    font-weight: 700;
    font-style: normal;
  }

  &.MuiButton-root.Mui-disabled {
    background-color: ${theme.palette.secondary.dark};
    opacity: 0.5;
    margin-left: 20px;
    color: white;
    font-size: 14px;
    font-weight: 700;
    font-style: normal;
  }
`;

export const TextArea = styled.textarea`
  width: 70%;
  min-width: 200px;
  min-height: 300px;
  resize: none;
  font-size: large;
  box-shadow: inset 1px 3px 4px lightgray;
  border: none;
`;

export const CheckIcon = styled(MuiCheckCircleIcon)<{ $disabled: boolean }>`
  &.MuiSvgIcon-root {
    color: ${({ $disabled }) => ($disabled ? 'grey' : theme.palette.success.main)};
  }
`;

export const Tooltip = withStyles({
  tooltip: {
    color: '#4A4A4A',
    backgroundColor: '#EDEDED',
    maxWidth: '220px',
    fontSize: '12px',
  },
})(MuiTooltip);

export const RecommendationBox = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
`;

export const RecommendationTitle = styled.div`
  margin-right: 16px;
`;

export const RecommendationSelectContainer = styled.div`
  width: 250px;
`;
