import { useCallback } from 'react';
import { SortOrder } from 'types/common';
import { RecruitmentQuestion } from 'types/question';
import {
  NewRecruitmentModule,
  Position,
  Recruitment,
  RecruitmentModule,
  RecruitmentState,
} from 'types/recruitment';
import {
  MAX_RECRUITMENT_SCORE,
  MIN_RECRUITMENT_SCORE,
} from 'components/Filters/RecruitmentFilters/RecruitmentFilters.const';
import { reverseDate } from 'utils/helpers';
import { ModuleWithQuestions } from '../pages/Recruitment/Recruitment.types';
import { useAxios } from './axios';
import { QuestionData, RecruitmentsFilter, UseGetRecruitments } from './types';

export const useCreateCancellation = () => {
  const [{ loading: isCancellingRecruitment }, createCancellationData] = useAxios(
    { method: 'POST' },
    { manual: true },
  );
  const createCancellation = useCallback(
    (id: number, reason: string) =>
      createCancellationData({
        url: `/recruitments/${id}/cancellation`,
        data: { reason },
      }),
    [createCancellationData],
  );
  return { createCancellation, isCancellingRecruitment };
};

export const useUpdateCancellation = () => {
  const [{ loading: isUpdatingCancellation }, updateCancellationData] = useAxios(
    { method: 'PATCH' },
    { manual: true },
  );
  const updateCancellation = useCallback(
    (id: number, reason: string) =>
      updateCancellationData({
        url: `/recruitments/${id}/cancellation`,
        data: { reason },
      }),
    [updateCancellationData],
  );
  return { updateCancellation, isUpdatingCancellation };
};

export const useUpdateRecruitmentModules = () => {
  const [, updateRecruitmentModulesData] = useAxios(
    { method: 'PATCH' },
    { manual: true },
  );

  return (recruitmentId: number, recruitmentModules: NewRecruitmentModule[]) =>
    updateRecruitmentModulesData({
      url: `/recruitments/${recruitmentId}/modules`,
      data: [...recruitmentModules],
    });
};

export const useUpdateRecruitmentModule = () => {
  const [, updateRecruitmentModuleData] = useAxios({ method: 'PATCH' }, { manual: true });

  return useCallback(
    (moduleId: number, recruitmentModule: NewRecruitmentModule, note: string = '') =>
      updateRecruitmentModuleData({
        url: `/recruitments/modules/${moduleId}`,
        data: {
          ...recruitmentModule,
          note,
        },
      }),
    [updateRecruitmentModuleData],
  );
};

export const useDeleteRecruitmentModule = () => {
  const [, deleteRecruitmentModuleData] = useAxios(
    { method: 'DELETE' },
    { manual: true },
  );

  return (moduleId: number) =>
    deleteRecruitmentModuleData({
      url: `/recruitments/modules/${moduleId}`,
    });
};

export const useGetRecruitments = ({ ended }: UseGetRecruitments = { ended: false }) => {
  const [
    { data = { data: [] }, loading: isLoadingRecruitmentsData },
    getRecruitmentsData,
  ] = useAxios<{
    data: Recruitment[];
    totalEndedRecruitments: number;
    totalNotEndedRecruitments: number;
  }>({ method: 'GET' }, { manual: true });

  const getRecruitments = useCallback(
    ({
      position = '',
      recruiter = '',
      hrContact = '',
      dateFrom = '',
      dateTo = '',
      search = '',
      name = '',
      minScore = MIN_RECRUITMENT_SCORE,
      maxScore = MAX_RECRUITMENT_SCORE,
      page = 0,
      cancelled = '',
      perPage,
      order,
    }: RecruitmentsFilter = {}) => {
      const cancelledValue = cancelled === '1' ? `${RecruitmentState.Cancelled},` : '';
      return getRecruitmentsData({
        url: '/recruitments',
        params: {
          ...(ended
            ? {
                recruitment_state: `${cancelledValue}${RecruitmentState.Ended},${RecruitmentState.Completed}`,
                ordering: order || SortOrder.Desc,
              }
            : {
                recruitment_state: `${RecruitmentState.Adjusted},${RecruitmentState.Formed},${RecruitmentState.InProgress}`,
                ordering: order || SortOrder.Asc,
              }),
          ...(position && { position }),
          ...(name && { candidate_name: name }),
          ...(recruiter && { recruiter }),
          ...(dateFrom && { 'recruitment_date[gte]': reverseDate(dateFrom) }),
          ...(dateTo && { 'recruitment_date[lte]': reverseDate(dateTo) }),
          ...(hrContact && { hr_contact: hrContact }),
          ...(search && { search }),
          ...(minScore > MIN_RECRUITMENT_SCORE && { 'score[gte]': minScore }),
          ...(maxScore < MAX_RECRUITMENT_SCORE && { 'score[lte]': maxScore }),
          ...(perPage && { per_page: perPage }),
          page: page + 1, // * Backend counts pages from 1
        },
      });
    },
    [getRecruitmentsData, ended],
  );

  return {
    recruitmentsData: data.data,
    isLoadingRecruitmentsData,
    getRecruitments,
  };
};

export const useGetRecruitment = () => {
  const [{ data, loading: isLoadingRecruitment }, getRecruitmentAsync] = useAxios<{
    data: Recruitment;
  }>({ method: 'GET' }, { manual: true });

  const getRecruitment = useCallback(
    (id?: number | string) => getRecruitmentAsync({ url: `/recruitments/${id}` }),
    [getRecruitmentAsync],
  );

  return {
    recruitmentData: data?.data,
    isLoadingRecruitment,
    getRecruitment,
  };
};

export const useCreateRecruitment = () => {
  const [, createRecruitment] = useAxios<{ data: Recruitment }>(
    {
      url: `/recruitments`,
      method: 'POST',
    },
    { manual: true },
  );

  return createRecruitment;
};

export const usePatchRecruitment = () => {
  const [{ loading: isLoadingPatchRecruitment }, patchRecruitmentData] = useAxios<{
    data: Recruitment;
  }>({ method: 'PATCH' }, { manual: true });

  const patchRecruitment = useCallback(
    (id: number, data: any) => patchRecruitmentData({ url: `/recruitments/${id}`, data }),
    [patchRecruitmentData],
  );

  return { patchRecruitment, isLoadingPatchRecruitment };
};

export const useGetCandidates = () => {
  const [{ data = { data: [] }, loading: isLoadingCandidates }, getCandidates] = useAxios(
    {
      url: '/recruitments/candidates',
      method: 'GET',
    },
    { manual: true },
  );
  const candidatesData = data?.data;

  return { candidatesData, isLoadingCandidates, getCandidates };
};

export const useGetRecruiters = () => {
  const [{ data = { data: [] }, loading: isLoadingRecruiters }, getRecruiters] = useAxios(
    {
      url: '/recruitments/recruiters',
      method: 'GET',
    },
    { manual: true },
  );
  const recruitersData = data?.data;

  return { recruitersData, isLoadingRecruiters, getRecruiters };
};

export const useGetHrContact = () => {
  const [{ data = { data: [] }, loading: isLoadingHr }, getHrContact] = useAxios(
    {
      url: '/recruitments/hr',
      method: 'GET',
    },
    { manual: true },
  );
  const hrData = data?.data;

  return { hrData, isLoadingHr, getHrContact };
};

export const useGetPositions = () => {
  const [{ data = { data: [] }, loading: isLoadingPositions }, getPositionsData] =
    useAxios<{ data: Position[] }>(
      { url: '/recruitments/positions', method: 'GET' },
      { manual: true },
    );

  return { positionsData: data.data, isLoadingPositions, getPositionsData };
};

export const useCreatePosition = () => {
  const [{ data, loading: isCreatingPosition }, createPosition] = useAxios(
    {
      url: `/recruitments/positions`,
      method: 'POST',
    },
    { manual: true },
  );
  const positionData = data?.data;

  return { positionData, isCreatingPosition, createPosition };
};

export const useCreateRecruitmentModules = () => {
  const [, createRecruitmentModulesData] = useAxios({ method: 'POST' }, { manual: true });

  return (recruitmentId: number, recruitmentModules: NewRecruitmentModule[]) =>
    createRecruitmentModulesData({
      url: `/recruitments/${recruitmentId}/modules`,
      data: [...recruitmentModules],
    });
};

export const useGetRecruitmentModules = (recruitmentId?: number | string) => {
  const [
    { data = { data: [] }, loading: isLoadingRecruitmentModules },
    getRecruitmentModules,
  ] = useAxios<{ data: RecruitmentModule[] }>(`/recruitments/${recruitmentId}/modules`, {
    manual: true,
  });

  const recruitmentModulesData = data?.data;

  return {
    recruitmentModulesData,
    getRecruitmentModules,
    isLoadingRecruitmentModules,
  };
};

export const useCreateRecruitmentQuestions = () => {
  const [, createRecruitmentQuestions] = useAxios(
    {
      method: 'POST',
    },
    { manual: true },
  );

  const createRecruitmentQuestionsAsync = (
    recruitmentId: number,
    modules: {
      recruitmentModules: { id: number; questionsId: number[] }[];
    },
  ) =>
    createRecruitmentQuestions({
      url: `/recruitments/${recruitmentId}/questions`,
      data: modules,
    });
  return { createRecruitmentQuestionsAsync };
};

export const useGetAllQuestionsForRecruitment = (recruitmentId: number | string) => {
  const [
    {
      data = {
        data: {
          recruitmentModules: [],
        },
      },
      loading: isLoadingAllQuestionsForRecruitment,
    },
    getAllQuestionsForRecruitment,
  ] = useAxios<{
    data: {
      recruitmentModules: ModuleWithQuestions[];
    };
  }>(
    {
      url: `/recruitments/${recruitmentId}/get_questions`,
      method: 'GET',
    },
    { manual: true },
  );
  const allQuestionsForRecruitmentData = data?.data.recruitmentModules;

  return {
    allQuestionsForRecruitmentData,
    isLoadingAllQuestionsForRecruitment,
    getAllQuestionsForRecruitment,
  };
};

export const useReloadRecruitmentQuestion = () => {
  const [{ data, loading: isReloadingRecruitmentQuestion }, reloadRecruitmentQuestion] =
    useAxios(
      {
        method: 'POST',
      },
      { manual: true },
    );

  const reloadQuestionAsync = useCallback(
    async (questionId: number, presentQuestionIds: number[]) =>
      reloadRecruitmentQuestion({
        url: `/questions/reload/${questionId}`,
        data: { presentQuestionIds },
      }),
    [reloadRecruitmentQuestion],
  );
  const newQuestionData = data?.data;

  return {
    newQuestionData,
    isReloadingRecruitmentQuestion,
    reloadQuestionAsync,
  };
};

export const useReloadRecruitmentQuestions = () => {
  const [{ data, loading: isReloadingRecruitmentQuestions }, reloadRecruitmentQuestions] =
    useAxios(
      {
        method: 'POST',
      },
      { manual: true },
    );

  const reloadQuestionsAsync = useCallback(
    async (moduleId: number, presentQuestionIds: number[]) =>
      reloadRecruitmentQuestions({
        url: `/recruitments/modules/${moduleId}/reload`,
        data: { presentQuestionIds },
      }),
    [reloadRecruitmentQuestions],
  );
  const newQuestionsData = data?.data;

  return {
    newQuestionsData,
    isReloadingRecruitmentQuestions,
    reloadQuestionsAsync,
  };
};

export const usePatchQuestion = () => {
  const [, patchQuestion] = useAxios<{ data: RecruitmentQuestion }>(
    {
      method: 'PATCH',
    },
    { manual: true },
  );
  const patchQuestionAsync = useCallback(
    (recruitmentQuestionId: number, data: QuestionData) =>
      patchQuestion({
        url: `/recruitments/questions/${recruitmentQuestionId}`,
        data,
      }),
    [patchQuestion],
  );

  return {
    patchQuestionAsync,
  };
};

export const useDeleteRecruitmentQuestion = () => {
  const [, deleteRecruitmentQuestionData] = useAxios(
    { method: 'DELETE' },
    { manual: true },
  );
  const deleteRecruitmentQuestion = (id: number) =>
    deleteRecruitmentQuestionData({ url: `/recruitments/questions/${id}` });

  return { deleteRecruitmentQuestion };
};

export const useSortRecruitmentQuestions = (recruitmentModuleId?: number) => {
  const [, sortRecruitmentQuestions] = useAxios(
    {
      method: 'POST',
      url: `/recruitments/modules/${recruitmentModuleId}/questions/sort`,
    },
    { manual: true },
  );

  return sortRecruitmentQuestions;
};

export const useGetDocxDocument = () => {
  const [{ loading: isLoadingDocxDownload }, getDocx] = useAxios(
    {
      method: 'GET',
      responseType: 'blob',
    },
    { manual: true },
  );

  const getDocxDocument = useCallback(
    async (id: number) => {
      const url = `/recruitments/${id}/document`;
      return getDocx({ url });
    },
    [getDocx],
  );

  return { isLoadingDocxDownload, getDocxDocument };
};

export const useGetSummaryDocxDocument = () => {
  const [{ loading: isLoadingSummaryDocxDownload }, getDocx] = useAxios(
    {
      method: 'GET',
      responseType: 'blob',
    },
    { manual: true },
  );

  const getSummaryDocxDocument = useCallback(
    async (hashId: string) => {
      const url = `/recruitments/summary/${hashId}/document`;
      return getDocx({ url });
    },
    [getDocx],
  );

  return { isLoadingSummaryDocxDownload, getSummaryDocxDocument };
};

export const useGetRecruitmentCvDocument = (id?: number) => {
  const [{ loading: isLoadingCv }, getCv] = useAxios<{ data: string }>(
    {
      method: 'GET',
      url: `/recruitments/${id}/cv`,
    },
    { manual: true },
  );

  return { isLoadingCv, getCv };
};

export const useDeleteRecruitmentCvDocument = () => {
  const [, deleteCv] = useAxios({ method: 'DELETE' }, { manual: true });

  return (recruitmentId: number) =>
    deleteCv({
      url: `/recruitments/${recruitmentId}/cv`,
    });
};

export const useCreateLink = (recruitmentId: number) => {
  const [{ loading: isCreatingLink }, createLink] = useAxios(
    {
      url: `/recruitments/${recruitmentId}/links`,
      method: 'POST',
    },
    { manual: true },
  );

  return { isCreatingLink, createLink };
};

export const useDeleteLink = () => {
  const [, deleteLinkData] = useAxios({ method: 'DELETE' }, { manual: true });

  return useCallback(
    (id: number) => deleteLinkData({ url: `/recruitments/links/${id}` }),
    [deleteLinkData],
  );
};

export const useSortRecruitmentModules = (recruitmentId: string) => {
  const [, sortRecruitmentModules] = useAxios(
    {
      method: 'POST',
      url: `/recruitments/${recruitmentId}/modules/sort`,
    },
    { manual: true },
  );

  return sortRecruitmentModules;
};
