import { useState } from 'react';
import { Dialog, TextField } from '@material-ui/core';
import { ModuleService } from 'api';
import { useUserContext } from 'context';
import { ValidationError } from 'components/ValidationError';
import { handleException } from 'utils/errorHandlingUtils';
import { getDate } from 'utils/formatters';
import { removeExtraSpaces, stringIsEmpty } from 'utils/helpers';
import { useCloseWithConfirm } from 'utils/hooks';
import {
  MAX_MODULE_DESCRIPTION_LENGTH,
  MAX_MODULE_NAME_LENGTH,
} from './ModuleForm.const';
import { ModuleFormProps } from './ModuleForm.types';
import * as S from './ModuleForm.css';

export const ModuleForm = ({
  module,
  editMode = false,
  onClose,
  moduleAction,
  open,
}: ModuleFormProps) => {
  const { user } = useUserContext();

  const [name, setName] = useState(module?.name || '');
  const [description, setDescription] = useState(module?.description || '');

  const { isCreatingModule, createModule } = ModuleService.useCreateModule();
  const { isPatchingModule, patchModule } = ModuleService.usePatchModule(module?.id);

  const isMakingRequest = editMode ? isPatchingModule : isCreatingModule;
  const handleClose = useCloseWithConfirm({ onClose });

  const defaultName = module?.name ?? '';
  const defaultDescription = module?.description ?? '';

  const isButtonDisabled =
    (removeExtraSpaces(name) === defaultName &&
      removeExtraSpaces(description) === defaultDescription) ||
    stringIsEmpty(name) ||
    isMakingRequest ||
    removeExtraSpaces(name).length > MAX_MODULE_NAME_LENGTH ||
    removeExtraSpaces(description).length > MAX_MODULE_DESCRIPTION_LENGTH;

  const submitFunction = editMode ? patchModule : createModule;

  const handleSubmit = async () => {
    try {
      const {
        data: { data },
      } = await submitFunction({
        data: {
          ...(defaultName !== removeExtraSpaces(name) && {
            name: removeExtraSpaces(name),
          }),
          ...((module || defaultDescription !== removeExtraSpaces(description)) && {
            description: removeExtraSpaces(description),
          }),
        },
      });
      moduleAction({
        ...data,
        updatedAt: getDate(data.updatedAt),
        numberOfQuestions: module ? module.numberOfQuestions : 0,
        numberOfTasks: module ? module.numberOfTasks : 0,
        questions: module ? module.questions : [],
      });
      onClose();
    } catch (e) {
      handleException(e);
    }
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      onClose={() => handleClose(name === defaultName)}
    >
      <S.DialogTitle>{editMode ? 'EDIT' : 'ADD'} MODULE</S.DialogTitle>
      <S.DialogContent>
        <S.Label>
          Author: <S.BoldText>{module?.updatedBy ?? user?.name}</S.BoldText>
        </S.Label>
        <S.Label>
          Date: <S.BoldText>{new Date().toLocaleDateString()}</S.BoldText>
        </S.Label>
        <S.FormControl>
          <S.ModuleLabel>Module name:</S.ModuleLabel>
          <TextField
            value={name}
            onChange={(e) => setName(e.target.value)}
            variant="outlined"
            size="small"
            placeholder=" e.g. JavaScript"
            error={name.length > MAX_MODULE_NAME_LENGTH}
          />

          {name.length > MAX_MODULE_NAME_LENGTH && (
            <ValidationError limit={MAX_MODULE_NAME_LENGTH} />
          )}
          <S.ModuleLabel>
            Module description <S.NotObligatory>(not obligatory):</S.NotObligatory>
          </S.ModuleLabel>
          <TextField
            multiline
            placeholder=" e.g. JavaScript module's description"
            rows={4}
            variant="outlined"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            error={description.length > MAX_MODULE_DESCRIPTION_LENGTH}
          />
          {description.length > MAX_MODULE_DESCRIPTION_LENGTH && (
            <ValidationError limit={MAX_MODULE_DESCRIPTION_LENGTH} />
          )}
        </S.FormControl>
      </S.DialogContent>
      <S.DialogActions>
        <S.CancelButton
          onClick={() =>
            handleClose(
              removeExtraSpaces(name) === defaultName &&
                removeExtraSpaces(description) === defaultDescription,
            )
          }
        >
          Cancel
        </S.CancelButton>
        <S.ApproveButton
          onClick={handleSubmit}
          variant="contained"
          disabled={isButtonDisabled}
        >
          {editMode ? 'Save' : 'Add'}
        </S.ApproveButton>
      </S.DialogActions>
    </Dialog>
  );
};
