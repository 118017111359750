import { useState } from 'react';
import { AccordionDetails, AccordionSummary, Tooltip } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { QuestionService } from 'api';
import Brain from 'assets/brain.svg';
import Hammer from 'assets/hammer.svg';
import { QuestionCriteria } from 'pages/Questions/components/QuestionCriteria/QuestionCriteria';
import { QuestionForm } from 'pages/Questions/components/QuestionForm';
import { DeleteIcon } from 'styles.global';
import DeleteDialog from 'components/DeleteDialog';
import { handleException } from 'utils/errorHandlingUtils';
import { copyToClipboard } from 'utils/helpers';
import { QuestionRowProps } from './QuestionRow.types';
import * as S from './QuestionRow.css';

export const QuestionRow = ({
  updateQuestion,
  deleteQuestion,
  toggleModuleView,
  question,
  moduleNames,
  displayModuleName = false,
}: QuestionRowProps) => {
  const [open, setOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);

  function handleToggleModuleView() {
    if (toggleModuleView) {
      toggleModuleView({
        id: question.moduleId,
        name: question.moduleName,
      });
    }
  }

  const deleteQuestionAsync = QuestionService.useDeleteQuestion(question.id);

  const handleDelete = async () => {
    try {
      await deleteQuestionAsync();
      deleteQuestion(question);
      setDeleteDialogOpen(false);
    } catch (e) {
      handleException(e);
    }
  };

  return (
    <S.QuestionRow onChange={() => setOpen((op) => !op)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <S.QuestionBasicInfo>
          <Tooltip
            placement="top-start"
            title="Click to copy to clipboard"
            enterDelay={250}
            enterNextDelay={250}
          >
            <S.QuestionTypeIcon
              src={question.isPractical ? Hammer : Brain}
              alt="icon"
              onClick={(e) => {
                copyToClipboard(question.text);
                e.stopPropagation();
              }}
            />
          </Tooltip>
          <S.QuestionText>
            {open ? (
              <S.BoldText> {question.text} </S.BoldText>
            ) : (
              <div>{question.text}</div>
            )}
          </S.QuestionText>
          {displayModuleName && (
            <S.QuestionRowElem>
              <S.QuestionModuleTile onClick={handleToggleModuleView}>
                {question.moduleName}
              </S.QuestionModuleTile>
            </S.QuestionRowElem>
          )}
          <S.LastModified>last modified {question.updatedAt}</S.LastModified>
        </S.QuestionBasicInfo>
      </AccordionSummary>
      <AccordionDetails>
        <S.CriteriaContainer>
          <QuestionCriteria criteria={question.criteria} />
          {open && (
            <S.ActionBar>
              <S.IconsContainer onClick={() => setIsEditDialogOpen(true)}>
                <S.EditIcon />
                <S.IconText>edit question</S.IconText>
              </S.IconsContainer>
              <S.IconsContainer onClick={() => setDeleteDialogOpen(true)}>
                <DeleteIcon />
                <S.IconText>delete question</S.IconText>
              </S.IconsContainer>
            </S.ActionBar>
          )}
        </S.CriteriaContainer>
      </AccordionDetails>
      {isEditDialogOpen && (
        <QuestionForm
          moduleNames={moduleNames}
          open={isEditDialogOpen}
          question={question}
          onClose={() => setIsEditDialogOpen(false)}
          updateQuestion={updateQuestion}
          editMode
        />
      )}
      {isDeleteDialogOpen && (
        <DeleteDialog
          open={isDeleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
          handleClick={handleDelete}
          title="DELETE QUESTION"
          content="You're trying to delete question"
        />
      )}
    </S.QuestionRow>
  );
};
