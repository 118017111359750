import { useEffect, useState } from 'react';
import { useIsAuthenticated } from '@azure/msal-react';
import { useUserContext } from '../../context/UserContext';

const authorizedRoles = [
  'technical_recruiter',
  'sphinx_dev',
  'hr',
  'sales',
  'management',
];

export const useUserIsAuthorized = (): boolean | undefined => {
  const [userIsAuthorized, setUserIsAuthorized] = useState(true);
  const isAuthenticated = useIsAuthenticated();
  const { user } = useUserContext();

  useEffect(() => {
    const roles = user?.roles;
    if (roles !== undefined) {
      setUserIsAuthorized(
        !(isAuthenticated && !authorizedRoles.some((role) => roles.includes(role))),
      );
    } else {
      setUserIsAuthorized(false);
    }
  }, [isAuthenticated, user]);

  return userIsAuthorized;
};
