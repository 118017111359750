import { ChangeEvent, useMemo, useState } from 'react';
import {
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { QuestionService } from 'api';
import { useUserContext } from 'context';
import { mapToCriterion } from 'pages/Questions/utils';
import { Criterion, NewQuestion, Question } from 'types/question';
import { useImmer } from 'use-immer';
import { ValidationError } from 'components/ValidationError';
import { getDate } from 'utils/formatters';
import {
  criteriaInput,
  removeExtraSpaces,
  sortModulesByName,
  stringIsEmpty,
} from 'utils/helpers';
import { useCloseWithConfirm } from 'utils/hooks';
import {
  MAX_CRITERIA_LENGTH,
  MAX_CRITERIA_TEXT_LENGTH,
  MAX_QUESTION_TEXT_LENGTH,
} from './QuestionForm.const';
import { Errors, QuestionFormProps, QuestionType } from './QuestionForm.types';
import * as S from './QuestionForm.css';

const isFormTouched = (
  question: Question | undefined,
  selectedModule: string,
  questionText: string,
  type: QuestionType,
  isCriteriaTouched: boolean,
  isSelectedModuleLast: boolean | string,
  criteria: Criterion[],
) =>
  question
    ? selectedModule !== question?.moduleName ||
      questionText.trim() !== question?.text.trim() ||
      (!question?.isPractical && type === QuestionType.Practical) ||
      (question?.isPractical && type === QuestionType.Theoretical) ||
      isCriteriaTouched
    : isSelectedModuleLast ||
      questionText.trim() ||
      criteria.map(({ name }) => name).filter(removeExtraSpaces).length ||
      type === QuestionType.Practical;

export const QuestionForm = ({
  question,
  editMode = false,
  onClose,
  addQuestion,
  updateQuestion,
  open,
  moduleNames,
}: QuestionFormProps) => {
  const { user } = useUserContext();
  const handleClose = useCloseWithConfirm({ onClose });

  const [errors, setErrors] = useState<Errors>({
    question: false,
    criteria: [false, false, false, false, false],
  });

  const { isCreatingQuestion, createQuestion } = QuestionService.useCreateQuestion();
  const { isPatchingQuestion, patchQuestion } = QuestionService.usePatchQuestion(
    question?.id,
  );

  const [lastModule, setLastModule] = useState<string | null>(null);

  const [type, setType] = useState(
    question?.isPractical ? QuestionType.Practical : QuestionType.Theoretical,
  );
  const [questionText, setQuestionText] = useState(question?.text || '');
  const [selectedModule, setSelectedModule] = useState(question?.moduleName || '');
  const [criteria, setCriteria] = useImmer<Criterion[]>(
    criteriaInput(question?.criteria),
  );

  const [createAnother, setCreateAnother] = useState(false);

  const modules = useMemo(() => sortModulesByName(moduleNames), [moduleNames]);

  const submitFunction = editMode ? patchQuestion : createQuestion;
  const isMakingRequest = editMode ? isPatchingQuestion : isCreatingQuestion;
  const isButtonDisabled =
    stringIsEmpty(questionText) ||
    !selectedModule ||
    isMakingRequest ||
    errors.question ||
    errors.criteria.some(Boolean);

  const handleSubmit = async (newQuestion: NewQuestion) => {
    const { data } = await submitFunction({ data: newQuestion });
    if (editMode && !!updateQuestion) {
      updateQuestion(
        {
          ...data?.data,
          updatedAt: getDate(data?.data.updatedAt),
          criteria: data?.data.criteria && mapToCriterion(data.data.criteria),
        },
        {
          differentModule: question?.moduleId !== data.data.moduleId,
          typeChanged: question?.isPractical !== data.data.isPractical,
          moduleId: question?.moduleId!,
        },
      );
    } else if (addQuestion) {
      addQuestion({
        ...data?.data,
        updatedAt: getDate(data?.data.updatedAt),
        criteria: data?.data.criteria && mapToCriterion(data.data.criteria),
      });
    }

    if (createAnother) {
      setQuestionText('');
      setCriteria(criteriaInput([]));
      setType(QuestionType.Theoretical);
      setLastModule(selectedModule);
    } else {
      onClose();
    }
  };

  const handleSelectedModuleChange = (event: ChangeEvent<{}>, value: string | null) => {
    setSelectedModule(value || '');
  };

  const handleTypeChange = (event: ChangeEvent<{ value: unknown }>) =>
    setType(event.target.value as QuestionType);

  function handleCriterionName(id: number, name: string) {
    if (name === '' && criteria.length > 1 && id !== 5) {
      setCriteria((draft) => {
        const newCriteria = draft
          .filter((_, index) => index !== id)
          .map((el, index) => ({ ...el, id: index }));

        setErrors((draftErrors) => {
          const newCriteriaErrors = [false, false, false, false, false];
          newCriteria.forEach((criterion, index) => {
            newCriteriaErrors[index] = criterion.name.length > MAX_CRITERIA_TEXT_LENGTH;
          });
          return { ...draftErrors, criteria: newCriteriaErrors };
        });

        if (newCriteria.filter((criterion) => criterion.name !== '').length === 4) {
          newCriteria.push({ id: 4, name: '' });
        }

        return newCriteria;
      });
      return;
    }

    setCriteria((draft) => {
      const criterion = draft.find((c) => c.id === id)!;
      criterion.name = name;
      if (id === draft.length - 1 && draft.length < MAX_CRITERIA_LENGTH) {
        draft.push({ id: id + 1, name: '' });
      }
    });

    setErrors((previousErrors) => {
      const newErrors = { ...previousErrors };
      newErrors.criteria[id] = removeExtraSpaces(name).length > MAX_CRITERIA_TEXT_LENGTH;
      return newErrors;
    });
  }

  function handleClick() {
    const moduleId = modules.find(({ name }) => name === selectedModule)!.id;

    const newQuestion: NewQuestion = {
      moduleId,
      text: questionText,
      isPractical: type === QuestionType.Practical,
      criteria: criteria
        .filter((c) => !stringIsEmpty(c.name))
        .map(({ name }: Criterion) => name),
    };
    handleSubmit(newQuestion);
  }

  function isCriteriaTouched() {
    const formCriteria = criteria.map(({ name }) => name).filter(removeExtraSpaces);
    const questionCriteria = question?.criteria
      ? question.criteria.map(({ name }) => name)
      : [];
    return formCriteria.length !== questionCriteria.length
      ? true
      : formCriteria.some((criterion, index) => criterion !== questionCriteria[index]);
  }

  const isSelectedModuleLast = lastModule
    ? lastModule !== selectedModule
    : selectedModule;

  const handleQuestionChange = (e: ChangeEvent<HTMLInputElement>) => {
    setQuestionText(e.target.value);
    setErrors((previousErrors) => ({
      ...previousErrors,
      question: removeExtraSpaces(e.target.value).length > MAX_QUESTION_TEXT_LENGTH,
    }));
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      onClose={() =>
        handleClose(
          !isFormTouched(
            question,
            selectedModule,
            questionText,
            type,
            isCriteriaTouched(),
            isSelectedModuleLast,
            criteria,
          ),
        )
      }
    >
      <S.DialogTitle>{editMode ? 'EDIT' : 'ADD'} QUESTION </S.DialogTitle>
      <DialogContent>
        <S.StaticContainer>
          <S.StaticLabel>
            Author: <S.BoldText>{question?.updatedBy ?? user?.name}</S.BoldText>
          </S.StaticLabel>
          <S.StaticLabel>
            Date:{' '}
            <S.BoldText>
              {question?.updatedAt || new Date().toLocaleDateString()}
            </S.BoldText>
          </S.StaticLabel>
        </S.StaticContainer>
        <S.FormControl>
          <S.QuestionLabel>Module:</S.QuestionLabel>
          <Autocomplete
            getOptionLabel={(option) => option}
            disabled={!modules.length}
            onChange={handleSelectedModuleChange}
            value={selectedModule}
            options={[selectedModule, ...modules.map(({ name }) => name)]}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                size="small"
                placeholder="Select module"
              />
            )}
          />
          <S.TypeLabel> Type: </S.TypeLabel>
          <FormControl>
            <RadioGroup row value={type} onChange={handleTypeChange}>
              <FormControlLabel
                value={QuestionType.Theoretical}
                control={<Radio color="default" />}
                label="Theoretical question"
              />
              <FormControlLabel
                value={QuestionType.Practical}
                control={<Radio color="default" />}
                label="Practical task"
              />
            </RadioGroup>
          </FormControl>
          <S.QuestionLabel>Question:</S.QuestionLabel>
          <TextField
            multiline
            placeholder="Enter question"
            rows={4}
            variant="outlined"
            value={questionText}
            onChange={handleQuestionChange}
            error={errors.question}
          />
          {errors.question && <ValidationError limit={MAX_QUESTION_TEXT_LENGTH} />}
          <S.Label>Criteria for response:</S.Label>
          <S.Criteria>
            {criteria.map((criterion, index) => (
              <div key={index}>
                <S.CriterionInput
                  value={criteria[index].name}
                  placeholder="Enter criterion"
                  key={criterion.id}
                  variant="outlined"
                  size="small"
                  onChange={(e) => handleCriterionName(criterion.id, e.target.value)}
                  error={errors.criteria[index]}
                />
                {errors.criteria[index] && (
                  <ValidationError limit={MAX_CRITERIA_TEXT_LENGTH} />
                )}
              </div>
            ))}
          </S.Criteria>
        </S.FormControl>
      </DialogContent>
      <S.DialogActions>
        <S.CancelButton
          onClick={() =>
            handleClose(
              !isFormTouched(
                question,
                selectedModule,
                questionText,
                type,
                isCriteriaTouched(),
                isSelectedModuleLast,
                criteria,
              ),
            )
          }
        >
          Cancel
        </S.CancelButton>
        <S.ApproveButton
          onClick={handleClick}
          variant="contained"
          disabled={isButtonDisabled}
        >
          {editMode ? 'Save' : 'Add'}
        </S.ApproveButton>
      </S.DialogActions>
      {!editMode && (
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <S.CheckedButton onClick={() => setCreateAnother(!createAnother)}>
            Create another
            <Checkbox checked={createAnother} />
          </S.CheckedButton>
        </Box>
      )}
    </Dialog>
  );
};
