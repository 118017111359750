import { getDate, getTime } from 'utils/formatters';

export const getEndedRecruitmentUrl = (
  id: string | number,
  recruitmentDate: string,
  candidateName: string,
  editMode: boolean,
) => {
  const date = `${getDate(recruitmentDate)}_${getTime(recruitmentDate)}`;
  const name = candidateName.split(' ').join('_');
  if (editMode) {
    return `/ended-recruitment/${id}/${name}/${date}?mode=edit`;
  }
  return `/ended-recruitment/${id}/${name}/${date}`;
};
