import {
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  FormControl as MuiFormControl,
  Typography,
} from '@material-ui/core';
import styled from 'styled-components';
import { Button } from 'styles.global';

export const DialogTitle = styled(Typography)`
  &.MuiTypography-root {
    font-weight: 600;
    align-self: center;
    margin-top: 20px;
    font-size: 25px;
    padding-top: 16px;
    padding-bottom: 20px;
    text-decoration: underline;
    text-underline-offset: 8px;
  }
`;

export const DialogContent = styled(MuiDialogContent)`
  &.MuiDialogContent-root {
    padding: 30px 100px;
  }
`;

export const ModuleLabel = styled(Typography)`
  &.MuiTypography-root {
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: bold;
  }
`;

export const NotObligatory = styled.span`
  font-size: 13px;
`;

export const Label = styled(Typography)`
  &.MuiTypography-root {
    font-size: 20px;
    margin-bottom: 10px;
  }
`;

export const FormControl = styled(MuiFormControl)`
  &.MuiFormControl-root {
    width: 100%;
    margin-top: 5px;
    text-align: left;
  }
`;

export const DialogActions = styled(MuiDialogActions)`
  &.MuiDialogActions-root {
    width: 400px;
    margin: auto;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 50px;
  }
`;

export const BoldText = styled.span`
  font-weight: bold;
`;

export const ApproveButton = styled(Button).attrs({ $secondary: true })`
  &.MuiButton-root {
    width: 123px;
  }
`;

export const CancelButton = styled(Button)`
  &.MuiButton-root {
    width: 123px;
  }
`;
