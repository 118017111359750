import { Accordion, AccordionDetails } from '@material-ui/core';
import styled from 'styled-components';
import { TextArea } from 'styles.global';
import theme from 'theme';

export const ModuleAccordion = styled(Accordion)`
  &.MuiAccordion-root {
    padding: 10px 0;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
  }
  .MuiAccordionSummary-root {
    padding: 0;
  }
`;

export const ModuleAccordionDetails = styled(AccordionDetails)`
  flex-direction: column;
`;

export const ModuleTitle = styled.div`
  width: 25%;
  font-size: large;
  overflow-wrap: break-word;
  margin: 0;
  font-weight: bold;
  padding: 5px 70px 5px 50px;
  background-color: #ffd242;
  clip-path: polygon(0 0, 93% 0%, 90% 120%, 0% 100%);
`;

export const TotalScore = styled.div`
  font-size: large;
  text-align: center;
  margin-left: auto;
  margin-right: 80px;
`;

export const BoldText = styled.span`
  font-weight: bold;
`;

export const ModuleSummary = styled.div`
  margin-left: 20px;
  white-space: pre-line;
`;

export const ModuleSummaryTitle = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
`;

export const ModuleSummaryTextArea = styled(TextArea)`
  resize: none;
  height: 150px;
  font-size: large;
  background-color: ${theme.palette.secondary.light};
  box-shadow: inset 1px 3px 4px lightgray;
  border: none;

  &:focus {
    outline-color: ${theme.palette.primary.main};
  }
`;
