import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { Box } from '@material-ui/core';
import { RecruitmentService } from 'api';
import debounce from 'lodash.debounce';
import { handleException } from 'utils/errorHandlingUtils';
import { MODULE_SUMMARY_DEBOUNCE_DELAY } from './ModuleSummary.const';
import { ModuleSummaryProps } from './ModuleSummary.types';
import * as S from './ModuleSummary.css';
import { ModuleTitle } from 'pages/Recruitment/components/RecruitmentModule/RecruitmentModule.css';

export const ModuleSummary = ({
  defaultValue,
  moduleDescription,
  taskNumber,
  questionNumber,
  recruitmentModuleId,
  id,
  isDisabled = false,
  inProgress = false,
  getRecruitmentModules,
}: ModuleSummaryProps) => {
  const updateRecruitmentModule = RecruitmentService.useUpdateRecruitmentModule();
  const [moduleNote, setModuleNote] = useState(defaultValue);

  const updateModuleSummary = useCallback(
    async (note: string) => {
      try {
        await updateRecruitmentModule(
          recruitmentModuleId,
          {
            moduleId: id,
            numberOfRandomQuestions: questionNumber,
            numberOfRandomTasks: taskNumber,
            chosenQuestionIds: [],
          },
          note,
        );
        if (getRecruitmentModules) {
          getRecruitmentModules();
        }
      } catch (e) {
        handleException(e);
      }
    },
    [
      id,
      questionNumber,
      recruitmentModuleId,
      taskNumber,
      updateRecruitmentModule,
      getRecruitmentModules,
    ],
  );
  const handleNoteChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => updateModuleSummary(e.target.value),
    [updateModuleSummary],
  );

  const debouncedModuleSummaryChange = useMemo(
    () => debounce(handleNoteChange, MODULE_SUMMARY_DEBOUNCE_DELAY),
    [handleNoteChange],
  );

  if (isDisabled && !defaultValue) {
    return null;
  }

  return (
    <div>
      {isDisabled ? (
        <Box mt={3}>
          <ModuleTitle>
            <Box display="flex">
              <S.ModuleSummaryIcon />
              Sum up module
            </Box>
          </ModuleTitle>
          <S.ModuleDescription mt={2}>{moduleDescription}</S.ModuleDescription>
          <S.BoldText>Note:</S.BoldText>
          <S.EndedModuleSummaryArea>{defaultValue}</S.EndedModuleSummaryArea>
        </Box>
      ) : (
        <Box mt={3}>
          <ModuleTitle>
            <Box display="flex">
              <S.ModuleSummaryIcon />
              Sum up module
            </Box>
          </ModuleTitle>
          <Box mt={2}>{moduleDescription}</Box>
          <Box mt={3}>
            <S.ModuleSummaryTextArea
              $empty={!moduleNote && !inProgress}
              defaultValue={defaultValue}
              spellCheck="false"
              placeholder="Enter module note"
              onChange={(e) => {
                debouncedModuleSummaryChange(e);
                setModuleNote(e.target.value);
              }}
            />
            {!moduleNote && !inProgress && (
              <S.ModuleNotFilled>
                Please fill it in to complete recruitment
              </S.ModuleNotFilled>
            )}
          </Box>
        </Box>
      )}
    </div>
  );
};
