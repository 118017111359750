import styled from 'styled-components';

export const EmptyList = styled.div`
  width: 100%;
`;

export const DeleteIcon = styled.div`
  display: flex;
  align-items: center;
  &:hover {
    background-color: rgba(237, 237, 237, 1);
    cursor: pointer;
  }
`;

export const IconText = styled.span`
  margin-right: 5px;
`;

export const EmptyModule = styled.p`
  font-style: italic;
`;
