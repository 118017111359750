import {
  ChangeEvent,
  Reducer,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Checkbox } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { QuestionService, TemplateService } from 'api';
import { Button } from 'styles.global';
import { Module } from 'types/module';
import { Question } from 'types/question';
import {
  AdjustRecruitmentModule as IAdjustRecruitmentModule,
  NewRecruitmentModule,
} from 'types/recruitment';
import { handleException } from 'utils/errorHandlingUtils';
import { newRecruitmentModule } from 'utils/formatters';
import { isNumber, removeExtraSpaces, sortModulesByName } from 'utils/helpers';
import { useDimensions } from 'utils/hooks';
import { SaveAsTemplateDialog } from './components/SaveAsTemplateDialog';
import { OnSubmitProps } from './components/SaveAsTemplateDialog/SaveAsTemplateDialog.types';
import { Actions, reducer, State } from './AdjustRecruitmentModule.reducer';
import { AdjustRecruitmentModuleProps } from './AdjustRecruitmentModule.types';
import AdjustRecruitmentModuleCore from './AdjustRecruitmentModuleCore';
import * as S from './AdjustRecruitmentModule.css';

const AdjustRecruitmentModule = ({
  handleSubmit,
  handleQuit,
  canSubmit = true,
  propState = null,
  isMakingRequest = false,
  propModules,
  propPositions,
  isTemplatePrivate,
  setTemplatePrivate,
  templates,
  handleTemplateChange,
  selectedTemplate,
  isAuthorEditing,
}: AdjustRecruitmentModuleProps) => {
  const { pathname } = useLocation();

  const { getQuestions: getQuestionsData } = QuestionService.useGetQuestions();
  const { createTemplate } = TemplateService.useCreateTemplate();
  const { createTemplateModules } = TemplateService.useCreateTemplateModules();
  const [ref, dimensions] = useDimensions();

  const [state, dispatch] = useReducer<Reducer<State, Actions>>(reducer, [
    newRecruitmentModule({ id: 0 }),
  ]);

  const [saveAsTemplate, setSaveAsTemplate] = useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);

  const isFilled = state.some(
    ({ selectedNumberOfQuestions, selectedNumberOfTasks, selectedQuestions }) =>
      selectedNumberOfQuestions ||
      selectedNumberOfTasks ||
      selectedQuestions.some(({ text }) => text),
  );

  const isUpdated = state.some((module) => module.isUpdated);

  const displaySaveAsPrivateCheckbox =
    pathname.match(/^\/add-template/) ||
    (pathname.match(/^\/edit-template/) && isAuthorEditing);

  const isValid = state.every(
    ({ errors: { numberOfQuestions, numberOfTasks } }) =>
      !numberOfQuestions && !numberOfTasks,
  );

  const isSubmitButtonDisabled = !isFilled || !isValid || !canSubmit || isMakingRequest;

  useEffect(() => {
    dispatch({
      type: 'setRecruitmentModules',
      recruitmentModules: propState || [newRecruitmentModule({ id: 0 })],
    });
  }, [propState]);

  const positions = useMemo(
    () => propPositions.map(({ positionName }) => positionName),
    [propPositions],
  );

  const modules = useMemo(
    () => sortModulesByName(propModules) as Module[],
    [propModules],
  );

  const handleModuleChange = useCallback(
    async (
      event: ChangeEvent<{}>,
      moduleName: string | null,
      recruitmentModuleId: number,
    ) => {
      if (moduleName) {
        const module = modules.find(({ name }) => name === moduleName)!;

        try {
          const response = await getQuestionsData({
            moduleIds: [module.id],
            perPage: module.numberOfQuestions + module.numberOfTasks,
          });

          const questions = response.data.data.map(
            ({ id, text, isPractical }: Question) => ({
              id,
              text,
              isPractical,
            }),
          );

          const recruitmentModule: IAdjustRecruitmentModule = {
            ...newRecruitmentModule({ id: recruitmentModuleId }),
            moduleId: module.id,
            moduleName,
            questions,
            numberOfQuestions: response.data.numberOfQuestions,
            numberOfTasks: response.data.numberOfTasks,
            availableNumberOfQuestions: response.data.numberOfQuestions,
            availableNumberOfTasks: response.data.numberOfTasks,
          };
          dispatch({ type: 'updateRecruitmentModule', recruitmentModule });
        } catch (e) {
          handleException(e);
        }
      } else if (state.length > 1) {
        dispatch({
          type: 'deleteRecruitmentModule',
          id: recruitmentModuleId,
        });
      } else {
        dispatch({
          type: 'updateRecruitmentModule',
          recruitmentModule: newRecruitmentModule({
            id: recruitmentModuleId,
          }),
        });
      }
    },
    [getQuestionsData, modules, state],
  );

  function addRecruitmentModule() {
    dispatch({
      type: 'addRecruitmentModule',
      recruitmentModule: newRecruitmentModule({ id: state.length }),
    });
  }

  const moduleAutocompleteOptions = useCallback(
    (value: string | null) => {
      const usedModules = state.map(({ moduleName }) => moduleName);
      const filteredModules = modules
        .filter(({ name }) => !usedModules.includes(name))
        .map(({ name }) => name);
      return value ? [value, ...filteredModules] : filteredModules;
    },
    [modules, state],
  );

  const questionAutocompleteOptions = useCallback(
    (value: string, { questions, selectedQuestions }: IAdjustRecruitmentModule) => {
      const usedQuestions = selectedQuestions.map(({ text }) => text);
      const filteredQuestions = questions
        .filter(({ text }) => !usedQuestions.includes(text))
        .map(({ text }) => text);
      return value ? [value, ...filteredQuestions] : filteredQuestions;
    },
    [],
  );

  const getQuestionInfo = useCallback(
    ({
      recruitmentModuleId,
      questionText,
    }: {
      recruitmentModuleId: number;
      questionText: string;
    }) => {
      const module = state.find(({ id }) => id === recruitmentModuleId)!;
      const question = module.questions.find(({ text }) => text === questionText)!;
      return { id: question?.id, isPractical: question?.isPractical };
    },
    [state],
  );

  const handleQuestionChange = useCallback(
    (
      event: ChangeEvent<{}>,
      text: string | null,
      recruitmentModuleId: number,
      questionId: number,
    ) => {
      const { selectedQuestions } = state.find(
        ({ id: selectedQuestionId }) => selectedQuestionId === recruitmentModuleId,
      )!;

      const selectedQuestion = selectedQuestions.find(
        ({ id: selectedQuestionId }) => selectedQuestionId === questionId,
      )!;

      if (!text && selectedQuestions.length > 1) {
        return dispatch({
          type: 'deleteSelectedQuestion',
          recruitmentModuleId,
          questionId: selectedQuestion.id,
        });
      }

      const questionText = text || '';

      const { id, isPractical } = getQuestionInfo({
        recruitmentModuleId,
        questionText,
      });

      dispatch({
        type: 'updateSelectedQuestion',
        recruitmentModuleId,
        question: {
          ...selectedQuestion,
          text: questionText,
          questionId: id,
          isPractical,
        },
      });
    },
    [getQuestionInfo, state],
  );

  const handleQuestionNumberChange = useCallback(
    (value: string, recruitmentModuleId: number) => {
      if (isNumber(value) || value === '') {
        dispatch({ type: 'changeQuestionNumber', number: +value, recruitmentModuleId });
      }
    },
    [],
  );

  const handleTaskNumberChange = useCallback(
    (value: string, recruitmentModuleId: number) => {
      if (isNumber(value) || value === '') {
        dispatch({ type: 'changeTaskNumber', number: +value, recruitmentModuleId });
      }
    },
    [],
  );

  const handleSaveTemplate = async ({ name, position, isPrivate }: OnSubmitProps) => {
    try {
      const { data } = await createTemplate({
        data: {
          name: removeExtraSpaces(name),
          position: removeExtraSpaces(position),
          private: isPrivate,
        },
      });
      const recruitmentModules = getRecruitmentModules() as NewRecruitmentModule[];
      await createTemplateModules(data.data.id, recruitmentModules);
      handleSubmit(recruitmentModules);
    } catch (e) {
      handleException(e);
    }
  };

  const getRecruitmentModules = () =>
    state
      .filter(({ moduleId }) => moduleId)
      .map(
        ({
          moduleId,
          selectedNumberOfQuestions,
          selectedNumberOfTasks,
          selectedQuestions,
        }) => ({
          moduleId,
          numberOfRandomQuestions: selectedNumberOfQuestions || 0,
          numberOfRandomTasks: selectedNumberOfTasks || 0,
          chosenQuestionIds: selectedQuestions
            .map(({ questionId }) => questionId)
            .filter(Boolean),
        }),
      )
      .filter(
        (module) =>
          module.numberOfRandomQuestions ||
          module.numberOfRandomTasks ||
          module.chosenQuestionIds.length,
      );

  const handleSave = () => {
    if (saveAsTemplate) {
      setDialogOpen(true);
    } else {
      handleSubmit(getRecruitmentModules() as NewRecruitmentModule[]);
    }
  };

  return (
    <div ref={ref}>
      {pathname.match(/^\/adjust-recruitment/) ? (
        <S.Header $width={dimensions.width}>
          <S.HeaderTitle underline>Question form configuration:</S.HeaderTitle>
        </S.Header>
      ) : (
        <S.TemplateHeader $width={dimensions.width}>
          <S.HeaderTitle />
        </S.TemplateHeader>
      )}

      <AdjustRecruitmentModuleCore
        adjustRecruitment={!!pathname.match(/^\/adjust-recruitment/)}
        state={state}
        modules={modules}
        moduleAutocompleteOptions={moduleAutocompleteOptions}
        questionAutocompleteOptions={questionAutocompleteOptions}
        handleModuleChange={handleModuleChange}
        handleQuestionChange={handleQuestionChange}
        handleQuestionNumberChange={handleQuestionNumberChange}
        handleTaskNumberChange={handleTaskNumberChange}
        templates={templates}
        selectedTemplate={selectedTemplate ?? null}
        handleTemplateChange={handleTemplateChange}
      />
      <Box mt={5}>
        <Button
          $secondary
          startIcon={<AddCircleOutlineIcon />}
          onClick={addRecruitmentModule}
        >
          Add module
        </Button>
      </Box>

      <Box
        display="flex"
        justifyContent="space-between"
        marginRight="32px"
        marginTop="64px"
      >
        <Button onClick={() => handleQuit(!isUpdated)}>Quit without saving</Button>
        <Box display="flex">
          {pathname.match(/^\/adjust-recruitment/) && (
            <S.HoverableContainer onClick={() => setSaveAsTemplate(!saveAsTemplate)}>
              <Checkbox checked={saveAsTemplate} color="primary" />
              Save as template too
            </S.HoverableContainer>
          )}
          {displaySaveAsPrivateCheckbox && (
            <S.HoverableContainer
              onClick={() => setTemplatePrivate && setTemplatePrivate(!isTemplatePrivate)}
            >
              <Checkbox checked={isTemplatePrivate} color="primary" />
              Save as my template only
            </S.HoverableContainer>
          )}
          <S.SaveButton
            onClick={handleSave}
            disabled={isSubmitButtonDisabled}
            variant="contained"
          >
            Save
          </S.SaveButton>
        </Box>
      </Box>
      {isDialogOpen && (
        <SaveAsTemplateDialog
          isOpen={isDialogOpen}
          onClose={() => setDialogOpen(false)}
          onSubmit={handleSaveTemplate}
          positions={positions}
        />
      )}
    </div>
  );
};

export default AdjustRecruitmentModule;
