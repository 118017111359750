import { useCallback } from 'react';
import { NewRecruitmentModule } from 'types/recruitment';
import { Template } from 'types/template';
import { useAxios } from './axios';
import { TemplatesFilter } from './types';

export const useCreateTemplate = () => {
  const [{ loading: isCreatingTemplate }, createTemplate] = useAxios(
    { url: '/templates', method: 'POST' },
    { manual: true },
  );
  return { isCreatingTemplate, createTemplate };
};

export const useCreateTemplateModules = () => {
  const [{ loading: isCreatingTemplateModules }, createTemplateModulesData] = useAxios(
    { method: 'POST' },
    { manual: true },
  );
  const createTemplateModules = (
    id: number,
    recruitmentModules: NewRecruitmentModule[],
  ) =>
    createTemplateModulesData({
      url: `/templates/${id}/modules`,
      data: [...recruitmentModules],
    });

  return { createTemplateModules, isCreatingTemplateModules };
};

export const useDeleteTemplate = () => {
  const [, deleteTemplateData] = useAxios({ method: 'DELETE' }, { manual: true });
  const deleteTemplate = (id?: number) => deleteTemplateData({ url: `/templates/${id}` });
  return { deleteTemplate };
};

export const usePatchTemplate = () => {
  const [{ loading: isPatchingTemplate }, patchTemplateData] = useAxios(
    { method: 'PATCH' },
    { manual: true },
  );

  const patchTemplate = (
    id: number | string,
    name: string,
    position: string,
    isPrivate: boolean,
  ) =>
    patchTemplateData({
      url: `/templates/${id}`,
      data: { name, position, private: isPrivate },
    });

  return { isPatchingTemplate, patchTemplate };
};

export const usePutTemplateModules = () => {
  const [{ loading: isPutingTemplateModules }, putTemplateModulesData] = useAxios(
    { method: 'PUT' },
    { manual: true },
  );

  const putTemplateModules = (
    id: number | string,
    templateModules: NewRecruitmentModule[],
  ) =>
    putTemplateModulesData({
      url: `/templates/${id}/modules`,
      data: [...templateModules],
    });

  return { isPutingTemplateModules, putTemplateModules };
};

export const useGetTemplate = () => {
  const [{ data, loading: isLoadingTemplate }, getTemplateData] = useAxios<{
    data: Template;
  }>({ method: 'GET' }, { manual: true });

  const getTemplate = useCallback(
    (id: number | string) => getTemplateData({ url: `/templates/${id}` }),
    [getTemplateData],
  );

  return {
    template: data?.data,
    isLoadingTemplate,
    getTemplate,
  };
};

export const useGetTemplates = () => {
  const [{ data = { data: [] }, loading: isLoadingTemplatesData }, getTemplatesData] =
    useAxios<{ data: Template[]; totalTemplates: number }>(
      { method: 'GET' },
      { manual: true },
    );

  const getTemplates = useCallback(
    ({
      search = '',
      name = '',
      position = '',
      author = '',
      perPage,
      page = 0,
    }: TemplatesFilter = {}) =>
      getTemplatesData({
        url: '/templates',
        params: {
          ...(search && { search }),
          ...(name && { name }),
          ...(position && { position }),
          ...(author && { created_by: author }),
          ...(perPage && { per_page: perPage }),
          page: page + 1, // * Backend counts pages from 1
        },
      }),
    [getTemplatesData],
  );
  return { templatesData: data.data, isLoadingTemplatesData, getTemplates };
};
