import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ModuleService, QuestionService, RecruitmentService, TemplateService } from 'api';
import AdjustRecruitmentModule from 'pages/components/AdjustRecruitmentModule';
import {
  AdjustRecruitmentModule as IAdjustRecruitmentModule,
  NewRecruitmentModule,
  RecruitmentState,
} from 'types/recruitment';
import Container from 'components/Container';
import { handleException } from 'utils/errorHandlingUtils';
import { createRecruitmentModulesFromTemplate } from 'utils/formatters';
import { useQuitWithRedirect } from 'utils/hooks';
import { RouteParams } from 'utils/types/RouteParams';
import { RecruitmentModuleProps, SelectedTemplate, TemplateOption } from './Panel.types';

const LeftPanel = ({ recruitment }: RecruitmentModuleProps) => {
  const { push } = useHistory();
  const handleQuit = useQuitWithRedirect({ redirectTo: '/todo' });
  const { id: recruitmentId } = useParams<RouteParams>();

  const [selectedTemplate, setSelectedTemplate] = useState<TemplateOption | null>(null);

  const { patchRecruitment } = RecruitmentService.usePatchRecruitment();
  const [recruitmentModules, setRecruitmentModules] =
    useState<IAdjustRecruitmentModule[]>();

  const [isLoading, setLoading] = useState(false);
  const { positionsData, getPositionsData } = RecruitmentService.useGetPositions();
  const { templatesData, getTemplates } = TemplateService.useGetTemplates();
  const { isLoadingModules, modulesData, getModules } = ModuleService.useGetModules();
  const { getTemplate } = TemplateService.useGetTemplate();
  const { getQuestions } = QuestionService.useGetQuestions();
  const { getRecruitmentModules } = RecruitmentService.useGetRecruitmentModules(
    recruitment.id,
  );
  const createRecruitmentModules = RecruitmentService.useCreateRecruitmentModules();
  const updateRecruitmentModules = RecruitmentService.useUpdateRecruitmentModules();

  const templates = useMemo(
    () => templatesData.map(({ id, name }) => ({ id, name })),
    [templatesData],
  );

  const fetchRecruitment = useCallback(async () => {
    try {
      const {
        data: { data: modules },
      } = await getModules();

      if (recruitment.recruitmentState === RecruitmentState.Adjusted) {
        setLoading(true);

        const {
          data: { data: recruitmentModulesData },
        } = await getRecruitmentModules();

        const templateModules = recruitmentModulesData.map(
          ({
            recruitmentChosenQuestions,
            id: baseId,
            moduleId,
            module: { name },
            numberOfRandomQuestions,
            numberOfRandomTasks,
          }) => ({
            id: baseId,
            templateChosenQuestions: recruitmentChosenQuestions.map(({ question }) => ({
              question: {
                id: question.id,
                text: question.text,
                isPractical: question.isPractical,
              },
            })),
            moduleId,
            moduleName: name,
            numberOfRandomQuestions,
            numberOfRandomTasks,
          }),
        );

        const {
          data: { data: questionsForRecruitmentModules },
        } = await getQuestions({
          moduleIds: templateModules.map(({ moduleId }) => moduleId),
        });

        setRecruitmentModules(
          await createRecruitmentModulesFromTemplate(
            questionsForRecruitmentModules,
            modules,
            templateModules,
          ),
        );
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      handleException(e);
    }
  }, [getModules, getQuestions, getRecruitmentModules, recruitment.recruitmentState]);

  async function handleTemplateChange(template: SelectedTemplate) {
    try {
      setSelectedTemplate(template);
      if (template) {
        setLoading(true);
        try {
          const {
            data: {
              data: { templateModules },
            },
          } = await getTemplate(template.id);
          const {
            data: { data: questionsForRecruitmentModules },
          } = await getQuestions({
            moduleIds: templateModules.map(({ moduleId }) => moduleId),
          });

          setRecruitmentModules(
            await createRecruitmentModulesFromTemplate(
              questionsForRecruitmentModules,
              modulesData,
              templateModules,
            ),
          );
        } catch (e) {
          handleException(e);
        }
        setLoading(false);
      } else {
        setRecruitmentModules(undefined);
      }
    } catch (e) {
      handleException(e);
    }
  }

  async function handleSubmit(newRecruitmentModules: NewRecruitmentModule[]) {
    try {
      if (recruitment.recruitmentState === RecruitmentState.Formed) {
        await createRecruitmentModules(recruitment.id, newRecruitmentModules);
        await patchRecruitment(+recruitmentId, {
          recruitmentState: RecruitmentState.Adjusted,
        });
      } else {
        await updateRecruitmentModules(recruitment.id, newRecruitmentModules);
      }
      push('/todo');
      window.scrollTo(0, 0);
    } catch (e) {
      handleException(e);
    }
  }

  useEffect(() => {
    fetchRecruitment();
    getPositionsData();
    getTemplates();
  }, [fetchRecruitment, getPositionsData, getTemplates]);

  return (
    <Container isLoading={isLoading || isLoadingModules}>
      <AdjustRecruitmentModule
        propState={recruitmentModules}
        handleSubmit={handleSubmit}
        handleQuit={handleQuit}
        propModules={modulesData}
        propPositions={positionsData}
        templates={templates}
        selectedTemplate={selectedTemplate}
        handleTemplateChange={handleTemplateChange}
      />
    </Container>
  );
};

export default LeftPanel;
