import { produce } from 'immer';
import { AdjustRecruitmentModule, RecruitmentModuleQuestion } from 'types/recruitment';

export type State = AdjustRecruitmentModule[];

type AddRecruitmentModule = {
  type: 'addRecruitmentModule';
  recruitmentModule: AdjustRecruitmentModule;
};

type DeleteRecruitmentModule = {
  type: 'deleteRecruitmentModule';
  id: number;
};

type DeleteSelectedQuestion = {
  type: 'deleteSelectedQuestion';
  recruitmentModuleId: number;
  questionId: number;
};

type SetRecruitmentModules = {
  type: 'setRecruitmentModules';
  recruitmentModules: AdjustRecruitmentModule[];
};

type ChangeTaskNumber = {
  type: 'changeTaskNumber';
  recruitmentModuleId: number;
  number: number;
};

type ChangeQuestionNumber = {
  type: 'changeQuestionNumber';
  recruitmentModuleId: number;
  number: number;
};

type UpdateRecruitmentModule = {
  type: 'updateRecruitmentModule';
  recruitmentModule: AdjustRecruitmentModule;
};

type UpdateSelectedQuestion = {
  type: 'updateSelectedQuestion';
  recruitmentModuleId: number;
  question: RecruitmentModuleQuestion;
};

export type Actions =
  | AddRecruitmentModule
  | DeleteRecruitmentModule
  | DeleteSelectedQuestion
  | ChangeQuestionNumber
  | ChangeTaskNumber
  | UpdateRecruitmentModule
  | UpdateSelectedQuestion
  | SetRecruitmentModules;

// eslint-disable-next-line consistent-return
export const reducer = produce((draft: State, action: Actions) => {
  function getModuleById(moduleId: number) {
    return draft.find(({ id }) => id === moduleId)!;
  }

  function getModuleIndex(moduleId: number) {
    return draft.findIndex(({ id }) => id === moduleId)!;
  }

  switch (action.type) {
    case 'addRecruitmentModule': {
      draft.push({ ...action.recruitmentModule, isUpdated: true });
      break;
    }

    case 'deleteSelectedQuestion': {
      const index = getModuleIndex(action.recruitmentModuleId);

      draft[index].selectedQuestions = draft[index].selectedQuestions
        .filter((i) => i.id !== action.questionId)
        .map((question) => ({ ...question, id: question.id - 1 }));
      break;
    }

    case 'deleteRecruitmentModule': {
      return draft
        .filter((module) => module.id !== action.id)
        .map((module) => ({ ...module, id: module.id - 1 }));
    }

    case 'setRecruitmentModules': {
      return action.recruitmentModules;
    }

    case 'changeQuestionNumber': {
      const module = getModuleById(action.recruitmentModuleId);
      module.isUpdated = true;
      module.selectedNumberOfQuestions = action.number;
      module.errors.numberOfQuestions =
        action.number > module.availableNumberOfQuestions!;
      break;
    }
    case 'changeTaskNumber': {
      const module = getModuleById(action.recruitmentModuleId);
      module.isUpdated = true;
      module.selectedNumberOfTasks = action.number;
      module.errors.numberOfTasks = action.number > module.availableNumberOfTasks!;
      break;
    }

    case 'updateRecruitmentModule': {
      const index = getModuleIndex(action.recruitmentModule.id);
      draft[index] = { ...action.recruitmentModule, isUpdated: true };
      break;
    }

    case 'updateSelectedQuestion': {
      const moduleIndex = getModuleIndex(action.recruitmentModuleId);
      draft[moduleIndex].isUpdated = true;
      const questionIndex = draft[moduleIndex].selectedQuestions.findIndex(
        ({ id }) => id === action.question.id,
      );

      if (action.question.id === draft[moduleIndex].selectedQuestions.length - 1) {
        draft[moduleIndex].selectedQuestions[questionIndex] = action.question;

        const addNewQuestion =
          action.question.text &&
          draft[moduleIndex].selectedQuestions.length <
            draft[moduleIndex].questions.length;

        if (addNewQuestion) {
          draft[moduleIndex].selectedQuestions = [
            ...draft[moduleIndex].selectedQuestions,
            {
              id: draft[moduleIndex].selectedQuestions.length,
              text: '',
              questionId: null,
              isPractical: null,
            },
          ];
        }
      } else {
        draft[moduleIndex].selectedQuestions[questionIndex] = action.question;
      }

      draft[moduleIndex].availableNumberOfTasks =
        draft[moduleIndex].numberOfTasks! -
        draft[moduleIndex].selectedQuestions.filter(({ isPractical }) => isPractical)
          .length;

      draft[moduleIndex].availableNumberOfQuestions =
        draft[moduleIndex].numberOfQuestions! -
        draft[moduleIndex].selectedQuestions.filter(
          ({ isPractical }) => isPractical != null && !isPractical, // * Null check is necessary
        ).length;

      draft[moduleIndex].errors.numberOfTasks =
        draft[moduleIndex].selectedNumberOfTasks! >
        draft[moduleIndex].availableNumberOfTasks!;

      draft[moduleIndex].errors.numberOfQuestions =
        draft[moduleIndex].selectedNumberOfQuestions! >
        draft[moduleIndex].availableNumberOfQuestions!;

      break;
    }

    default:
      break;
  }
});
