import { KeyboardDateTimePicker as MuiKeyboardDateTimePicker } from '@material-ui/pickers';
import styled from 'styled-components';
import theme from 'theme';

export const KeyboardDateTimePicker = styled(MuiKeyboardDateTimePicker)`
  &.MuiPickersToolbar-toolbar {
    height: 100px;
    display: flex;
    align-items: center;
    background-color: ${theme.palette.secondary.dark};
  }
`;
