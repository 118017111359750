import React from 'react';
import ReactDOM from 'react-dom';
import { EventType, PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { MuiThemeProvider as ThemeProvider } from '@material-ui/core/styles';
import { config } from 'authConfig';
import { RecruitmentContextProvider } from 'context';
import { ConfirmProvider } from 'material-ui-confirm';
import theme from 'theme';
import { App } from './App';

const isGeneratedViewDisplayed = Boolean(
  window.location.pathname.match(/^\/generated-ended-recruitment/),
);

export const msalInstance = new PublicClientApplication(config);

const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: any) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const { account } = event.payload;
    msalInstance.setActiveAccount(account);
  }
});

msalInstance
  .handleRedirectPromise()
  .then(() => {
    const account = msalInstance.getActiveAccount();
    if (!account && !isGeneratedViewDisplayed) {
      msalInstance.loginRedirect();
    }
  })
  .catch((err) => {
    console.error(err);
  });

ReactDOM.render(
  <MsalProvider instance={msalInstance}>
    <ConfirmProvider>
      <RecruitmentContextProvider>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </RecruitmentContextProvider>
    </ConfirmProvider>
  </MsalProvider>,
  document.getElementById('root'),
);
