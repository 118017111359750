import {
  Chip,
  Table as MuiTable,
  TableCell as MuiTableCell,
  TableHead as MuiTableHead,
} from '@material-ui/core';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import styled from 'styled-components';
import { Button } from 'styles.global';
import theme from 'theme';

export {
  PopoverOptions,
  PopoverOption,
  OptionsIcon,
} from 'components/Popover/Popover.css';

export { EmptyListImage } from 'styles.global';

export const ArrowUp = styled(ArrowUpward)`
  && {
    margin-top: 5px;
    :hover {
      cursor: pointer;
      color: ${theme.palette.secondary.dark}80;
    }
  }
`;

export const ArrowDown = styled(ArrowDownward)`
  && {
    margin-top: 5px;
    :hover {
      cursor: pointer;
      color: ${theme.palette.secondary.dark}80;
    }
  }
`;

export const BoldTableCell = styled(MuiTableCell)`
  &.MuiTableCell-root {
    font-weight: bold;
  }
`;

export const TableCell = styled(MuiTableCell)<{ $cellWidth: string }>`
  &.MuiTableCell-root {
    width: ${({ $cellWidth }) => $cellWidth};
  }
`;

export const StartButton = styled(Button).attrs({ $primary: true })`
  &.MuiButton-root {
    width: 81px;
  }
`;

export const AdjustButton = styled(Button).attrs({ $secondary: true })`
  &.MuiButton-root {
    width: 90px;
  }
`;

export const InProgressButton = styled(Button).attrs({ $primary: true })`
  &.MuiButton-root {
    width: 120px;
    padding: 7.5px 0;
  }
`;

export const Table = styled(MuiTable)`
  &.MuiTable-root {
    word-break: break-word;
  }
`;

export const TableHead = styled(MuiTableHead)`
  border-bottom: 1.5px black solid;
`;

export const CustomizedTableCell = styled(MuiTableCell)`
  &.MuiTableCell-root {
    padding-right: 0;
  }
`;

export const FiltersCount = styled(Chip)`
  position: relative;
  top: -15px;
  left: -3px;

  &.MuiChip-root {
    background-color: ${theme.palette.primary.main};
  }
`;

export const BoldText = styled.span`
  font-weight: bold;
`;

export const NotFoundContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
`;
