import { RecruitmentService } from 'api';
import { handleException } from 'utils/errorHandlingUtils';
import { useDimensions } from 'utils/hooks';
import { RightPanelProps } from './Panel.types';
import * as S from '../AdjustRecruitment.css';

const RightPanel = ({
  id,
  candidateName,
  positionName,
  seniorityLevel,
  recruitmentDate,
  hr,
  recruiter,
  cvFile,
}: RightPanelProps) => {
  const [ref, dimensions] = useDimensions();
  const { getCv, isLoadingCv } = RecruitmentService.useGetRecruitmentCvDocument(id);

  const handleDownloadCv = async () => {
    try {
      const response = await getCv();
      window.open(response.data.data, '_blank', 'noopener,noreferrer');
    } catch (e) {
      handleException(e);
    }
  };

  return (
    <S.RightPanelContainer ref={ref}>
      <S.RightPanel $width={dimensions.width}>
        <S.RightPanelHeader>Candidate information:</S.RightPanelHeader>
        <S.RightPanelCell>
          <S.CandidateInfo>ID:</S.CandidateInfo>
          {id}
        </S.RightPanelCell>
        <S.RightPanelCell>
          <S.CandidateInfo>Name:</S.CandidateInfo>
          {candidateName}
        </S.RightPanelCell>
        <S.RightPanelCell>
          <S.CandidateInfo>Position:</S.CandidateInfo>
          {positionName}
        </S.RightPanelCell>
        <S.RightPanelCell>
          <S.CandidateInfo>Level:</S.CandidateInfo>
          {seniorityLevel || '-'}
        </S.RightPanelCell>
        <S.RightPanelCell>
          <S.CandidateInfo>Date:</S.CandidateInfo>
          {recruitmentDate}
        </S.RightPanelCell>
        <S.RightPanelCell>
          <S.CandidateInfo>HR Contact:</S.CandidateInfo>
          {`${hr.name} ${hr.surname}`}
        </S.RightPanelCell>
        <S.RightPanelCell>
          <S.CandidateInfo>Recruiter:</S.CandidateInfo>
          {`${recruiter.name} ${recruiter.surname}`}
        </S.RightPanelCell>
        {!!cvFile && (
          <S.RightPanelCell>
            <S.CandidateInfo>CV:</S.CandidateInfo>
            <S.ClickableRightPanelCell onClick={handleDownloadCv}>
              <div>{cvFile}</div>
              {isLoadingCv ? <S.CircularProgress size="16px" /> : <S.DownloadIcon />}
            </S.ClickableRightPanelCell>
          </S.RightPanelCell>
        )}
      </S.RightPanel>
    </S.RightPanelContainer>
  );
};

export default RightPanel;
