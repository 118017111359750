import { TextField as MUITextField } from '@material-ui/core';
import styled from 'styled-components';
import theme from 'theme';

export const Header = styled.p`
  font-size: 24px;
  text-decoration: underline ${theme.palette.primary.main};
  text-underline-offset: 4px;
  margin-bottom: 50px;
`;

export const TextField = styled(MUITextField)`
  &.MuiTextField-root {
    width: 300px;
  }
`;
