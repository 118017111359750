import styled from 'styled-components';
import theme from 'theme';

export const ValidationError = styled.div`
  color: ${theme.palette.error.main};
  margin-top: 3px;
  margin-left: 5px;
  display: flex;
  height: 15px;
  font-size: 14px;
`;
