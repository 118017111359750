import { useMemo, useState } from 'react';
import { AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Brain from 'assets/brain.svg';
import Hammer from 'assets/hammer.svg';
import { ModuleSummary } from 'components/ModuleSummary';
import { percentageCalculator } from 'utils/helpers';
import { EndedQuestion } from '../EndedQuestion';
import { RecruitmentModuleProps } from './EndedRecruitmentModule.types';
import * as S from './EndedRecruitmentModule.css';

const EndedRecruitmentModule = ({
  module,
  editable,
  setLoadingRecruitment,
  getRecruitmentModules,
}: RecruitmentModuleProps) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(editable);

  const questionsNumber = (isPractical: boolean) =>
    module.recruitmentQuestions.filter((q) => q.question.isPractical === isPractical)
      .length;

  const reviewedQuestionsNumber = (isPractical: boolean) =>
    module.recruitmentQuestions.filter(
      (q) => q.question.isPractical === isPractical && q.grade !== null,
    ).length;

  const renderQuestions = useMemo(
    () =>
      module.recruitmentQuestions.map((question, index) => (
        <S.QuestionContainer key={index.toString()}>
          <EndedQuestion
            endedQuestion={question}
            moduleId={module.id}
            editable={editable}
            setLoadingRecruitment={setLoadingRecruitment}
          />
        </S.QuestionContainer>
      )),
    [module.id, module.recruitmentQuestions, editable, setLoadingRecruitment],
  );

  return (
    <S.ModuleAccordion
      expanded={isAccordionOpen}
      onChange={() => setIsAccordionOpen((prev) => !prev)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <S.ModuleTitle>{module?.module?.name ?? 'Not found'}</S.ModuleTitle>
        <S.Icons>
          <S.Icon src={Brain} alt="brain" />
          <S.IconInfo>
            <S.ReviewedQuestions>{reviewedQuestionsNumber(false)}/</S.ReviewedQuestions>
            {questionsNumber(false)} questions
          </S.IconInfo>
          <S.Icon src={Hammer} alt="hammer" />
          <S.IconInfo>
            <S.ReviewedQuestions>{reviewedQuestionsNumber(true)}/</S.ReviewedQuestions>
            {questionsNumber(true)} tasks
          </S.IconInfo>
        </S.Icons>
        <S.TotalScore>
          Score: <S.BoldText>{module.grade ?? 0}</S.BoldText>/{module.maxGrade ?? 0}
          <S.BoldText>{` (${percentageCalculator(
            module.grade,
            module.maxGrade,
          )}%)`}</S.BoldText>
        </S.TotalScore>
      </AccordionSummary>
      <S.ModuleAccordionDetails>
        {renderQuestions}
        <ModuleSummary
          isDisabled={!editable}
          taskNumber={questionsNumber(true)}
          questionNumber={questionsNumber(false)}
          defaultValue={module.note || ''}
          recruitmentModuleId={module.id}
          id={module.moduleId}
          getRecruitmentModules={getRecruitmentModules}
          moduleDescription={module.module.description || ''}
        />
      </S.ModuleAccordionDetails>
    </S.ModuleAccordion>
  );
};

export default EndedRecruitmentModule;
