import styled from 'styled-components';
import theme from 'theme';

export const Main = styled.div`
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 16px;
`;

export const ModulesContainer = styled.div`
  width: 100%;
  padding-top: 16px;
`;

export const Summary = styled.div`
  background-color: black;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12), 0px 0px 4px rgba(0, 0, 0, 0.08);
  border: 0.5px #ededed solid;
`;

export const SummaryScore = styled.div`
  color: white;
  font-weight: bold;
  margin-right: 40px;
  font-size: 20px;
`;

export const SummaryValues = styled.div`
  display: flex;
`;

export const YellowText = styled.span`
  color: ${theme.palette.primary.main};
`;

export const SummaryTitle = styled.div`
  margin-left: 30px;
  color: ${theme.palette.primary.main};
  font-size: 20px;
  font-weight: bold;
`;

export const NotFoundContainer = styled.div`
  height: 100vh;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

export { EmptyListImage as NotFoundImage } from 'styles.global';
