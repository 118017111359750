import Keycloak from 'keycloak-js';

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'

export const keycloak = Keycloak({
  realm: window.env?.KEYCLOAK_REALM_NAME || 'internal-jit-applications',
  url: window.env?.KEYCLOAK_SERVER_URL || 'https://keycloak.test.jit.ninja/auth/',
  clientId: window.env?.KEYCLOAK_CLIENT_ID || 'sphinx',
});

export const keycloakInitOptions = {
  onLoad: 'check-sso',
  silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
  promiseType: 'native',
  pickMethod: 'S256',
};
