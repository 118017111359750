import styled from 'styled-components';

export const UnauthorizedContainer = styled.div`
  height: 100vh;
  width: 100%;
  margin: auto;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

export { EmptyListImage as UnauthorizedImage } from 'styles.global';
