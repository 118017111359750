import { Box, Button as MuiButton, CircularProgress } from '@material-ui/core';
import { DeleteOutline } from '@material-ui/icons';
import styled, { createGlobalStyle, css } from 'styled-components';
import theme from 'theme';

export const GlobalStyle = createGlobalStyle`
  body {
    font-family: "Moderat JIT", sans-serif;
    padding: 0;
    margin: 0;
    background-color: #f8f8f8;
  }
`;

export const Loader = styled(CircularProgress)`
  &.MuiCircularProgress-root {
    margin-top: 30vh;
    color: ${theme.palette.primary.main};
  }
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const CenteredBox = styled(Box)`
  &.MuiBox-root {
    display: flex;
    justify-content: center;
  }
`;

export const HorizontalLine = styled.div`
  margin-bottom: 30px;
  margin-top: 30px;
  background-color: rgb(230, 230, 230, 50%);
  height: 2px;
`;

export const DeleteIcon = styled(DeleteOutline)`
  margin-right: 8px;
  width: 20px;
  padding: 4px;
  &:hover {
    background-color: rgba(237, 237, 237, 1);
    cursor: pointer;
  }
`;

export const TextArea = styled.textarea`
  font-family: 'Moderat JIT', sans-serif;
  &:focus {
    outline-color: ${theme.palette.primary.main};
  }
`;

interface ButtonProps {
  $primary?: boolean;
  $secondary?: boolean;
  $generateLink?: boolean;
  $downloadFile?: boolean;
  disabled?: boolean;
}

// * https://stackoverflow.com/questions/15852122/hex-transparency-in-colors/17239853#17239853
export const Button = styled(MuiButton)<ButtonProps>`
  &.MuiButton-root {
    font-family: 'Moderat JIT', sans-serif;

    letter-spacing: 0;
    border-radius: 0;
    line-height: 1.25;
    padding: 7.5px 24px;
    text-transform: none;
    font-weight: bold;
    word-break: normal;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14),
      0px 1px 5px rgba(0, 0, 0, 0.12);

    ${({ $primary }) =>
      $primary &&
      css`
        background-color: ${theme.palette.primary.main};
        &:hover {
          background-color: ${`${theme.palette.primary.main}80`};
        }
      `}

    ${({ $secondary }) =>
      $secondary &&
      css`
        background-color: ${theme.palette.secondary.dark};
        color: white;
        &:hover {
          background-color: ${theme.palette.secondary.dark}B3;
        }
      `}

      ${({ $generateLink }) =>
      $generateLink &&
      css`
        background-color: ${theme.palette.success.main};
        color: white;
        &:hover {
          background-color: ${theme.palette.success.main}80;
        }
      `}

      ${({ $downloadFile }) =>
      $downloadFile &&
      css`
        background-color: #425cff;
        color: white;
        &:hover {
          background-color: #425cff80;
        }
      `}
  }
`;

export const EmptyListImage = styled.img`
  width: 400px;
  height: 500px;
`;
