import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Popper, PopperProps, TextField } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutline from '@material-ui/icons/RemoveCircleOutline';
import { Autocomplete } from '@material-ui/lab';
import { QuestionService, RecruitmentService } from 'api';
import { AxiosResponse } from 'axios';
import { Actions } from 'pages/Recruitment/modulesState';
import { Button } from 'styles.global';
import { Module } from 'types/module';
import { Question } from 'types/question';
import { NewRecruitmentModule, RecruitmentModule } from 'types/recruitment';
import { handleException } from 'utils/errorHandlingUtils';
import { RouteParams } from 'utils/types/RouteParams';
import * as S from './AddRecruitmentModule.css';

type Props = {
  allModules: Module[];
  usedModules: RecruitmentModule[];
  dispatch: (value: Actions) => void;
};

const CustomPopper = (props: PopperProps) => (
  <Popper {...props} className={S.popperStyles().root} placement="bottom" />
);

export const AddRecruitmentModule = ({ allModules, usedModules, dispatch }: Props) => {
  const { id } = useParams<RouteParams>();
  const [isFormShown, setIsFormShown] = useState(false);
  const [selectedModule, setSelectedModule] = useState<Module | null>(null);
  const [selectedQuestions, setSelectedQuestions] = useState<(Question | null)[]>([null]);

  const { getQuestions, questionsData } = QuestionService.useGetQuestions();
  const createRecruitmentModules = RecruitmentService.useCreateRecruitmentModules();
  const { createRecruitmentQuestionsAsync } =
    RecruitmentService.useCreateRecruitmentQuestions();
  const sortRecruitmentModules = RecruitmentService.useSortRecruitmentModules(id);

  const modulesAutocompleteOptions = useMemo(() => {
    const usedModulesIds = usedModules.map(({ module }) => module.id);
    const filteredQuestions = allModules.filter(
      (question) => !usedModulesIds.includes(question.id),
    );
    return filteredQuestions;
  }, [usedModules, allModules]);

  const questionAutocompleteOptions = useMemo(() => {
    const usedQuestionsIds = selectedQuestions.map((question) => question?.id);
    const filteredQuestions = questionsData.filter(
      (question: Question) => !usedQuestionsIds.includes(question.id),
    );
    return filteredQuestions;
  }, [selectedQuestions, questionsData]);

  const onModuleChange = (module: Module | null) => {
    if (module) setSelectedModule(module);
    else {
      setSelectedModule(null);
      setSelectedQuestions([null]);
    }
  };

  const onQuestionChange = (newQuestion: Question | null, questionIdx: number) => {
    if (newQuestion) {
      if (questionIdx === selectedQuestions.length - 1) {
        setSelectedQuestions((prev: (Question | null)[]) => [
          ...prev.map((q, idx) => (questionIdx === idx ? newQuestion : q)),
          null,
        ]);
      } else {
        setSelectedQuestions((prev: (Question | null)[]) =>
          prev.map((q, idx) => (questionIdx === idx ? newQuestion : q)),
        );
      }
    } else {
      setSelectedQuestions((prev) => prev.filter((_, idx) => idx !== questionIdx));
    }
  };

  const onSubmit = async () => {
    if (id && selectedModule) {
      const selectedQuestionsIds = selectedQuestions
        .filter((q) => q)
        .map((question) => (question as Question).id);

      const addModulePayload: NewRecruitmentModule[] = [
        {
          chosenQuestionIds: selectedQuestionsIds,
          moduleId: selectedModule.id,
          numberOfRandomQuestions: 0,
          numberOfRandomTasks: 0,
        },
      ];

      try {
        const moduleResponse: AxiosResponse<{ data: { id: number }[] }> =
          await createRecruitmentModules(+id, addModulePayload);

        const addedModuleId = moduleResponse.data.data[0].id;
        const addQuestionsPayload = {
          recruitmentModules: [
            {
              id: addedModuleId,
              questionsId: selectedQuestionsIds,
            },
          ],
        };

        await createRecruitmentQuestionsAsync(+id, addQuestionsPayload);
        const sortedModules = await sortRecruitmentModules({
          data: {
            ids_list: [...usedModules.map((m) => m.id), addedModuleId],
          },
        });

        dispatch({
          type: 'setRecruitmentModules',
          recruitmentModules: sortedModules.data.data,
        });

        setIsFormShown(false);
        setSelectedModule(null);
        setSelectedQuestions([]);
      } catch (e) {
        handleException(e);
      }
    }
  };

  useEffect(() => {
    const getQuestionsData = async () => {
      if (selectedModule) {
        const data = await getQuestions({
          moduleIds: [selectedModule.id],
          perPage: selectedModule.numberOfQuestions + selectedModule.numberOfTasks,
        });
        return data;
      }
    };
    getQuestionsData();
  }, [getQuestions, selectedModule, selectedModule?.id]);

  return (
    <>
      {!isFormShown && (
        <Button
          $secondary
          startIcon={<AddCircleOutlineIcon />}
          onClick={() => setIsFormShown(true)}
        >
          Add module
        </Button>
      )}
      {isFormShown && (
        <S.AddQuestionsContainer>
          <S.ActionButton onClick={() => setIsFormShown(false)}>
            <RemoveCircleOutline />
          </S.ActionButton>
          <S.InputsContainer>
            <S.AddInputWrapper>
              Select module:
              <Autocomplete
                PopperComponent={CustomPopper}
                getOptionLabel={(option: Module) => option.name}
                onChange={(_, value) => onModuleChange(value)}
                options={modulesAutocompleteOptions}
                value={selectedModule}
                blurOnSelect
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Start typing or select"
                    size="small"
                    multiline
                  />
                )}
              />
            </S.AddInputWrapper>
            <S.AddInputWrapper>
              Select questions:
              {selectedQuestions.map((question, idx) => (
                <Autocomplete
                  key={question?.id}
                  PopperComponent={CustomPopper}
                  getOptionLabel={(option: Question) => option.text}
                  onChange={(_, value) => onQuestionChange(value, idx)}
                  options={questionAutocompleteOptions}
                  value={question}
                  blurOnSelect
                  disabled={!selectedModule}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Start typing or select"
                      size="small"
                      multiline
                    />
                  )}
                />
              ))}
            </S.AddInputWrapper>
            <S.SubmitButton onClick={onSubmit} disabled={selectedQuestions.length <= 1}>
              Submit
            </S.SubmitButton>
          </S.InputsContainer>
        </S.AddQuestionsContainer>
      )}
    </>
  );
};
