import { useCallback, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { RecruitmentSummaryService } from 'api';
import NotFound from 'assets/not-found.svg';
import { RecruitmentInfo } from 'pages/Recruitment/components/RecruitmentInfo';
import Container from 'components/Container';
import { handleException } from 'utils/errorHandlingUtils';
import { percentageCalculator } from 'utils/helpers';
import { RouteParams } from 'utils/types/RouteParams';
import GeneratedEndedRecruitmentModule from './components/GeneratedEndedRecruitmentModule';
import GeneratedSummary from './components/GeneratedSummary';
import * as S from './GeneratedEndedRecruitment.css';

const GeneratedEndedRecruitment = () => {
  const { pathname } = useLocation();

  const { id } = useParams<RouteParams>();

  const [recruitmentNotFound, setRecruitmentNotFound] = useState(false);

  const { recruitmentSummaryData, getRecruitmentSummary, isLoadingRecruitmentSummary } =
    RecruitmentSummaryService.useGetRecruitmentSummary(id);

  const loadView = useCallback(async () => {
    try {
      await getRecruitmentSummary();
    } catch (e) {
      setRecruitmentNotFound(true);
      handleException(e);
    }
  }, [getRecruitmentSummary]);

  useEffect(() => {
    loadView();
  }, [loadView, id]);

  const isEditable = Boolean(pathname.match(/^\/editable-generated-ended-recruitment/));

  return recruitmentNotFound ? (
    <S.NotFoundContainer>
      <Typography variant="h3">No recruitment found</Typography>
      <S.NotFoundImage src={NotFound} alt="not-found" />
    </S.NotFoundContainer>
  ) : (
    <Container isLoading={isLoadingRecruitmentSummary}>
      <S.Main>
        <RecruitmentInfo shortRecruitment={recruitmentSummaryData?.recruitment} />
        <S.Summary>
          <S.SummaryTitle>Summary</S.SummaryTitle>
          <S.SummaryValues>
            <S.SummaryScore>
              Score:{' '}
              <S.YellowText>
                {recruitmentSummaryData?.recruitment.grade ?? 0}
              </S.YellowText>
              /{recruitmentSummaryData?.recruitment.maxGrade ?? 0} (
              <S.YellowText>{`${percentageCalculator(
                recruitmentSummaryData?.recruitment?.grade,
                recruitmentSummaryData?.recruitment?.maxGrade,
              )}%`}</S.YellowText>
              )
            </S.SummaryScore>
          </S.SummaryValues>
        </S.Summary>
        <S.ModulesContainer>
          {recruitmentSummaryData?.recruitmentModulesSummaries?.map((module) => (
            <GeneratedEndedRecruitmentModule
              key={module.id}
              module={module}
              editable={isEditable}
            />
          ))}
          <GeneratedSummary
            recruitmentData={recruitmentSummaryData}
            editable={isEditable}
          />
        </S.ModulesContainer>
      </S.Main>
    </Container>
  );
};

export default GeneratedEndedRecruitment;
