import { useConfirm } from 'material-ui-confirm';
import { UseCloseWithConfirm } from './types';

export function useCloseWithConfirm({
  onClose,
  title = 'Do you want to discard changes?',
  confirmationText = 'Yes',
  cancellationText = 'No',
}: UseCloseWithConfirm) {
  const confirm = useConfirm();

  return async (canClose: boolean) => {
    if (!canClose) {
      await confirm({
        title,
        confirmationText,
        cancellationText,
        dialogProps: { maxWidth: 'sm' },
      });
    }
    onClose();
  };
}
