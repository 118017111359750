export const config = {
  auth: {
    clientId: `${
      window.env?.REACT_APP_CLIENT_ID || 'e1ce52ab-0871-475a-90a4-16fe84df523a'
    }`,
    authority: `https://login.microsoftonline.com/${
      window.env?.REACT_APP_TENANT_ID || 'd1eaeb60-a904-4581-97bb-f9de26b4eaec'
    }`,
    redirectUri: `${window.env?.REACT_APP_REDIRECT_URI || 'http://localhost:3000/myapp'}`,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  scopes: [
    `api://${
      window.env?.REACT_APP_CLIENT_ID || 'e1ce52ab-0871-475a-90a4-16fe84df523a'
    }/app`,
  ],
};
