import { produce } from 'immer';
import { Module } from 'types/module';
import { Question, UpdateQuestion } from 'types/question';

export type State = Module[];

type AddModules = {
  type: 'addModules';
  modules: Module[];
};

type AddModule = {
  type: 'addModule';
  module: Module;
};

type UpdateModule = {
  type: 'updateModule';
  module: Module;
};

type DeleteModule = {
  type: 'deleteModule';
  id: number;
};

type AddQuestionsToModule = {
  type: 'addQuestionsToModule';
  id: number;
  questions: Question[];
};

type AddQuestionToModule = {
  type: 'addQuestionToModule';
  question: Question;
};

type DeleteQuestionFromModule = {
  type: 'deleteQuestionFromModule';
  question: Question;
};

type UpdateQuestionInModule = {
  type: 'updateQuestionInModule';
  question: Question;
  options: UpdateQuestion;
};

export type Actions =
  | AddModules
  | AddModule
  | UpdateModule
  | DeleteModule
  | AddQuestionsToModule
  | AddQuestionToModule
  | DeleteQuestionFromModule
  | UpdateQuestionInModule;

const updateQuestionInModule = (draft: State, action: UpdateQuestionInModule) => {
  if (action.options.differentModule) {
    let moduleIndex = draft.findIndex(({ id }) => id === action.options.moduleId);

    const questionIndex = draft[moduleIndex].questions.findIndex(
      ({ id }) => id === action.question.id,
    );

    draft[moduleIndex].questions.splice(questionIndex, 1);

    if (action.options.typeChanged) {
      if (action.question.isPractical) {
        draft[moduleIndex].numberOfQuestions -= 1;
      } else {
        draft[moduleIndex].numberOfTasks -= 1;
      }
    } else if (action.question.isPractical) {
      draft[moduleIndex].numberOfTasks -= 1;
    } else {
      draft[moduleIndex].numberOfQuestions -= 1;
    }

    moduleIndex = draft.findIndex(({ id }) => id === action.question.moduleId);
    draft[moduleIndex].questions.unshift(action.question);

    if (action.question.isPractical) {
      draft[moduleIndex].numberOfTasks += 1;
    } else {
      draft[moduleIndex].numberOfQuestions += 1;
    }
  } else {
    const moduleIndex = draft.findIndex(({ id }) => id === action.question.moduleId);
    const questionIndex = draft[moduleIndex].questions.findIndex(
      ({ id }) => id === action.question.id,
    );

    draft[moduleIndex].questions.splice(questionIndex, 1);
    draft[moduleIndex].questions.unshift(action.question);

    if (action.options.typeChanged) {
      if (action.question.isPractical) {
        draft[moduleIndex].numberOfQuestions -= 1;
        draft[moduleIndex].numberOfTasks += 1;
      } else {
        draft[moduleIndex].numberOfQuestions += 1;
        draft[moduleIndex].numberOfTasks -= 1;
      }
    }
  }
};

export const reducer = produce((draft: State, action: Actions) => {
  switch (action.type) {
    case 'addModules': {
      return action.modules;
    }
    case 'addModule': {
      draft.unshift(action.module);
      break;
    }
    case 'updateModule': {
      const index = draft.findIndex(({ id }) => id === action.module.id);
      draft.splice(index, 1);
      draft.unshift(action.module);
      break;
    }
    case 'deleteModule': {
      const index = draft.findIndex(({ id }) => id === action.id);
      draft.splice(index, 1);
      break;
    }
    case 'deleteQuestionFromModule': {
      const moduleIndex = draft.findIndex(({ id }) => id === action.question.moduleId);
      const questionIndex = draft[moduleIndex].questions.findIndex(
        ({ id }) => id === action.question.id,
      );

      draft[moduleIndex].questions.splice(questionIndex, 1);

      if (action.question.isPractical) {
        draft[moduleIndex].numberOfTasks -= 1;
      } else {
        draft[moduleIndex].numberOfQuestions -= 1;
      }
      break;
    }

    case 'addQuestionsToModule': {
      const index = draft.findIndex(({ id }) => id === action.id);
      draft[index].questions = action.questions;
      break;
    }

    case 'addQuestionToModule': {
      const index = draft.findIndex(({ id }) => id === action.question.moduleId);
      draft[index].questions.unshift(action.question);

      if (action.question.isPractical) {
        draft[index].numberOfTasks += 1;
      } else {
        draft[index].numberOfQuestions += 1;
      }
      break;
    }

    case 'updateQuestionInModule': {
      updateQuestionInModule(draft, action);
      break;
    }

    default:
      break;
  }
  return draft;
});
