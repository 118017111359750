import FileCopyIcon from '@material-ui/icons/FileCopy';
import SaveAltOutlinedIcon from '@material-ui/icons/SaveAltOutlined';
import styled from 'styled-components';
import { Button } from 'styles.global';
import theme from 'theme';

export const RecruitmentReviewTitle = styled.div`
  background-color: black;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12), 0px 0px 4px rgba(0, 0, 0, 0.08);
  border: 0.5px #ededed solid;
`;

export const SummaryTitle = styled.div`
  margin-left: 30px;
  color: ${theme.palette.primary.main};
  font-size: 20px;
  font-weight: bold;
`;

export const RecruitmentReview = styled.div`
  background-color: white;
  padding: 10px 30px 10px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12), 0px 0px 4px rgba(0, 0, 0, 0.08);
  border: 0.5px #ededed solid;
`;

export const DownloadButton = styled(Button).attrs({ $downloadFile: true })`
  &.MuiButton-root {
    margin-right: 20px;
  }
`;

export const DocumentIcon = styled(SaveAltOutlinedIcon)`
  &.MuiSvgIcon-root {
    color: white;
    background-color: transparent;
    margin-right: 10px;
    font-size: 18px;
  }
`;

export const CopyIcon = styled(FileCopyIcon)`
  &.MuiSvgIcon-root {
    background-color: transparent;
    margin-right: 10px;
    font-size: 18px;
  }
`;

export const SaveButton = styled(Button).attrs({ $primary: true })`
  &.MuiButton-root {
    margin-left: 20px;
    font-size: 14px;
    font-weight: 700;
    font-style: normal;
    padding-bottom: 10px;
  }
`;
