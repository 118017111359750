import { CircularProgress as MuiCircularProgress } from '@material-ui/core';
import SaveAltOutlinedIcon from '@material-ui/icons/SaveAltOutlined';
import styled, { css } from 'styled-components';
import theme from 'theme';
import { HeaderProps, HeaderTitleProps } from './AdjustRecruitment.types';

export const AdjustContainer = styled.div`
  display: flex;
  word-break: keep-all;
  background-color: white;
  min-height: 100vh;
  padding-bottom: 32px;
  position: relative;
`;

export const LeftPanel = styled.div`
  width: 77%;
  padding-left: 32px;
  padding-top: 32px;
  background-color: white;
`;

export const AdjustRecruitmentRightPanelContainer = styled.div`
  padding: 16px 32px;
  width: 15%;
  height: calc(100% - 32px);
  align-self: flex-start;
  background-color: white;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.12);
  position: absolute;
  right: 0;
  z-index: 995;
`;

export const RightPanelContainer = styled.div`
  width: 99%;
`;

export const RightPanel = styled.div<{ $width: number }>`
  position: fixed;
  height: 100%;
  overflow-y: auto;
`;

export const RightPanelHeader = styled.p`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 30px;
`;

export const RightPanelCell = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
  align-items: center;
`;

export const ClickableRightPanelCell = styled.div`
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`;

export const Header = styled.div<HeaderProps>`
  display: flex;
  font-size: ${(props) => props.fontSize || '1.5rem'};
`;

export const TemplateText = styled.div`
  margin-right: 32px;
  font-size: 18px;
`;

export const HeaderTitle = styled.p<HeaderTitleProps>`
  flex: 3;
  ${(props) =>
    props.underline &&
    css`
      text-decoration: underline ${theme.palette.primary.main};
      text-underline-offset: 5px;
    `}
`;

export const CandidateInfo = styled.span`
  font-weight: bold;
  margin-right: 10px;
  font-size: 14px;
`;

export const DownloadIcon = styled(SaveAltOutlinedIcon)`
  &.MuiSvgIcon-root {
    background-color: transparent;
    font-size: 18px;
    margin-left: 14px;
  }
`;

export const CircularProgress = styled(MuiCircularProgress)`
  &.MuiCircularProgress-root {
    margin-top: 2px;
    margin-left: 12px;
    color: ${theme.palette.secondary.dark};
  }
`;
