import { Module } from 'types/module';
import { QuestionForRecruitmentModules } from 'types/question';
import { AdjustRecruitmentModule, RecruitmentModuleQuestion } from 'types/recruitment';
import { TemplateModule } from 'types/template';

const createTemplateModule = (
  id: number,
  templateModule: TemplateModule,
  module: Module,
  moduleQuestions: RecruitmentModuleQuestion[],
  selectedQuestions: RecruitmentModuleQuestion[],
): AdjustRecruitmentModule => ({
  ...newRecruitmentModule({ id }),
  moduleId: templateModule.moduleId,
  moduleName: module.name,
  moduleDescription: module.description,
  questions: moduleQuestions,
  numberOfQuestions: module.numberOfQuestions,
  numberOfTasks: module.numberOfTasks,
  availableNumberOfQuestions:
    module.numberOfQuestions -
    templateModule.templateChosenQuestions.filter(({ question }) => !question.isPractical)
      .length,
  availableNumberOfTasks:
    module.numberOfTasks -
    templateModule.templateChosenQuestions.filter(({ question }) => question.isPractical)
      .length,
  selectedNumberOfQuestions: templateModule.numberOfRandomQuestions,
  selectedNumberOfTasks: templateModule.numberOfRandomTasks,
  selectedQuestions: [...selectedQuestions].concat(
    selectedQuestions.length < module.numberOfTasks + module.numberOfQuestions
      ? {
          id: selectedQuestions.length,
          text: '',
          questionId: null,
          isPractical: null,
        }
      : [],
  ), // when all questions from module are selected, there is no need for adding input
  isUpdated: false,
});

export function newRecruitmentModule({ id }: { id: number }): AdjustRecruitmentModule {
  return {
    id,
    moduleId: null,
    moduleName: null,
    moduleDescription: null,
    numberOfQuestions: null,
    numberOfTasks: null,
    selectedNumberOfQuestions: null,
    selectedNumberOfTasks: null,
    availableNumberOfQuestions: null,
    availableNumberOfTasks: null,
    questions: [],
    selectedQuestions: [{ id: 0, text: '', questionId: null, isPractical: null }],
    errors: {
      numberOfQuestions: false,
      numberOfTasks: false,
    },
    isUpdated: false,
  };
}

export async function createRecruitmentModulesFromTemplate(
  questionsForRecruitmentModules: QuestionForRecruitmentModules[],
  modules: Module[],
  templateModules: TemplateModule[],
) {
  const recruitmentModules = templateModules.map((templateModule, index) => {
    const module = modules.find(({ id }) => id === templateModule.moduleId)!;

    const moduleQuestions = questionsForRecruitmentModules
      .filter(({ moduleId }) => moduleId === templateModule.moduleId)
      .map(({ text, isPractical, id }) => ({ id, text, isPractical }));

    const selectedQuestions = templateModule.templateChosenQuestions.map(
      ({ question }, qIndex) => ({
        id: qIndex,
        questionId: question.id,
        text: question.text,
        isPractical: question.isPractical,
      }),
    );

    return createTemplateModule(
      index,
      templateModule,
      module,
      moduleQuestions as RecruitmentModuleQuestion[],
      selectedQuestions,
    );
  });

  return recruitmentModules.length ? recruitmentModules : undefined;
}
