export const UNAUTHORIZED = '/unauthorized';
export const TODO = '/todo';
export const ENDED_RECRUITMENT = '/ended-recruitment';
export const ENDED_RECRUITMENT_ID = '/ended-recruitment/:id';
export const QUESTIONS = '/questions';
export const TEMPLATES = '/templates';
export const TEMPLATES_ID = '/templates/:id';
export const ADD_TEMPLATE = '/add-template';
export const EDIT_TEMPLATE_ID = '/edit-template/:id';
export const RECRUITMENT_ID = '/recruitment/:id';
export const ADJUST_RECRUITMENT_ID = '/adjust-recruitment/:id';
export const GENERATED_RECRUITMENT_ID = '/generated-ended-recruitment/:id';
export const EDITABLE_GENERATED_RECRUITMENT_ID =
  '/editable-generated-ended-recruitment/:id';
export const REPORTS = '/reports';
