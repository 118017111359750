import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Dialog, TextField } from '@material-ui/core';
import { RecruitmentService } from 'api';
import { Button } from 'styles.global';
import { ValidationError } from 'components/ValidationError';
import { handleException } from 'utils/errorHandlingUtils';
import { removeExtraSpaces } from 'utils/helpers';
import { useCloseWithConfirm, useQueryParams } from 'utils/hooks';
import { MAX_REASON_LENGTH } from './CancelRecruitmentDialog.const';
import { CancelRecruitmentDialogProps } from './CancelRecruitmentDialog.types';
import * as S from './CancelRecruitmentDialog.css';

export const CancelRecruitmentDialog = ({
  onClose,
  id,
  name,
  edit = false,
  canceler,
  defaultReason,
  setPaginationOptions,
}: CancelRecruitmentDialogProps) => {
  const queryParams = useQueryParams();
  const { push } = useHistory();

  const handleClose = useCloseWithConfirm({ onClose });

  const [reason, setReason] = useState(defaultReason || '');

  const { createCancellation, isCancellingRecruitment } =
    RecruitmentService.useCreateCancellation();

  const { updateCancellation, isUpdatingCancellation } =
    RecruitmentService.useUpdateCancellation();

  const handleCancelRecruitment = async () => {
    try {
      if (edit) {
        await updateCancellation(id, removeExtraSpaces(reason));
      } else {
        await createCancellation(id, removeExtraSpaces(reason));
        delete queryParams.page;
        push({
          pathname: '/todo',
          search: `${new URLSearchParams({
            ...queryParams,
          })}`,
        });
      }
      setPaginationOptions((prevState) => ({
        ...prevState,
        shouldFetch: true,
      }));
      onClose();
    } catch (e) {
      handleException(e);
    }
  };

  const isReasonValid = removeExtraSpaces(reason).length < MAX_REASON_LENGTH;

  const isButtonDisabled =
    isCancellingRecruitment ||
    isUpdatingCancellation ||
    !removeExtraSpaces(reason) ||
    removeExtraSpaces(reason).length >= MAX_REASON_LENGTH;

  return (
    <Dialog
      open
      fullWidth
      maxWidth="sm"
      onClose={() => handleClose(reason === defaultReason || !removeExtraSpaces(reason))}
    >
      <S.StyledDialogTitle>
        {edit ? 'EDIT CANCELLATION REASON' : 'CANCEL RECRUITMENT'}
      </S.StyledDialogTitle>
      <S.DialogContent>
        <S.Label>
          {!edit && (
            <>
              You&apos;re trying to cancel recruitment of
              <span style={{ fontWeight: 'bold' }}> {name}. </span>
              <Box fontSize="15px" mt="10px">
                (This action is irreversible)
              </Box>
            </>
          )}
        </S.Label>
        <S.InputTitle>{canceler && `Cancelled by: ${canceler}`}</S.InputTitle>
        <S.InputTitle>Reason:</S.InputTitle>
        <TextField
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          variant="outlined"
          placeholder="Enter reason"
          size="small"
          fullWidth
          error={!isReasonValid}
        />
        {!isReasonValid && <ValidationError limit={MAX_REASON_LENGTH} />}
      </S.DialogContent>
      <S.DialogActions>
        <Button
          onClick={() =>
            handleClose(reason === defaultReason || !removeExtraSpaces(reason))
          }
        >
          Cancel
        </Button>
        <Button
          $primary
          onClick={handleCancelRecruitment}
          disabled={isButtonDisabled}
          variant="contained"
        >
          Submit
        </Button>
      </S.DialogActions>
    </Dialog>
  );
};
