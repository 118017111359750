export const displayGrade = (grade?: number, maxGrade?: number) => {
  const mathGrade = !grade ? 0 : grade;
  const mathMaxGrade = !maxGrade ? 0 : maxGrade;

  if (mathMaxGrade === 0) {
    return '0/0 (0%)';
  }

  return `${mathGrade}/${mathMaxGrade} (${Math.round(
    (mathGrade * 100) / mathMaxGrade,
  )}%)`;
};

export const percentageCalculator = (grade?: number, maxGrade?: number) => {
  if (!grade || !maxGrade) {
    return 0;
  }
  return Math.round((grade * 100) / maxGrade);
};
