import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import plLocale from 'date-fns/locale/pl';
import AdjustRecruitment from 'pages/AdjustRecruitment';
import Ended from 'pages/EndedRecruitment';
import GeneratedEndedRecruitment from 'pages/EndedRecruitment/components/GeneratedEndedRecruitment';
import PreviewEndedRecruitment from 'pages/EndedRecruitment/components/PreviewEndedRecruitment';
import Questions from 'pages/Questions';
import Recruitment from 'pages/Recruitment';
import Reports from 'pages/Reports';
import { AddTemplate, EditTemplate, Templates, TemplateView } from 'pages/Templates';
import Todo from 'pages/TodoRecruitment';
import Unauthorized from 'pages/Unauthorized';
import { GlobalStyle } from 'styles.global';
import AppContainer from 'components/AppContainer';
import Navbar from 'components/Navbar';
import ToastProvider from 'components/ToastProvider';
import { useUserIsAuthorized } from 'utils/hooks';
import { AppRouterProps } from './router.types';
import * as URL from './url';

export const AppRouter = ({
  user,
  authenticated,
  isGeneratedViewDisplayed,
}: AppRouterProps) => {
  const userIsAuthorized = useUserIsAuthorized();
  const isAuthenticated = useIsAuthenticated();
  const getRoutes = () => (
    <>
      <Route path={URL.GENERATED_RECRUITMENT_ID} component={GeneratedEndedRecruitment} />
      <Route
        path={URL.EDITABLE_GENERATED_RECRUITMENT_ID}
        component={GeneratedEndedRecruitment}
      />
      <Route path={URL.ENDED_RECRUITMENT_ID} component={PreviewEndedRecruitment} />
      <Route exact path={URL.ENDED_RECRUITMENT} component={Ended} />
      <Route path={URL.QUESTIONS} component={Questions} />
      <Route exact path={URL.TEMPLATES} component={Templates} />
      <Route path={URL.TEMPLATES_ID} component={TemplateView} />
      <Route path={URL.REPORTS} component={Reports} />
      <Route path={URL.ADD_TEMPLATE} component={AddTemplate} />
      <Route path={URL.EDIT_TEMPLATE_ID} component={EditTemplate} />
      <Route path={URL.RECRUITMENT_ID} component={Recruitment} />
      <Route path={URL.ADJUST_RECRUITMENT_ID} component={AdjustRecruitment} />
      <Route exact path={URL.TODO} component={Todo} />
      <Redirect to={URL.TODO} />
    </>
  );

  return (
    <Router>
      <GlobalStyle />
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={plLocale}>
        <Navbar />
        <ToastProvider />
        {isGeneratedViewDisplayed && (!authenticated || !user) && (
          <Switch>
            <Route
              path={URL.GENERATED_RECRUITMENT_ID}
              component={GeneratedEndedRecruitment}
            />
            <Redirect to={URL.GENERATED_RECRUITMENT_ID} />
          </Switch>
        )}
        {userIsAuthorized && !!user && (
          <Switch>
            <AppContainer routes={getRoutes} />
          </Switch>
        )}
        {!userIsAuthorized && isAuthenticated && (
          <Switch>
            <Route path={URL.UNAUTHORIZED} component={Unauthorized} />
            <Redirect to={URL.UNAUTHORIZED} />
          </Switch>
        )}
      </MuiPickersUtilsProvider>
    </Router>
  );
};
