import { memo } from 'react';
import { Popper, PopperProps, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { HorizontalLine } from 'styles.global';
import { TemplateAutocomplete } from 'components/TemplateAutocomplete';
import { AdjustRecruitmentModuleCoreProps } from './AdjustRecruitmentModule.types';
import * as S from './AdjustRecruitmentModule.css';

const CustomPopper = (props: PopperProps) => (
  <Popper {...props} className={S.popperStyles().root} placement="bottom" />
);

const AdjustRecruitmentModuleCore = ({
  state,
  modules,
  moduleAutocompleteOptions,
  questionAutocompleteOptions,
  handleQuestionChange,
  handleQuestionNumberChange,
  handleTaskNumberChange,
  handleModuleChange,
  templates,
  selectedTemplate,
  handleTemplateChange,
  adjustRecruitment,
}: AdjustRecruitmentModuleCoreProps) => (
  <S.CoreContainer>
    {!!templates && !!handleTemplateChange && (
      <S.TemplateSelect>
        <S.TemplateText>Choose recruitment template: </S.TemplateText>
        <TemplateAutocomplete
          templates={templates ?? []}
          selectedTemplate={selectedTemplate}
          onTemplateChange={handleTemplateChange}
        />
      </S.TemplateSelect>
    )}
    {state.map((recruitmentModule, index) => (
      <div key={recruitmentModule.id}>
        {index !== 0 && <HorizontalLine />}
        <S.ModuleHeader fontSize="18px">
          {adjustRecruitment ? (
            <S.ModuleHeaderTitleContainer>
              <S.ModuleHeaderTitle>Module: {index + 1}</S.ModuleHeaderTitle>
            </S.ModuleHeaderTitleContainer>
          ) : (
            <S.HeaderTitle>Module: {index + 1}</S.HeaderTitle>
          )}
        </S.ModuleHeader>
        <S.ModulePanel>
          <S.TheoreticalQuestionLabel>
            Number of random theoretical questions:
          </S.TheoreticalQuestionLabel>
          <S.SelectedModule>
            <Autocomplete
              getOptionLabel={(option) => option}
              disabled={!modules.length}
              onChange={(event, value) =>
                handleModuleChange(event, value, recruitmentModule.id)
              }
              value={recruitmentModule.moduleName}
              options={moduleAutocompleteOptions(recruitmentModule.moduleName)}
              filterSelectedOptions
              noOptionsText="No options"
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Start typing or select"
                  size="small"
                />
              )}
            />
          </S.SelectedModule>
          <S.NumberOfQuestionsInput>
            <S.NumberOfQuestionsTextField
              style={{ width: '60%' }}
              disabled={!recruitmentModule.moduleName}
              error={recruitmentModule.errors.numberOfQuestions}
              helperText={
                recruitmentModule.errors.numberOfQuestions &&
                `Available questions in module: ${recruitmentModule.availableNumberOfQuestions}`
              }
              value={recruitmentModule.selectedNumberOfQuestions || ''}
              onChange={(event) =>
                handleQuestionNumberChange(event.target.value, recruitmentModule.id)
              }
              variant="outlined"
              size="small"
              placeholder="Enter number"
            />
          </S.NumberOfQuestionsInput>
          <S.PracticalQuestionLabel>
            Number of random practical tasks:
          </S.PracticalQuestionLabel>
          <S.NumberOfTasksInput>
            <S.NumberOfTasksTextField
              style={{ width: '60%' }}
              disabled={!recruitmentModule.moduleName}
              error={recruitmentModule.errors.numberOfTasks}
              helperText={
                recruitmentModule.errors.numberOfTasks &&
                `Available tasks in module: ${recruitmentModule.availableNumberOfTasks}`
              }
              value={recruitmentModule.selectedNumberOfTasks || ''}
              onChange={(event) =>
                handleTaskNumberChange(event.target.value, recruitmentModule.id)
              }
              variant="outlined"
              size="small"
              placeholder="Enter number"
            />
          </S.NumberOfTasksInput>
          <S.SelectedQuestionsLabel>
            Additional mandatory question/task:
            <S.SelectedQuestionsDescription>
              This question/task will be drawn automatically from the module
            </S.SelectedQuestionsDescription>
          </S.SelectedQuestionsLabel>
          <S.SelectedQuestions>
            {recruitmentModule.selectedQuestions.map((question) => (
              <Autocomplete
                PopperComponent={CustomPopper}
                style={{ marginBottom: '15px' }}
                key={question.id}
                getOptionLabel={(option) => option}
                onChange={(event, value) =>
                  handleQuestionChange(event, value, recruitmentModule.id, question.id)
                }
                value={question.text || null}
                options={questionAutocompleteOptions(question.text, recruitmentModule)}
                disabled={!recruitmentModule.moduleName}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Start typing or select"
                    size="small"
                    multiline
                  />
                )}
              />
            ))}
          </S.SelectedQuestions>
        </S.ModulePanel>
      </div>
    ))}
  </S.CoreContainer>
);

export default memo(AdjustRecruitmentModuleCore);
