import { createContext, Dispatch, SetStateAction, useContext, useMemo } from 'react';
import { User } from 'types/user';
import { UserProps } from './UserContext.types';

const UserContext = createContext<{
  user: User | null;
  setUser: Dispatch<SetStateAction<User | null>> | null;
}>({ user: null, setUser: null });

export const UserContextProvider = ({ children, user, setUser }: UserProps) => {
  const value = useMemo(() => ({ user, setUser }), [user, setUser]);
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
export const useUserContext = () => useContext(UserContext);
