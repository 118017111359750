import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { SingleValue } from 'react-select';
import { toast } from 'react-toastify';
import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { RecruitmentService, RecruitmentSummaryService } from 'api';
import convertToCamelCase from 'camelcase-keys';
import { useUserContext } from 'context';
import { keycloak } from 'keycloak';
import debounce from 'lodash.debounce';
import RecruiterReview from 'pages/EndedRecruitment/components/RecruiterReview';
import { Button } from 'styles.global';
import { HireState } from 'types/recruitment';
import { handleException } from 'utils/errorHandlingUtils';
import { copyToClipboard, exportFile } from 'utils/helpers';
import { useRedirect } from 'utils/hooks';
import { RouteParams } from 'utils/types/RouteParams';
import { UPDATE_OPINION_DELAY } from './constants';
import { GeneratedSummaryProps } from './GeneratedSummary.types';
import * as S from './GeneratedSummary.css';

const GeneratedSummary = ({ recruitmentData, editable }: GeneratedSummaryProps) => {
  const redirect = useRedirect('/ended-recruitment');
  const { push } = useHistory();
  const { id } = useParams<RouteParams>();
  const { setUser, user } = useUserContext();

  const { isLoadingSummaryDocxDownload, getSummaryDocxDocument } =
    RecruitmentService.useGetSummaryDocxDocument();

  const { patchRecruitmentSummary, isPatchingRecruitmentSummary } =
    RecruitmentSummaryService.usePatchRecruitmentSummary(recruitmentData?.id);

  const [recommendation, setRecommendation] = useState<{
    value: HireState;
    label: string;
  }>();
  const [cooperation, setCooperation] = useState(recruitmentData?.cooperation);
  const [independence, setIndependence] = useState(recruitmentData?.independence);
  const [management, setManagement] = useState(recruitmentData?.management);

  const cooperationChecked = editable ? cooperation : recruitmentData?.cooperation;
  const independenceChecked = editable ? independence : recruitmentData?.independence;
  const managementChecked = editable ? management : recruitmentData?.management;
  const [generalNote, setGeneralNote] = useState(recruitmentData?.generalNote || '');

  useEffect(() => {
    if (recruitmentData?.hire) {
      setRecommendation({ value: HireState.Hire, label: 'Hire' });
    } else if (recruitmentData?.hire === false) {
      setRecommendation({ value: HireState.NotHire, label: 'Not hire' });
    }
  }, [recruitmentData?.hire, setRecommendation]);

  const handleDownloadDocument = async () => {
    try {
      const response = await getSummaryDocxDocument(id);
      exportFile(response);
    } catch (e) {
      handleException(e);
    }
  };

  const handleNoteChange = useCallback(
    async (event: ChangeEvent<HTMLTextAreaElement>) => {
      try {
        await patchRecruitmentSummary({
          data: {
            generalNote: event.target.value,
          },
        });
      } catch (e) {
        handleException(e);
      }
    },
    [patchRecruitmentSummary],
  );

  const debouncedOpinionChange = useMemo(
    () => debounce(handleNoteChange, UPDATE_OPINION_DELAY),
    [handleNoteChange],
  );

  const cooperationClicked = async (value: boolean) => {
    if (editable) {
      try {
        await patchRecruitmentSummary({
          data: {
            cooperation: value,
          },
        });
      } catch (e) {
        handleException(e);
      } finally {
        setCooperation(value);
      }
    }
  };

  const independenceClicked = async (value: boolean) => {
    if (editable) {
      try {
        await patchRecruitmentSummary({
          data: {
            independence: value,
          },
        });
      } catch (e) {
        handleException(e);
      } finally {
        setIndependence(value);
      }
    }
  };

  const managementClicked = async (value: boolean) => {
    if (editable) {
      try {
        await patchRecruitmentSummary({
          data: {
            management: value,
          },
        });
      } catch (e) {
        handleException(e);
      } finally {
        setManagement(value);
      }
    }
  };

  const handleRecommendationChange = async (
    event: SingleValue<{
      value: HireState;
      label: string;
    }>,
  ) => {
    if (editable) {
      try {
        await patchRecruitmentSummary({
          data: {
            hire: event?.value === HireState.Hire,
          },
        });
      } catch (e) {
        handleException(e);
      } finally {
        setRecommendation(event ?? undefined);
      }
    }
  };

  const backToList = () => {
    if (keycloak.authenticated && !user && !!setUser) {
      keycloak
        .loadUserInfo()
        .then((resp: any) => setUser(convertToCamelCase(resp)))
        .catch(() => toast.error('Unable to get user info'));
    }
    redirect();
  };

  const handleClick = async (complete: boolean) => {
    try {
      await patchRecruitmentSummary({
        data: {
          ...(cooperationChecked !== null && { cooperation: cooperationChecked }),
          ...(independenceChecked !== null && { independence: independenceChecked }),
          ...(managementChecked !== null && { management: managementChecked }),
          generalNote,
        },
      });
      push('/ended-recruitment');
    } catch (e) {
      handleException(e);
    }
  };

  const copyLink = () => {
    const splitPathname = window.location.pathname.split('/');
    splitPathname.shift();
    splitPathname.shift();
    const pathname = ['/generated-ended-recruitment', ...splitPathname].join('/');
    copyToClipboard(`${window.location.origin}${pathname}`);
  };

  return (
    <>
      <S.RecruitmentReviewTitle>
        <S.SummaryTitle>Recruiter review</S.SummaryTitle>
      </S.RecruitmentReviewTitle>
      <S.RecruitmentReview>
        <RecruiterReview
          cooperationChecked={cooperationChecked}
          cooperationClicked={cooperationClicked}
          independenceChecked={independenceChecked}
          independenceClicked={independenceClicked}
          managementChecked={managementChecked}
          managementClicked={managementClicked}
          editable={editable}
          recommendation={recommendation}
          recruitmentSummaryData={recruitmentData}
          handleRecommendationChange={handleRecommendationChange}
          debouncedOpinionChange={debouncedOpinionChange}
          setGeneralNote={setGeneralNote}
          generatedView
        />
        <Box display="flex" justifyContent="space-between" mb={3} marginTop="30px">
          {keycloak.authenticated && <Button onClick={backToList}>Back to list</Button>}

          {editable && (
            <div>
              <S.DownloadButton
                disabled={isPatchingRecruitmentSummary}
                startIcon={
                  isLoadingSummaryDocxDownload ? (
                    <CircularProgress
                      size="20px"
                      style={{ color: 'white', marginRight: '10px' }}
                    />
                  ) : (
                    <S.DocumentIcon />
                  )
                }
                onClick={handleDownloadDocument}
              >
                Download as file
              </S.DownloadButton>
              <Button
                $generateLink
                variant="contained"
                startIcon={<S.CopyIcon />}
                onClick={copyLink}
              >
                Copy link
              </Button>
              <S.SaveButton
                onClick={() => handleClick(false)}
                disabled={isPatchingRecruitmentSummary}
                variant="contained"
                endIcon={
                  isPatchingRecruitmentSummary ? (
                    <CircularProgress style={{ color: 'black' }} size="1rem" />
                  ) : (
                    ''
                  )
                }
              >
                Save
              </S.SaveButton>
            </div>
          )}
        </Box>
      </S.RecruitmentReview>
    </>
  );
};

export default GeneratedSummary;
