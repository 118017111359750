import { CircularProgress as MuiCircularProgress } from '@material-ui/core';
import SaveAltOutlinedIcon from '@material-ui/icons/SaveAltOutlined';
import styled from 'styled-components';
import { Button } from 'styles.global';

export const ReportPageContainer = styled.div`
  background-color: #ffffff;
  min-height: calc(100vh - 60px);
`;

export const DocumentIcon = styled(SaveAltOutlinedIcon)`
  &.MuiSvgIcon-root {
    margin-right: 10px;
    color: #000;
  }
`;

export const MainContainer = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 400px;
`;

export const CircularProgress = styled(MuiCircularProgress)`
  &.MuiCircularProgress-root {
    color: #000;
    margin-right: 10px;
  }
`;

export const DownloadButton = styled(Button)`
  &.MuiButtonBase-root {
    margin-top: 12px;
  }
`;

export const Field = styled.div`
  margin-top: 8px;
  width: 100%;
`;

export const Label = styled.label`
  display: block;
  margin-bottom: -4px;
`;
