import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { FormControl, Radio,RadioGroup, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { usePatchQuestion } from 'api/recruitments';
import { QuestionData } from 'api/types';
import BrainPNG from 'assets/brain.svg';
import HammerPNG from 'assets/hammer.svg';
import debounce from 'lodash.debounce';
import { QuestionCriteria } from 'pages/Questions/components/QuestionCriteria/QuestionCriteria';
import { SCORES } from 'pages/Recruitment/components/RatableQuestion/RatableQuestion.const';
import { useIsMount } from 'utils/hooks';
import { UPDATE_QUESTION_DELAY } from './constants';
import { QuestionProps } from './EndedQuestion.types';
import * as S from './EndedQuestion.css';

export const EndedQuestion = ({
  endedQuestion,
  editable,
  setLoadingRecruitment,
  moduleId
}: QuestionProps) => {
  const { patchQuestionAsync } = usePatchQuestion();
  const [note, setNote] = useState(endedQuestion.note);
  const [assessed, setAssessed] = useState(!!endedQuestion.grade);
  const [score, setScore] = useState(endedQuestion.grade);
  const isMounted = useIsMount();


  const debouncedNoteChange = useMemo(() => {
    function handleNoteChange(e: ChangeEvent<HTMLTextAreaElement>) {
      setNote(e.target.value);
      setLoadingRecruitment(false);
    }
    return debounce(handleNoteChange, UPDATE_QUESTION_DELAY);
  }, [setLoadingRecruitment]);

  useEffect(() => {
    if (isMounted) {
      patchQuestionAsync(endedQuestion.id, {
        grade: endedQuestion.grade!,
        note: note ?? '',
      });
    }
  }, [note, endedQuestion, patchQuestionAsync, isMounted]);

  const isAccordionDisabled =
    (!endedQuestion?.question.criteria || !endedQuestion?.question.criteria.length) &&
    !editable &&
    !endedQuestion.note;

    const rateQuestion = useCallback(
      (props: QuestionData) => {
          setTimeout(() => {
            patchQuestionAsync(endedQuestion.id, props).catch(() => {
              // this is intentional - we don't want to show the user an error
            });
          }, 500);
        },
      [patchQuestionAsync, endedQuestion.id],
    )

    const handleScoreChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (!assessed) {
        setAssessed(true);
      }
      setScore(Number(e.target.value));
      rateQuestion({
        grade: Number(e.target.value),
      });
    };


  return (
    <>
    <S.Main>
      <S.Accordion disabled={isAccordionDisabled}>
        <S.Summary expandIcon={isAccordionDisabled ? null : <ExpandMoreIcon />}>
          <S.Inner>
            <S.Icon
              src={endedQuestion.question.isPractical ? HammerPNG : BrainPNG}
              alt="icon"
            />
            <S.QuestionText>{endedQuestion.question.text}</S.QuestionText>
          </S.Inner>
        </S.Summary>
        <S.AccordionDetails>
          <S.CriteriaContainer>
            <QuestionCriteria criteria={endedQuestion?.question?.criteria} />
          </S.CriteriaContainer>
          {(editable || endedQuestion.note) && (
            <>
              <S.Note>Note: </S.Note>
              {editable ? (
                <S.TextArea
                  defaultValue={endedQuestion.note ?? ''}
                  onChange={(e) => {
                    debouncedNoteChange(e);
                    setLoadingRecruitment(true);
                  }}
                />
              ) : (
                <div>{endedQuestion.note}</div>
              )}
            </>
          )}
        </S.AccordionDetails>
      </S.Accordion>
    </S.Main>
     {editable ? (  <FormControl component="fieldset">
     <S.ScoreLabel>Score the answer</S.ScoreLabel>
     <RadioGroup row onChange={handleScoreChange}>
       {SCORES.map((i) => (
         <S.FormControlLabel
           key={i}
           value={i}
           label={<Typography style={{fontSize: 12, marginBottom: 20, marginTop: -5}}>{i}</Typography>}
           checked={score === i}
           control={<Radio size='small' />}
           labelPlacement="bottom"
         />
       ))}
     </RadioGroup>
   </FormControl>
   ) : (
    <S.QuestionValues>
   <S.QuestionScore>
    Score: <S.BoldText>{endedQuestion.grade ?? 0}</S.BoldText>
    {`/${endedQuestion.question.maxGrade ?? 0}`}
     </S.QuestionScore>
   </S.QuestionValues>
   )}
   </>
  );
};
