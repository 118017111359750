import { CircularProgress as MuiCircularProgress } from '@material-ui/core';
import SaveAltOutlinedIcon from '@material-ui/icons/SaveAltOutlined';
import styled from 'styled-components';
import theme from 'theme';

export const T1 = styled.div`
  font-size: large;
  border-bottom: 2px solid #ffd242;
  width: fit-content;
  margin-bottom: 16px;
  margin-top: 16px;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 16px;
`;

export const Info = styled.div`
  display: flex;
  width: 300px;
  padding: 8px 0;

  &.remuneratedRecruiterInfo {
    width: 400px;
  }
`;

export const Bolded = styled.div`
  font-weight: bold;
  margin-right: 5px;
`;

export const InfoContent = styled.div`
  word-break: break-word;
  display: flex;
  align-items: center;
`;

export const ClickableInfoContent = styled.div`
  word-break: break-word;
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`;

export const Container = styled.div`
  background-color: white;
  padding: 10px 30px 10px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12), 0px 0px 4px rgba(0, 0, 0, 0.08);
  border: 0.5px #ededed solid;
`;

export const DownloadIcon = styled(SaveAltOutlinedIcon)`
  &.MuiSvgIcon-root {
    background-color: transparent;
    font-size: 18px;
    margin-left: 14px;
  }
`;

export const CircularProgress = styled(MuiCircularProgress)`
  &.MuiCircularProgress-root {
    margin-top: 2px;
    margin-left: 12px;
    color: ${theme.palette.secondary.dark};
  }
`;
