import CloseIcon from '@material-ui/icons/Close';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import styled from 'styled-components';
import { TextArea } from 'styles.global';

export const Note = styled.div`
  width: 20vw;
  font-size: 1vw;
`;

export const NoteContainer = styled.div`
  width: 90%;
  margin: 0 auto;
`;

export const RecruiterNoteTextArea = styled(TextArea)`
  width: 100%;
  display: block;
  resize: none;
  height: 50vh;
  box-sizing: border-box;
  font-size: 1vw;
`;

export const AddNoteContainer = styled.div`
  position: fixed;
  right: 12px;
  margin-top: 4px;
  z-index: 9998;
  width: 42px;
  height: 42px;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0px;
  color: white;
  &:hover {
    cursor: pointer;
    min-width: 147px;
    font-size: 16px;
  }
`;

export const AddNoteIcon = styled(NoteAddIcon)`
  &.MuiSvgIcon-root {
    width: 24px;
    height: 24px;
    padding: 5px;
    background-color: black;
    color: white;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const CloseNoteIcon = styled(CloseIcon)`
  &.MuiSvgIcon-root {
    font-size: 1vw;
  }

  :hover {
    cursor: pointer;
  }
`;
