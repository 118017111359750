import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import { RecruitmentService, UserService } from 'api';
import { useUserContext } from 'context';
import { RecruitmentTable } from 'pages/TodoRecruitment/components/RecruitmentTable';
import { Button } from 'styles.global';
import { Person } from 'types/common';
import { MyRecruitmentsFilter } from 'components/Filters/MyRecruitmentsFilter';
import Pagination from 'components/Pagination';
import { SearchBar } from 'components/SearchBar';
import { handleException } from 'utils/errorHandlingUtils';
import { getFullName } from 'utils/formatters';
import { usePagination, useQueryParams } from 'utils/hooks';
import { RecruitmentForm } from './components/RecruitmentForm';
import * as S from './ToDoPage.css';

const ToDoPage = () => {
  const { push } = useHistory();

  const queryParams = useQueryParams();

  const { paginationOptions, setPaginationOptions } = usePagination();

  const { user } = useUserContext();
  const [filtersCount, setFiltersCount] = useState(0);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [hrs, setHrs] = useState<Person[]>([]);
  const [technicalRecruiters, setTechnicalRecruiters] = useState<Person[]>([]);

  const { getTechnicalRecruiters } = UserService.useGetTechnicalRecruiters();
  const { createTechnicalRecruiter } = UserService.useCreateTechnicalRecruiter();

  const { getHrs } = UserService.useGetHrs();
  const { createHr } = UserService.useCreateHr();
  const { recruitmentsData, getRecruitments, isLoadingRecruitmentsData } =
    RecruitmentService.useGetRecruitments();

  const { positionsData, getPositionsData } = RecruitmentService.useGetPositions();

  const fetchTechnicalRecruiters = useCallback(async () => {
    try {
      const {
        data: { data },
      } = await getTechnicalRecruiters();

      if (
        user?.name &&
        user?.roles?.includes('technical_recruiter') &&
        !data.map(({ name, surname }) => getFullName(name, surname)).includes(user?.name)
      ) {
        const [name, ...surname] = user.name.split(' ');
        const {
          data: { data: newTechnicalRecruiter },
        } = await createTechnicalRecruiter(name, surname.join(' '));
        return setTechnicalRecruiters([...data, newTechnicalRecruiter as Person]);
      }
      setTechnicalRecruiters(data);
    } catch (e) {
      handleException(e);
    }
  }, [createTechnicalRecruiter, getTechnicalRecruiters, user?.name, user?.roles]);

  const fetchHrs = useCallback(async () => {
    try {
      const {
        data: { data },
      } = await getHrs();
      if (
        user?.name &&
        user?.roles?.includes('hr') &&
        !data.map(({ name, surname }) => getFullName(name, surname)).includes(user?.name)
      ) {
        const [name, ...surname] = user.name.split(' ');
        const {
          data: { data: newHr },
        } = await createHr(name, surname.join(' '));
        return setHrs([...data, newHr as Person]);
      }
      setHrs(data);
    } catch (e) {
      handleException(e);
    }
  }, [createHr, getHrs, user?.name, user?.roles]);

  const fetchRecruitments = useCallback(async () => {
    try {
      const { data } = await getRecruitments({
        ...queryParams,
        page: paginationOptions.page,
        perPage: paginationOptions.perPage,
      });
      setPaginationOptions((prevState) => ({
        ...prevState,
        count: data.totalNotEndedRecruitments,
        shouldFetch: false,
      }));
    } catch (e) {
      handleException(e);
    }
  }, [
    getRecruitments,
    paginationOptions.page,
    paginationOptions.perPage,
    queryParams,
    setPaginationOptions,
  ]);

  useEffect(() => {
    fetchHrs();
    fetchTechnicalRecruiters();
    getPositionsData();
  }, [fetchHrs, fetchTechnicalRecruiters, getPositionsData]);

  useEffect(() => {
    setFiltersCount(
      [
        queryParams.position,
        queryParams.hrContact,
        queryParams.recruiter,
        queryParams.dateFrom,
        queryParams.dateTo,
        queryParams.name,
      ].filter(Boolean).length,
    );
  }, [
    queryParams.position,
    queryParams.hrContact,
    queryParams.recruiter,
    queryParams.dateFrom,
    queryParams.dateTo,
    queryParams.name,
  ]);

  useEffect(() => {
    if (paginationOptions.shouldFetch) {
      fetchRecruitments();
      window.scrollTo(0, 0);
    }
  }, [fetchRecruitments, paginationOptions.shouldFetch]);

  const handleSearchChange = (search: string) => {
    delete queryParams.page;
    if (!search) {
      delete queryParams.search;
    }
    push({
      pathname: '/todo',
      search: `${new URLSearchParams({
        ...queryParams,
        ...(search && { search }),
      })}`,
    });
  };

  return (
    <S.ToDoPageContainer>
      <>
        <S.Actionbar>
          <SearchBar defaultValue={queryParams.search} onChange={handleSearchChange} />
          <MyRecruitmentsFilter
            redirectTo="todo"
            currentUser={user}
            queryParams={queryParams}
          />
          <Button
            $primary
            onClick={() => setIsDialogOpen(true)}
            startIcon={<ControlPointIcon />}
          >
            ADD RECRUITMENT
          </Button>
        </S.Actionbar>
        <Pagination paginationOptions={paginationOptions} />
        <RecruitmentTable
          isLoadingRecruitmentsData={isLoadingRecruitmentsData}
          recruitments={recruitmentsData}
          filtersCount={filtersCount}
          setPaginationOptions={setPaginationOptions}
          hrs={hrs}
          technicalRecruiters={technicalRecruiters}
          positions={positionsData}
          getPositionsData={getPositionsData}
        />
      </>
      {isDialogOpen && (
        <RecruitmentForm
          setPaginationOptions={setPaginationOptions}
          onClose={() => setIsDialogOpen(false)}
          hrs={hrs}
          technicalRecruiters={technicalRecruiters}
          positions={positionsData}
          getPositionsData={getPositionsData}
        />
      )}
    </S.ToDoPageContainer>
  );
};

export default ToDoPage;
