import { TextField } from '@material-ui/core';
import { Props } from './Autocomplete.types';
import * as S from './Autocomplete.css';

const Autocomplete = ({
  defaultValue,
  value,
  onChange,
  textFieldChange,
  selectOptions,
  placeholder,
  disabled,
  size,
  noOptionsText,
  error = false,
}: Props) => {
  const options = selectOptions.filter(
    (option) =>
      !!option.trim() && (option.toLowerCase().includes(value.toLowerCase()) || !value),
  );

  return (
    <S.StyledAutocomplete
      freeSolo
      noOptionsText={noOptionsText}
      disabled={disabled}
      options={options}
      getOptionSelected={(option, v) => option === v}
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder ?? ''}
          onChange={textFieldChange}
          variant="outlined"
          size={size}
          error={error}
        />
      )}
    />
  );
};

export default Autocomplete;
