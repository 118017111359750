import {
  Accordion as MuiAccordion,
  AccordionSummary,
  FormControlLabel as MuiFormControlLabel,
  Select as MuiSelect,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CommentIcon from '@material-ui/icons/Comment';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import MuiRefreshIcon from '@material-ui/icons/Refresh';
import styled from 'styled-components';
import { Button, TextArea } from 'styles.global';
import theme from 'theme';

export const Main = styled.div`
  display: flex;
  margin-bottom: 10px;
  align-items: center;
`;

export const Bold = styled.span`
  font-weight: bold;
`;

export const FormControlLabel = styled(MuiFormControlLabel)`
  &.MuiFormControlLabel-root {
    margin: -5px;
  }
`;

export const ScoreLabel = styled.span`
  margin-left: 10px;
  margin-bottom: 10px;
`;

export const Accordion = styled(MuiAccordion)`
  &.MuiAccordion-root {
    transition: width 0.07s;
    width: ${(props) => (props.expanded ? '100%' : '65%')};
    padding: 0;
    .Mui-disabled {
      background-color: rgba(255, 255, 255, 0);
      color: black;
      opacity: 1;
    }
  }
`;

export const Summary = styled(AccordionSummary)`
  &.MuiAccordionSummary-root {
    display: flex;
  }
`;

export const Inner = styled.div`
  max-width: 70%;
  display: flex;
`;

export const Icon = styled.img`
  margin: auto 5px auto 10px;
  filter: brightness(0.8);
`;

export const QuestionText = styled.div`
  word-break: keep-all;
`;

export const ExpandedQuestionText = styled.div`
  word-break: keep-all;
  font-weight: bold;
  :hover {
    color: rgba(0, 0, 0, 0.5);
  }
`;

export const CriteriaTitle = styled.div`
  margin-left: 5%;
  margin-top: 3vh;
`;

export const CriteriaContainer = styled.div`
  min-height: 100px;
  width: 50%;
  word-break: break-word;
`;

export const RightSide = styled.div`
  width: 28%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LeftSide = styled.div`
  width: 72%;
  border-right: 1px solid lightgray;
`;

export const NextButton = styled(Button).attrs({ $primary: true })`
  &.MuiButton-root {
    width: 200px;
    margin-top: 40px;
  }
`;

export const NoteIcon = styled(CommentIcon)`
  margin-right: 10px;
`;

export const RightArrow = styled(ChevronRightIcon)`
  position: absolute;
  right: 10px;
`;

export const Select = styled(MuiSelect)`
  .MuiSelect-root {
    border: 1px solid gray;
    padding: 10px;
    width: 200px;
  }
`;

export const NoteTextArea = styled(TextArea)`
  width: 98%;
  margin-top: 20px;
  min-height: 150px;
  resize: none;
  background-color: ${theme.palette.secondary.light};
  border: none;
  box-shadow: inset 1px 3px 4px lightgray;
  font-size: large;
  &:focus {
    outline-color: ${theme.palette.primary.main};
  }
`;

export const AssessedQuestionIcon = styled(CheckCircleIcon)`
  color: ${theme.palette.success.main};
`;

export const ActionButton = styled(Button)`
  &.MuiButton-root {
    border-bottom: 1px solid lightgray;
    border-right: 1px solid lightgray;
    text-transform: none;
    margin-bottom: 16px;
  }
`;

export const BinIcon = styled(DeleteOutlineIcon)`
  &.MuiSvgIcon-root {
    margin-right: 10px;
  }
`;

export const RefreshIcon = styled(MuiRefreshIcon)`
  &.MuiSvgIcon-root {
    margin-right: 10px;
  }
`;

export const NoteButton = styled(Button).attrs({ $secondary: true })`
  &.MuiButton-root {
    height: 32px;
  }
`;

export const RedrawButton = styled(Button)`
  &.MuiButton-root {
    margin-right: 20px;
    height: 32px;
  }
`;

export const DeleteButton = styled(Button)`
  &.MuiButton-root {
    margin-right: 20px;
    height: 32px;
    color: ${theme.palette.error.main};
  }
`;

export const LeaveNote = styled.div`
  font-size: 18px;
  font-weight: bold;
`;
