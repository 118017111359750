import * as S from '../Toast.css';

type Props = {
  message: string;
};

export const ErrorToast = ({ message }: Props) => (
  <S.ToastContainer>
    <S.ErrorIconStyle />
    <S.ToastStyle>{message}</S.ToastStyle>
  </S.ToastContainer>
);
