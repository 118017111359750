import {
  DialogActions as MuiDialogActions,
  FormControl as MuiFormControl,
  TextField,
  Typography,
} from '@material-ui/core';
import styled from 'styled-components';
import { Button } from 'styles.global';
import theme from 'theme';

export const CheckedButton = styled.div`
  :hover {
    cursor: pointer;
  }
`;

export const Label = styled(Typography)`
  &.MuiTypography-root {
    margin-top: 20px;
    font-weight: 600;
  }
`;

export const TypeLabel = styled(Typography)`
  &.MuiTypography-root {
    margin-top: 30px;
    font-weight: 600;
  }
`;

export const BoldText = styled.span`
  font-weight: bold;
`;

export const QuestionLabel = styled(Typography)`
  &.MuiTypography-root {
    margin-top: 15px;
    margin-bottom: 10px;
    font-weight: 600;
  }
`;

export const FormControl = styled(MuiFormControl)`
  &.MuiFormControl-root {
    width: 100%;
    margin-top: 5px;
    text-align: left;
  }
`;

export const DialogTitle = styled(Typography)`
  &.MuiTypography-root {
    font-weight: 600;
    align-self: center;
    margin-top: 20px;
    font-size: 25px;
    padding-top: 16px;
    padding-bottom: 20px;
    text-decoration: underline;
    text-underline-offset: 8px;
    text-decoration-color: ${theme.palette.primary.main};
  }
`;

export const StaticContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StaticLabel = styled(Typography)`
  &.MuiTypography-root {
    font-size: 16px;
  }
`;

export const Criteria = styled.div`
  max-height: 200px;
  overflow-y: auto;
`;

export const CriterionInput = styled(TextField)`
  &.MuiTextField-root {
    margin-top: 10px;
    width: 100%;
  }
`;

export const DialogActions = styled(MuiDialogActions)`
  &.MuiDialogActions-root {
    width: 400px;
    margin: auto;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }
`;

export const ApproveButton = styled(Button).attrs({ $primary: true })`
  &.MuiButton-root {
    width: 123px;
  }
`;

export const CancelButton = styled(Button)`
  &.MuiButton-root {
    width: 123px;
  }
`;
