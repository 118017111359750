import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Box, Checkbox, Slider, TextField } from '@material-ui/core';
import { Autocomplete as MUIAutocomplete } from '@material-ui/lab';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { isValid } from 'date-fns';
import { Button } from 'styles.global';
import DateInput from 'components/DateInput';
import * as S from 'components/Filters/FiltersDrawer/Filters.css';
import ErrorToast from 'components/Toasts/ErrorToast';
import { getDate, getFullName } from 'utils/formatters';
import { useQueryParams } from 'utils/hooks';
import { Filters } from '../FiltersDrawer';
import { MAX_RECRUITMENT_SCORE, MIN_RECRUITMENT_SCORE } from './RecruitmentFilters.const';
import { RecruitmentFiltersProps } from './RecruitmentFilters.types';

export const RecruitmentFilters = ({
  redirectTo,
  hrs,
  technicalRecruiters,
  positions,
  hideSlider = true,
}: RecruitmentFiltersProps) => {
  const { push } = useHistory();

  const [isOpen, setOpen] = useState(false);

  const queryParams = useQueryParams();

  const [dateFromValue, setDateFromValue] = useState(
    queryParams.dateFrom ? new Date(queryParams.dateFrom) : null,
  );
  const [dateFromError, setDateFromError] = useState(false);
  const [dateToValue, setDateToValue] = useState(
    queryParams.dateTo ? new Date(queryParams.dateTo) : null,
  );
  const [dateToError, setDateToError] = useState(false);

  const [filters, setFilters] = useState({
    name: queryParams.name || '',
    position: queryParams.position || '',
    hrContact: queryParams.hrContact || '',
    recruiter: queryParams.recruiter || '',
    dateFrom: queryParams.dateFrom || '',
    dateTo: queryParams.dateTo || '',
    minScore: Number(queryParams.minScore) || MIN_RECRUITMENT_SCORE,
    maxScore: Number(queryParams.maxScore) || MAX_RECRUITMENT_SCORE,
    cancelled: queryParams.cancelled === '1',
  });

  const [prevFilters] = useState({
    ...filters,
  });

  const clearFilters = () => {
    setDateFromValue(null);
    setDateToValue(null);
    setFilters({
      position: '',
      recruiter: '',
      hrContact: '',
      dateFrom: '',
      dateTo: '',
      name: '',
      minScore: MIN_RECRUITMENT_SCORE,
      maxScore: MAX_RECRUITMENT_SCORE,
      cancelled: false,
    });
  };

  const handlePositionChange = (e: ChangeEvent<{}>, newValue: string | null) => {
    setFilters({
      ...filters,
      position: newValue || '',
    });
  };

  const handleRecruiterChange = (e: ChangeEvent<{}>, newValue: string | null) => {
    setFilters({
      ...filters,
      recruiter: newValue || '',
    });
  };

  const handleHrContactChange = (e: ChangeEvent<{}>, newValue: string | null) => {
    setFilters({
      ...filters,
      hrContact: newValue || '',
    });
  };

  const getFormattedDate = (e: MaterialUiPickersDate) => {
    if (isValid(e) && e !== null) {
      const dateTime = getDate(e, 'yyyy-MM-dd');
      const formattedDate = dateTime.split(' ').join('T');
      return formattedDate || '';
    }
    return '';
  };

  const handleDateFromChange = (e: MaterialUiPickersDate) => {
    setDateFromValue(e);
    const formattedDate = getFormattedDate(e);
    setDateFromError(false);
    setFilters({
      ...filters,
      dateFrom: formattedDate,
    });
  };

  const handleDateToChange = (e: MaterialUiPickersDate) => {
    setDateToValue(e);
    setDateToError(false);
    const formattedDate = getFormattedDate(e);
    setFilters({
      ...filters,
      dateTo: formattedDate,
    });
  };

  const handleDateOnBlur = (
    setError: Dispatch<SetStateAction<boolean>>,
    date: Date | null,
  ) => {
    if (!isValid(date) && date !== null) {
      setError(true);
    } else {
      setError(false);
    }
  };

  const handleNameChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
    setFilters({ ...filters, name: value });

  const handleClose = () => {
    // setTimeout so there is better visual effect when closing
    setTimeout(() => {
      setFilters(prevFilters);
    }, 100);
  };

  const handleSubmit = () => {
    if (
      (dateFromValue !== null && !isValid(new Date(dateFromValue))) ||
      (dateToValue !== null && !isValid(new Date(dateToValue)))
    ) {
      toast.error(<ErrorToast message="Please enter valid dates" />);
      return;
    }
    const {
      position,
      recruiter,
      hrContact,
      dateFrom,
      dateTo,
      name,
      minScore,
      maxScore,
      cancelled,
    } = filters;
    const { search, perPage } = queryParams;

    push({
      pathname: redirectTo,
      search: `?${new URLSearchParams({
        ...(search && { search }),
        ...(perPage && perPage !== '10' && { perPage }),
        ...(name && { name }),
        ...(position && { position }),
        ...(recruiter && { recruiter }),
        ...(hrContact && { hrContact }),
        ...(dateFrom && { dateFrom }),
        ...(dateTo && { dateTo }),
        ...(minScore > MIN_RECRUITMENT_SCORE && { minScore: minScore.toString() }),
        ...(maxScore < MAX_RECRUITMENT_SCORE && { maxScore: maxScore.toString() }),
        ...(cancelled && { cancelled: '1' }),
      })}`,
    });
  };

  const handleSliderChange = (event: ChangeEvent<{}>, value: number | number[]) => {
    if (typeof value === 'number') return;
    setFilters({ ...filters, minScore: value[0], maxScore: value[1] });
  };

  return (
    <Filters
      onClose={handleClose}
      onSubmit={handleSubmit}
      isOpen={isOpen}
      setOpen={setOpen}
      submitDisabled={dateFromError || dateToError}
    >
      <>
        {!hideSlider && (
          <>
            <S.Label> Score (in %) </S.Label>
            <Slider
              value={[filters.minScore, filters.maxScore]}
              onChange={handleSliderChange}
              valueLabelDisplay="auto"
            />
          </>
        )}
        <S.Label>Name</S.Label>
        <TextField
          variant="outlined"
          size="small"
          placeholder="Type to filter"
          value={filters.name}
          onChange={handleNameChange}
        />
        <S.Label>Position</S.Label>
        <MUIAutocomplete
          options={positions.map(({ positionName }) => positionName)}
          onChange={handlePositionChange}
          value={filters.position}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              size="small"
              placeholder="Select position"
            />
          )}
        />
        <S.Label>Recruiter</S.Label>
        <MUIAutocomplete
          options={technicalRecruiters.map(({ name, surname }) =>
            getFullName(name, surname),
          )}
          onChange={handleRecruiterChange}
          value={filters.recruiter}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              size="small"
              placeholder="Select recruiter"
            />
          )}
        />
        <S.Label>HR Contact</S.Label>
        <MUIAutocomplete
          options={hrs.map(({ name, surname }) => getFullName(name, surname))}
          onChange={handleHrContactChange}
          defaultValue={filters.hrContact}
          value={filters.hrContact}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              size="small"
              placeholder="Select HR contact"
            />
          )}
        />
        <S.Label> Date from: </S.Label>
        <DateInput
          name="dateFrom"
          onChange={handleDateFromChange}
          value={dateFromValue}
          onBlur={() => handleDateOnBlur(setDateFromError, dateFromValue)}
          invalidDateMessage={dateFromError ? 'Invalid date' : ''}
        />
        <S.Label> Date to: </S.Label>
        <DateInput
          name="dateTo"
          onChange={handleDateToChange}
          value={dateToValue}
          invalidDateMessage={dateToError ? 'Invalid date' : ''}
          onBlur={() => handleDateOnBlur(setDateToError, dateToValue)}
        />
        {!hideSlider && (
          <S.CancelledRecruitmentContainer
            onClick={() => setFilters({ ...filters, cancelled: !filters.cancelled })}
          >
            Show cancelled recruitments
            <Checkbox size="medium" checked={filters.cancelled} />
          </S.CancelledRecruitmentContainer>
        )}
        <Box marginLeft="auto" paddingTop="30px">
          <Button $primary onClick={clearFilters}>
            Clear
          </Button>
        </Box>
      </>
    </Filters>
  );
};
