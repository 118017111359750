import { useCallback } from 'react';
import { sortAscendingPersonList } from 'pages/TodoRecruitment/utils';
import { Person } from 'types/common';
import { User } from 'types/user';
import { useAxios } from './axios';

export const useGetTechnicalRecruiters = () => {
  const [{ data = { data: [] } }, getTechnicalRecruiters] = useAxios<{ data: Person[] }>(
    { url: '/technical_recruiters' },
    { manual: true },
  );
  return {
    technicalRecruiters: sortAscendingPersonList(data.data),
    getTechnicalRecruiters,
  };
};

export const useCreateTechnicalRecruiter = () => {
  const [, createTechnicalRecruiterData] = useAxios(
    { url: '/technical_recruiters', method: 'POST' },
    { manual: true },
  );

  const createTechnicalRecruiter = useCallback(
    (name: string, surname: string) =>
      createTechnicalRecruiterData({ data: { name, surname } }),
    [createTechnicalRecruiterData],
  );

  return { createTechnicalRecruiter };
};

export const useGetHrs = () => {
  const [{ data = { data: [] } }, getHrs] = useAxios<{
    data: Person[];
  }>({ url: '/hrs' }, { manual: true });

  return { hrs: sortAscendingPersonList(data.data), getHrs };
};

export const useCreateHr = () => {
  const [, createHrData] = useAxios({ url: '/hrs', method: 'POST' }, { manual: true });

  const createHr = useCallback(
    (name: string, surname: string) => createHrData({ data: { name, surname } }),
    [createHrData],
  );

  return { createHr };
};

export const useGetLoggedUser = () => {
  const [{ data = { data: null } }, getLoggedUser] = useAxios<{
    data: User;
  }>({ url: '/user/me' }, { manual: true });

  return { loggedUser: data.data, getLoggedUser };
};
