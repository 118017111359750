import { useState } from 'react';
import { Dialog } from '@material-ui/core';
import { RecruitmentService } from 'api';
import { Button } from 'styles.global';
import { ValidationError } from 'components/ValidationError';
import { handleException } from 'utils/errorHandlingUtils';
import { removeExtraSpaces } from 'utils/helpers';
import { useCloseWithConfirm } from 'utils/hooks';
import { MAX_LINK_LENGTH } from './GenerateLink.const';
import { GenerateLinkFormProps } from './GenerateLinkForm.types';
import * as S from './GenerateLinkForm.css';

export const GenerateLinkForm = ({
  onClose,
  recruitmentId,
  setPaginationOptions,
}: GenerateLinkFormProps) => {
  const handleClose = useCloseWithConfirm({ onClose });
  const [linkName, setLinkName] = useState('');

  const { createLink, isCreatingLink } = RecruitmentService.useCreateLink(recruitmentId);

  const isLinkValid = removeExtraSpaces(linkName).length < MAX_LINK_LENGTH;

  const handleGenerate = async () => {
    try {
      await createLink({ data: { name: removeExtraSpaces(linkName) } });
      setPaginationOptions((prevState) => ({
        ...prevState,
        shouldFetch: true,
      }));
      onClose();
    } catch (e) {
      handleException(e);
    }
  };

  const isButtonDisabled =
    !removeExtraSpaces(linkName) ||
    isCreatingLink ||
    removeExtraSpaces(linkName).length >= MAX_LINK_LENGTH;

  return (
    <Dialog
      open
      fullWidth
      maxWidth="sm"
      onClose={() => handleClose(!removeExtraSpaces(linkName))}
      onKeyPress={({ code }) => code === 'Enter' && handleGenerate()}
    >
      <S.StyledDialogTitle>GENERATE LINK</S.StyledDialogTitle>
      <S.DialogContent>
        <>
          <S.InputTitle>Name of the link:</S.InputTitle>
          <S.InputSubTitle>(Name will be visible only in the system)</S.InputSubTitle>
        </>
        <S.TextField
          onChange={(e) => setLinkName(e.target.value)}
          variant="outlined"
          size="small"
          placeholder="Enter name"
          fullWidth
        />
        {!isLinkValid && <ValidationError limit={MAX_LINK_LENGTH} />}
      </S.DialogContent>
      <S.DialogActions>
        <Button onClick={() => handleClose(!removeExtraSpaces(linkName))}>Cancel</Button>
        <Button
          onClick={handleGenerate}
          disabled={isButtonDisabled}
          variant="contained"
          $generateLink
          type="submit"
        >
          Generate
        </Button>
      </S.DialogActions>
    </Dialog>
  );
};
