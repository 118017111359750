import { Drawer } from '@material-ui/core';
import { Button } from 'styles.global';
import { FiltersProps } from './Filters.types';
import * as S from './Filters.css';

export const Filters = ({
  onClose,
  onSubmit,
  children,
  isOpen,
  setOpen,
  submitDisabled = false,
}: FiltersProps) => {
  const handleClose = () => {
    onClose();
    setOpen(false);
  };

  const handleSubmit = () => {
    onSubmit();
    setOpen(false);
  };

  return (
    <>
      <S.FilterIcon onClick={() => setOpen(true)} />
      <Drawer
        open={isOpen}
        anchor="right"
        onClose={handleClose}
        onKeyPress={({ code }) => code === 'Enter' && handleSubmit()}
      >
        <S.Title>Filters</S.Title>
        <S.FilterBox>
          {children}
          <S.ButtonsWrapper>
            <Button onClick={handleClose}>Cancel</Button>
            <Button $primary onClick={handleSubmit} disabled={submitDisabled}>
              Save
            </Button>
          </S.ButtonsWrapper>
        </S.FilterBox>
      </Drawer>
    </>
  );
};
