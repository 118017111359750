import styled, { css } from 'styled-components';

export const Main = styled.div<{ $isNarrow: boolean }>`
  padding-top: 16px;
  padding-right: 32px;
  padding-left: 32px;

  ${(props) =>
    props.$isNarrow &&
    css`
      width: 70%;
      margin: auto;
    `}
`;

export const ModulesContainer = styled.div`
  margin-bottom: 100px;
`;
