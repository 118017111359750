import { Button } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import styled from 'styled-components';
import theme from 'theme';

export const FilterIcon = styled(FilterListIcon)`
  &:hover {
    cursor: pointer;
    color: ${theme.palette.secondary.dark}80;
  }
`;

export const FilterBox = styled.div`
  width: 400px;
  margin-top: 20px;
  padding: 0 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const CancelledRecruitmentContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  user-select: none;
  margin-top: 20px;
  &:hover {
    cursor: pointer;
  }
`;

export const FilterInputContainer = styled.div`
  margin-top: 10px;
`;

export const Label = styled.p``;

export const Title = styled.h3`
  margin-bottom: 10px;
  clip-path: polygon(0 0, 93% 0%, 90% 120%, 0% 100%);
  width: 50%;
  background-color: ${theme.palette.primary.main};
  padding: 5px 0px 5px 40px;
`;

export const ClearFilters = styled.p`
  margin-left: auto;
  text-decoration: underline;
  font-size: 16px;
  &:hover {
    color: blue;
  }
`;

export const ClearDateButton = styled(Button)`
  &.MuiButton-root {
    margin-left: auto;
    text-transform: none;
    padding: 0;
    letter-spacing: 0;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  padding: 15px 0;

  & .MuiButton-root {
    text-transform: none;
  }
`;
