import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import { DeleteOutline } from '@material-ui/icons';
import Refresh from '@material-ui/icons/Refresh';
import styled from 'styled-components';

export const Main = styled.div`
  display: flex;
  margin-bottom: 10px;
  white-space: pre-wrap;
`;

export const BoldText = styled.span`
  font-weight: bold;
`;

export const Accordion = styled(MuiAccordion)`
  &.MuiAccordion-root {
    transition: width 0.07s;
    width: ${(props) => (props.expanded ? '100%' : '60%')};
    padding: 0;
    .Mui-disabled {
      color: black;
      opacity: 1;
      background-color: white;
    }
  }
`;
export const Summary = styled(AccordionSummary)`
  &.MuiAccordionSummary-root {
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
  }
`;

export const RefreshIcon = styled(Refresh)<{ $refreshable?: boolean }>`
  margin: auto 0px auto 10px;
  filter: brightness(0.8);
  box-shadow: none;
  opacity: ${({ $refreshable }) => ($refreshable ? '100%' : '0%')};

  &:hover {
    cursor: ${({ $refreshable }) => $refreshable && 'pointer'};
    box-shadow: ${({ $refreshable }) =>
      $refreshable && '0px 1px 3px 1px rgba(30, 30, 30, 0.2)'};
    transition: ${({ $refreshable }) => $refreshable && 'box-shadow 0.2s ease-out'};
  }
`;

export const DeleteIcon = styled(DeleteOutline)`
  margin: auto 0px auto 10px;
  filter: brightness(0.8);
  box-shadow: none;

  &:hover {
    cursor: pointer;
    box-shadow: 0px 1px 3px 1px rgba(30, 30, 30, 0.2);
    transition: box-shadow 0.2s ease-out;
  }
`;

export const Inner = styled.div`
  padding: 5px;
  width: 100%;
  display: flex;
`;

export const QuestionTypeIcon = styled.img`
  margin: auto 10px auto 10px;
  filter: brightness(0.8);
`;

export const Icon = styled.img`
  margin: auto 5px auto 10px;
  filter: brightness(0.8);
`;

export const QuestionText = styled.div<{ expanded: boolean }>`
  margin: auto;
  margin-left: 0;
  word-break: keep-all;
  font-weight: ${({ expanded }) => expanded && 'bold'};
  width: ${({ expanded }) => (expanded ? `55.5%` : `100%`)};
`;

export const AccordionDetails = styled(MuiAccordionDetails)`
  &.MuiAccordionDetails-root {
    display: flex;
    flex-direction: column;
    word-break: break-word;
  }
`;
