import { useConfirm } from 'material-ui-confirm';
import { UseQuitWithRedirect } from './types';
import { useRedirect } from '.';

export function useQuitWithRedirect({ redirectTo, canQuit = true }: UseQuitWithRedirect) {
  const confirm = useConfirm();
  const redirect = useRedirect(redirectTo);
  return async (canQuit2: boolean) => {
    if (!canQuit || !canQuit2) {
      await confirm({
        title: 'Discard changes?',
        confirmationText: 'Yes',
        dialogProps: { maxWidth: 'xs' },
      });
      redirect();
    }
    redirect();
  };
}
