import { Accordion, AccordionDetails, Box } from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import Edit from '@material-ui/icons/Edit';
import styled, { css } from 'styled-components';
import { ModuleNameProps, QuestionsContainerProps } from './ModuleRow.types';

export const AddIcon = styled(Add)`
  & {
    margin-right: 5px;
  }
`;

export const EditIcon = styled(Edit)`
  & {
    margin-right: 5px;
  }
`;

export const ModuleRow = styled(Accordion)`
  border: 1px solid lightgray;
  margin-bottom: 20px;
  &.MuiAccordion-root {
    background-color: white;
  }
`;

export const QuestionsContainer = styled(AccordionDetails)<QuestionsContainerProps>`
  display: flex;
  flex-direction: column;
  max-height: 70vh;

  ${(props) =>
    props.$enableScroll &&
    css`
      overflow-y: auto;
    `}
`;

export const IconsContainer = styled(Box)`
  display: flex;
  align-items: center;
  padding: 5px;
  &:hover {
    background-color: rgba(237, 237, 237, 1);
    cursor: pointer;
  }
`;

export const ModuleName = styled.div<ModuleNameProps>`
  flex: 5;
  font-size: 20px;
  font-weight: bold;
  word-break: ${(props) => (!props.breakWord ? 'keep-all' : 'break-all')};
  margin: auto;
`;

export const ModuleIcons = styled.div`
  flex: 4;
  margin: auto;
  padding-left: 10px;
`;

export const Icons = styled.div`
  display: flex;
  align-items: center;
`;

export const IconInfo = styled.p`
  margin-left: 5px;
`;

export const ModuleButtons = styled.div`
  flex: 4;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
`;

export const ModuleDate = styled.div`
  flex: 3;
  margin: auto;
  margin-right: 24px;
`;

export const Date = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const BoldText = styled.span`
  font-weight: bold;
`;

export const ModuleDescription = styled.div`
  margin-bottom: 20px;
  word-break: break-word;
`;

export const AddQuestionButton = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  &:hover {
    background-color: rgba(237, 237, 237, 1);
    cursor: pointer;
  }
`;
