import { TextField } from '@material-ui/core';
import styled, { css } from 'styled-components';
import { Button } from 'styles.global';
import theme from 'theme';

export const TemplateContainer = styled.div`
  display: flex;
  word-break: keep-all;
  padding-bottom: 30px;
  min-height: 100vh;
  background: white;
`;

export const LeftPanel = styled.div`
  border-right: 2px solid rgb(230, 230, 230, 50%);
  flex: 4;
  padding-left: 32px;
`;

export const RightPanel = styled.div`
  padding-top: 60px;
  padding-left: 30px;
  flex: 1;
  position: sticky;
  align-self: flex-start;
  top: 0;
`;

export const TemplateTextField = styled(TextField)`
  width: 300%;
`;

export const Header = styled.div<{ fontSize?: string }>`
  display: flex;
  margin-top: 50px;
  font-size: ${(props) => props.fontSize || '1.5rem'};
  padding-right: 32px;
`;

export const HeaderTitle = styled.p<{ underline?: boolean }>`
  font-size: 20px;
  flex: 3;
  ${(props) =>
    props.underline &&
    css`
      text-decoration: underline ${theme.palette.primary.main};
      text-underline-offset: 5px;
    `}
`;

export const HeaderTime = styled.p<{ bold?: boolean }>`
  flex: 1;
  ${(props) =>
    props.bold &&
    css`
      font-weight: bold;
    `}
`;

export const ModulePanel = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-column-gap: 50px;
  grid-row-gap: 10px;
`;

export const TheoreticalQuestionLabel = styled.div`
  font-size: 16px;
  grid-row: 1 / 2;
  grid-column: 2 / 3;
`;

export const PracticalQuestionLabel = styled.div`
  font-size: 16px;
  grid-row: 3 / 4;
  grid-column: 2 / 3;
  margin-top: 30px;
`;

export const SelectedModule = styled.div`
  grid-row: 2 / 3;
  grid-column: 1 / 2;
`;

export const NumberOfQuestionsInput = styled.div`
  grid-row: 2 / 3;
  grid-column: 2 / 3;
`;

export const NumberOfQuestionsTextField = styled(TextField)`
  width: 48%;
`;

export const NumberOfTasksTextField = styled(TextField)`
  width: 48%;
`;

export const NumberOfTasksInput = styled.div`
  grid-row: 4 / 5;
  grid-column: 2 / 3;
`;

export const PracticalQuestionMinutes = styled.div`
  grid-row: 4 / 5;
  grid-column: 3 / 4;
`;

export const SelectedQuestionsMinutes = styled.div`
  grid-row: 6 / 7;
  grid-column: 3 / 4;
`;

export const SaveButton = styled(Button).attrs({ $primary: true })`
  &.MuiButton-root {
    width: 178px;
    margin-right: 30px;
  }
`;

export const SelectedQuestions = styled.div`
  width: 75%;
  grid-row: 6 / 7;
  grid-column: 2 / 3;
`;

export const SelectedQuestionsLabel = styled.div`
  font-size: 18px;
  grid-row: 5 / 6;
  grid-column: 2 / 3;
  margin-top: 30px;
`;

export const SelectedQuestionsDescription = styled.p`
  font-size: 15px;
  margin-top: 5px;
`;

export const TheoreticalQuestionMinutes = styled.div`
  grid-row: 2 / 3;
  grid-column: 3 / 4;
`;
