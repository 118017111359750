import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { TemplateAutocompleteProps } from './TemplateAutocomplete.types';

export const TemplateAutocomplete = ({
  templates,
  selectedTemplate,
  onTemplateChange,
}: TemplateAutocompleteProps) => (
  <Autocomplete
    getOptionLabel={({ name }) => name}
    onChange={(_, value) => onTemplateChange(value)}
    value={selectedTemplate}
    options={templates}
    filterSelectedOptions
    noOptionsText="No options"
    renderInput={(params) => (
      <TextField
        {...params}
        variant="outlined"
        placeholder="Start typing or select"
        size="small"
        style={{ whiteSpace: 'nowrap', minWidth: '250px' }}
      />
    )}
  />
);
