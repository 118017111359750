import { RefObject, useEffect, useRef, useState } from 'react';

export const useDimensions = (): [
  RefObject<HTMLDivElement>,
  {
    width: number;
    height: number;
  },
] => {
  const ref = useRef<HTMLDivElement>(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const update = () => {
      if (ref.current) {
        setDimensions({
          width: ref.current.offsetWidth,
          height: ref.current.offsetHeight,
        });
      }
    };

    window.addEventListener('resize', update);
    update();

    return () => {
      window.removeEventListener('resize', update);
    };
  }, []);

  return [ref, dimensions];
};
