import { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ModuleService, QuestionService, RecruitmentService, TemplateService } from 'api';
import { useUserContext } from 'context';
import { SelectedTemplate } from 'pages/AdjustRecruitment/components/Panel.types';
import AdjustRecruitmentModule from 'pages/components/AdjustRecruitmentModule';
import {
  AdjustRecruitmentModule as IAdjustRecruitmentModule,
  NewRecruitmentModule,
} from 'types/recruitment';
import Container from 'components/Container';
import { handleException } from 'utils/errorHandlingUtils';
import { createRecruitmentModulesFromTemplate } from 'utils/formatters';
import { removeExtraSpaces, stringIsEmpty } from 'utils/helpers';
import { useQuitWithRedirect } from 'utils/hooks';
import { RouteParams } from 'utils/types/RouteParams';
import { NAME_LIMIT, POSITION_LIMIT } from '../components/const';
import { TemplateHeader } from '../components/TemplateHeader';
import * as S from '../TemplatesPage.css';

export const EditTemplate = () => {
  const { user } = useUserContext();
  const { push } = useHistory();
  const { id: templateId } = useParams<RouteParams>();

  const [name, setName] = useState('');
  const [position, setPosition] = useState('');

  const [isTemplatePrivate, setTemplatePrivate] = useState(false);

  const { isLoadingModules, modulesData, getModules } = ModuleService.useGetModules();
  const { template, getTemplate } = TemplateService.useGetTemplate();
  const { getQuestions } = QuestionService.useGetQuestions();
  const { isPutingTemplateModules, putTemplateModules } =
    TemplateService.usePutTemplateModules();
  const { isPatchingTemplate, patchTemplate } = TemplateService.usePatchTemplate();

  const { positionsData, getPositionsData } = RecruitmentService.useGetPositions();
  const { templatesData, getTemplates } = TemplateService.useGetTemplates();

  const [selectedTemplate, setSelectedTemplate] = useState<SelectedTemplate>(null);
  const [isLoading, setLoading] = useState(false);

  const [recruitmentModules, setRecruitmentModules] =
    useState<IAdjustRecruitmentModule[]>();

  useEffect(() => {
    getModules();
    getPositionsData();
    getTemplates();
  }, [getModules, getPositionsData, getTemplates]);

  const handleQuit = useQuitWithRedirect({
    redirectTo: '/templates',
    canQuit: template?.position === position && template.name === name,
  });

  const fetchTemplate = useCallback(
    async ({ id, populateState }: { id: number; populateState: boolean }) => {
      setLoading(true);

      try {
        const {
          data: { data },
        } = await getTemplate(id);

        setTemplatePrivate(data.private);

        const { templateModules, name: templateName, position: templatePosition } = data;

        const {
          data: { data: questionsForRecruitmentModules },
        } = await getQuestions({
          moduleIds: templateModules.map(({ moduleId }) => moduleId),
        });

        setRecruitmentModules(
          await createRecruitmentModulesFromTemplate(
            questionsForRecruitmentModules,
            modulesData,
            templateModules,
          ),
        );
        if (populateState) {
          setName(templateName);
          setPosition(templatePosition);
        }
      } catch (e) {
        handleException(e);
      }
      setLoading(false);
    },
    [getQuestions, getTemplate, modulesData],
  );

  useEffect(() => {
    if (modulesData.length) {
      fetchTemplate({ id: +templateId, populateState: true });
    }
  }, [fetchTemplate, templateId, modulesData]);

  async function handleSubmit(newTemplateModules: NewRecruitmentModule[]) {
    if (name || position) {
      await patchTemplate(
        templateId,
        removeExtraSpaces(name),
        removeExtraSpaces(position),
        isTemplatePrivate,
      );
    }
    await putTemplateModules(templateId, newTemplateModules);
    push('/templates');
  }

  async function handleTemplateChange(templateArg: SelectedTemplate) {
    try {
      setSelectedTemplate(templateArg);
      if (templateArg) {
        fetchTemplate({ id: templateArg.id, populateState: false });
      } else {
        setRecruitmentModules(undefined);
      }
    } catch (e) {
      handleException(e);
    }
  }

  const canSubmit =
    !stringIsEmpty(name) &&
    !stringIsEmpty(position) &&
    removeExtraSpaces(name).length <= NAME_LIMIT &&
    removeExtraSpaces(position).length <= POSITION_LIMIT;

  return (
    <Container isLoading={isLoading || isLoadingModules}>
      <S.TemplateFormContainer>
        <S.LeftPanel>
          <TemplateHeader
            title="Edit template"
            name={name}
            position={position}
            setName={setName}
            setPosition={setPosition}
            selectedTemplate={selectedTemplate}
            handleTemplateChange={handleTemplateChange}
            templateName={template?.name || ''}
            positionsProp={positionsData}
            templatesProp={templatesData}
          />
          <AdjustRecruitmentModule
            handleSubmit={handleSubmit}
            handleQuit={handleQuit}
            propState={recruitmentModules}
            canSubmit={canSubmit}
            isMakingRequest={isPutingTemplateModules || isPatchingTemplate}
            propModules={modulesData}
            propPositions={positionsData}
            isTemplatePrivate={isTemplatePrivate}
            setTemplatePrivate={setTemplatePrivate}
            isAuthorEditing={user?.name === template?.createdBy}
          />
        </S.LeftPanel>
      </S.TemplateFormContainer>
    </Container>
  );
};
