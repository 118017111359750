import { AxiosResponse } from 'axios';

const getFilenameFromHeaders = (response: AxiosResponse<any>): string =>
  response.headers['content-disposition'].split('filename=')[1].split(';')[0];

export const exportFile = (response: AxiosResponse<any>, fileTitle?: string) => {
  const fileName = fileTitle || getFilenameFromHeaders(response);
  const url = window.URL.createObjectURL(new Blob([response.request.response]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(url);
};
