import { useEffect } from 'react';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import { useUserContext } from 'context';
import * as URL from 'router/url';
import { AppContainerProps } from './AppContainer.types';
import * as S from './AppContainer.css';

const appUrls = [
  URL.TODO,
  URL.ENDED_RECRUITMENT,
  URL.ENDED_RECRUITMENT_ID,
  URL.QUESTIONS,
  URL.TEMPLATES,
  URL.TEMPLATES_ID,
  URL.ADD_TEMPLATE,
  URL.EDIT_TEMPLATE_ID,
  URL.RECRUITMENT_ID,
  URL.ADJUST_RECRUITMENT_ID,
  URL.EDITABLE_GENERATED_RECRUITMENT_ID,
  URL.GENERATED_RECRUITMENT_ID,
  URL.REPORTS,
];

const AppContainer = ({ routes }: AppContainerProps) => {
  const { replace } = useHistory();
  const { pathname, search } = useLocation();
  const { user } = useUserContext();

  useEffect(() => {
    if (appUrls.some((el) => !!matchPath(pathname, el))) {
      replace(pathname + search);
    } else {
      replace(URL.TODO);
    }
  }, [replace, pathname, search, user?.roles]);

  return <S.AppPageContainer>{routes()}</S.AppPageContainer>;
};

export default AppContainer;
