declare global {
  interface Window {
    env: any;
  }
}

export interface Base {
  id: number;
  name: string;
}

export interface Person extends Base {
  surname: string;
}

export enum SortOrder {
  Desc = 'desc',
  Asc = 'asc',
}
