import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary,
  FormControlLabel as MuiFormControlLabel,
} from '@material-ui/core';
import styled from 'styled-components';
import theme from 'theme';

export const Main = styled.div`
  display: flex;
  margin-bottom: 10px;
  width: 75%;
`;

export const FormControlLabel = styled(MuiFormControlLabel)`
width: 15%;
  &.MuiFormControlLabel-root {
    margin: -3px;
  }
`;

export const ScoreLabel = styled.span`
  margin-left: 10px;
  margin-bottom: 5px;
`;

export const BoldText = styled.span`
  font-weight: bold;
  margin-left: 10px;
  margin-bottom: 10px;
`;
export const QuestionValues = styled.div`
  display: flex;
  justify-content: space-between;
  width: 25%;
  margin-right: 60px;
`;

export const QuestionScore = styled.div`
  padding-top: 15px;
  margin-left: 15px;
`;

export const Accordion = styled(MuiAccordion)`
  &.MuiAccordion-root {
    transition: width 0.1s;
    width: 95%;
    padding: 0;
    .Mui-disabled {
      background-color: rgba(255, 255, 255, 1);
      color: black;
      opacity: 1;
    }
  }
`;

export const AccordionDetails = styled(MuiAccordionDetails)`
  &.MuiAccordionDetails-root {
    display: flex;
    flex-direction: column;
  }
`;

export const Summary = styled(AccordionSummary)`
  &.MuiAccordionSummary-root {
    width: 100%;
    margin: 0;
    padding: 0;
    padding-right: 24px;
    display: flex;
  }
`;

export const TextArea = styled.textarea`
  width: 100%;
  min-height: 150px;
  resize: none;
  background-color: ${theme.palette.secondary.light};
  border: none;
  box-shadow: inset 1px 3px 4px lightgray;
  font-size: large;

  &:focus {
    outline-color: ${theme.palette.primary.main};
  }
`;

export const Inner = styled.div`
  padding: 5px;
  width: 100%;
  display: flex;
`;

export const QuestionTypeIcon = styled.img`
  margin: auto 10px;
  filter: brightness(0.8);
`;

export const Icon = styled.img`
  margin: auto 5px auto 10px;
  filter: brightness(0.8);
`;

export const QuestionText = styled.div`
  margin: auto;
  margin-left: 0;
  word-break: keep-all;
`;

export const CriteriaTitle = styled.div`
  margin-left: 5%;
  margin-top: 3vh;
  font-weight: bold;
`;

export const CriteriaContainer = styled.div`
  width: 50%;
  word-break: break-word;
`;

export const NoteContainer = styled.div`
  margin-left: 5%;
  margin-right: 5%;
  min-height: 150px;
  resize: none;
  background-color: ${theme.palette.secondary.light};
  border: none;
  box-shadow: inset 1px 3px 4px lightgray;
  font-size: large;
`;

export const Note = styled.p`
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 5px;
`;
