import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import theme from 'theme';

export const NavbarContainer = styled.div`
  background-color: black;
  height: 60px;
  position: sticky;
  top: 0;
  display: flex;
  z-index: 999;
  justify-content: center;
  padding-left: 16px;
  padding-right: 16px;
`;

export const RecruitmentNavbarContainer = styled.div`
  background-color: black;
  color: white;
  position: sticky;
  height: 60px;
  top: 0;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 40px;
  padding-left: 40px;
`;

export const Navbar = styled.div`
  width: 1440px;
  display: flex;
  flex-direction: row;
  color: white;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const NavbarElem = styled.div``;

export const PauseButton = styled.button`
  background-color: black;
  color: white;
  border: none;
  display: flex;
  height: 100%;
  &:hover {
    color: rgb(240, 240, 240);
    cursor: pointer;
  }
`;

export const ExitIcon = styled.img`
  margin-left: 10px;
  font-size: 30px;
`;

export const LogoutButton = styled.div`
  font-size: 20px;
  font-weight: 300;
  &:hover {
    color: #ffffffcc;
    cursor: pointer;
  }
`;

export const StyledLink = styled(Link)<{ $isActive: boolean }>`
  text-decoration: None;
  color: ${(props) => (props.$isActive ? theme.palette.primary.main : 'white')};
  font-size: 20px;
  text-transform: uppercase;
  &:hover {
    color: ${theme.palette.primary.main};
  }
`;

export const NavbarTitle = styled.p`
  font-size: 20px;
  font-weight: lighter;
  line-height: 24px;
`;

export const Logo = styled.img<{ $clickable?: boolean }>`
  height: 40px;
  width: auto;
  margin-right: 30px;
  ${({ $clickable }) =>
    $clickable &&
    css`
      cursor: pointer;
    `}
`;
