import { ControlPoint } from '@material-ui/icons';
import styled from 'styled-components';

export const RightButtons = styled.div`
  display: flex;
  justify-content: flex-end;

  & > button {
    margin-left: 10px;
  }
`;

export const ButtonIcon = styled(ControlPoint)<{ $isModule?: boolean }>`
  &.MuiSvgIcon-root {
    color: ${(props) => (props.$isModule ? 'white' : 'black')};
  }
`;
