import { Reducer, useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ModuleService, QuestionService } from 'api';
import ModuleList from 'pages/Questions/components/ModuleList';
import QuestionList from 'pages/Questions/components/QuestionList';
import { ChosenModule, Module } from 'types/module';
import { Question, UpdateQuestion } from 'types/question';
import Pagination from 'components/Pagination';
import { Picker } from 'components/Picker';
import { SearchBar } from 'components/SearchBar';
import { handleException } from 'utils/errorHandlingUtils';
import { getDate } from 'utils/formatters';
import { useIsMount, usePagination, useQueryParams } from 'utils/hooks';
import { ChosenModules } from './components/ChosenModules';
import { QuestionButtons } from './components/QuestionButtons';
import { Actions, reducer, State } from './QuestionsPage.reducer';
import {
  createModuleParams,
  escapeAmpersand,
  fixModuleParam,
  mapToCriterion,
} from './utils';
import * as S from './QuestionsPage.css';

const QuestionsPage = () => {
  const { push } = useHistory();
  const queryParams = useQueryParams();

  const [modules, modulesDispatch] = useReducer<Reducer<State, Actions>>(reducer, []);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [searchedQuestion, setSearchedQuestion] = useState(queryParams.search || '');
  const [chosenModules, setChosenModules] = useState<ChosenModule[]>([]);

  const urlParams = `${new URLSearchParams({
    ...(searchedQuestion && { search: queryParams.search }),
    ...(queryParams.perPage &&
      queryParams.perPage !== '10' && { perPage: queryParams.perPage }),
  })}`;

  const isMount = useIsMount();

  const { getModules, isLoadingModules } = ModuleService.useGetModules();

  const { getQuestions, isLoadingQuestionsData } = QuestionService.useGetQuestions();

  const isFetching = isLoadingModules || isLoadingQuestionsData;

  const renderPagination = !isFetching && questions.length > 0;

  const { paginationOptions, setPaginationOptions } = usePagination();

  const filteredModules = useMemo(() => {
    if (chosenModules.length) {
      const chosenModulesIds = chosenModules.map(({ id }) => id);
      return modules.filter(({ id }: Module) => chosenModulesIds.includes(id));
    }
    return modules;
  }, [modules, chosenModules]);

  const moduleNames = modules.map(({ id, name }) => ({ id, name }));

  const fetchQuestions = useCallback(async () => {
    if (paginationOptions.shouldFetch) {
      try {
        const { data } = await getQuestions({
          moduleIds: chosenModules.map(({ id }) => id),
          text: searchedQuestion,
          page: paginationOptions.page,
          perPage: paginationOptions.perPage,
        });

        setQuestions(
          data.data.map((question: any) => ({
            ...question,
            criteria: question.criteria && mapToCriterion(question.criteria),
            updatedAt: getDate(question.updatedAt),
          })),
        );
        setPaginationOptions((prevState) => ({
          ...prevState,
          count: data.numberOfQuestions + data.numberOfTasks,
          shouldFetch: false,
        }));
      } catch (e) {
        handleException(e);
      }
    }
  }, [
    paginationOptions.shouldFetch,
    paginationOptions.page,
    paginationOptions.perPage,
    getQuestions,
    chosenModules,
    searchedQuestion,
    setPaginationOptions,
  ]);

  const fetchModules = useCallback(async () => {
    try {
      const {
        data: { data },
      } = await getModules();

      modulesDispatch({
        type: 'addModules',
        modules: data.map((module) => ({
          ...module,
          questions: [],
          updatedAt: getDate(module.updatedAt),
        })),
      });
    } catch (e) {
      handleException(e);
    }
  }, [getModules]);

  useEffect(() => {
    fetchModules();
  }, [fetchModules]);

  useEffect(() => {
    if (modules) {
      setChosenModules(
        modules.filter(({ name }) =>
          Array.isArray(queryParams.module)
            ? queryParams.module.includes(name)
            : name === queryParams.module,
        ),
      );
    }
  }, [modules, queryParams.module]);

  useEffect(() => {
    if (!queryParams.search) {
      setSearchedQuestion('');
    }
  }, [queryParams.search]);

  useEffect(() => {
    if (isMount) {
      if (searchedQuestion.length) {
        fetchQuestions();
      } else {
        setQuestions([]);
      }
    }
  }, [fetchQuestions, searchedQuestion, isMount]);

  function toggleModuleView(module: ChosenModule) {
    setChosenModules([module]);
    setSearchedQuestion('');
    push(`/questions?module=${escapeAmpersand(module.name)}`);
  }

  function clearChosenModules() {
    setChosenModules([]);
    push({
      pathname: '/questions',
      search: urlParams,
    });
  }

  function handleDeleteChosenModule(moduleId: number) {
    const newChosenModules = chosenModules.filter(({ id }) => id !== moduleId);
    setChosenModules(newChosenModules);

    const moduleParams = createModuleParams(newChosenModules, !urlParams.length);
    push({
      pathname: '/questions',
      search: `${urlParams}${newChosenModules.length ? moduleParams : ''}`,
    });
  }

  function getModuleName(moduleId: number) {
    return modules.find(({ id }) => id === moduleId)!.name;
  }

  function deleteQuestion(question: Question) {
    deleteQuestionFromModule(question);

    if (searchedQuestion.length) {
      delete queryParams.page;
      push({
        pathname: '/questions',
        search: `${new URLSearchParams({
          ...queryParams,
        })}`,
      });
      setPaginationOptions((prevState) => ({ ...prevState, shouldFetch: true }));
    }
  }

  function addQuestion(question: Question) {
    addQuestionToModule({
      ...question,
      moduleName: getModuleName(question.moduleId),
    });
    if (searchedQuestion.length) {
      setPaginationOptions((prevState) => ({
        ...prevState,
        page: 0,
        shouldFetch: true,
      }));
    }
  }

  function handleSearchQuestion(search: string) {
    setSearchedQuestion(search);

    delete queryParams.page;
    if (!search) {
      delete queryParams.perPage;
      delete queryParams.search;
    }

    const query = new URLSearchParams({
      ...queryParams,
      ...(search && { search }),
    });

    push({
      pathname: '/questions',
      search: `?${
        queryParams.module ? fixModuleParam(query, queryParams.module) : query
      }`,
    });
  }

  function handleChooseModules(value: ChosenModule[]) {
    setChosenModules(value);
    const moduleParam = createModuleParams(value, !urlParams.length);
    push({
      pathname: '/questions',
      search: `${urlParams}${value.length ? moduleParam : ''}`,
    });
  }

  function updateQuestion() {
    setPaginationOptions({ ...paginationOptions, page: 0, shouldFetch: true });
  }

  function deleteModule(id: number) {
    modulesDispatch({ type: 'deleteModule', id });
    updatePathAfterModuleChange(id);
  }

  function updateQuestionInModule(question: Question, options: UpdateQuestion) {
    modulesDispatch({ type: 'updateQuestionInModule', question, options });
  }

  function deleteQuestionFromModule(question: Question) {
    modulesDispatch({ type: 'deleteQuestionFromModule', question });
  }

  function addQuestionToModule(question: Question) {
    modulesDispatch({ type: 'addQuestionToModule', question });
  }

  function addQuestionsToModule(questionsToAdd: Question[], id: number) {
    modulesDispatch({
      type: 'addQuestionsToModule',
      id,
      questions: questionsToAdd,
    });
  }

  function updateModule(module: Module) {
    window.scrollTo(0, 0);
    modulesDispatch({ type: 'updateModule', module });
    updatePathAfterModuleChange(module.id);
  }

  function addModule(module: Module) {
    modulesDispatch({ type: 'addModule', module });
  }

  const updatePathAfterModuleChange = (id: number) => {
    const moduleParam = createModuleParams(
      chosenModules.filter((module) => module.id !== id),
      !urlParams.length,
    );
    push({
      pathname: '/questions',
      search: `${urlParams}${chosenModules.length ? moduleParam : ''}`,
    });
  };

  return (
    <S.QuestionPageContainer>
      <S.AddStripe>
        <S.LeftActionsBox>
          <SearchBar defaultValue={queryParams.search} onChange={handleSearchQuestion} />
          <Picker
            modules={moduleNames}
            chosenModules={chosenModules}
            setChosenModules={handleChooseModules}
          />
        </S.LeftActionsBox>
        <QuestionButtons
          addModule={addModule}
          addQuestion={addQuestion}
          moduleNames={moduleNames}
        />
      </S.AddStripe>
      <ChosenModules
        chosenModules={chosenModules}
        handleDeleteChosenModule={handleDeleteChosenModule}
        clearChosenModules={clearChosenModules}
      />
      {searchedQuestion.length ? (
        <>
          {renderPagination && <Pagination paginationOptions={paginationOptions} />}
          <QuestionList
            isFetching={isFetching}
            questions={questions}
            deleteQuestion={deleteQuestion}
            updateQuestion={updateQuestion}
            toggleModuleView={toggleModuleView}
            moduleNames={moduleNames}
            displayModuleName
          />
        </>
      ) : (
        <ModuleList
          modules={filteredModules}
          moduleNames={moduleNames}
          isFetching={isFetching}
          deleteModule={deleteModule}
          addQuestionsToModule={addQuestionsToModule}
          deleteQuestion={deleteQuestion}
          updateQuestion={updateQuestionInModule}
          updateModule={updateModule}
          addQuestion={addQuestion}
        />
      )}
    </S.QuestionPageContainer>
  );
};

export default QuestionsPage;
