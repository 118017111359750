import { Accordion, Box, Collapse, IconButton } from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import styled from 'styled-components';

export const EditIcon = styled(Edit)`
  & {
    margin-right: 8px;
    width: 20px;
    padding: 4px;
  }
`;

export const QuestionRow = styled(Accordion)`
  width: 100%;
  border: 1px solid rgb(230, 230, 230);
  margin-bottom: 10px;
  word-break: break-word;
`;

export const QuestionBasicInfo = styled.div`
  width: 100%;
  display: flex;
`;

export const BoldText = styled.span`
  font-weight: bold;
`;

export const QuestionText = styled.div`
  width: 60%;
  margin: auto;
  margin-left: 0;
  white-space: pre-wrap;
`;

export const CriteriaContainer = styled(Box)`
  width: 100%;
  margin-left: 40px;
`;

export const ActionBar = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  &:hover {
    background-color: rgba(237, 237, 237, 1);
    cursor: pointer;
  }
`;

export const IconText = styled.span`
  margin-right: 8px;
`;

export const QuestionTypeIcon = styled.img`
  margin: auto 10px auto 10px;
`;

export const CollapsibleCell = styled(Collapse)`
  width: 100%;
`;

export const CenteredIconButton = styled(IconButton)`
  height: 30px;
  margin: auto;
`;

export const QuestionRowElem = styled.div`
  width: 20%;
  margin: auto;
`;

export const QuestionModuleTile = styled.div`
  margin: auto;
  font-size: 16px;
  padding: 2px 8px 2px 8px;
  border-radius: 10px;
  width: fit-content;
  word-break: break-word;
  background-color: rgba(237, 237, 237, 1);
  text-align: center;

  &:hover {
    background-color: rgb(220, 220, 220);
  }
`;

export const LastModified = styled.div`
  margin: auto;
  margin-right: 20px;
`;
