import { ChangeEvent, MouseEvent, useState } from 'react';
import ArrowDown from '@material-ui/icons/KeyboardArrowDown';
import { AutocompleteCloseReason } from '@material-ui/lab/Autocomplete';
import { ChosenModule } from 'types/module';
import { PickerProps } from './Picker.types';
import * as S from './Picker.css';

export const Picker = ({ modules, chosenModules, setChosenModules }: PickerProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [pendingValue, setPendingValue] = useState<ChosenModule[]>([]);
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setPendingValue(chosenModules);
    setAnchorEl(event.currentTarget);
    setIsOpen(true);
  };

  const handleClose = (event: ChangeEvent<{}>, reason: AutocompleteCloseReason) => {
    if (reason === 'toggleInput') {
      return;
    }
    setChosenModules(pendingValue);
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
    setIsOpen(false);
  };

  const sortedChosenModules = chosenModules.sort((a, b) => a.name.localeCompare(b.name));

  const sortedPickerOptions = [
    ...sortedChosenModules,
    ...modules
      .filter((module) => !chosenModules.map(({ id }) => id).includes(module.id))
      .sort((a, b) => a.name.localeCompare(b.name)),
  ];

  return (
    <>
      <S.PopperContainer>
        <S.StyledButton onClick={handleClick}>
          Select modules
          <ArrowDown />
        </S.StyledButton>
      </S.PopperContainer>
      <S.StyledPopper open={isOpen} anchorEl={anchorEl} placement="bottom-start">
        <S.StyledAutocomplete
          open
          onClose={handleClose}
          multiple
          value={pendingValue}
          onChange={(_, newValue) => {
            setPendingValue(newValue);
          }}
          disableCloseOnSelect
          disablePortal
          noOptionsText="No modules"
          renderOption={({ name }, { selected }) => (
            <>
              <S.StyledDoneIcon selected={selected} data-testid="done-icon" />
              <S.StyledText>{name}</S.StyledText>
            </>
          )}
          options={sortedPickerOptions}
          getOptionLabel={({ name }) => name}
          renderInput={(params) => (
            <S.StyledInput
              ref={params.InputProps.ref}
              inputProps={params.inputProps}
              autoFocus
            />
          )}
        />
      </S.StyledPopper>
    </>
  );
};
