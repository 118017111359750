import { RecruitmentSummary } from 'types/recruitment';
import { useAxios } from './axios';

export const useGetRecruitmentSummary = (id: string) => {
  const [{ data, loading: isLoadingRecruitmentSummary }, getRecruitmentSummary] =
    useAxios<{
      data: RecruitmentSummary;
    }>({ method: 'GET', url: `/recruitments/summary/${id}` }, { manual: true });

  return {
    recruitmentSummaryData: data?.data,
    isLoadingRecruitmentSummary,
    getRecruitmentSummary,
  };
};

export const useUpdateRecruitmentSummaryModule = (recruitmentModuleSummaryId: number) => {
  const [, updateRecruitmentSummaryModule] = useAxios(
    {
      method: 'PATCH',
      url: `/recruitments/modules/summary/${recruitmentModuleSummaryId}`,
    },
    { manual: true },
  );

  return updateRecruitmentSummaryModule;
};

export const usePatchRecruitmentSummary = (id?: number | string) => {
  const [{ loading: isPatchingRecruitmentSummary }, patchRecruitmentSummary] = useAxios(
    {
      url: `/recruitments/summary/${id}`,
      method: 'PATCH',
    },
    { manual: true },
  );

  return { patchRecruitmentSummary, isPatchingRecruitmentSummary };
};
