import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ClickAwayListener, Popper, PopperProps, TextField } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutline from '@material-ui/icons/RemoveCircleOutline';
import { Autocomplete } from '@material-ui/lab';
import { QuestionService, RecruitmentService } from 'api';
import { AxiosResponse } from 'axios';
import { Actions } from 'pages/Recruitment/modulesState';
import { Module } from 'types/module';
import { Question, RecruitmentQuestion } from 'types/question';
import { RecruitmentModule } from 'types/recruitment';
import { handleException } from 'utils/errorHandlingUtils';
import { RouteParams } from 'utils/types/RouteParams';
import * as S from './AddRecruitmentQuestion.css';

type Props = {
  recruitmentModule: RecruitmentModule;
  modules: Module[];
  questions: RecruitmentQuestion[];
  dispatch: (value: Actions) => void;
};

const CustomPopper = (props: PopperProps) => (
  <Popper {...props} className={S.popperStyles().root} placement="bottom" />
);

export const AddRecruitmentQuestion = ({
  recruitmentModule,
  modules,
  questions,
  dispatch,
}: Props) => {
  const { id } = useParams<RouteParams>();
  const [isInputShown, setIsInputShown] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState<Question | null>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const { getQuestions, questionsData } = QuestionService.useGetQuestions();
  const { createRecruitmentQuestionsAsync } =
    RecruitmentService.useCreateRecruitmentQuestions();
  const sortRecruitmentQuestions = RecruitmentService.useSortRecruitmentQuestions(
    recruitmentModule.id,
  );

  const questionAutocompleteOptions = useMemo(() => {
    const usedQuestionsIds = questions.map(({ question }) => question.id);
    const filteredQuestions = (questionsData as Question[]).filter(
      (question) => !usedQuestionsIds.includes(question.id),
    );
    return filteredQuestions;
  }, [questions, questionsData]);

  const module = modules.find(({ name }) => recruitmentModule.module.name === name)!;

  const onChange = (question: Question | null) => {
    if (question) setSelectedQuestion(question);
    else setSelectedQuestion(null);
  };

  const onSubmit = async () => {
    if (id && selectedQuestion) {
      const payload = {
        recruitmentModules: [
          {
            id: recruitmentModule.id,
            questionsId: [selectedQuestion.id],
          },
        ],
      };
      try {
        const response: AxiosResponse<{ data: RecruitmentQuestion[] }> =
          await createRecruitmentQuestionsAsync(+id, payload);
        setSelectedQuestion(null);
        const addedQuestions = response.data.data;
        dispatch({
          type: 'addQuestions',
          questions: addedQuestions,
          moduleId: recruitmentModule.id,
        });
        await sortRecruitmentQuestions({
          data: {
            ids_list: [...questions, ...addedQuestions].map((q) => q.id),
          },
        });
        dispatch({
          type: 'setRecruitmentQuestions',
          recruitmentQuestions: [...questions, ...addedQuestions],
        });
      } catch (e) {
        handleException(e);
      }
    }
  };

  useEffect(() => {
    const getQuestionsData = async () => {
      const data = await getQuestions({
        moduleIds: [module.id],
        perPage: module.numberOfQuestions + module.numberOfTasks,
      });
      return data;
    };
    getQuestionsData();
  }, [getQuestions, module?.id, module?.numberOfQuestions, module?.numberOfTasks]);

  return (
    <S.AddQuestionContainer>
      {!isInputShown && (
        <S.AddQuestionLabel onClick={() => setIsInputShown(true)}>
          <S.ActionButton>
            <AddCircleOutlineIcon />
          </S.ActionButton>
          Add question
        </S.AddQuestionLabel>
      )}
      {isInputShown && (
        <S.ActionContainer>
          <S.ActionButton onClick={() => setIsInputShown(false)}>
            <RemoveCircleOutline />
          </S.ActionButton>
          <S.AddQuestionInputWrapper>
            <ClickAwayListener onClickAway={() => setIsDropdownOpen(false)}>
              <Autocomplete
                PopperComponent={CustomPopper}
                getOptionLabel={(option: Question) => option.text}
                onChange={(_, value) => onChange(value)}
                options={questionAutocompleteOptions}
                value={selectedQuestion}
                disabled={!recruitmentModule.module.name}
                blurOnSelect
                open={isDropdownOpen}
                onOpen={() => setIsDropdownOpen(true)}
                onClose={() => setIsDropdownOpen(false)}
                onBlur={() => setIsDropdownOpen(false)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Start typing or select"
                    size="small"
                    multiline
                  />
                )}
              />
            </ClickAwayListener>
          </S.AddQuestionInputWrapper>
          <S.SubmitButton onClick={onSubmit} disabled={!selectedQuestion}>
            Submit
          </S.SubmitButton>
        </S.ActionContainer>
      )}
    </S.AddQuestionContainer>
  );
};
