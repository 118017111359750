import { useAxios } from './axios';

export const useGetCustomReport = () => {
  const [, getXlsx] = useAxios({ method: 'GET', responseType: 'blob' }, { manual: true });

  const getCustomReport = (dateFrom: string, dateTo: string) => {
    const url = `/reports`;
    return getXlsx({
      url,
      params: {
        dateFrom,
        dateTo,
      },
    });
  };

  return { getCustomReport };
};
