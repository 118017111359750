import { toast } from 'react-toastify';
import { MAX_CRITERIA_LENGTH } from 'pages/Questions/components/QuestionForm/QuestionForm.const';
import { Base, SortOrder } from 'types/common';
import { Module } from 'types/module';
import { Criterion } from 'types/question';
import { handleException } from 'utils/errorHandlingUtils';

export function criteriaInput(criteria: Criterion[] | undefined): Criterion[] {
  if (criteria?.length) {
    const id = criteria[criteria.length - 1].id + 1;
    return criteria.length === MAX_CRITERIA_LENGTH
      ? criteria
      : criteria.concat([{ id, name: '' }]);
  }
  return [{ id: 0, name: '' }];
}

export function sortModulesByName(module: Module[] | Base[]) {
  return module.sort((a, b) => a.name.localeCompare(b.name));
}

export const reverseDate = (date: string) => date.split('-').reverse().join('-');

export const isNumber = (value: string) => /^\d+$/.test(value);

export const removeExtraSpaces = (s: string) => s.replace(/\s+/g, ' ').trim();

export const stringIsEmpty = (s: string) => /^\s*$/.test(s);

export const copyToClipboard = async (text: string) => {
  try {
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(text);
      toast.info('Copied to clipboard', { autoClose: 1500 });
    }
  } catch (e) {
    handleException(e);
  }
};

export const getSortOrder = (order: string | undefined, initialValue: SortOrder) => {
  if (!order) return initialValue;
  return order === SortOrder.Asc ? SortOrder.Desc : SortOrder.Asc;
};
