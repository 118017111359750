import { useEffect, useState } from 'react';
import { Box, Checkbox, Dialog } from '@material-ui/core';
import { NAME_LIMIT, POSITION_LIMIT } from 'pages/Templates/components/const';
import { TemplateNameTextField } from 'pages/Templates/components/TemplateNameTextField';
import { TemplatePositionAutocomplete } from 'pages/Templates/components/TemplatePositionAutocomplete';
import { removeExtraSpaces, stringIsEmpty } from 'utils/helpers';
import { SaveAsTemplateDialogProps } from './SaveAsTemplateDialog.types';
import * as S from './SaveAsTemplateDialog.css';

export const SaveAsTemplateDialog = ({
  isOpen,
  onClose,
  onSubmit,
  positions,
}: SaveAsTemplateDialogProps) => {
  const [name, setName] = useState('');
  const [position, setPosition] = useState('');
  const [isPrivate, setPrivate] = useState(false);

  const [errors, setErrors] = useState({
    name: false,
    position: false,
  });

  useEffect(() => {
    setErrors({
      position: removeExtraSpaces(position).length > POSITION_LIMIT,
      name: removeExtraSpaces(name).length > NAME_LIMIT,
    });
  }, [name, position]);

  const isSubmitButtonDisabled =
    errors.name || errors.position || stringIsEmpty(position) || stringIsEmpty(name);

  return (
    <Dialog open={isOpen} fullWidth maxWidth="sm" onClose={onClose}>
      <S.DialogTitle>SAVE AS TEMPLATE</S.DialogTitle>
      <S.DialogContent>
        <S.Label>Name: </S.Label>
        <TemplateNameTextField
          name={name}
          setName={setName}
          isError={errors.name}
          fullWidth
        />
        <Box mt={3}>
          <S.Label>Position: </S.Label>
          <TemplatePositionAutocomplete
            position={position}
            setPosition={setPosition}
            isError={errors.position}
            positions={positions}
            fullWidth
          />
        </Box>
        <Box mt={3}>
          <Checkbox
            style={{ paddingLeft: '0' }}
            color="primary"
            value={isPrivate}
            onChange={(e) => setPrivate(e.target.checked)}
          />
          Save as my template only
        </Box>
      </S.DialogContent>
      <S.DialogActions>
        <S.CancelButton onClick={onClose} color="default" variant="outlined">
          Cancel
        </S.CancelButton>
        <S.SaveButton
          onClick={() => onSubmit({ name, position, isPrivate })}
          variant="contained"
          disabled={isSubmitButtonDisabled}
        >
          Save
        </S.SaveButton>
      </S.DialogActions>
    </Dialog>
  );
};
