import { DateTimeInputProps } from './DateTimeInput.types';
import * as S from './DateTimeInput.css';

const DateTimeInput = ({
  name,
  autoFocus,
  label,
  onBlur,
  onChange,
  value,
  onFocus,
  onKeyDown,
  minDate,
  error = false,
}: DateTimeInputProps) => (
  <S.KeyboardDateTimePicker
    name={name}
    autoFocus={autoFocus}
    label={label}
    onChange={(date) => {
      if (onChange) {
        onChange(date);
      }
    }}
    onBlur={(_) => {
      if (onBlur) {
        onBlur();
      }
    }}
    ampm={false}
    invalidDateMessage=""
    maxDateMessage=""
    minDateMessage=""
    margin="dense"
    inputVariant="outlined"
    value={value}
    clearable
    format="dd.MM.yyyy, HH:mm"
    inputProps={{
      name,
    }}
    InputAdornmentProps={{
      style: {
        display: 'inherit',
      },
    }}
    KeyboardButtonProps={{
      style: {
        display: 'inherit',
      },
    }}
    onFocus={onFocus}
    style={{ textTransform: 'capitalize', width: '100%' }}
    onKeyDown={onKeyDown}
    placeholder="DD.MM.YYYY, HH:MM"
    minDate={minDate}
    fullWidth
    autoComplete="off"
    error={error}
  />
);

export default DateTimeInput;
