import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { RecruitmentService } from 'api';
import { useUserContext } from 'context';
import { MyRecruitmentsFilter } from 'components/Filters/MyRecruitmentsFilter';
import {
  MAX_RECRUITMENT_SCORE,
  MIN_RECRUITMENT_SCORE,
} from 'components/Filters/RecruitmentFilters/RecruitmentFilters.const';
import Pagination from 'components/Pagination';
import { SearchBar } from 'components/SearchBar';
import { handleException } from 'utils/errorHandlingUtils';
import { usePagination, useQueryParams } from 'utils/hooks';
import { EndedRecruitmentTable } from './components/EndedRecruitmentTable';
import * as S from './EndedPage.css';

const EndedPage = () => {
  const { paginationOptions, setPaginationOptions } = usePagination();
  const { user } = useUserContext();

  const { recruitmentsData, getRecruitments, isLoadingRecruitmentsData } =
    RecruitmentService.useGetRecruitments({ ended: true });

  const queryParams = useQueryParams();

  const [filtersCount, setFiltersCount] = useState(0);

  const { push } = useHistory();

  const fetchRecruitments = useCallback(async () => {
    if (paginationOptions.shouldFetch) {
      try {
        const { data } = await getRecruitments({
          ...queryParams,
          page: paginationOptions.page,
          perPage: paginationOptions.perPage,
        });
        setPaginationOptions((prevState) => ({
          ...prevState,
          count: data.totalEndedRecruitments + data.totalNotEndedRecruitments,
          shouldFetch: false,
        }));
      } catch (e) {
        handleException(e);
      }
    }
  }, [
    paginationOptions.shouldFetch,
    paginationOptions.page,
    paginationOptions.perPage,
    getRecruitments,
    queryParams,
    setPaginationOptions,
  ]);

  useEffect(() => {
    setFiltersCount(
      [
        queryParams.position,
        queryParams.hrContact,
        queryParams.recruiter,
        queryParams.dateFrom,
        queryParams.dateTo,
        queryParams.name,
        Number(queryParams.minScore) > MIN_RECRUITMENT_SCORE ||
          Number(queryParams.maxScore) < MAX_RECRUITMENT_SCORE,
      ].filter(Boolean).length,
    );
  }, [
    queryParams.dateFrom,
    queryParams.dateTo,
    queryParams.hrContact,
    queryParams.maxScore,
    queryParams.minScore,
    queryParams.name,
    queryParams.position,
    queryParams.recruiter,
  ]);

  useEffect(() => {
    if (paginationOptions.shouldFetch) {
      fetchRecruitments();
      window.scrollTo(0, 0);
    }
  }, [fetchRecruitments, paginationOptions.shouldFetch]);

  const handleSearchChange = (search: string) => {
    delete queryParams.page;
    if (!search) {
      delete queryParams.search;
    }
    push({
      pathname: '/ended-recruitment',
      search: `${new URLSearchParams({
        ...queryParams,
        ...(search && { search }),
      })}`,
    });
  };

  return (
    <S.EndedPageContainer>
      <S.Actionbar>
        <SearchBar defaultValue={queryParams.search} onChange={handleSearchChange} />
        <MyRecruitmentsFilter
          redirectTo="ended-recruitment"
          currentUser={user}
          queryParams={queryParams}
        />
      </S.Actionbar>
      <Pagination paginationOptions={paginationOptions} />
      <EndedRecruitmentTable
        isLoadingRecruitmentsData={isLoadingRecruitmentsData}
        setPaginationOptions={setPaginationOptions}
        recruitments={recruitmentsData}
        filtersCount={filtersCount}
      />
    </S.EndedPageContainer>
  );
};

export default EndedPage;
