import { Dialog } from '@material-ui/core';
import { DeleteDialogProps } from './DeleteDialog.types';
import * as S from './DeleteDialog.css';

const DeleteDialog = ({
  content,
  onClose,
  open,
  handleClick,
  title,
  buttonLabel = 'Delete',
}: DeleteDialogProps) => (
  <Dialog open={open} fullWidth maxWidth="sm" onClose={onClose}>
    <S.DialogTitle>{title}</S.DialogTitle>
    <S.DialogContent>
      <S.Label>{content}</S.Label>
      <S.Label>Are you sure?</S.Label>
    </S.DialogContent>
    <S.DialogActions>
      <S.CancelButton onClick={onClose} color="default" variant="outlined">
        Cancel
      </S.CancelButton>
      <S.DeleteButton onClick={handleClick} variant="contained">
        {buttonLabel}
      </S.DeleteButton>
    </S.DialogActions>
  </Dialog>
);

export default DeleteDialog;
