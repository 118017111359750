import { ChosenModule } from 'types/module';
import { Criterion } from 'types/question';

export function escapeAmpersand(value: string) {
  return value.replace(/&/g, '%26');
}

export function mapToCriterion(criteria: string[]): Criterion[] {
  return criteria.map((name, id) => ({ name, id }));
}

export function createModuleParams(modules: ChosenModule[], isOnlyParam: boolean) {
  const moduleName = (name: string) => `module=${escapeAmpersand(name)}`;
  return `${isOnlyParam ? '?' : '&'}${modules
    .map(({ name }) => moduleName(name))
    .join('&')}`;
}

export function fixModuleParam(
  searchParams: URLSearchParams,
  modules: string | string[],
) {
  searchParams.delete('module');

  if (Array.isArray(modules)) {
    modules.forEach((module) => searchParams.append('module', module));
  } else if (modules) {
    searchParams.append('module', modules);
  }

  return searchParams;
}
