import {
  Table as MuiTable,
  TableCell as MuiTableCell,
  TableHead as MuiTableHead,
} from '@material-ui/core';
import MuiLockIcon from '@material-ui/icons/Lock';
import styled from 'styled-components';
import { Button } from 'styles.global';

export {
  PopoverOptions,
  PopoverOption,
  OptionsIcon,
} from 'components/Popover/Popover.css';

export { EmptyListImage } from 'styles.global';

export const Table = styled(MuiTable)`
  &.MuiTable-root {
    word-break: break-word;
  }
`;

export const BoldTableCell = styled(MuiTableCell)`
  &.MuiTableCell-root {
    font-weight: bold;
  }
`;

export const TableCell = styled(MuiTableCell)<{ $cellWidth: string }>`
  &.MuiTableCell-root {
    width: ${({ $cellWidth }) => $cellWidth};
  }
`;

export const TableHead = styled(MuiTableHead)`
  border-bottom: 1.5px black solid;
`;

export const ViewButton = styled(Button).attrs({ $secondary: true })`
  &.MuiButton-root {
    margin-right: 10px;
  }
`;

export const BoldText = styled.span`
  font-weight: bold;
`;

export const LockIcon = styled(MuiLockIcon)`
  &.MuiSvgIcon-root {
    margin-right: 10px;
    font-size: 20px;
  }
`;

export const NotFoundContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
`;
