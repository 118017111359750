import { Module } from 'types/module';
import { Question, RecruitmentQuestion } from 'types/question';
import { Base, Person } from './common';

export interface HRContact extends Person {}
export interface Recruiter extends Person {}
export interface Candidate extends Person {}

export interface Position {
  id: number;
  positionName: string;
}

export interface RecruitmentModuleQuestion {
  id: number;
  text: string;
  questionId: number | null;
  isPractical: boolean | null;
  criteria?: string[];
  grade?: number;
  maxGrade?: number;
}

export enum RecruitmentState {
  Formed = 'formed',
  Adjusted = 'adjusted',
  InProgress = 'in progress',
  Ended = 'ended',
  Completed = 'completed',
  Cancelled = 'cancelled',
}

export enum HireState {
  Hire = 'hire',
  NotHire = 'not hire',
}

export interface AutogeneratedLink extends Base {
  createdAt: string;
  createdBy: string;
  hashId: string;
}

export interface Recruitment {
  id: number;
  candidateId: number;
  positionId: number;
  candidateName: string;
  position: Position;
  recruitmentDate: string;
  grade?: number;
  maxGrade?: number;
  technicalRecruiter: Person;
  remuneratedTechRecruiter?: Person | null;
  hr: Person;
  recruitmentModules: RecruitmentModule[];
  recruitmentState: RecruitmentState;
  seniorityLevel: string | null;
  cooperation: boolean;
  generalNote?: string;
  recruiterNote: string;
  independence: boolean;
  management: boolean;
  reviewed: boolean;
  hire: boolean | null;
  autogeneratedLinks: AutogeneratedLink[];
  cvFile: string | null;
  recruitmentCancellation: {
    canceler: string;
    reason: string;
  };
  recruitmentOfferLink: string | null;
}

export interface ShortRecruitmentModule {
  grade?: number;
  maxGrade?: number;
  module: Module;
}

export interface RecruitmentModuleSummary {
  note: string;
  id: number;
  recruitmentModule: ShortRecruitmentModule;
}

export interface ShortRecruitment {
  id: number;
  candidateName: string;
  grade?: number;
  maxGrade?: number;
  technicalRecruiter: Person;
  hr: Person;
  position: Position;
  seniorityLevel: string;
}

export interface RecruitmentSummary {
  cooperation: boolean;
  generalNote?: string;
  hire: boolean;
  id: number;
  independence: boolean;
  management: boolean;
  recruitmentModulesSummaries: RecruitmentModuleSummary[];
  recruitment: ShortRecruitment;
}

export interface RecruitmentModule {
  recruitmentChosenQuestions: {
    question: Question;
  }[];
  deleted: boolean;
  id: number;
  module: Module;
  grade?: number;
  maxGrade?: number;
  moduleId: number;
  numberOfRandomQuestions: number;
  numberOfRandomTasks: number;
  recruitmentId: number;
  recruitmentQuestions: RecruitmentQuestion[];
  note?: string;
}

export interface NewRecruitmentModule {
  chosenQuestionIds: number[];
  moduleId: number;
  numberOfRandomTasks: number;
  numberOfRandomQuestions: number;
}

export interface AdjustRecruitmentModule {
  id: number;
  moduleId: number | null;
  numberOfQuestions: number | null;
  numberOfTasks: number | null;
  moduleName: string | null;
  moduleDescription: string | null;
  questions: RecruitmentModuleQuestion[];
  selectedQuestions: RecruitmentModuleQuestion[];
  selectedNumberOfQuestions: number | null;
  selectedNumberOfTasks: number | null;
  availableNumberOfQuestions: number | null;
  availableNumberOfTasks: number | null;
  errors: {
    numberOfQuestions: boolean;
    numberOfTasks: boolean;
  };
  isUpdated: boolean;
}
