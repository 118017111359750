import { format } from 'date-fns';

export const getDate = (date: string | Date, displayFormat?: string) =>
  format(new Date(date), displayFormat || 'dd.MM.yyyy');

export const getTime = (date: string) => format(new Date(date), 'HH:mm');

export const getDateTime = (date: string | Date, displayFormat?: string) =>
  format(new Date(date), displayFormat || 'dd.MM.yyyy HH:mm');

// function that converts "01:30:30" to "90:30"
export const formatHMStoMS = (value: string) => {
  const splitted = value.split(':');
  const m = Number(splitted[0]) * 60 + Number(splitted[1]);
  const s = splitted[2];
  return `${m < 10 ? '0' : ''}${m}:${s}`;
};
