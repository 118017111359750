import { useEffect, useMemo, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { TemplateAutocomplete } from 'components/TemplateAutocomplete';
import { removeExtraSpaces } from 'utils/helpers';
import { NAME_LIMIT, POSITION_LIMIT } from '../const';
import { TemplateNameTextField } from '../TemplateNameTextField';
import { TemplatePositionAutocomplete } from '../TemplatePositionAutocomplete';
import { TemplateHeaderProps } from './TemplateHeader.types';
import * as S from './TemplateHeader.css';

export const TemplateHeader = ({
  title,
  name,
  position,
  setName,
  setPosition,
  selectedTemplate,
  handleTemplateChange,
  templateName = '',
  positionsProp,
  templatesProp,
}: TemplateHeaderProps) => {
  const [errors, setErrors] = useState({
    name: false,
    position: false,
  });

  const positions = useMemo(
    () => positionsProp.map(({ positionName }) => positionName),
    [positionsProp],
  );

  const templates = useMemo(
    () => templatesProp.map((t) => ({ id: t.id, name: t.name })),
    [templatesProp],
  );

  useEffect(() => {
    setErrors({
      position: removeExtraSpaces(position).length > POSITION_LIMIT,
      name: removeExtraSpaces(name).length > NAME_LIMIT,
    });
  }, [name, position]);

  return (
    <>
      <S.Header>{title}</S.Header>
      <Box display="flex" justifyContent="space-between">
        <Box marginRight="32px">
          <Box display="flex" justifyContent="space-between" width="400px">
            <Typography variant="h6"> Name: </Typography>
            <TemplateNameTextField name={name} setName={setName} isError={errors.name} />
          </Box>
          <Box display="flex" justifyContent="space-between" width="400px" mt={3}>
            <Typography variant="h6"> Position: </Typography>
            <TemplatePositionAutocomplete
              position={position}
              positions={positions}
              isError={errors.position}
              setPosition={setPosition}
            />
          </Box>
        </Box>
        <Box>
          <Box display="flex" flexDirection="column">
            <Box display="flex">
              <Typography variant="h6">Template:</Typography>
              <Box ml={3} width="50%">
                <TemplateAutocomplete
                  templates={templates.filter((t) => t.name !== templateName)}
                  selectedTemplate={selectedTemplate}
                  onTemplateChange={handleTemplateChange}
                />
              </Box>
            </Box>
            <Box width="70%" color="#7c7c7c" mt={2}>
              Use this option if you want to create/edit a template based on another
              user&apos;s template
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
