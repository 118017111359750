import { Loader, LoaderContainer } from 'styles.global';
import { ContainerProps } from './Container.types';

const Container = ({ isLoading, children }: ContainerProps) => (
  <div>
    {isLoading ? (
      <LoaderContainer data-testid="loaderContainer">
        <Loader size="100px" />
      </LoaderContainer>
    ) : (
      <div>{children}</div>
    )}
  </div>
);

export default Container;
