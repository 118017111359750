import { ChosenModulesProps } from './ChosenModules.types';
import * as S from './ChosenModules.css';

export const ChosenModules = ({
  chosenModules,
  handleDeleteChosenModule,
  clearChosenModules,
}: ChosenModulesProps) => (
  <>
    {chosenModules.length > 0 && (
      <S.ClearAll onClick={clearChosenModules}>Clear all</S.ClearAll>
    )}
    <S.ChosenModulesContainer>
      {chosenModules.map(({ id, name }) => (
        <S.ChosenModuleTile onClick={() => handleDeleteChosenModule(id)} key={id}>
          {name}
          <S.CrossIcon fontSize="small" />
        </S.ChosenModuleTile>
      ))}
    </S.ChosenModulesContainer>
  </>
);
