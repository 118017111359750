import { toast } from 'react-toastify';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { loginRequest } from 'authConfig';
import axios, { AxiosError } from 'axios';
import { makeUseAxios } from 'axios-hooks';
import convertToCamelCase from 'camelcase-keys';
import { msalInstance } from 'index';
import convertToSnakeCase from 'snakecase-keys';
import ErrorToast from 'components/Toasts/ErrorToast';

const axiosInstance = axios.create({
  baseURL: `/api/v1`,
});

// * before sending request convert body from camelCase to snake_case
axiosInstance.interceptors.request.use(
  (response) => ({
    ...response,
    data: response.data && convertToSnakeCase(response.data, { deep: true }),
    headers: {
      ...response.headers,
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  }),
  (error) => Promise.reject(error),
);

// * before forwarding request convert body from snake_case to camelCase
axiosInstance.interceptors.response.use(
  (response) => ({
    ...response,
    data: convertToCamelCase(response.data, { deep: true }),
  }),
  async (error: AxiosError) => {
    if (error.response && error.response.status === 401) {
      const originalRequest = error.config;
      const account = msalInstance.getActiveAccount();
      if (account) {
        const request = {
          ...loginRequest,
          account,
          forceRefresh: false,
        };
        msalInstance
          .acquireTokenSilent(request)
          .then((response) => {
            localStorage.setItem('token', response.accessToken);
          })
          .catch(async (msalError) => {
            await msalInstance.acquireTokenRedirect(request);

            if (msalError instanceof InteractionRequiredAuthError) {
              // fallback to interaction when silent call fails
              await msalInstance.acquireTokenRedirect(request);
            }
          })
          .finally(() =>
            axiosInstance.request({
              ...originalRequest,
              data: JSON.parse(originalRequest.data),
            }),
          );
      } else {
        localStorage.removeItem('token');
        window.location.reload();
      }
      return Promise.reject(error);
    }

    let serverMessage = error?.response?.data?.message;
    let serverDetails = error?.response?.data?.details;
    if (error?.response?.data instanceof Blob) {
      const blob = new Blob([error.response.data]);
      const data = await blob.text();
      const { message: dataMessage, details } = JSON.parse(data);
      serverMessage = dataMessage;
      serverDetails = details;
    }

    let message =
      "Unknown error has occurred. Please contact Sphinx team (Teams channel 'New Technologies').";

    // there is two possibilities: server error message is string or object in form {key1: ['value1'], key2: ['value2'],...}

    if (serverMessage) {
      switch (typeof serverMessage) {
        case 'string':
          message = serverMessage;
          break;
        case 'object':
          message = '';
          Object.keys(serverMessage).forEach((el) => {
            const value = serverMessage[el];
            message += `${el}: ${value[0]}\n`;
          });
          break;
        default:
          break;
      }
    }

    if (!axios.isCancel(error) && error?.response) {
      toast.error(<ErrorToast message={message} />);
    }

    if (serverDetails) {
      // eslint-disable-next-line no-console
      console.error(`API response error serverDetails: ${serverDetails}`);
    }

    return Promise.reject(error);
  },
);

export const useAxios = makeUseAxios({
  axios: axiosInstance,
});
