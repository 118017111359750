import { Checkbox as MuiCheckbox } from '@material-ui/core';
import styled from 'styled-components';
import theme from 'theme';

export const ReviewTitle = styled.div`
  font-size: large;
  border-bottom: 2px solid #ffd242;
  width: fit-content;
  margin-bottom: 16px;
  margin-top: 16px;
`;

export const QuestionsOpinion = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

export const TitleQuestionOpinion = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const QuestionText = styled.div`
  margin-right: 30px;
`;

export const TitleQuestionCheckbox = styled.div`
  display: flex;
  margin-right: 8px;
  justify-content: flex-end;
`;

export const QuestionCheckbox = styled.div`
  display: flex;
  justify-content: flex-end;
`;
export const QuestionWordCheckbox = styled.div`
  margin-right: 18px;
`;

export const QuestionOpinion = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
`;

export const GeneralOpinion = styled.div`
  margin-bottom: 16px;
  width: 60%;
  word-break: break-word;
`;

export const GeneralOpinionContent = styled.div`
  margin-left: 16px;
  font-weight: 300;
  font-size: 16px;
  white-space: pre-line;
`;

export const TextArea = styled.textarea`
  width: 70%;
  min-width: 200px;
  min-height: 300px;
  resize: none;
  font-size: large;
  box-shadow: inset 1px 3px 4px lightgray;
  border: none;

  &:focus {
    outline-color: ${theme.palette.primary.main};
  }
`;

export const RecommendationBox = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
`;

export const RecommendationTitle = styled.div`
  margin-right: 16px;
`;

export const RecommendationSelectContainer = styled.div`
  width: 250px;
`;

export const ReviewSubtitle = styled.div`
  font-size: 18px;
  width: fit-content;
  margin-bottom: 16px;
  margin-top: 16px;
`;

export const Checkbox = styled(MuiCheckbox)<{ $editable: boolean }>`
  &.MuiButtonBase-root {
    cursor: ${({ $editable }) => ($editable ? 'pointer' : 'default')};
  }
`;
