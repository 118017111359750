import { MouseEvent, useState } from 'react';
import { Popover as MuiPopover } from '@material-ui/core';
import { PopoverProps } from './Popover.types';
import * as S from './Popover.css';

export const Popover = ({
  isOpen,
  onOpen,
  onClose,
  disabled = false,
  children,
}: PopoverProps) => {
  const [optionsAnchor, setOptionsAnchor] = useState<null | HTMLElement>(null);

  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    onOpen();
    setOptionsAnchor(event.currentTarget);
  };

  const handleClose = () => {
    onClose();
    setOptionsAnchor(null);
  };

  return (
    <>
      <S.OptionsIcon disabled={disabled} onClick={handleOpen} />
      <MuiPopover
        open={isOpen}
        anchorEl={optionsAnchor}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <S.PopoverOptions>{children}</S.PopoverOptions>
      </MuiPopover>
    </>
  );
};
