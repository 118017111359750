import styled from 'styled-components';
import theme from 'theme';

export const TemplatePageContainer = styled.div`
  background-color: #ffffff;
  padding: 16px 32px;
  min-height: 100vh;
`;

export const Actionbar = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 30px 0;
`;

export const Header = styled.p`
  font-size: 24px;
  text-decoration: underline ${theme.palette.primary.main};
  text-underline-offset: 4px;
  margin-bottom: 50px;
`;

export const TemplateFormContainer = styled.div`
  display: flex;
  word-break: keep-all;
  padding-bottom: 30px;
  min-height: 100vh;
  background: white;
`;

export const LeftPanel = styled.div`
  border-right: 2px solid rgb(230, 230, 230, 50%);
  flex: 4;
  padding-left: 32px;
  padding-top: 32px;
`;

export const RightPanel = styled.div`
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 16px;
  flex: 1;
  position: sticky;
  align-self: flex-start;
`;
