import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import { TemplateService } from 'api';
import { Button } from 'styles.global';
import Pagination from 'components/Pagination';
import { SearchBar } from 'components/SearchBar';
import { handleException } from 'utils/errorHandlingUtils';
import { getDate } from 'utils/formatters';
import { usePagination, useQueryParams } from 'utils/hooks';
import { TemplateTable } from './components/TemplateTable';
import * as S from './TemplatesPage.css';

export const Templates = () => {
  const { paginationOptions, setPaginationOptions } = usePagination();
  const queryParams = useQueryParams();

  const { push } = useHistory();

  const [filtersCount, setFiltersCount] = useState(0);

  const { templatesData, getTemplates, isLoadingTemplatesData } =
    TemplateService.useGetTemplates();

  const templates = useMemo(
    () =>
      templatesData?.map((template) => ({
        ...template,
        createdAt: getDate(template.createdAt),
        updatedAt: getDate(template.updatedAt),
      })),
    [templatesData],
  );

  const fetchTemplates = useCallback(async () => {
    if (paginationOptions.shouldFetch) {
      try {
        const { data } = await getTemplates({
          ...queryParams,
          page: paginationOptions.page,
          perPage: paginationOptions.perPage,
        });
        setPaginationOptions((prevState) => ({
          ...prevState,
          count: data.totalTemplates,
          shouldFetch: false,
        }));
      } catch (e) {
        handleException(e);
      }
    }
  }, [
    getTemplates,
    paginationOptions.page,
    paginationOptions.perPage,
    paginationOptions.shouldFetch,
    queryParams,
    setPaginationOptions,
  ]);

  useEffect(() => {
    setFiltersCount(
      [queryParams.position, queryParams.author, queryParams.name].filter(Boolean).length,
    );
  }, [queryParams.author, queryParams.name, queryParams.position]);

  useEffect(() => {
    if (paginationOptions.shouldFetch) {
      fetchTemplates();
      window.scrollTo(0, 0);
    }
  }, [fetchTemplates, paginationOptions.shouldFetch]);

  const handleSearchChange = (search: string) => {
    delete queryParams.page;
    if (!search) {
      delete queryParams.search;
    }
    push({
      pathname: '/templates',
      search: `${new URLSearchParams({
        ...queryParams,
        ...(search && { search }),
      })}`,
    });
  };

  return (
    <S.TemplatePageContainer>
      <S.Actionbar>
        <SearchBar defaultValue={queryParams.search} onChange={handleSearchChange} />
        <Button
          $primary
          onClick={() => push('/add-template')}
          startIcon={<ControlPointIcon />}
        >
          ADD TEMPLATE
        </Button>
      </S.Actionbar>
      <Pagination paginationOptions={paginationOptions} />
      <TemplateTable
        isLoadingTemplatesData={isLoadingTemplatesData}
        templates={templates}
        filtersCount={filtersCount}
        setPaginationOptions={setPaginationOptions}
      />
    </S.TemplatePageContainer>
  );
};
