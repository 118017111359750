import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import { TemplateService } from 'api';
import EmptyList from 'assets/empty-list.svg';
import { Template } from 'types/template';
import Container from 'components/Container';
import DeleteDialog from 'components/DeleteDialog';
import { TemplateFilters } from 'components/Filters/TemplateFilters';
import { Popover } from 'components/Popover';
import { handleException } from 'utils/errorHandlingUtils';
import { useQueryParams } from 'utils/hooks';
import { TemplateTableProps } from './TemplateTable.types';
import * as S from './TemplateTable.css';
import { FiltersCount } from 'pages/TodoRecruitment/components/RecruitmentTable/RecruitmentTable.css';

export const TemplateTable = ({
  filtersCount,
  templates,
  isLoadingTemplatesData,
  setPaginationOptions,
}: TemplateTableProps) => {
  const queryParams = useQueryParams();

  const { push } = useHistory();

  const [currentId, setCurrentId] = useState<null | number>(null);
  const [isDialogOpen, setDialogOpen] = useState(false);

  const [templateToRemove, setTemplateToRemove] = useState<Template>();
  const { deleteTemplate } = TemplateService.useDeleteTemplate();

  const handleDialogClose = () => {
    setDialogOpen(false);
    setCurrentId(null);
  };

  const handleDialogOpen = (template: Template) => {
    setTemplateToRemove(template);
    setDialogOpen(true);
  };

  const handleDeleteTemplate = async () => {
    try {
      await deleteTemplate(templateToRemove?.id);
      delete queryParams.page;
      push({
        pathname: '/templates',
        search: `${new URLSearchParams({
          ...queryParams,
        })}`,
      });
      setPaginationOptions((prevState) => ({ ...prevState, shouldFetch: true }));
      setDialogOpen(false);
    } catch (e) {
      handleException(e);
    }
  };

  const handlePopoverOpen = (id: number) => {
    setCurrentId(id);
  };

  const handlePopoverClose = () => {
    setCurrentId(null);
  };

  return (
    <Container isLoading={isLoadingTemplatesData}>
      <S.Table>
        <S.TableHead>
          <TableRow>
            <S.BoldTableCell>NAME</S.BoldTableCell>
            <S.BoldTableCell>POSITION</S.BoldTableCell>
            <S.BoldTableCell>ADDED DATE</S.BoldTableCell>
            <S.BoldTableCell>LAST UPDATED</S.BoldTableCell>
            <S.BoldTableCell>AUTHOR</S.BoldTableCell>
            <TableCell align="right">
              <Box display="flex" justifyContent="flex-end">
                <TemplateFilters redirectTo="/templates" />
                {filtersCount > 0 && <FiltersCount label={filtersCount} size="small" />}
              </Box>
            </TableCell>
          </TableRow>
        </S.TableHead>
        <TableBody>
          {templates.map((template) => (
            <TableRow key={template.id}>
              <S.TableCell $cellWidth="25%">
                <Box display="flex">
                  <div>{template.private && <S.LockIcon />}</div>
                  <div>{template.name}</div>
                </Box>
              </S.TableCell>
              <S.TableCell $cellWidth="20%"> {template.position}</S.TableCell>
              <S.TableCell $cellWidth="15%">{template.createdAt}</S.TableCell>
              <S.TableCell $cellWidth="15%">{template.updatedAt}</S.TableCell>
              <S.TableCell $cellWidth="10%"> {template.createdBy}</S.TableCell>
              <TableCell align="right">
                <S.ViewButton
                  variant="contained"
                  size="small"
                  onClick={() => push(`/templates/${template.id}`)}
                >
                  View
                </S.ViewButton>
                <Popover
                  isOpen={currentId === template.id}
                  onClose={handlePopoverClose}
                  onOpen={() => handlePopoverOpen(template.id)}
                >
                  <S.PopoverOptions>
                    <S.PopoverOption
                      onClick={() => push(`/edit-template/${template.id}`)}
                    >
                      EDIT TEMPLATE
                    </S.PopoverOption>
                    <S.PopoverOption onClick={() => handleDialogOpen(template)} danger>
                      DELETE TEMPLATE
                    </S.PopoverOption>
                  </S.PopoverOptions>
                </Popover>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        {isDialogOpen && (
          <DeleteDialog
            open={isDialogOpen}
            onClose={handleDialogClose}
            handleClick={handleDeleteTemplate}
            title="DELETE TEMPLATE"
            content={
              <>
                You&apos;re trying to delete template{' '}
                <S.BoldText>{templateToRemove?.name}.</S.BoldText>
              </>
            }
          />
        )}
      </S.Table>
      {!templates.length && (
        <S.NotFoundContainer>
          <Typography variant="h3">No templates found</Typography>
          <S.EmptyListImage src={EmptyList} alt="empty-list" />
        </S.NotFoundContainer>
      )}
    </Container>
  );
};
