import { Draggable, Droppable } from 'react-beautiful-dnd';
import { AccordionSummary, Box } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Brain from 'assets/brain.svg';
import Hammer from 'assets/hammer.svg';
import { Question } from '../Question';
import { StartRecruitmentModuleProps } from './StartRecruitmentModule.types';
import * as S from './StartRecruitmentModule.css';

export const StartRecruitmentModule = ({
  module,
  onRedrawAll,
  onReloadQuestion,
  onDeleteQuestion,
  expanded,
  setCurrentModule,
  questions,
}: StartRecruitmentModuleProps) => {
  const numberOfQuestions = module.questions.filter(
    ({ question }) => !question.isPractical,
  ).length;

  const numberOfTasks = module.questions.filter(
    ({ question }) => question.isPractical,
  ).length;

  const renderRedrawAll =
    expanded && module.questions.filter(({ refreshable }) => refreshable).length > 0;

  const draggableQuestions = questions.map(({ question, refreshable }, index) => (
    <Draggable key={question.id} draggableId={String(question.id)} index={index}>
      {(providedDraggable) => (
        <div
          ref={providedDraggable.innerRef}
          {...providedDraggable.draggableProps}
          {...providedDraggable.dragHandleProps}
        >
          <Question
            question={question}
            refreshable={refreshable}
            key={question.id}
            reloadQuestion={onReloadQuestion}
            moduleId={module.id}
            currentQuestionsIds={questions.map((q) => q.question.id)}
            deleteQuestion={onDeleteQuestion}
          />
        </div>
      )}
    </Draggable>
  ));

  return (
    <S.ModuleAccordion
      key={module.id}
      expanded={expanded}
      onChange={() => setCurrentModule(expanded ? undefined : module.id)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <S.ModuleTitle>{module.name}</S.ModuleTitle>
        <S.Icons>
          <S.Icon src={Brain} alt="brain" />
          <S.IconInfo>{numberOfQuestions} questions</S.IconInfo>
          <S.Icon src={Hammer} alt="hammer" />
          <S.IconInfo>{numberOfTasks} tasks</S.IconInfo>
          {renderRedrawAll && (
            <S.RedrawAll onClick={(event) => onRedrawAll(event, module)}>
              redraw all
              <S.Refresh />
            </S.RedrawAll>
          )}
        </S.Icons>
      </AccordionSummary>
      <S.ModuleAccordionDetails>
        <Box mb={3}>{module.description}</Box>
        {expanded && (
          <Droppable
            droppableId="startRecruitmentQuestions"
            direction="vertical"
            type={`startQuestions ${module.id}`}
          >
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {draggableQuestions}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        )}
      </S.ModuleAccordionDetails>
    </S.ModuleAccordion>
  );
};
