import { createStyles, IconButton, makeStyles } from '@material-ui/core';
import styled from 'styled-components';
import { Button } from 'styles.global';
import theme from 'theme';

export const popperStyles = makeStyles(() =>
  createStyles({
    root: {
      '& .MuiAutocomplete-listbox': {
        whiteSpace: 'pre-wrap',
      },
    },
  }),
);

export const AddQuestionContainer = styled.div`
  margin-top: 12px;
`;

export const AddQuestionInputWrapper = styled.div`
  width: 700px;
`;

export const AddQuestionLabel = styled.div`
  display: flex;
  align-items: center;
  width: max-content;
  padding-right: 8px;

  &:hover {
    cursor: pointer;
  }
`;

export const SubmitButton = styled(Button).attrs({ $primary: true })`
  &.MuiButton-root {
    width: 200px;
    height: 100%;
    margin-left: 4px;
  }
`;

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const ActionButton = styled(IconButton)`
  &.MuiButtonBase-root {
    padding: 10px;
    color: ${theme.palette.secondary.dark};
  }
`;
