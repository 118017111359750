import { useState } from 'react';
import { ModuleForm } from 'pages/Questions/components/ModuleForm';
import { QuestionForm } from 'pages/Questions/components/QuestionForm';
import { Button } from 'styles.global';
import { ButtonsProps, Dialogs } from './QuestionButtons.types';
import * as S from './QuestionButtons.css';

export const QuestionButtons = ({
  moduleNames,
  addModule,
  addQuestion,
}: ButtonsProps) => {
  const [openDialogs, setOpenDialogs] = useState<Dialogs>({
    questionDialog: false,
    moduleDialog: false,
  });

  function questionDialog(isOpen: boolean) {
    setOpenDialogs({ ...openDialogs, questionDialog: isOpen });
  }

  function moduleDialog(isOpen: boolean) {
    setOpenDialogs({ ...openDialogs, moduleDialog: isOpen });
  }

  return (
    <S.RightButtons>
      <Button
        $secondary
        onClick={() => moduleDialog(true)}
        startIcon={<S.ButtonIcon $isModule />}
      >
        ADD MODULE
      </Button>
      <Button $primary onClick={() => questionDialog(true)} startIcon={<S.ButtonIcon />}>
        ADD QUESTION
      </Button>
      {openDialogs.moduleDialog && (
        <ModuleForm
          open={openDialogs.moduleDialog}
          moduleAction={addModule}
          onClose={() => moduleDialog(false)}
        />
      )}
      {openDialogs.questionDialog && (
        <QuestionForm
          open={openDialogs.questionDialog}
          addQuestion={addQuestion}
          moduleNames={moduleNames}
          onClose={() => questionDialog(false)}
        />
      )}
    </S.RightButtons>
  );
};
