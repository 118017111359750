import { useState } from 'react';
import { Box } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import BrainPNG from 'assets/brain.svg';
import HammerPNG from 'assets/hammer.svg';
import { QuestionCriteria } from 'pages/Questions/components/QuestionCriteria/QuestionCriteria';
import DeleteDialog from 'components/DeleteDialog';
import { QuestionProps } from './Question.types';
import * as S from './Question.css';

export const Question = ({
  question,
  reloadQuestion,
  moduleId,
  currentQuestionsIds,
  refreshable,
  deleteQuestion,
}: QuestionProps) => {
  const [isDeleteDialogOpen, setOpenDeleteDialog] = useState(false);

  const handleDelete = () => {
    deleteQuestion(question, moduleId);
    setOpenDeleteDialog(false);
  };

  const isAccordionDisabled = !question.criteria || !question.criteria.length;

  return (
    <S.Main>
      <S.Accordion disabled={isAccordionDisabled} style={{ width: '80%' }}>
        <S.Summary expandIcon={isAccordionDisabled ? null : <ExpandMoreIcon />}>
          <S.Inner>
            <S.Icon src={question.isPractical ? HammerPNG : BrainPNG} alt="icon" />
            <S.QuestionText expanded={false}>{question.text}</S.QuestionText>
          </S.Inner>
        </S.Summary>
        <S.AccordionDetails>
          <QuestionCriteria criteria={question.criteria} />
        </S.AccordionDetails>
      </S.Accordion>
      <Box display="flex">
        <S.RefreshIcon
          onClick={() =>
            refreshable && reloadQuestion(question.id, moduleId, currentQuestionsIds)
          }
          $refreshable={refreshable}
        />
        <S.DeleteIcon onClick={() => setOpenDeleteDialog(true)} />
      </Box>

      {isDeleteDialogOpen && (
        <DeleteDialog
          open={isDeleteDialogOpen}
          onClose={() => setOpenDeleteDialog(false)}
          handleClick={handleDelete}
          title="DELETE QUESTION"
          content="You're trying to delete question"
        />
      )}
    </S.Main>
  );
};
