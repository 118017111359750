import { useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { RecruitmentService } from 'api';
import { RecruitmentState } from 'types/recruitment';
import Container from 'components/Container';
import ErrorToast from 'components/Toasts/ErrorToast';
import { handleException } from 'utils/errorHandlingUtils';
import { RouteParams } from 'utils/types/RouteParams';
import { LeftPanel, RightPanel } from './components';
import * as S from './AdjustRecruitment.css';

const AdjustRecruitment = () => {
  const urlParams = useParams<RouteParams>();
  const { push } = useHistory();
  const { recruitmentData, getRecruitment } = RecruitmentService.useGetRecruitment();

  const fetchRecruitment = useCallback(async () => {
    try {
      const {
        data: { data },
      } = await getRecruitment(+urlParams.id);
      if (!data?.recruitmentDate) {
        toast.error(
          <ErrorToast
            message={`Please fill all required data in recruitment with id ${data?.id} before adjusting`}
          />,
        );
        push('/todo');
      } else if (
        data &&
        ![RecruitmentState.Formed, RecruitmentState.Adjusted].includes(
          data.recruitmentState,
        )
      ) {
        toast.error(
          <ErrorToast message="Edition of this recruitment is not available." />,
        );
        push('/todo');
      }
    } catch (e) {
      handleException(e);
    }
  }, [getRecruitment, push, urlParams]);

  useEffect(() => {
    fetchRecruitment();
  }, [fetchRecruitment]);

  return (
    <Container isLoading={!recruitmentData}>
      {recruitmentData && (
        <S.AdjustContainer>
          <S.LeftPanel>
            <LeftPanel recruitment={recruitmentData} />
          </S.LeftPanel>
          <S.AdjustRecruitmentRightPanelContainer>
            <RightPanel
              id={recruitmentData.id}
              candidateName={recruitmentData.candidateName}
              positionName={recruitmentData.position?.positionName}
              seniorityLevel={recruitmentData.seniorityLevel}
              recruitmentDate={recruitmentData.recruitmentDate.split('T').join(' ')}
              hr={recruitmentData.hr}
              recruiter={recruitmentData.technicalRecruiter}
              cvFile={recruitmentData.cvFile}
            />
          </S.AdjustRecruitmentRightPanelContainer>
        </S.AdjustContainer>
      )}
    </Container>
  );
};

export default AdjustRecruitment;
