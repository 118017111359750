import { memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { RecruitmentService, UserService } from 'api';
import EmptyList from 'assets/empty-list.svg';
import { CancelRecruitmentDialog } from 'pages/TodoRecruitment/components/CancelRecruitmentDialog';
import { Base, SortOrder } from 'types/common';
import { RecruitmentState } from 'types/recruitment';
import Container from 'components/Container';
import DeleteDialog from 'components/DeleteDialog';
import { RecruitmentFilters } from 'components/Filters/RecruitmentFilters';
import { handleException } from 'utils/errorHandlingUtils';
import { getDate, getEndedRecruitmentUrl, getTime } from 'utils/formatters';
import { copyToClipboard, displayGrade, exportFile } from 'utils/helpers';
import { getSortOrder } from 'utils/helpers/common';
import { useQueryParams } from 'utils/hooks';
import { GenerateLinkForm } from './components/GenerateLinkForm';
import {
  HandleRedirectProps,
  RecruitmentToCancel,
  TableProps,
} from './EndedRecruitmentTable.types';
import * as S from './EndedRecruitmentTable.css';
import { FiltersCount } from 'pages/TodoRecruitment/components/RecruitmentTable/RecruitmentTable.css';

const EndedRecruitmentTable = ({
  recruitments,
  filtersCount,
  setPaginationOptions,
  isLoadingRecruitmentsData,
}: TableProps) => {
  const [isGenerateLinkDialogOpen, setGenerateLinkDialogOpen] = useState(false);
  const [generatingRecruitmentId, setGeneratingRecruitmentId] = useState<number>();
  const [downloadingRecruitmentId, setDownloadingRecruitmentId] = useState<number>();
  const [downloadingHashId, setDownloadingHashId] = useState<string>();
  const [recruitmentToCancel, setRecruitmentToCancel] = useState<RecruitmentToCancel>();
  const [isCancelDialogOpen, setCancelDialogOpen] = useState(false);

  const { push } = useHistory();
  const queryParams = useQueryParams();

  const { getDocxDocument, isLoadingDocxDownload } =
    RecruitmentService.useGetDocxDocument();
  const { getSummaryDocxDocument, isLoadingSummaryDocxDownload } =
    RecruitmentService.useGetSummaryDocxDocument();

  const { technicalRecruiters, getTechnicalRecruiters } =
    UserService.useGetTechnicalRecruiters();
  const { hrs, getHrs } = UserService.useGetHrs();
  const { positionsData, getPositionsData } = RecruitmentService.useGetPositions();

  const deleteLink = RecruitmentService.useDeleteLink();

  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [linkToDelete, setLinkToDelete] = useState<Base>({ id: 0, name: '' });

  useEffect(() => {
    getHrs();
    getTechnicalRecruiters();
    getPositionsData();
  }, [getHrs, getTechnicalRecruiters, getPositionsData]);

  const handleRedirect = ({
    id,
    recruitmentDate,
    candidateName,
    editMode = false,
  }: HandleRedirectProps) => {
    push(getEndedRecruitmentUrl(id, recruitmentDate, candidateName, editMode));
  };

  const generatedLink = ({
    id,
    recruitmentDate,
    candidateName,
    editMode = false,
  }: HandleRedirectProps) => {
    const date = `${getDate(recruitmentDate)}_${getTime(recruitmentDate)}`;
    const name = candidateName.split(' ').join('_');
    if (editMode) {
      return `/editable-generated-ended-recruitment/${id}/${name}/${date}`;
    }
    return `/generated-ended-recruitment/${id}/${name}/${date}`;
  };

  const handleDownloadDocument = async (id: number) => {
    try {
      setDownloadingRecruitmentId(id);
      const response = await getDocxDocument(id);
      exportFile(response);
    } catch (e) {
      handleException(e);
    }
  };

  const handleDownloadSummaryDocument = async (hashId: string) => {
    try {
      setDownloadingHashId(hashId);
      const response = await getSummaryDocxDocument(hashId);
      exportFile(response);
    } catch (e) {
      handleException(e);
    }
  };

  const handleGenerateLink = (recruitmentId: number) => {
    setGeneratingRecruitmentId(recruitmentId);
    setGenerateLinkDialogOpen(true);
  };

  const handleSortChange = () => {
    push({
      pathname: '/ended-recruitment',
      search: `${new URLSearchParams({
        ...queryParams,
        order: getSortOrder(queryParams.order, SortOrder.Asc),
      })}`,
    });
  };

  const getArrowButton = () => {
    if (!queryParams.order) return <S.ArrowDown onClick={handleSortChange} />;
    return queryParams.order === SortOrder.Asc ? (
      <S.ArrowUp onClick={handleSortChange} />
    ) : (
      <S.ArrowDown onClick={handleSortChange} />
    );
  };

  const handleOpenDialog = (name: string, id: number) => {
    setLinkToDelete({ name, id });
    setDeleteDialogOpen(true);
  };

  const handleLinkDelete = async () => {
    try {
      await deleteLink(linkToDelete.id);
      setPaginationOptions((prevState) => ({ ...prevState, shouldFetch: true }));
      setDeleteDialogOpen(false);
    } catch (e) {
      handleException(e);
    }
  };

  const handleOpenCancelRecruitmentDialog = (recruitment: RecruitmentToCancel) => {
    setRecruitmentToCancel(recruitment);
    setCancelDialogOpen(true);
  };

  const DownloadButton = memo(({ id }: { id: number }) => (
    <div>
      {isLoadingDocxDownload && downloadingRecruitmentId === id ? (
        <S.CircularProgress size="16px" />
      ) : (
        <S.DocumentIcon onClick={() => handleDownloadDocument(id)} />
      )}
    </div>
  ));

  const SecondButton = memo(
    ({
      id,
      candidateName,
      recruitmentDate,
      recruitmentState,
    }: {
      id: number;
      candidateName: string;
      recruitmentDate: string;
      recruitmentState: RecruitmentState;
    }) => (
      <div>
        {recruitmentState !== RecruitmentState.Completed ? (
          <S.EditIcon
            onClick={() =>
              handleRedirect({
                id,
                candidateName,
                recruitmentDate,
                editMode: true,
              })
            }
          />
        ) : (
          <S.LinkIcon onClick={() => handleGenerateLink(id)} />
        )}
      </div>
    ),
  );

  return (
    <Container isLoading={isLoadingRecruitmentsData}>
      <S.Table>
        <S.TableHead>
          <TableRow>
            <S.BoldTableCell>NAME</S.BoldTableCell>
            <S.BoldTableCell>POSITION</S.BoldTableCell>
            <S.BoldTableCell>LEVEL</S.BoldTableCell>
            <S.BoldTableCell>SCORE</S.BoldTableCell>
            <S.BoldTableCell>DATE</S.BoldTableCell>
            <S.BoldTableCell>RECRUITER</S.BoldTableCell>
            <S.BoldTableCell>REMUNERATED TECH RECRUITER</S.BoldTableCell>
            <S.BoldTableCell>HR CONTACT</S.BoldTableCell>
            <TableCell align="right">
              <Box display="flex" justifyContent="flex-end">
                <Box mr={4} mt={-0.5}>
                  {getArrowButton()}
                </Box>
                <RecruitmentFilters
                  redirectTo="/ended-recruitment"
                  hideSlider={false}
                  hrs={hrs}
                  technicalRecruiters={technicalRecruiters}
                  positions={positionsData}
                />
                {filtersCount > 0 && <FiltersCount label={filtersCount} size="small" />}
              </Box>
            </TableCell>
          </TableRow>
        </S.TableHead>
        <TableBody>
          {recruitments.map(
            ({
              id,
              candidateName,
              position,
              seniorityLevel,
              grade,
              maxGrade,
              recruitmentDate,
              technicalRecruiter,
              remuneratedTechRecruiter,
              hr,
              recruitmentState,
              autogeneratedLinks,
              recruitmentCancellation,
            }) => (
              <>
                <TableRow key={id}>
                  <S.TableCell $cellWidth="20%">{candidateName}</S.TableCell>
                  <S.TableCell $cellWidth="14%">{position.positionName}</S.TableCell>
                  <S.TableCell $cellWidth="9%">{seniorityLevel}</S.TableCell>
                  <S.BoldTableCell $cellWidth="10%">
                    <div>{displayGrade(grade, maxGrade)}</div>
                  </S.BoldTableCell>
                  <S.TableCell $cellWidth="12%">{getDate(recruitmentDate)}</S.TableCell>
                  <S.TableCell $cellWidth="12%">{`${technicalRecruiter?.name} ${technicalRecruiter?.surname}`}</S.TableCell>
                  <S.TableCell $cellWidth="12%">
                    {remuneratedTechRecruiter
                      ? `${remuneratedTechRecruiter?.name} ${remuneratedTechRecruiter?.surname}`
                      : ''}
                  </S.TableCell>
                  <S.TableCell $cellWidth="12%">{`${hr?.name} ${hr?.surname}`}</S.TableCell>
                  <TableCell align="right">
                    {recruitmentState !== RecruitmentState.Cancelled ? (
                      <Box display="flex" justifyContent="flex-end">
                        {recruitmentState === RecruitmentState.Completed && (
                          <DownloadButton id={id} />
                        )}
                        <SecondButton
                          id={id}
                          candidateName={candidateName}
                          recruitmentDate={recruitmentDate}
                          recruitmentState={recruitmentState}
                        />
                        <S.ViewIcon
                          onClick={() =>
                            handleRedirect({
                              id,
                              candidateName,
                              recruitmentDate,
                            })
                          }
                        />
                      </Box>
                    ) : (
                      <Box
                        display="flex"
                        justifyContent="flex-end"
                        alignItems="center"
                        width="160px"
                      >
                        <Box mr="10px">Cancelled</Box>
                        <Tooltip title={recruitmentCancellation.reason}>
                          <S.InfoIcon />
                        </Tooltip>
                        <S.EditReasonIcon
                          onClick={() =>
                            handleOpenCancelRecruitmentDialog({
                              id,
                              name: candidateName,
                              reason: recruitmentCancellation.reason,
                              canceler: recruitmentCancellation.canceler,
                            })
                          }
                        />
                      </Box>
                    )}
                  </TableCell>
                </TableRow>
                {autogeneratedLinks.map(({ hashId, name, createdAt, id: linkId }) => (
                  <S.GeneratedTableRow key={hashId}>
                    <S.TableCell $cellWidth="20%">
                      <S.GeneratedLinkTitleContainer>
                        <S.ArrowIcon />
                        <S.ListLinkIcon />
                        <S.GeneratedLinkTitle>{name}</S.GeneratedLinkTitle>
                      </S.GeneratedLinkTitleContainer>
                    </S.TableCell>
                    <S.TableCell $cellWidth="14%" colSpan={2} $noWrap>
                      <Tooltip
                        enterDelay={200}
                        enterNextDelay={200}
                        title={`${window.location.origin}${generatedLink({
                          id: hashId,
                          recruitmentDate,
                          candidateName,
                        })}`}
                        placement="top"
                      >
                        <span>{`${window.location.origin}${generatedLink({
                          id: hashId,
                          recruitmentDate,
                          candidateName,
                        })}`}</span>
                      </Tooltip>
                    </S.TableCell>
                    <S.TableCell $cellWidth="10%" align="center">
                      <Tooltip
                        enterDelay={200}
                        enterNextDelay={200}
                        title="Copy link"
                        placement="top"
                      >
                        <S.CopyIcon
                          onClick={() =>
                            copyToClipboard(
                              `${window.location.origin}${generatedLink({
                                id: hashId,
                                recruitmentDate,
                                candidateName,
                              })}`,
                            )
                          }
                        />
                      </Tooltip>
                    </S.TableCell>
                    <S.TableCell $cellWidth="12%">{getDate(createdAt)}</S.TableCell>
                    <S.TableCell $cellWidth="12%">{`${technicalRecruiter?.name} ${technicalRecruiter?.surname}`}</S.TableCell>
                    <S.TableCell $cellWidth="12%">
                      {remuneratedTechRecruiter &&
                        `${remuneratedTechRecruiter.name} ${remuneratedTechRecruiter.surname}`}
                    </S.TableCell>
                    <S.TableCell $cellWidth="12%">{`${hr?.name} ${hr?.surname}`}</S.TableCell>
                    <TableCell align="right">
                      <Box display="flex" justifyContent="flex-end" alignItems="center">
                        {isLoadingSummaryDocxDownload && downloadingHashId === hashId ? (
                          <Box width="42px">
                            <S.CircularProgress size="18px" />
                          </Box>
                        ) : (
                          <S.DocumentIcon
                            $outlined
                            onClick={() => handleDownloadSummaryDocument(hashId)}
                          />
                        )}
                        <S.GeneratedEditIcon
                          onClick={() =>
                            push(
                              generatedLink({
                                editMode: true,
                                id: hashId,
                                recruitmentDate,
                                candidateName,
                              }),
                            )
                          }
                        />
                        <S.ViewIcon
                          $outlined
                          onClick={() =>
                            push(
                              generatedLink({
                                id: hashId,
                                recruitmentDate,
                                candidateName,
                              }),
                            )
                          }
                        />
                        <S.BinIcon onClick={() => handleOpenDialog(name, linkId)} />
                      </Box>
                    </TableCell>
                  </S.GeneratedTableRow>
                ))}
              </>
            ),
          )}
        </TableBody>
      </S.Table>
      {isDeleteDialogOpen && (
        <DeleteDialog
          open={isDeleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
          handleClick={handleLinkDelete}
          title="DELETE LINK"
          content={
            <>
              You&apos;re trying to delete link{' '}
              <S.BoldText>{linkToDelete.name}</S.BoldText>.
            </>
          }
        />
      )}
      {!recruitments.length && (
        <S.NotFoundContainer>
          <Typography variant="h3">No recruitments found</Typography>
          <S.EmptyListImage src={EmptyList} alt="empty-list" />
        </S.NotFoundContainer>
      )}
      {isGenerateLinkDialogOpen && generatingRecruitmentId && (
        <GenerateLinkForm
          setPaginationOptions={setPaginationOptions}
          recruitmentId={generatingRecruitmentId}
          onClose={() => setGenerateLinkDialogOpen(false)}
        />
      )}
      {isCancelDialogOpen && recruitmentToCancel && (
        <CancelRecruitmentDialog
          onClose={() => setCancelDialogOpen(false)}
          id={recruitmentToCancel.id}
          name={recruitmentToCancel.name}
          defaultReason={recruitmentToCancel.reason}
          canceler={recruitmentToCancel.canceler}
          setPaginationOptions={setPaginationOptions}
          edit
        />
      )}
    </Container>
  );
};

export { EndedRecruitmentTable };
