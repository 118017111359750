import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import EmptyList from 'assets/empty-list.svg';
import { RecruitmentForm } from 'pages/TodoRecruitment/components/RecruitmentForm';
import { Button } from 'styles.global';
import { Base, SortOrder } from 'types/common';
import { Recruitment, RecruitmentState } from 'types/recruitment';
import Container from 'components/Container';
import { RecruitmentFilters } from 'components/Filters/RecruitmentFilters';
import { Popover } from 'components/Popover';
import { getDate, getTime } from 'utils/formatters';
import { getSortOrder } from 'utils/helpers/common';
import { useQueryParams } from 'utils/hooks';
import { CancelRecruitmentDialog } from '../CancelRecruitmentDialog';
import { TableProps } from './RecruitmentTable.types';
import * as S from './RecruitmentTable.css';

export const RecruitmentTable = ({
  filtersCount,
  recruitments,
  setPaginationOptions,
  technicalRecruiters,
  isLoadingRecruitmentsData,
  hrs,
  positions,
  getPositionsData,
}: TableProps) => {
  const [currentOptionsId, setCurrentOptionsId] = useState<number | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const [currentRecruitment, setCurrentRecruitment] = useState<Recruitment | null>(null);
  const [isCancelDialogOpen, setCancelDialogOpen] = useState(false);
  const [recruitmentToCancel, setRecruitmentToCancel] = useState<Base>();

  const { push } = useHistory();

  const queryParams = useQueryParams();

  const handleRecruitmentEdit = (recruitment: Recruitment) => {
    setEditMode(true);
    setCurrentRecruitment(recruitment);
    handlePopoverClose();
    setIsDialogOpen(true);
  };

  const handleOpenCancelRecruitmentDialog = (recruitment: Base) => {
    setRecruitmentToCancel(recruitment);
    setCancelDialogOpen(true);
  };

  const handlePopoverOpen = (id: number) => {
    setCurrentOptionsId(id);
  };

  const handlePopoverClose = () => {
    setCurrentOptionsId(null);
  };

  const handleDialogClose = () => {
    setCancelDialogOpen(false);
    handlePopoverClose();
  };

  const handleSortChange = () => {
    push({
      pathname: '/todo',
      search: `${new URLSearchParams({
        ...queryParams,
        order: getSortOrder(queryParams.order, SortOrder.Desc),
      })}`,
    });
  };

  const getArrowButton = () => {
    if (!queryParams.order) {
      return <S.ArrowUp onClick={handleSortChange} />;
    }

    return queryParams.order === SortOrder.Asc ? (
      <S.ArrowUp onClick={handleSortChange} />
    ) : (
      <S.ArrowDown onClick={handleSortChange} />
    );
  };

  function getButton(recruitment: Recruitment) {
    switch (recruitment.recruitmentState) {
      case RecruitmentState.Formed:
        if (!recruitment.recruitmentDate) {
          return (
            <Button $secondary onClick={() => handleRecruitmentEdit(recruitment)}>
              Edit
            </Button>
          );
        }
        return (
          <S.AdjustButton onClick={() => push(`/adjust-recruitment/${recruitment.id}`)}>
            Adjust
          </S.AdjustButton>
        );
      case RecruitmentState.Adjusted:
        return (
          <S.StartButton onClick={() => push(`/recruitment/${recruitment.id}`)}>
            Start
          </S.StartButton>
        );
      case RecruitmentState.InProgress:
        return (
          <S.InProgressButton onClick={() => push(`/recruitment/${recruitment.id}`)}>
            In Progress
          </S.InProgressButton>
        );
      default:
        return null;
    }
  }

  return (
    <Container isLoading={isLoadingRecruitmentsData}>
      <S.Table>
        <S.TableHead>
          <TableRow>
            <S.BoldTableCell>NAME</S.BoldTableCell>
            <S.BoldTableCell>POSITION</S.BoldTableCell>
            <S.BoldTableCell>LEVEL</S.BoldTableCell>
            <S.BoldTableCell>DATE</S.BoldTableCell>
            <S.BoldTableCell>TIME</S.BoldTableCell>
            <S.BoldTableCell>RECRUITER</S.BoldTableCell>
            <S.BoldTableCell>REMUNERATED TECH RECRUITER</S.BoldTableCell>
            <S.BoldTableCell>HR CONTACT</S.BoldTableCell>
            <TableCell align="right">{getArrowButton()}</TableCell>
            <TableCell align="right">
              <Box display="flex" justifyContent="flex-end">
                <RecruitmentFilters
                  redirectTo="/todo"
                  hrs={hrs}
                  technicalRecruiters={technicalRecruiters}
                  positions={positions}
                />
                {filtersCount > 0 && <S.FiltersCount label={filtersCount} size="small" />}
              </Box>
            </TableCell>
          </TableRow>
        </S.TableHead>
        <TableBody>
          {recruitments.length
            ? recruitments.map((recruitment) => (
                <TableRow key={recruitment.id}>
                  <S.TableCell $cellWidth="16%">{recruitment.candidateName}</S.TableCell>
                  <S.TableCell $cellWidth="16%">
                    {recruitment.position.positionName}
                  </S.TableCell>
                  <S.TableCell $cellWidth="10%">{recruitment.seniorityLevel}</S.TableCell>
                  <S.TableCell $cellWidth="10%">
                    {recruitment.recruitmentDate
                      ? getDate(recruitment.recruitmentDate)
                      : ''}
                  </S.TableCell>
                  <S.TableCell $cellWidth="6%">
                    {recruitment.recruitmentDate
                      ? getTime(recruitment.recruitmentDate)
                      : ''}
                  </S.TableCell>
                  <S.TableCell $cellWidth="14%">
                    {recruitment.technicalRecruiter
                      ? `${recruitment.technicalRecruiter?.name} ${recruitment.technicalRecruiter?.surname}`
                      : ''}
                  </S.TableCell>
                  <S.TableCell $cellWidth="14%">
                    {recruitment.remuneratedTechRecruiter
                      ? `${recruitment.remuneratedTechRecruiter?.name} ${recruitment.remuneratedTechRecruiter?.surname}`
                      : ''}
                  </S.TableCell>
                  <S.TableCell $cellWidth="14%">
                    {recruitment.hr
                      ? `${recruitment.hr?.name} ${recruitment.hr?.surname}`
                      : ''}
                  </S.TableCell>
                  <S.CustomizedTableCell align="right">
                    {getButton(recruitment)}
                  </S.CustomizedTableCell>
                  <TableCell align="left">
                    <Popover
                      isOpen={currentOptionsId === recruitment.id}
                      onOpen={() => handlePopoverOpen(recruitment.id)}
                      onClose={handlePopoverClose}
                    >
                      <S.PopoverOptions>
                        {[RecruitmentState.Adjusted, RecruitmentState.Formed].includes(
                          recruitment.recruitmentState,
                        ) &&
                          !!recruitment.recruitmentDate && (
                            <S.PopoverOption
                              onClick={() => {
                                handleRecruitmentEdit(recruitment);
                              }}
                            >
                              EDIT RECRUITMENT
                            </S.PopoverOption>
                          )}
                        {recruitment.recruitmentState === RecruitmentState.Adjusted &&
                          !!recruitment.recruitmentDate && (
                            <S.PopoverOption
                              onClick={() =>
                                push(`/adjust-recruitment/${recruitment.id}`)
                              }
                            >
                              EDIT QUESTIONS
                            </S.PopoverOption>
                          )}
                        {!!recruitment.recruitmentOfferLink && (
                          <S.PopoverOption
                            onClick={() =>
                              window.open(
                                recruitment.recruitmentOfferLink!,
                                '_blank',
                                'noopener,noreferrer',
                              )
                            }
                          >
                            RECRUITMENT OFFER
                          </S.PopoverOption>
                        )}
                        <S.PopoverOption
                          onClick={() =>
                            handleOpenCancelRecruitmentDialog({
                              id: recruitment.id,
                              name: recruitment.candidateName,
                            })
                          }
                          danger
                        >
                          CANCEL RECRUITMENT
                        </S.PopoverOption>
                      </S.PopoverOptions>
                    </Popover>
                  </TableCell>
                </TableRow>
              ))
            : null}
        </TableBody>
      </S.Table>
      {!recruitments.length && (
        <S.NotFoundContainer>
          <Typography variant="h3">No recruitments found</Typography>
          <S.EmptyListImage src={EmptyList} alt="empty-list" />
        </S.NotFoundContainer>
      )}

      {isDialogOpen && (
        <RecruitmentForm
          editMode={isEditMode}
          onClose={() => setIsDialogOpen(false)}
          recruitment={isEditMode && currentRecruitment ? currentRecruitment : undefined}
          setPaginationOptions={setPaginationOptions}
          hrs={hrs}
          technicalRecruiters={technicalRecruiters}
          positions={positions}
          getPositionsData={getPositionsData}
        />
      )}
      {isCancelDialogOpen && recruitmentToCancel && (
        <CancelRecruitmentDialog
          onClose={handleDialogClose}
          id={recruitmentToCancel.id}
          name={recruitmentToCancel.name}
          setPaginationOptions={setPaginationOptions}
        />
      )}
    </Container>
  );
};
